import axios from 'axios'

import { ROUTES } from 'settings/settings'
import { LogService } from 'services/Log/Log'
import { SearchConditions, SnippetsFilter } from 'models/models'
import { MONITOR_SNIPPET_MODE_VALYE_BY_LABEL, MONITOR_SNIPPET_SORT_VALUE_BY_LABEL } from 'settings/settings'

interface Props {
  startDate?: string
  endDate?: string
  conditions: SearchConditions | SearchConditions[]
  snippetsFilter?: SnippetsFilter
}

export async function getAttentionFlags({ startDate, endDate, conditions, snippetsFilter }: Props) {
  try {
    const date = startDate?.length && endDate?.length ? `start_date:gte:${startDate},end_date:lte:${endDate}` : ''
    const platformQuery = snippetsFilter?.filter_platform
      ? `,platform:in:${JSON.stringify(snippetsFilter?.filter_platform)}`.replaceAll('"', '')
      : ''
    const sentimentQuery = snippetsFilter?.filter_sentiment?.length
      ? snippetsFilter?.filter_sentiment
        ? `,sentiment:in:${JSON.stringify(snippetsFilter?.filter_sentiment)}`.replaceAll('"', '')
        : ''
      : ''

    const languageQuery = snippetsFilter?.lang?.length ? `&lang=${snippetsFilter?.lang}` : ''

    // construct the query params string
    const q = `${date}${platformQuery}${sentimentQuery}${languageQuery}`
    const sort = snippetsFilter
      ? `${MONITOR_SNIPPET_SORT_VALUE_BY_LABEL[snippetsFilter.sort]}:${
          MONITOR_SNIPPET_MODE_VALYE_BY_LABEL[snippetsFilter.sort_mode]
        }`
      : ''
    const queryParams = `q=${q}&sort=${sort}&per_page=1&page=1`

    let conditionsCopy = JSON.parse(JSON.stringify(conditions))
    if (snippetsFilter?.filter_keyword && snippetsFilter?.filter_keyword?.length > 0) {
      if (!conditionsCopy) {
        conditionsCopy = []
      } else if (!Array.isArray(conditionsCopy)) {
        conditionsCopy = [conditionsCopy]
      }

      conditionsCopy.push({
        keywords_expression: snippetsFilter?.filter_keyword,
      })
    }

    // send the actual HTTP request
    const url = process.env.REACT_APP_SEARCH_API + `${ROUTES.executiveFlags}?${queryParams}`
    const { data } = await axios.post(url, { conditions: conditionsCopy })

    return data
  } catch (error) {
    LogService.error({ message: 'getFlags error', error })
    throw error
  }
}
