import dayjs from 'dayjs'
import { memo } from 'react'
import millify from 'millify'
import classNames from 'classnames'
import { Avatar, Skeleton } from 'antd'

import VideoPlayer from './VideoPlayer'
import Carousel from 'components/common/Carousel/Carousel'

import AvatarIcon from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as ViewIcon } from 'assets/images/view-icon.svg'
import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'
import { ReactComponent as LikeTweetIcon } from 'assets/images/twitter-like-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/snippet_warning_icon.svg'
import { ReactComponent as CommentIcon } from 'assets/images/twitter-comment-icon.svg'
import { ReactComponent as NormalSentiment } from 'assets/images/sentiment-normal-icon.svg'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

import { ISnippet } from 'models/models'
import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'
import InfoTooltip from '../BrandsDashboard/components/InfoTooltip/InfoTooltip'

const TopPosts = ({ isLoadingFeed, snippets }: { isLoadingFeed: boolean; snippets: ISnippet[] }) => {
  const sentimentIcons = {
    Positive: <PositiveSentiment height={20} width={20} className='snippet-item__sentiment' />,
    Neutral: <NormalSentiment height={20} width={20} className='snippet-item__sentiment' />,
    Negative: <NegativeSentiment height={20} width={20} className='snippet-item__sentiment' />,
  }

  console.log('snippets', snippets)

  return (
    <Carousel
      emptyItem={() => (
        <div className='bd__body__trending-posts--empty-container'>
          {isLoadingFeed && <LoadingState />}
          {!isLoadingFeed && (
            <BrandDataFetching
              emptyStateIcon={<EmptyFile />}
              emptyStateTitle={'No Data to display'}
              // emptyStateDescription={'Please select any another brand '}
            />
          )}
        </div>
      )}
      items={snippets?.slice(0, 10) || []}
      renderItem={(item) => (
        <div className='bd__body__trending-posts--content'>
          <div className={classNames('bd__body__trending-posts--post-card', { loading: isLoadingFeed })}>
            {isLoadingFeed ? (
              <LoadingState />
            ) : (
              <>
                <div className='bd__body__trending-posts__creator'>
                  <div className='bd__body__trending-posts__creator--container'>
                    <div className='bd__body__trending-posts__creator__profile'>
                      <div className='bd__body__trending-posts__creator__profile-avatar'>
                        <Avatar size={20} src={item?.author?.channel_thumb_url || AvatarIcon} />
                      </div>
                      <div className='bd__body__trending-posts__creator__profile-name'>
                        {(item?.author?.channel_name === 'N/A'
                          ? item?.author?.channel_title
                          : item?.author?.channel_name
                          ? item?.author?.channel_name
                          : item?.author?.channel_title) || ''}
                      </div>
                      <div className='bd__body__trending-posts__creator__profile-channel'>
                        {
                          PLATFORM_ICONS_GREY?.find(
                            (platform) =>
                              platform?.title?.toLowerCase() ===
                              (item?.author?.platform || item.sourcePlatform?.toLowerCase()),
                          )?.icon
                        }
                      </div>
                    </div>
                    <div className='bd__body__trending-posts__creator__channel-name'>
                      {item?.author?.channel_title || ''}
                    </div>
                    <div className='bd__body__trending-posts__creator__date-posted'>
                      {dayjs(item?.date).format('MMM DD, YYYY')}
                    </div>
                  </div>
                  <InfoTooltip
                    text='Represents the top posts that mention your brand, ranked by impressions.'
                    width={20}
                    height={20}
                  />
                </div>

                <div
                  className='bd__body__trending-posts__description'
                  dangerouslySetInnerHTML={{ __html: item?.snippet_text || '' }}
                />
                <div className='bd__body__trending-posts__iframe'>
                  <VideoPlayer
                    url={item.playSourceLink}
                    fallbackApiEndpoint={`${process.env.REACT_APP_SEARCH_API}media_info?documentId=${item.documentId}`}
                    onError={(error) => console.error('**********************************', error)}
                  />
                </div>
                <div className='bd__body__trending-posts__stats'>
                  <MetricsDisplay
                    metrics={[
                      { value: millify(item.views || 0), icon: <ViewIcon /> },
                      { value: millify(item.likes || 0), icon: <LikeTweetIcon /> },
                      { value: millify(item.comments || 0), icon: <CommentIcon /> },
                      { value: '20%', icon: <WarningIcon /> },
                      { value: '', icon: sentimentIcons[item.sentiment] },
                    ]}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      showArrows={true}
    />
  )
}

export default memo(TopPosts)

interface MetricItem {
  value: string | number
  icon: React.ReactNode
  label?: string
}

interface MetricsDisplayProps {
  metrics: MetricItem[]
  className?: string
}

const MetricsDisplay: React.FC<MetricsDisplayProps> = ({ metrics, className = '' }) => {
  return (
    <div className={`metrics-display ${className}`}>
      {metrics.map((metric, index) => (
        <div key={index} className='metric-item'>
          <div className='metric-content'>
            {metric.icon}
            <span className='metric-value'>{metric.value}</span>
          </div>
          {metric.label && <span className='metric-label'>{metric.label}</span>}
        </div>
      ))}
    </div>
  )
}

const LoadingState = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      <Skeleton.Input active={true} size='large' style={{ width: '30%', height: 20 }} block />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
          alignItems: 'flex-start',
          width: '100%',
        }}>
        <Skeleton.Input active={true} size='large' block style={{ width: '20%', height: 20 }} />
        <Skeleton.Input active={true} size='large' block style={{ height: 20 }} />
        <Skeleton.Input active={true} size='large' block style={{ height: 20 }} />
        <Skeleton.Input active={true} size='large' block style={{ width: '80%', height: 20 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Skeleton.Image active={true} style={{ height: 300, width: 700 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
        <Skeleton.Input active={true} size='large' style={{ width: '30%', height: 20 }} />
      </div>
    </div>
  )
}
