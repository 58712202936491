import dayjs from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { API, defineNarrativeAPI, watchlistAPIs } from 'api/api'
import { ICommunity, ICommunityDropdown, INarrativeDropdown, IUniqueContent, WatchlistChannel } from 'models/models'
import { MainStore } from '../main/main.store'
import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'utils/helper'

type Channel = {
  platform: string
  channel_urls: string[]
}

interface WatchlistProps {
  name: string
  description: string
  channels: Channel[]
}

export class DefineWatchlistStore extends MainStore {
  watchlistData: WatchlistProps = {
    name: '',
    description: '',
    channels: [],
  }
  formError: string = ''
  isLoadingNarrative: boolean = false
  isWatchlistSuccessModalOpen: string = ''
  modalData: 'notify' | 'workLater' | 'loseChanges' | undefined = undefined
  formData: any = {}
  narrativeDetailsData: any = {}
  booleanSearchWord: string | null = null
  twitterMode: boolean = false

  matchingResults: number = 0
  showResults: boolean = false

  narrativeFilterData: INarrativeDropdown[] = []
  communityFilterData: ICommunityDropdown[] = []

  tenant_id: string = ''

  contentLoader: boolean = false

  userSearched: boolean = false

  matchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' = 'noSearch'
  textualMatchingResultsState: 'noSearch' | 'normal' | 'noResultsBack' | 'tooMany' | 'loading' = 'noSearch'

  keywords: string[] = []

  listFilter: {
    filter: 'Total impressions' | 'Date'
    mode: 'Ascending' | 'Descending'
  } = { filter: 'Total impressions', mode: 'Descending' }

  tableCommunities: ICommunity[] = []

  //TODO: give them a proper type
  twitterPosts: any[] = []
  newsArticles: any[] = []

  twitterMatchingResults: number = 0
  newsMatchingResults: number = 0

  constructor() {
    super()
    makeObservable(this, {
      watchlistData: observable,
      formError: observable,
      isLoadingNarrative: observable,
      isWatchlistSuccessModalOpen: observable,
      modalData: observable,
      formData: observable,
      booleanSearchWord: observable,
      twitterMode: observable,
      matchingResults: observable,
      showResults: observable,
      narrativeFilterData: observable,
      communityFilterData: observable,
      tenant_id: observable,
      contentLoader: observable,
      userSearched: observable,
      matchingResultsState: observable,
      textualMatchingResultsState: observable,
      keywords: observable,
      tableCommunities: observable,
      twitterPosts: observable,
      newsArticles: observable,
      twitterMatchingResults: observable,
      newsMatchingResults: observable,
      pagination: observable,
      textualUniqueContentList: observable,
      normalUniqueContentList: observable,
      narrativeDetailsData: observable,

      setModalData: action.bound,
      setFormData: action.bound,
      setBooleanSearchWord: action.bound,
      setMatchingResults: action.bound,
      setShowResults: action.bound,
      setTenantId: action.bound,
      setUserSearched: action.bound,
      setKeywords: action.bound,
      setListFilter: action.bound,
      setTableCommunities: action.bound,
      getData: action.bound,
      setNarrativeDetailsData: action.bound,
      setIsWatchlistSuccessModalOpen: action.bound,
    })
  }

  pagination = {
    current: 1,
    pageSize: 50,
    showSizeChanger: false,
  }

  textualUniqueContentList: IUniqueContent[] = []
  normalUniqueContentList: IUniqueContent[] = []

  get normalUniqueContent() {
    if (this.normalUniqueContentList.length === 0) {
      const contentList: IUniqueContent[] = []
      const postIDs: string[] = []
      this.snippets.forEach((element) => {
        if (contentList.length >= 5) return
        if (postIDs.includes(element.title)) return

        postIDs.push(element.title)
        contentList.push({
          title: element.title,
          website: element.sourcePlatform,
          id: element.id,
          imgLink: '',
          link: element.openSourceLink,
        })
      })

      this.setNormalUniqueContent(contentList)
    }
    return this.normalUniqueContentList
  }

  get getModeConditions() {
    const { name, channels: watchlistChannels } = this.formData
    this.setBooleanSearchWord(name)

    return [{ channel_urls: watchlistChannels.map((channel: WatchlistChannel) => channel.channel_urls).flat() }]
  }

  setPagination = ({ current }: { current: number }) => {
    this.pagination = { ...this.pagination, current }
  }

  setNormalUniqueContent = (list: IUniqueContent[]) => {
    if (list.length === 0) return
    this.normalUniqueContentList = list
  }

  setBooleanSearchWord = (word: string) => {
    this.booleanSearchWord = word
  }

  setIsWatchlistSuccessModalOpen = (state: string) => {
    this.isWatchlistSuccessModalOpen = state
  }

  resetUniqueContentLists = () => {
    this.textualUniqueContentList = []
    this.normalUniqueContentList = []
  }

  updateMatchingResultsState = () => {
    if (this.matchingResults === 0) {
      if (!this.userSearched) {
        this.matchingResultsState = 'noSearch'
        return
      }
      this.matchingResultsState = 'noResultsBack'
      return
    }
    if (this.matchingResults > 6000000) {
      this.matchingResultsState = 'tooMany'
      return
    }
    this.matchingResultsState = 'normal'
  }

  updateUserSearched = () => {
    this.userSearched = true
  }

  setTableLoader = (state: boolean) => {
    this.contentLoader = state
  }

  closeModal = () => {
    this.modalData = undefined
  }

  fetchDropdowns = async () => {
    try {
      const [narrativesDropdownData, communitiesDropdownData] = await Promise.all([
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'narrative',
        }),
        API.get({
          page: 1,
          pageSize: 5000,
          isPromise: true,
          route: 'community',
        }),
      ])
      this.setCreatorsFilter(communitiesDropdownData.data.items)
      this.setNarrativesFilter(narrativesDropdownData.data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  saveWatchList = async (data: any) => {
    try {
      return await watchlistAPIs.saveWatchlist(data)
    } catch (err: any) {
      displayApiError(err)
    }
  }

  verifyChannelURL = async (payload: { url: string }) => {
    try {
      return await watchlistAPIs.verifyChannelURL(payload)
    } catch (err: any) {
      displayApiError(err)
    }
  }

  getNumberArrayFromID = ({ array, mode }: { array: string[]; mode: 'narrative' | 'communities' }) => {
    if (!array || array?.length === 0) return undefined

    switch (mode) {
      case 'narrative':
        return this.narrativeFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.narrative_number
          })
      case 'communities':
        return this.communityFilterData
          .filter((el) => array.includes(el.id))
          .map((el) => {
            return el.community_number
          })
    }
  }

  setCreatorsFilter = (data: ICommunityDropdown[]) => {
    this.communityFilterData = data
  }

  setNarrativesFilter = (data: INarrativeDropdown[]) => {
    this.narrativeFilterData = data
  }

  setFormData = (formData: any) => {
    this.formData = formData
  }

  setMatchingResults = (matchingResults: number) => {
    this.matchingResults = matchingResults
    this.updateMatchingResultsState()
  }

  setShowResults = (state: boolean) => {
    this.showResults = state
  }

  addFormData = (newFormData: any) => {
    this.formData = { ...this.formData, ...newFormData }
  }

  setNarrativeDetailsData = (newFormData: any) => {
    this.narrativeDetailsData = { ...this.formData, ...newFormData }
  }

  publishNarrative = async () => {
    try {
      const {
        name,
        description,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
        subset: parent_narrative_ids,
        linkContent: related_contents,
        twitterSwitch,
        enableRetweet,
        verifiedOnly,
        profileCountry,
      } = this.formData

      const resp = await defineNarrativeAPI.publishNarrative({
        name,
        type: twitterSwitch ? 'textual' : 'keyword',
        description,
        communities,
        keywords_expression,
        parent_narrative_ids,
        related_contents: related_contents,
        tenant_id: this.tenant_id,
        enable_retweet: enableRetweet,
        verified_only: verifiedOnly,
        profile_country: profileCountry,
      })

      if (resp.id) {
        this.setModalData('notify')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  republishWatchlist = async (watchlistId: string, watchlistData: any) => {
    try {
      this.setFormData(watchlistData)

      const resp = await watchlistAPIs.EditWatchlist(this.formData, watchlistId)
      if (resp === 'Watchlist was successfully updated') {
        this.setModalData('notify')
      }
      return resp
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setModalData = (data: 'notify' | 'workLater' | 'loseChanges') => {
    this.modalData = data
  }

  saveNarrative = async () => {
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
        twitterSwitch,
      } = this.formData

      const resp = await defineNarrativeAPI.saveNarrative({
        name,
        description,
        related_contents: related_contents ? [related_contents] : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: twitterSwitch ? 'textual' : 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  getTimeSpan = ({ rangeInDays = 1 }: { rangeInDays?: number } = { rangeInDays: 1 }) => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const pastDate = dayjs().subtract(rangeInDays, 'days').format('YYYY-MM-DD')

    return {
      startDate: pastDate,
      endDate: currentDate,
    }
  }

  fetchCommunities = async () => {
    try {
      const { data } = await API.get({
        route: 'community',
        page: 1,
        pageSize: 50,
        getError: true,
      })

      const ids = data.items.map((el: any) => el.community_number)
      this.getCommunities(ids)
      this.setTableCommunities(data.items)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setTableCommunities = (communities: ICommunity[]) => {
    this.tableCommunities = communities
  }

  setKeywords = (keywords: string[]) => {
    this.keywords = keywords
  }

  setTenantId = (id: string) => {
    this.tenant_id = id
  }

  getChannels = (channels: any) => {
    let parsedChannels: any = []
    // eslint-disable-next-line array-callback-return
    channels.map((channel: any, i: number) => {
      parsedChannels.push({
        platform: channel.platform,
        channel_urls: channel.channel_by_platform.map((plt: any) => plt.channel_url),
      })
    })
    return parsedChannels
  }

  getData = async (id: string) => {
    try {
      const { data: watchlistData } = await API.get({ route: 'watchlist', id })
      const channels = this.getChannels(watchlistData.channels)
      this.setNarrativeDetailsData(watchlistData)
      this.addFormData({
        name: watchlistData.name,
        description: watchlistData.description,
        channels,
      })
    } catch (e: any) {
      const response = e.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  editNarrative = async (narrativeID?: string) => {
    if (!narrativeID) return
    try {
      const {
        name,
        description,
        linkContent: related_contents,
        subset: parent_narrative_ids,
        focusCommunities: communities,
        booleanSearch: keywords_expression,
      } = this.formData

      const resp = await defineNarrativeAPI.editNarrative({
        id: narrativeID,
        name,
        description,
        related_contents: related_contents ? related_contents.join(',') : undefined,
        parent_narrative_ids,
        communities,
        keywords_expression,
        tenant_id: this.tenant_id,
        type: 'keyword',
      })
      if (resp.id) {
        this.setModalData('workLater')
      }
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    }
  }

  setListFilter = (filter: any) => {
    this.listFilter = filter
    this.setPagination({ current: 1 })
    this.setSnippets([])
  }

  setUserSearched = (state: boolean) => {
    this.userSearched = state
  }

  resetStore = () => {
    this.isFeedEmpty = true
    this.showResults = false
    this.twitterMode = false
    this.formError = ''
    this.isLoadingNarrative = false
    this.modalData = undefined
    this.formData = {}
    this.narrativeFilterData = []
    this.communityFilterData = []
    this.userSearched = false
    this.listFilter = { filter: 'Total impressions', mode: 'Descending' }
    this.matchingResultsState = 'noSearch'
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.pagination = {
      current: 1,
      pageSize: 50,
      showSizeChanger: false,
    }
    this.snippets = []
    this.twitterPosts = []
    this.newsArticles = []
    this.twitterMatchingResults = 0
    this.newsMatchingResults = 0
    this.booleanSearchWord = ''
  }
}
