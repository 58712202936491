import { useEffect, useState } from 'react'

import { ConditionsPayloadType, Mode, NarrativeListDataType } from 'types/types'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { store } from 'store'
import { IBarChartWatchlistData, ISnippet } from 'models/models'
import { ExecutiveSummaryTypes, SectionTypes } from './ExecutiveSummary'
import { powerInsightsAPI } from 'api/api'
import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'utils/helper'

const useBrandManagement = () => {
  const [topPosts, setTopPosts] = useState<ISnippet[]>([])
  const [topVoices, setTopVoices] = useState<ISnippet[]>([])
  const [summaryLoading, setSummaryLoading] = useState(true)
  const [topPostsLoading, setTopPostsLoading] = useState(true)
  const [topVoicesLoading, setTopVoicesLoading] = useState(true)
  const [followedVoiceLoading, setFollowedVoiceLoading] = useState(true)
  const [followedVoiceData, setFollowedVoiceData] = useState<IBarChartWatchlistData[]>([])
  const [executiveSummary, setExecutiveSummary] = useState<ExecutiveSummaryTypes>({
    title: '',
    sections: [],
    error: false,
  })

  const {
    activeItem,
    fetchSnippets,
    selectedBrand,
    setActiveItem,
    snippetsFilter,
    fetchCategories,
    fetchAssetsList,
    setSelectedBrand,
    getSnippetFilterPayload,
    fetchPowerInsightsForDashboardPage,
  } = store.assetsStore

  const { fetchWatchlist } = store.vectorsStore

  const BRANDS = 'Brands'

  const initializeBrands = async (isDeleteAbleBrand?: boolean, selectedBrand?: AssetListObjType) => {
    try {
      const categoriesResult = await fetchCategories({ is_asset: true })
      const brandCategory = categoriesResult.find((item: AssetListObjType) => item.name === BRANDS)
      if (!brandCategory) return

      const params: AssetListParamsType = {
        page: 1,
        per_page: 100,
        sort: 'name:asc',
        is_asset: true,
        is_active: true,
        q: `category_id:eq:${brandCategory.category_id}`,
      }

      const assetsResult = await fetchAssetsList({ params: params })

      if (assetsResult?.items?.length) {
        const firstActiveBrand = assetsResult?.items?.find((brand) => brand.is_active)

        if (firstActiveBrand && !selectedBrand) {
          setSelectedBrand(firstActiveBrand)
          setActiveItem({
            id: firstActiveBrand.id,
            name: firstActiveBrand.name,
            conditions: firstActiveBrand.conditions,
            mode: firstActiveBrand.mode as Mode,
            category_id: firstActiveBrand.category_id || '',
            description: firstActiveBrand.description || '',
          })
          fetchPowerInsightsForDashboardPage(
            {
              name: firstActiveBrand?.name || '',
              conditions: firstActiveBrand?.conditions || {},
              mode: 'asset',
              id: firstActiveBrand?.id || '',
            },
            true,
          )
          fetchDailyExecutiveSummary(0, firstActiveBrand?.conditions as ConditionsPayloadType)
          await getActiveTopicsData(firstActiveBrand?.conditions as ConditionsPayloadType)
          getTopPosts(firstActiveBrand?.conditions as ConditionsPayloadType)
          getTopVoices(firstActiveBrand?.conditions as ConditionsPayloadType)
        } else if (isDeleteAbleBrand && firstActiveBrand) {
          setSelectedBrand(firstActiveBrand)
          setActiveItem({
            id: firstActiveBrand.id,
            name: firstActiveBrand.name,
            conditions: firstActiveBrand.conditions,
            mode: firstActiveBrand.mode as Mode,
            category_id: firstActiveBrand.category_id || '',
            description: firstActiveBrand.description || '',
          })
          fetchPowerInsightsForDashboardPage(
            {
              name: firstActiveBrand?.name || '',
              conditions: firstActiveBrand?.conditions || {},
              mode: 'asset',
              id: firstActiveBrand?.id || '',
            },
            true,
          )
          fetchDailyExecutiveSummary(0, firstActiveBrand?.conditions as ConditionsPayloadType)
          await getActiveTopicsData(firstActiveBrand?.conditions as ConditionsPayloadType)
          getTopPosts(firstActiveBrand?.conditions as ConditionsPayloadType)
          getTopVoices(firstActiveBrand?.conditions as ConditionsPayloadType)
        } else if (!isDeleteAbleBrand && selectedBrand) {
          setSelectedBrand(selectedBrand)
          setActiveItem({
            id: selectedBrand.id,
            name: selectedBrand.name,
            conditions: selectedBrand.conditions,
            mode: selectedBrand.mode as Mode,
            category_id: selectedBrand.category_id || '',
            description: selectedBrand.description || '',
          })
          fetchPowerInsightsForDashboardPage(
            {
              name: selectedBrand?.name || '',
              conditions: selectedBrand?.conditions || {},
              mode: 'asset',
              id: selectedBrand?.id || '',
            },
            true,
          )
          fetchDailyExecutiveSummary(0, selectedBrand?.conditions as ConditionsPayloadType)
          await getActiveTopicsData(selectedBrand?.conditions as ConditionsPayloadType)
          getTopPosts(selectedBrand?.conditions as ConditionsPayloadType)
          getTopVoices(selectedBrand?.conditions as ConditionsPayloadType)
        }
      } else setSelectedBrand(undefined)
    } catch (error) {
      console.error('Failed to initialize brands:', error)
    }
  }

  const getActiveTopicsData = async (brandConditions: ConditionsPayloadType) => {
    const requestParams = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_active: true,
    }
    try {
      setFollowedVoiceLoading(true)
      const data = await fetchWatchlist({ params: requestParams })

      const allPromises = data?.items?.map(async (item: NarrativeListDataType) => {
        if (item.conditions.channel_urls?.length) {
          const snippetData = await fetchSnippets({
            order: 'desc',
            per_page: 100,
            isReset: true,
            conditions: [brandConditions, { channel_urls: item.conditions.channel_urls }],
          })

          return {
            ...item,
            snippetCount: snippetData?.total_count || 0,
          }
        }
        return { ...item, snippetCount: 0 }
      })

      const results = await Promise.allSettled(allPromises || [])

      const watchListData: IBarChartWatchlistData[] = results
        .filter(
          (result): result is PromiseFulfilledResult<IBarChartWatchlistData> =>
            result.status === 'fulfilled' && result.value,
        )
        .map((result) => result.value)
        // @ts-ignore
        .filter((watchListObj) => watchListObj?.snippetCount)

      setFollowedVoiceData(watchListData)
    } catch (error) {
      console.error('Error fetching topics:', error)
    } finally {
      setFollowedVoiceLoading(false)
    }
  }

  const fetchDailyExecutiveSummary = async (version: number, conditions: ConditionsPayloadType) => {
    try {
      setSummaryLoading(true)
      const payload = await getSnippetFilterPayload()
      const summaryVersion = version === 0 ? null : version
      payload.conditions = [conditions as ConditionsPayloadType]
      const { data } = await powerInsightsAPI.summary.getSummaryV2(payload, false, summaryVersion)
      if (!data.response_code) {
        const translatedSections = data.sections?.map((item: SectionTypes) => {
          const { snippets } = UtilService.snippetTranslator({
            rawSnippets: item.snippets,
          })
          return { ...item, snippets }
        })

        setExecutiveSummary({
          ...data,
          sections: translatedSections,
          error: false,
        })
      }
      if (data.response_code === 'NO_DATA') {
        setExecutiveSummary({ title: '', sections: [], error: false })
      }
    } catch (error) {
      setExecutiveSummary({ title: '', sections: [], error: true })
    } finally {
      setSummaryLoading(false)
    }
  }

  const getTopPosts = async (conditions: ConditionsPayloadType) => {
    try {
      setTopPostsLoading(true)
      const data = await fetchSnippets({
        key: 'impression_count',
        order: 'desc',
        per_page: 50,
        isReset: true,
        conditions: [conditions],
      })
      setTopPosts(data?.items as ISnippet[])
    } catch (error) {
      setTopPosts([])
      displayApiError(error)
    } finally {
      setTopPostsLoading(false)
    }
  }

  const getTopVoices = async (conditions: ConditionsPayloadType) => {
    try {
      setTopVoicesLoading(true)
      const data = await fetchSnippets({
        key: 'channel_follower_count',
        order: 'desc',
        per_page: 50,
        isReset: true,
        conditions: [conditions],
      })
      setTopVoices(data?.items as ISnippet[])
    } catch (error) {
      setTopVoices([])
      displayApiError(error)
    } finally {
      setTopVoicesLoading(false)
    }
  }

  const handleSelectBrand = async (brand?: AssetListObjType) => {
    if (brand) {
      setSelectedBrand(brand)
      setActiveItem({
        id: brand.id,
        name: brand.name,
        conditions: brand.conditions,
        mode: brand.mode as Mode,
        category_id: brand.category_id || '',
        description: brand.description || '',
      })

      try {
        setTopPostsLoading(true)
        setTopVoicesLoading(true)
        fetchDailyExecutiveSummary(0, brand.conditions as ConditionsPayloadType)
        await getActiveTopicsData(brand.conditions as ConditionsPayloadType)
        getTopPosts(brand.conditions as ConditionsPayloadType)
        getTopVoices(brand.conditions as ConditionsPayloadType)
        fetchPowerInsightsForDashboardPage(
          {
            name: brand?.name || '',
            conditions: brand?.conditions || {},
            mode: 'asset',
            id: brand?.id || '',
          },
          true,
        )

        const { setSnippetsFilter } = store.assetsModalStore
        setSnippetsFilter(snippetsFilter)
      } catch (error) {
        console.error('Error fetching data after brand selection:', error)
      } finally {
      }
    }
  }

  useEffect(() => {
    initializeBrands(false, selectedBrand)
  }, [])

  return {
    topPosts,
    topVoices,
    activeItem,
    selectedBrand,
    summaryLoading,
    topPostsLoading,
    topVoicesLoading,
    executiveSummary,
    followedVoiceData,
    followedVoiceLoading,
    handleSelectBrand,
    fetchDailyExecutiveSummary,
    refreshBrands: initializeBrands,
  }
}

export default useBrandManagement
