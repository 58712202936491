import { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as SummaryIcon } from 'assets/images/anticipatory-summary-icon.svg'
import { ReactComponent as CollapseIcon } from 'assets/images/icon-close.svg'
import { ReactComponent as SimulatingPrompt } from 'assets/images/icons/report/simulating.svg'
import classNames from 'classnames'
import { MonitorSnippetItem } from 'components/Monitor/SnippetList/MonitorSnippetItem/SnippetItem'
import { useEffectOnce } from 'react-use'
import { getPromptSimulation } from 'api/monitor/powerInsights/summary/getPromptSimulation'
import './SimulatePrompt.scss'

type PropsType = {
  onClose: () => void
  subStore?: 'assets' | 'vectors'
  payload: any
}

const SimulatePrompt = (props: PropsType) => {
  const [dailyExecutiveSummary, setDailyExecutiveSummary] = useState<{ [key: number]: any[] }>({})
  const [isLoadingExecutiveSummary, setIsLoadingExecutiveSummary] = useState(false)
  const [summaryFetchingError, setSummaryFetchingError] = useState(false)
  const [selectedSummarySectionIndex, setSelectedSummarySectionIndex] = useState<number | undefined>()
  const [summaryVersion] = useState(0)

  const { onClose, subStore = 'assets', payload } = props

  const {
    showSurprise,
    watchlists,
    communities,
    setSnippetsForBrandDailyExecutiveSummary,
    summarySnippetsWithSurprises,
    setSnippets,
  } = store[`${subStore}Store`]

  useEffectOnce(() => {
    if (summarySnippetsWithSurprises.length > 0) {
      setSnippets([])
    }
  })

  useEffect(() => {
    fetchDailyExecutiveSummary(0)
    return () => setSnippets([])
  }, [])

  const fetchDailyExecutiveSummary = async (version: number) => {
    try {
      setIsLoadingExecutiveSummary(true)
      const { data } = await getPromptSimulation(payload)
      if (!data.response_code) {
        setDailyExecutiveSummary({ ...dailyExecutiveSummary, [version]: data.sections })
        setSelectedSummarySectionIndex(0)
        setSnippetsForBrandDailyExecutiveSummary(data.sections[0].snippets)
      }
      if (data.response_code === 'NO_DATA') {
        setDailyExecutiveSummary({ ...dailyExecutiveSummary, [version]: [] })
        setSelectedSummarySectionIndex(0)
        setSnippetsForBrandDailyExecutiveSummary([])
      }
    } catch (error) {
      setSummaryFetchingError(true)
      setSnippets([])
    } finally {
      setIsLoadingExecutiveSummary(false)
    }
  }

  const retryFetchingSummary = () => {
    setIsLoadingExecutiveSummary(true)
    setSummaryFetchingError(false)
    fetchDailyExecutiveSummary(0)
  }

  const onSectionSelection = (currentIndex: number, snippets: any[]) => {
    if (selectedSummarySectionIndex === currentIndex) return
    setSelectedSummarySectionIndex(currentIndex)
    setSnippetsForBrandDailyExecutiveSummary(snippets)
  }

  // const changeSummaryVersion = (direction: 'increment' | 'decrement') => {
  //   const nextVersion = direction === 'increment' ? summaryVersion + 1 : summaryVersion - 1

  //   if (nextVersion < 0 || nextVersion > 5) return

  //   setSummaryFetchingError(false)
  //   if (dailyExecutiveSummary[nextVersion]) {
  //     setSelectedSummarySectionIndex(0)
  //     setSnippetsForBrandDailyExecutiveSummary(dailyExecutiveSummary[nextVersion][0].snippets)
  //   } else {
  //     fetchDailyExecutiveSummary(nextVersion)
  //   }
  //   setSummaryVersion(nextVersion)
  // }

  const noDataPresent =
    !isLoadingExecutiveSummary &&
    !summaryFetchingError &&
    (!dailyExecutiveSummary[summaryVersion] || dailyExecutiveSummary[summaryVersion]?.length === 0)

  return (
    <Modal open={true} className='daily-summary' footer={null} closable={false} mask={false}>
      <div>
        <div className='header'>
          <h4>
            <SummaryIcon /> Simulation results
          </h4>

          <div className='collapse-option' onClick={onClose}>
            <CollapseIcon />
          </div>
        </div>
        <div className='body'>
          {isLoadingExecutiveSummary && (
            <div className='body__empty-state'>
              <SimulatingPrompt />
              <h5>We are simulating your prompt</h5>
              <p>
                We’re currently processing your data and finalizing your simulation. This usually takes around 1-3
                minutes.
              </p>
            </div>
          )}
          {!isLoadingExecutiveSummary && (
            <>
              <div className='summary-section'>
                <div className='summary-section-header'>
                  <h4>Key Highlights</h4>
                </div>
                <div className='summary-list'>
                  {!summaryFetchingError &&
                    dailyExecutiveSummary[summaryVersion]?.map(
                      (section: { subheading: string; summary: string; snippets: any[] }, index) => (
                        <div
                          className={classNames('summary', {
                            'summary-selected': selectedSummarySectionIndex === index,
                          })}
                          key={index}
                          onClick={() => onSectionSelection(index, section.snippets)}>
                          <h4>
                            <span>{`${index + 1}. `}</span>
                            {section.subheading}
                          </h4>
                          <p>{section.summary}</p>
                        </div>
                      ),
                    )}

                  {summaryFetchingError && (
                    <div className='summary-sections-empty summary-error'>
                      <span className='summary-error-text'>
                        Failed to load the summary. Please try again now or later.
                      </span>
                      <button className='summary-retry-error' onClick={retryFetchingSummary}>
                        Retry
                      </button>
                    </div>
                  )}
                  {noDataPresent && (
                    <div className='summary-sections-empty'>
                      <h5>No data to show</h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='snippets-section'>
                <div className='snippets-section-header'>
                  <h4>
                    Featured Snippets{' '}
                    <span>
                      {summarySnippetsWithSurprises.length > 0 &&
                        `${summarySnippetsWithSurprises.length} ${
                          summarySnippetsWithSurprises.length === 1 ? 'item' : 'items'
                        }`}
                    </span>
                  </h4>
                </div>
                <div className='snippets-list'>
                  {!summaryFetchingError &&
                    summarySnippetsWithSurprises.map((item) => (
                      <div key={item.id}>
                        <MonitorSnippetItem
                          ref={null}
                          mode={'asset'}
                          subStore={'assets'}
                          snippet={item}
                          allCommunities={communities}
                          allWatchlist={watchlists}
                          showSurprise={showSurprise}
                          snippetItemClassName='snippet-item-bordered'
                        />
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}
export default observer(SimulatePrompt)
