import { Route } from 'react-router-dom'
import { PrivateRoute } from 'components/Auth/PrivateRoute/PrivateRoute'
import DashboardMetrics from './DashboardMetrics/DashboardMetrics'
import { Creators } from './Monitor/PowerInsights/Creators/Creators'
import { MonitorBarChart } from './Monitor/PowerInsights/MonitorBarChart/MonitorBarChart'
import { MonitorHeatMap } from './Monitor/PowerInsights/MonitorHeatMap/MonitorHeatMap'
import { MonitorBeehiveChart } from './Monitor/PowerInsights/MonitorBeehiveChart/MonitorBeehiveChart'
import { MonitorMapChart } from './Monitor/PowerInsights/Map/MonitorMapChart'
import { ExecutiveLanguageRisks } from './Monitor/PowerInsights/ExecutiveLanguageRisks/ExecutiveLanguageRisks'
import { skipRoles } from 'utils/helper'
import { MonitorGraph } from './Monitor/PowerInsights/MonitorGraph/MonitorGraph'
import { BrandAssets } from './Asset/BrandsDashboard/components/PowerInsights/Assets/Assets'
import { MonitorFlags } from './Monitor/PowerInsights/MonitorFlags/MonitorFlags'
import { SubStore } from 'types/types'

const powerInsightsCommonRoutes = ({
  subStore,
  loaderStore,
  tenantsStore,
}: {
  subStore: SubStore
  loaderStore: any
  tenantsStore: any
}) => {
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const {
    statsPeopleLoading,
    statsCompanyLoading,
    statsLanguageLoading,
    isLoadingWatchlist,
    statsPoliticianLoading,
    statsLineChartLoading,
    statsBeeSwarmLoading,
    statsTreeDataLoading,
    statsHashTagLoading,
    statsCashTagLoading,
    statsHandleLoading,
  } = loaderStore

  const routes = []
  routes.push(
    <Route path='metrics' element={<PrivateRoute allowedRoles={skipRoles([])} element={<DashboardMetrics />} />} />,
    <Route
      path='assets'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <BrandAssets
              subStore={subStore}
              isBrandDashboard={subStore === 'assets'}
              skipBrandIntersection={subStore !== 'assets'}
              detailText='Understand the relative performance of the active brands you are following.'
            />
          }
        />
      }
    />,
    <Route
      path='topics_flags'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorFlags
              subStore={subStore}
              detailText={
                'List of Followed Topics which were mentioned in the selected timeframe. You will only see data if the topics you are following are present in the context of the dataset defined by this screen. '
              }
            />
          }
        />
      }
    />,
    <Route
      path='trends'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={<MonitorGraph subStore={subStore} dataLoading={statsLineChartLoading} />}
        />
      }
    />,
    <Route
      path='creators'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <Creators
              subStore={subStore}
              detailText={
                'The list of unique creators represented in the snippet feed. Select a creator to filter the snippet feed or click on their image to find more information about them and creators similar to them.'
              }
            />
          }
        />
      }
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'watchlist'}
              subStore={subStore}
              indexName='watchlistData'
              dataLoading={isLoadingWatchlist}
            />
          }
        />
      }
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={<MonitorHeatMap subStore={subStore} selectMode dataLoading={statsTreeDataLoading} />}
        />
      }
    />,
    <Route
      path='creator_hive'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={<MonitorBeehiveChart subStore={subStore} dataLoading={statsBeeSwarmLoading} />}
        />
      }
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={<PrivateRoute allowedRoles={skipRoles([])} element={<MonitorMapChart subStore={subStore} />} />}
    />,
    <Route
      path={`${changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme)?.toLowerCase()}`}
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <ExecutiveLanguageRisks subStore={subStore} isPowerInsightsMode dataLoading={statsLineChartLoading} />
          }
        />
      }
    />,
    <Route
      path='companies'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'company'}
              subStore={subStore}
              indexName='companyData'
              dataLoading={statsCompanyLoading}
            />
          }
        />
      }
    />,
    <Route
      path='people'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'people'}
              subStore={subStore}
              indexName='peopleData'
              dataLoading={statsPeopleLoading}
            />
          }
        />
      }
    />,
    <Route
      path='hashtags'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'hashtag'}
              subStore={subStore}
              indexName='hashtagsData'
              dataLoading={statsHashTagLoading}
            />
          }
        />
      }
    />,
    <Route
      path='handles'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'handle'}
              subStore={subStore}
              indexName='handlesData'
              dataLoading={statsHandleLoading}
            />
          }
        />
      }
    />,
    <Route
      path='cashtags'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'cashtag'}
              subStore={subStore}
              indexName='cashtagsData'
              dataLoading={statsCashTagLoading}
            />
          }
        />
      }
    />,
    <Route
      path='politician'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'politician'}
              subStore={subStore}
              indexName='politicianData'
              dataLoading={statsPoliticianLoading}
            />
          }
        />
      }
    />,
    <Route
      path='languages'
      element={
        <PrivateRoute
          allowedRoles={skipRoles([])}
          element={
            <MonitorBarChart
              tab={'language'}
              subStore={subStore}
              indexName='languagesData'
              dataLoading={statsLanguageLoading}
            />
          }
        />
      }
    />,
  )
  return routes
}

export default powerInsightsCommonRoutes
