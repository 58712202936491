import millify from 'millify'

export const snippetTranslator = ({
  rawSnippets,
  isFilterApplied = false,
}: {
  rawSnippets: any
  isFilterApplied?: boolean
}) => {
  const creators: string[] = []

  return {
    snippets: rawSnippets.map((snippet: any, index: number) => {
      creators.push(snippet?.channel_id)

      let author: { [key: string]: any } = {}

      let text = snippet.snippet_text
      if (isFilterApplied && snippet?.translated_snippet?.translated_text)
        text = snippet?.translated_snippet?.translated_text

      return {
        /* HACK: added the index so all the ids are unique */
        id: snippet.post_id + index + snippet?.channel_id + text + snippet.document_id,
        snippet_text: text,
        title: snippet.post_title,
        date: snippet.upload_date,
        views: snippet.impression_count,
        viewsMilify: millify(snippet.impression_count || 0),
        views_diff: snippet.impression_diff_count,
        views_diff_milify: millify(snippet.impression_diff_count || 0),
        playSourceLink: snippet?.media_url,
        openSourceLink: snippet?.post_url,
        sourcePlatform: snippet.platform,
        channelId: snippet?.channel_id,
        author,
        duration: snippet.duration,
        offset: snippet.offset,
        sentiment: snippet.sentiment,
        translatedSnippet: snippet.translated_snippet,
        documentId: snippet.document_id,
        document_id: snippet.document_id,
        source: snippet.snippet_source,
        comments: snippet.comment_count,
        dislikes: snippet.dislike_count,
        likes: snippet.like_count,
        postId: snippet.post_id,
        channel_follower_count: snippet.channel_follower_count,
      }
    }),
    creators,
  }
}
