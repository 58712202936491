import { store } from 'store'
import millify from 'millify'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffectOnce, useUnmount } from 'react-use'
import { useEffect, useRef, useState, ReactNode } from 'react'

import { MonitorSnippetItem } from './MonitorSnippetItem/SnippetItem'
import { DataFetching } from 'components/common/DataFetching/DataFetching'
import { DashboardSnippetItem } from './MonitorSnippetItem/DashboardSnippetItem'
import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'

import { Mode, SubStore } from 'types/types'

import { ReactComponent as BackToTopButton } from 'assets/images/backToTopScroll.svg'

import useInfiniteScroll from 'hooks/useInfiniteScroll'

import './SnippetList.scss'
interface Props {
  mode: Mode
  subStore: SubStore
  isForModal?: boolean
  includeHeight?: number
  uniqueScrollId: string
  showSearchChip: boolean
  emptyStateTitle?: string
  selectedSnippets?: string[]
  searchedDataResults: number
  isCustomEmptyState?: boolean
  isDashboardSnippet?: boolean
  emptyStateDescription?: string
  parentAPICallsLoading?: boolean
  emptyStateIcon?: JSX.Element | ReactNode
  emptyStateButton?: JSX.Element | ReactNode
  handleSelectedSnippets?: (snippetId: string) => void
}

export const SnippetList = observer(
  ({
    mode,
    subStore,
    isForModal,
    uniqueScrollId,
    emptyStateIcon,
    showSearchChip,
    emptyStateTitle,
    selectedSnippets,
    emptyStateButton,
    searchedDataResults,
    emptyStateDescription,
    handleSelectedSnippets,
    isDashboardSnippet = false,
    isCustomEmptyState = false,
    parentAPICallsLoading = false,
    includeHeight,
  }: Props) => {
    const { loaderStore } = store

    const {
      snippets,
      watchlists,
      communities,
      showSurprise,
      snippetsTotal,
      getWatchlists,
      fetchSnippets,
      setIsFeedEmpty,
      snippetsFilter,
      snippetsWithSurprises,
    } = store[`${subStore}${isForModal ? 'ModalStore' : 'Store'}`]
    const { isLoadingFeed, isLoadingDefaultPlatforms } = loaderStore
    const listRef = useRef<HTMLDivElement>(null)
    const [showButton, setShowButton] = useState(false)

    const { lastElementRef } = useInfiniteScroll({
      onIntersect: () => {
        if ((snippetsWithSurprises?.length || 0) < (snippetsTotal || 0)) {
          fetchSnippets()
        }
      },
      enabled: !isLoadingFeed && snippetsWithSurprises.length > 0,
    })

    const handleShowButton = () => {
      const element = document.querySelector(`#${uniqueScrollId}`)

      element?.addEventListener('scroll', (e) => {
        setShowButton((e.currentTarget as HTMLElement)?.scrollTop > 780)
      })
      return () => {
        element?.addEventListener('scroll', (e) => {
          setShowButton((e.currentTarget as HTMLElement)?.scrollTop > 780)
        })
      }
    }

    useEffect(() => {
      handleShowButton()
    }, [])

    useEffectOnce(() => {
      getWatchlists()
    })

    useUnmount(() => {
      setIsFeedEmpty(true)
    })

    return (
      <>
        <div
          className='snippet-list__wrapper'
          style={{ height: includeHeight ? `calc(100% - ${includeHeight}px)` : '100%' }}>
          {showSearchChip && searchedDataResults > 0 && (
            <div className='snippet-list__container'>
              <>
                <span className='snippet-list__search-result'>{millify(searchedDataResults) + ' results'}</span>
              </>
            </div>
          )}
          <div
            className={classNames('c-snippets-list__list', {
              dashboard_snippet_list: isDashboardSnippet,
            })}
            id={uniqueScrollId}
            ref={listRef}>
            {!isLoadingFeed && !isLoadingDefaultPlatforms && !parentAPICallsLoading && !snippets.length ? (
              isCustomEmptyState ? (
                <BrandDataFetching
                  emptyStateIcon={emptyStateIcon}
                  emptyStateTitle={emptyStateTitle}
                  emptyStateButton={emptyStateButton}
                  emptyStateDescription={emptyStateDescription}
                />
              ) : (
                <DataFetching title={'title'} page={'notFound'} daysFilters={snippetsFilter.days} />
              )
            ) : (
              <>
                {snippetsWithSurprises?.map((item, index) => (
                  <div key={item.id}>
                    {isDashboardSnippet ? (
                      <DashboardSnippetItem
                        ref={index === snippetsWithSurprises.length - 1 ? lastElementRef : null}
                        mode={mode}
                        subStore={subStore}
                        isForModal={isForModal}
                        snippet={item}
                        allWatchlist={watchlists}
                        allCommunities={communities}
                        showSurprise={showSurprise}
                        selectedSnippets={selectedSnippets}
                        handleSelectedSnippets={handleSelectedSnippets}
                      />
                    ) : (
                      <MonitorSnippetItem
                        ref={index === snippetsWithSurprises.length - 1 ? lastElementRef : null}
                        mode={mode}
                        subStore={subStore}
                        snippet={item}
                        isForModal={isForModal}
                        allWatchlist={watchlists}
                        allCommunities={communities}
                        showSurprise={showSurprise}
                      />
                    )}
                  </div>
                ))}
              </>
            )}

            {showButton && (
              <BackToTopButton
                className='snippet-list__backToTop'
                style={{ position: 'absolute', bottom: '20px', right: '20px', zIndex: 999999 }}
                onClick={() =>
                  listRef.current?.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              />
            )}
          </div>
        </div>
      </>
    )
  },
)
