import { useEffect, useRef, useState } from 'react'
import { Modal, Spin, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useEffectOnce } from 'react-use'
import classNames from 'classnames'

import { ISnippet } from 'models/models'
import { getPreviewImage } from 'api/monitor/getPreviewImage'
import { getPreviewTranscript } from 'api/monitor/getPreviewTranscript'

import fallBackImage from 'assets/images/icons/dashboard/fallback-image.png'
import { ReactComponent as NewsIcon } from 'assets/images/news-icon.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-new-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon.svg'
import { ReactComponent as BitChuteIcon } from 'assets/images/bitchute-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-icon.svg'
import { ReactComponent as NormalSentiment } from 'assets/images/sentiment-normal-icon.svg'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'
import { ReactComponent as OpenIcon } from 'assets/images/open-icon.svg'
import { ReactComponent as DescriptionSourceIcon } from 'assets/images/snippet-source-description-icon.svg'
import { ReactComponent as TitleSourceIcon } from 'assets/images/snippet-source-title-icon.svg'
import { ReactComponent as ImageDescriptionSourceIcon } from 'assets/images/snippet-source-image-description-icon.svg'
import { ReactComponent as ImageSourceIcon } from 'assets/images/snippet-source-image-text-icon.svg'
import { ReactComponent as TranscriptSourceIcon } from 'assets/images/snippet-source-transcript-icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/short_down_arrow.svg'
import './PreviewModal.scss'

type TranscriptInfo = {
  post_description: string
  transcription: {
    offset: string | null
    snippet_text: string
  }[]
}

interface Props {
  openedMedia: ISnippet | null
  onModalClose: () => void
  onOpenSource: (obj: string) => void
}

export const PreviewModal = ({ openedMedia, onModalClose, onOpenSource }: Props) => {
  const ref: any = useRef(null)
  const ref2: any = useRef(null)
  const ref3: any = useRef(null)

  console.log('openedMedia***********', openedMedia)

  const isNewsSnippet = openedMedia?.sourcePlatform === 'News'
  const isImageContent = openedMedia?.source === 'ocr' || openedMedia?.source === 'image_caption'
  const isImageOrTextContent =
    openedMedia?.source === 'ocr' || openedMedia?.source === 'image_caption' || openedMedia?.source === 'transcript'
  const isSnippetTooLong = openedMedia && openedMedia?.snippet_text?.length > 500
  const formattedDate = dayjs(openedMedia?.date).format('MMM DD, YYYY')
  const fullTextComponent: any[] = []
  const partTextComponent: any[] = []
  const sentimentIcons = {
    Positive: <PositiveSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
    Neutral: <NormalSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
    Negative: <NegativeSentiment height={20} width={20} className='c-preview-modal__sentiment' />,
  }
  const iconsObj = {
    YouTube: <YoutubeIcon />,
    Twitter: <TwitterIcon />,
    Podcast: <PodcastIcon />,
    News: <NewsIcon />,
    Facebook: <FacebookIcon />,
    Rumble: <RumbleIcon />,
    BitChute: <BitChuteIcon />,
    TikTok: <TikTokIcon />,
    Odysee: <OdyseeIcon />,
    Surprise: undefined,
  }
  const snippetSourceIcons = {
    description: { logo: <DescriptionSourceIcon />, name: 'description', title: 'Description' },
    title: { logo: <TitleSourceIcon />, name: 'title', title: 'Title' },
    image_caption: {
      logo: <ImageDescriptionSourceIcon />,
      name: 'image description',
      title: 'Caption',
    },
    ocr: { logo: <ImageSourceIcon />, name: 'image text', title: 'OCR' },
    transcript: { logo: <TranscriptSourceIcon />, name: 'transcript', title: 'Transcript' },
  }
  let fullText = openedMedia?.snippet_text || ''
  let partText = openedMedia?.snippet_text?.slice(0, 500) || ''
  let url = ''
  let offsetReadable = ''

  const [openedSnippet, setOppenedSnippet] = useState(false)

  if (openedMedia) {
    if (openedMedia?.offset / 60 > 60) {
      const hours = openedMedia?.offset / 60 / 60
      const minutes = openedMedia?.offset / 60 - 60
      const sec = (minutes % 1) * 60
      offsetReadable = Math.floor(hours) + 'h ' + Math.floor(minutes) + 'm ' + Math.floor(sec) + 's'
    } else {
      const minutes = openedMedia?.offset / 60
      const sec = (minutes % 1) * 60
      offsetReadable = Math.floor(minutes) + 'm ' + Math.floor(sec) + 's'
    }
  }

  fullTextComponent.push(fullText)
  partTextComponent.push(partText)

  switch (openedMedia?.sourcePlatform) {
    case 'YouTube':
      url =
        'https://www.youtube.com/embed/' + openedMedia.playSourceLink.split('v=')[1] + '?start=' + openedMedia.offset
      break
    case 'TikTok':
      let id: any = openedMedia?.postUrl
      if (id?.length) {
        id = id.split('/')
        id = id[id.length - 1]
        url = 'https://www.tiktok.com/embed/' + id
      }
      break
    default:
      if (openedMedia?.playSourceLink) url = openedMedia?.playSourceLink
      break
  }

  const [previewImage, setPreviewImage] = useState('')
  const [imageTranscript, setImageTranscript] = useState<TranscriptInfo>({
    post_description: '',
    transcription: [],
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [expandedContent, setExpandedContent] = useState<'description' | 'transcript' | ''>('transcript')
  const [calculatedHeight1, setCalculatedHeight1] = useState('300px')
  const [calculatedHeight2, setCalculatedHeight2] = useState('300px')

  useEffectOnce(() => {
    if (isImageContent) {
      try {
        if (openedMedia?.documentId?.length) {
          getPreviewImage(openedMedia.documentId).then((response) => {
            if (response) {
              setPreviewImage(response)
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  })

  useEffectOnce(() => {
    if (isImageOrTextContent) {
      try {
        if (openedMedia?.postId?.length) {
          setIsLoading(true)
          getPreviewTranscript(openedMedia.postId, openedMedia.source).then((response) => {
            if (response) {
              setImageTranscript(response)
              setIsLoading(false)
            }
          })
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }
  })

  useEffect(() => {
    if (ref2?.current && ref?.current && imageTranscript?.transcription.length > 0) {
      const scrollHeight = ref2.current.scrollHeight
      const clientHeight = ref.current.clientHeight

      setCalculatedHeight1(scrollHeight >= clientHeight ? `${clientHeight - 100}px` : 'max-content')
    }
  }, [ref?.current?.clientHeight, imageTranscript?.transcription])

  useEffect(() => {
    if (ref3?.current && ref?.current && imageTranscript?.post_description.length > 0) {
      const scrollHeight = ref3.current.scrollHeight
      const clientHeight = ref.current.clientHeight

      setCalculatedHeight2(scrollHeight >= clientHeight ? `${clientHeight - 100}px` : 'max-content')
    }
  }, [ref?.current?.clientHeight, imageTranscript?.post_description, expandedContent])

  const toggleSection = (section: 'description' | 'transcript') => {
    if (section === expandedContent) setExpandedContent('')
    else setExpandedContent(section)
  }

  return (
    <Modal
      className={classNames('c-preview-modal', { 'c-preview-modal__image_content': isImageOrTextContent })}
      zIndex={9999}
      footer={null}
      open={!!openedMedia}
      onCancel={onModalClose}
      closable={false}>
      <div
        className={classNames(
          { 'c-preview-modal__container': !isImageOrTextContent },
          { 'c-preview-modal__container-2': isImageOrTextContent },
        )}>
        <div className='c-preview-modal__content' ref={ref}>
          <div className='c-preview-modal__title'>
            <div className='c-preview-modal__title__text' title={openedMedia?.title || ''}>
              {openedMedia?.title || ''}
            </div>

            <div className='c-preview-modal__title__right-div'>
              {openedMedia?.sourcePlatform !== 'Podcast' && (
                <span
                  className='c-preview-modal__open-source-btn'
                  onClick={() => {
                    onOpenSource(openedMedia?.openSourceLink!)
                    // onModalClose()
                  }}>
                  <OpenIcon height={15} />
                  <span>Open source</span>
                </span>
              )}
              {openedMedia?.source && (
                <div className='c-preview-modal__title__snippet-source'>
                  {snippetSourceIcons[openedMedia?.source].logo}
                  <span>{snippetSourceIcons[openedMedia?.source].title}</span>
                </div>
              )}
            </div>
          </div>
          <div className='c-preview-modal__video-content'>
            {openedMedia?.sourcePlatform === 'Podcast' && (
              <audio
                controlsList={'nodownload'}
                controls
                className='c-preview-modal__audio'
                id='player'
                src='https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1'></audio>
            )}
            {isNewsSnippet ? (
              <img
                className='c-preview-modal__news-cover-img'
                alt='cover-img'
                src={openedMedia?.extras?.articleThumbImg}
              />
            ) : (
              <>
                {isImageContent ? (
                  <img
                    className={classNames('c-preview-modal__default_img', {
                      'c-preview-modal__default_img__image-width': isImageOrTextContent,
                    })}
                    src={previewImage}
                    alt='preview-img'
                    onError={(e: any) => (e.target.src = fallBackImage)}
                  />
                ) : (
                  <iframe
                    title={openedMedia?.snippet_text.slice(0, 20)}
                    className={classNames('c-preview-modal__iframe', {
                      'c-preview-modal__iframe__image-width': isImageOrTextContent,
                    })}
                    src={url}
                  />
                )}
              </>
            )}

            {openedMedia?.source === 'transcript' && (
              <span className='c-preview-modal__play-at'>
                relevant content starts at: <b className='c-preview-modal__play-at--primary'>{offsetReadable}</b>
              </span>
            )}
          </div>
          <p className='c-preview-modal--description-text'>
            <span
              className='c-preview-modal__description'
              dangerouslySetInnerHTML={
                {
                  __html: isSnippetTooLong
                    ? openedSnippet
                      ? fullTextComponent
                      : partTextComponent
                    : fullTextComponent,
                } as any
              }
            />
            {isSnippetTooLong && (
              <span className='expandable-text'>
                ...
                {openedSnippet ? (
                  <span onClick={() => setOppenedSnippet(false)} className='c-preview-modal__description--more'>
                    less
                  </span>
                ) : (
                  <span onClick={() => setOppenedSnippet(true)} className='c-preview-modal__description--more'>
                    more
                  </span>
                )}
              </span>
            )}
          </p>

          <div className='c-preview-modal__footer'>
            <div className='c-preview-modal__footer__left__list'>
              {openedMedia && iconsObj[openedMedia?.sourcePlatform]}
              <div className='c-preview-modal__dot' style={{ margin: '0 5px' }} />
              <img
                className='c-preview-modal__footer__left__user-img'
                src={openedMedia?.author?.channel_thumb_url}
                alt=''
              />
              <span className='c-preview-modal__channel-title' title={openedMedia?.author?.channel_title}>
                {openedMedia?.author?.channel_title}
              </span>
              {!isNewsSnippet && (
                <>
                  <div className='c-preview-modal__dot' />
                  {openedMedia?.viewsMilify} view{parseInt(openedMedia?.viewsMilify || '0') === 1 || 's'}
                </>
              )}
              <div className='c-preview-modal__dot' />
              <div className='c-preview-modal__footer__left__date'>{formattedDate}</div>
              <div className='c-preview-modal__dot' />
              {openedMedia?.sentiment && (
                <Tooltip
                  overlayClassName='c-preview-modal__footer__left__tooltip'
                  title={
                    <span>
                      This snippet expresses a {openedMedia?.sentiment} sentiment as identified by Pendulums ML model.
                    </span>
                  }
                  placement='bottom'>
                  <div className='c-preview-modal__details'>{sentimentIcons[openedMedia?.sentiment]}</div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>

        {isImageOrTextContent && (
          <div className='c-preview-modal__transcript'>
            {imageTranscript?.post_description?.length > 0 && (
              <div className='c-preview-modal__transcript__container'>
                <div className='c-preview-modal__transcript__title-div'>
                  <ArrowIcon
                    className={classNames({
                      'c-preview-modal__transcript__title-div__rotate': expandedContent !== 'description',
                    })}
                    onClick={() => toggleSection('description')}
                  />
                  <span className='c-preview-modal__transcript__title-div__label'>Description</span>
                </div>
                {expandedContent === 'description' && (
                  <div
                    ref={ref3}
                    className='c-preview-modal__transcript__content'
                    style={{ height: calculatedHeight2 }}>
                    {imageTranscript.post_description}
                  </div>
                )}
              </div>
            )}
            <div className='c-preview-modal__transcript__container'>
              <div className='c-preview-modal__transcript__title-div'>
                <ArrowIcon
                  className={classNames({
                    'c-preview-modal__transcript__title-div__rotate': expandedContent !== 'transcript',
                  })}
                  onClick={() => toggleSection('transcript')}
                />
                <span className='c-preview-modal__transcript__title-div__label'>
                  {openedMedia.source === 'image_caption' ? 'Caption' : 'Transcript'}
                </span>
              </div>
              {expandedContent === 'transcript' && (
                <Spin spinning={isLoading}>
                  <div
                    ref={ref2}
                    className='c-preview-modal__transcript__content'
                    style={{ height: calculatedHeight1 }}>
                    {imageTranscript.transcription.map((data) => (
                      <div className='c-preview-modal__transcript__info-container'>
                        {data.offset && <div className='c-preview-modal__transcript__time'>{data.offset}</div>}
                        <span
                          className={classNames('c-preview-modal__transcript__text', {
                            'c-preview-modal__transcript__text__bold':
                              openedMedia?.snippet_text.replace(/<\/?[^>]+(>|$)/g, '') === data.snippet_text,
                          })}>
                          {data.snippet_text}
                        </span>
                      </div>
                    ))}
                    <div className='c-preview-modal__transcript__info-container'>
                      <span className='c-preview-modal__transcript__text'>{''} </span>
                    </div>
                  </div>
                </Spin>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
