export type RolesPermissions = {
  [key: string]: {
    [feature: string]: string
  }
}

export const rolesPermissions: RolesPermissions = {
  VIEWER: {
    assets: 'turn_on_off',
    vectors: 'no_access',
    flags: 'no_access',
    library: 'no_access',
    myStuff: 'no_access',
    monitor: 'review_and_receive',
    manageProfile: 'full_access',
    manageTenant: 'no_access',
    riskWand: 'view',
  },
  USER: {
    assets: 'create',
    vectors: 'create',
    flags: 'create',
    library: 'full_access',
    myStuff: 'full_access',
    monitor: 'full_access',
    manageProfile: 'full_access',
    manageTenant: 'no_access',
    riskWand: 'full_access',
  },
  ANALYST: {
    assets: 'turn_on_off',
    vectors: 'view_create_watchlists',
    flags: 'turn_on_off',
    library: 'full_access',
    myStuff: 'full_access',
    monitor: 'full_access',
    manageProfile: 'full_access',
    manageTenant: 'no_access',
    riskWand: 'view',
  },
  BUILDER: {
    assets: 'create',
    vectors: 'create',
    flags: 'create',
    library: 'full_access',
    myStuff: 'full_access',
    monitor: 'full_access',
    manageProfile: 'full_access',
    manageTenant: 'no_access',
    riskWand: 'full_access',
  },
  ADMIN: {
    assets: 'create',
    vectors: 'create',
    flags: 'create',
    library: 'full_access',
    myStuff: 'full_access',
    monitor: 'full_access',
    manageProfile: 'full_access',
    manageTenant: 'full_access',
    riskWand: 'full_access',
  },
  PENDULUM_ADMIN: {
    assets: 'create',
    vectors: 'create',
    flags: 'create',
    library: 'full_access',
    myStuff: 'full_access',
    monitor: 'full_access',
    manageProfile: 'full_access',
    manageTenant: 'full_access',
    riskWand: 'full_access',
  },
}
