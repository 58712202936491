import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

export const getCashTagData = async ({
  page = 1,
  per_page = 1,
  query,
  powerInsightSort,
  conditions,
}: SnippetFilterPayload) => {
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}${query.hashtag}${query.cashtag}${query.handle}`
  const queryParams = `q=${q}&sort=${powerInsightSort}&per_page=${per_page}&page=${page}`

  const endpoint = `${process.env.REACT_APP_SEARCH_API}${ROUTES.powerInsightsCashTags}?${queryParams}`

  const { data } = await axios.post(endpoint, { conditions })
  return data
}
