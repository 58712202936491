import { observer } from 'mobx-react-lite'
import { Spin, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { useParams } from 'react-router-dom'
import { store } from 'store'
import moment from 'moment'

import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'
import { ReactComponent as EmailIcon } from 'assets/images/email.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/sort-arrow-icon.svg'
import { ReactComponent as DefaultSortIcon } from 'assets/images/sorter-icon.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import ProfileImage from 'assets/images/LogoiconMobile.svg'

import { AdminActionsType, IBasicInfo, IUsers } from 'models/models'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import { MANAGE_ADMIN_ACTIONS } from 'settings/settings'
import { SortInfo } from 'pages/Vectors/CreateEditWatchListStepper/types'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { genericDelete } from 'api/common/genericDelete'
import { Mode } from 'types/types'
import { ChangeOwnerModal } from 'components/common/ChangeOwnerModal/ChangeOwnerModal'
import { SubscriptionModal } from 'components/common/SubscriptionModal/SubscriptionModal'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import './Manage.scss'

const ENTITY_MAPPING_FOR_OWNERSHIP = {
  influencers: 'WATCHLIST',
  bookmarks: 'BOOKMARK',
  reports: 'REPORT',
  'horizon-topics': 'NARRATIVE',
  brands: 'BRAND',
  'followed-topics': 'FLAG',
}
export const List = observer(() => {
  const { section } = useParams()

  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedId, setSelectedId] = useState<string>('')
  const [selectedItemOwner, setSelectedItemOwner] = useState<IUsers | null>(null)
  const [loading, setLoading] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openChangeOwnerModal, setOpenChangeOwnerModal] = useState<boolean>(false)
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState<boolean>(false)
  const [sortStates, setSortStates] = useState<SortInfo>({
    columnName: 'name',
    direction: 'descend',
  })

  const { listData, pagination, users, setPagination, fetchData } = store['manageStore']
  const { showToaster } = store['toasterStore']

  const columns: ColumnsType<IBasicInfo> = useMemo(() => {
    return [
      {
        key: 'checkbox',
        dataIndex: 'id',
        title: '',
        width: '5%',
        render: (column, item) => {
          return (
            <div
              className={classNames('list__checkbox_container', {
                // eslint-disable-next-line
                ['list__checkbox_container__selected']: selectedRows.includes(item.id),
              })}
              aria-label='Select'
              onClick={(e) => {
                e.stopPropagation()

                setSelectedRows((prevState) =>
                  prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [item.id],
                )
              }}>
              {selectedRows.includes(item.id) && <CheckIcon aria-label='Select' />}
            </div>
            //eslint-enable
          )
        },
      },
      {
        key: 'name',
        dataIndex: 'name',
        width: '35%',
        title: () => {
          return (
            <span className='list__title'>
              Name
              <Sorter order={sortStates?.columnName === 'name' ? sortStates?.direction || null : null} />
            </span>
          )
        },
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'name' ? sortStates.direction : null,
        sorter: (a, b) => a.name.localeCompare(b.name),
        showSorterTooltip: false,
        render: (column, item) => {
          return <span className='list__name'>{column}</span>
        },
      },
      {
        key: 'created',
        dataIndex: 'created',
        width: '20%',
        title: () => {
          return (
            <span className='list__title'>
              Created on
              <Sorter order={sortStates?.columnName === 'created' ? sortStates?.direction || null : null} />
            </span>
          )
        },
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'created' ? sortStates.direction : null,
        sorter: (a, b) => a.created.localeCompare(b.created),
        showSorterTooltip: false,
        render: (column, item) => {
          return <span className='list__date'>{moment(column).format('YYYY-MM-DD')}</span>
        },
      },
      {
        key: 'owner',
        dataIndex: 'user',
        width: '20%',
        title: () => {
          return (
            <span className='list__title'>
              Owner
              <Sorter order={sortStates?.columnName === 'owner' ? sortStates?.direction || null : null} />
            </span>
          )
        },
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'owner' ? sortStates.direction : null,
        sorter: (a, b) => {
          const user1 = users?.[a.owner_id] || { given_name: '' }
          const user2 = users?.[b.owner_id] || { given_name: '' }

          return user1.given_name.localeCompare(user2.given_name)
        },
        showSorterTooltip: false,
        render: (column, item) => {
          return (
            <div className='list__owner-info'>
              <img
                className='list__owner-info__img'
                src={users?.[item.owner_id]?.avatar_url || ''}
                alt='owner.img'
                onError={(e: any) => (e.target.src = ProfileImage)}
              />
              <span className='list__owner-info__text'>
                {users?.[item.owner_id]
                  ? `${users?.[item.owner_id]?.given_name || ''} ${users?.[item.owner_id]?.family_name || ''}`
                  : ''}
              </span>
            </div>
          )
        },
      },
      {
        key: 'actions',
        title: 'Actions',
        width: '20%',
        render: (column, item) => {
          return (
            <div className='list__actions'>
              <button
                className='list__actions__icons__owner-btn'
                onClick={() => changeOwner(item.id, users?.[item.owner_id])}>
                Change owner
              </button>
              <div className='list__actions__icons'>
                <div className='list__actions__icons__div' onClick={() => shareItem(item.id)}>
                  <ShareIcon />
                </div>
                <div className='list__actions__icons__div' onClick={() => deleteItem(item.id)}>
                  <DeleteIcon />
                </div>
                {(section === 'horizon-topics' || section === 'brands') && (
                  <div className='list__actions__icons__div' onClick={subscribe}>
                    <EmailIcon height={20} />
                  </div>
                )}
              </div>
            </div>
          )
        },
      },
    ]
  }, [selectedRows, users, listData, sortStates])

  useEffect(() => {
    fetchListData({ page: 1, per_page: 10 })
  }, [section])

  const fetchListData = async (pagination: { page: number; per_page: number }) => {
    try {
      if (section) {
        setLoading(true)
        setPagination(pagination)
        await fetchData(section as AdminActionsType)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const shareItem = (id: string) => {
    setSelectedId(id)
    setOpenShareModal(true)
  }

  const deleteItem = (id: string) => {
    setSelectedId(id)
    setOpenDeleteModal(true)
  }

  const changeOwner = (id: string, user: IUsers) => {
    if (user) {
      setSelectedId(id)
      setSelectedItemOwner(user)
      setOpenChangeOwnerModal(true)
    }
  }

  const subscribe = () => {
    setOpenSubscriptionModal(true)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IBasicInfo> | SorterResult<IBasicInfo>[],
  ) => {
    if (!Array.isArray(sorter) && sorter.columnKey) {
      setSortStates({
        columnName: sorter.columnKey as string,
        direction: sorter.order || null,
      })
    }
  }

  const deleteHandler = async () => {
    try {
      await genericDelete(selectedId, MANAGE_ADMIN_ACTIONS[section || '']?.endpoint as Mode).then(
        (res: any | 'error') => {
          if (res !== 'error') {
            showToaster({
              iconType: 'success',
              message: ` ${MANAGE_ADMIN_ACTIONS[section || '']?.type}  deleted successfully`,
            })

            setSelectedRows([])
            setOpenDeleteModal(false)
            fetchListData({ page: 1, per_page: 10 })
          }
        },
      )
    } catch (error) {
      console.error('Error in deletion:', error)
      showToaster({
        iconType: 'error',
        message: 'An error occurred while sharing. Please try again.',
      })
    }
  }

  return (
    <Spin spinning={loading}>
      <Table
        className='list'
        columns={columns}
        dataSource={listData?.items || []}
        onChange={handleTableChange}
        pagination={false}
      />
      <div className='am_dashboard_pagination_container'>
        <DefaultPagination
          className='amc_pagination'
          totalCount={listData.total_count}
          currentPage={pagination.page}
          pageSize={pagination.per_page}
          showPageSizeChanger={false}
          nextIcon={ArrowNextIcon}
          prevIcon={ArrowNextIcon}
          iconHeight={20}
          iconWidth={20}
          onChange={(page, per_page) => {
            fetchListData({ page, per_page })
          }}
          locale={{ items_per_page: '' }}
        />
      </div>
      {openShareModal && section && selectedId && (
        <ShareReportModal
          title={`Share ${MANAGE_ADMIN_ACTIONS[section]?.type}`}
          icon={null}
          desc={
            section === 'reports'
              ? `Share this report with one or several team members and subscribe them to email notifications.`
              : `Share this ${MANAGE_ADMIN_ACTIONS[section]?.type} with one or several team members`
          }
          type='share'
          secondButtonText='Share'
          className='manage-admin-actions__report-modal'
          isReport={section === 'reports'}
          endpoint={MANAGE_ADMIN_ACTIONS[section]?.endpoint as ShareReportEndpoint}
          reportId={selectedId}
          onClose={() => {
            setOpenShareModal(false)
          }}
        />
      )}
      {openDeleteModal && section && selectedId && (
        <DeleteConfirmationModal
          title={`Are you sure you want to delete selected ${MANAGE_ADMIN_ACTIONS[section]?.type}?`}
          onClose={() => setOpenDeleteModal(false)}
          onContinue={deleteHandler}
        />
      )}
      {openChangeOwnerModal && selectedItemOwner && selectedId && (
        <ChangeOwnerModal
          open={openChangeOwnerModal}
          owner={selectedItemOwner}
          onClose={() => setOpenChangeOwnerModal(false)}
          onSuccess={() => {
            setOpenChangeOwnerModal(false)
            fetchListData({ page: 1, per_page: 10 })
          }}
          entityId={selectedId}
          entityType={ENTITY_MAPPING_FOR_OWNERSHIP[section as keyof typeof ENTITY_MAPPING_FOR_OWNERSHIP]}
        />
      )}
      {openSubscriptionModal && (
        <SubscriptionModal open={openSubscriptionModal} onClose={() => setOpenSubscriptionModal(false)} />
      )}
    </Spin>
  )
})

interface SorterProps {
  order: SortOrder
}
type SortOrder = 'descend' | 'ascend' | null

const Sorter = (props: SorterProps) => {
  const { order } = props

  return order ? <ArrowIcon className={classNames({ list__sortIcon: order === 'ascend' })} /> : <DefaultSortIcon />
}
