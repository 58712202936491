import { ReactNode } from 'react'

import './BrandDataFetching.scss'

const BrandDataFetching = ({
  emptyStateButton,
  emptyStateTitle = '',
  emptyStateDescription = '',
  emptyStateIcon = '',
}: {
  emptyStateTitle?: string
  emptyStateDescription?: string
  emptyStateIcon?: JSX.Element | ReactNode
  emptyStateButton?: JSX.Element | ReactNode
}) => {
  return (
    <div className='data-fetching__main-content brand-snippets-empty-state'>
      {emptyStateIcon}
      <div className='brand-snippets-empty-state__description'>
        <h3>{emptyStateTitle}</h3>
        {emptyStateDescription && <p>{emptyStateDescription}</p>}
        {emptyStateButton && emptyStateButton}
      </div>
    </div>
  )
}

export default BrandDataFetching
