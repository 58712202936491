import { observer } from 'mobx-react-lite'
import { store } from 'store'
import classNames from 'classnames'
import {
  ConditionsPayloadType,
  FolderListObjType,
  MoveTopFolderDataType,
  NarrativeListDataType,
  NarrativeParamsType,
} from 'types/types'
import { ReactComponent as FolderIcon } from 'assets/images/icons/dashboard/folder.svg'
import { ReactComponent as FolderOpen } from 'assets/images/icons/dashboard/folder-open.svg'
import { ReactComponent as DraggedIcon } from 'assets/images/icons/dashboard/menu-alt-4.svg'
import { ReactComponent as DraggedOver } from 'assets/images/icons/my_struff_icon.svg'
import { ReactComponent as SearchIcon } from 'assets/images/add-icon-grey.svg'
import { ReactComponent as MoreItems } from 'assets/images/three-dots.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { SetStateAction, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useEffectOnce } from 'react-use'
import { Dropdown, Skeleton, Spin } from 'antd'
import CreateFolderModal from 'components/Monitor/CreateFolderModal/CreateFolderModal'
import { useNavigate, useParams } from 'react-router-dom'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { ListSelectedRowData } from 'models/models'
import AlertModal from 'components/AlertModal/AlertModal'
import { AssetListObjType, EntityTypes } from 'store/asset/types'
import { ReactComponent as CloseIcon } from 'assets/images/icons/report/close-icon-size-20.svg'
import { ReactComponent as TickIcon } from 'assets/images/icons/monitor/check_icon.svg'
import { MaybeNull, Unknown } from 'utils/commonTypes'
import MoveToFolderModal from 'components/Monitor/MoveToFolderModal/MoveToFolderModal'
import TagManager from 'react-gtm-module'
import { ReactComponent as AddFolderIcon } from 'assets/images/icons/monitor/add_folder_icon.svg'
import usePermissions from 'hooks/usePermissions'
import AddBrandWizard from 'components/Asset/BrandsDashboard/components/AddBrandWizard/AddBrandWizard'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'
import { ReactComponent as MenuAltIcon } from 'assets/images/menu-alt.svg'
import { ReactComponent as CollapseIcon } from 'assets/images/icons/dashboard/collapse-icon-arrow.svg'
import TopicDetails from 'components/Asset/BrandsDashboard/components/TopicDetails/TopicDetails'
import './InfluencerListVertical.scss'
import { openNotification } from 'services/Util/openNotification'

type PropsType = {
  showSidebar: boolean
  selectedInfluencer: NarrativeListDataType | undefined
  setSelectedInfluencer: (searchItem: MaybeNull<NarrativeListDataType>) => void
  triggerReplaceItem: boolean
  setTriggerReplaceItem: React.Dispatch<SetStateAction<boolean>>
  triggerUpdateItem: { alertTurnedOff: boolean } | undefined
  setTriggerUpdateItem: React.Dispatch<SetStateAction<any>>
  handleCreateNewInfluencer: (state: boolean) => void
  setIsNarrativeCreation: (state: boolean) => void
}

type SearchItemDictonaryObject = {
  data: { total_count: number; items: any[] }
  per_page: number
  page: number
}

type OtherFolder = { name: string; id: string }
type Folder = { name: string; id: string }

const OTHER = 'OTHER'
const PER_PAGE = 10

const EmptyState = () => (
  <>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
    <p className='search-items-loading'>
      <Skeleton paragraph active />
    </p>
  </>
)

const InfluencerListVertical = observer((props: PropsType) => {
  const {
    selectedInfluencer,
    setSelectedInfluencer,
    showSidebar,
    triggerReplaceItem,
    setTriggerReplaceItem,
    triggerUpdateItem,
    setTriggerUpdateItem,
    handleCreateNewInfluencer,
    setIsNarrativeCreation,
  } = props

  const influencerURL = useParams()
  const navigate = useNavigate()
  const { vectorsStore, toasterStore, userStore } = store
  const { userId, userName, tenantId, roleId } = userStore

  const {
    userFolderList,
    setSnippetsFilter,
    snippetsFilter,
    fetchWatchlist,
    moveToFolder,
    CDUFolder,
    fetchFolderList,
    deleteItem,
    getSearchData,
    toggleIsSidebarOpen,
  } = vectorsStore
  const { showToaster } = toasterStore
  const { fetchIdsBasedData } = store.mainStore
  const [currentOpenFolder, setCurrentOpenFolder] = useState<
    { name: string; id: string; collapsed: boolean } | undefined
  >()
  const [searchItemsDictionary, setSearchItemsDictionary] = useState<{
    [key: string]: SearchItemDictonaryObject
  }>({})
  const [movingSearchItem, setMovingSearchItem] = useState(false)

  const [searchItemsDictionaryLoaders, setSearchItemsDictionaryLoaders] = useState<{ [key: string]: boolean }>({})
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false)
  const [folderToEdit, setFolderToEdit] = useState<FolderListObjType | OtherFolder | undefined>()
  const [searchItemToEdit, setSearchItemToEdit] = useState<NarrativeListDataType | undefined>()
  const [openShareFolderModal, setOpenShareFolderModal] = useState(false)
  const [openShareSearchItemModal, setOpenShareSearchItemModal] = useState(false)
  const [openDeleteFolderModal, setOpenDeleteFolderModal] = useState(false)
  const [isOpenDeleteSearchItemModal, setOpenDeleteSearchItemModal] = useState(false)
  const [isOpenSearchItemAlertModal, setOpenSearchItemAlertModal] = useState(false)
  const [isFolderNameEditOn, setIsFolderNameEditOn] = useState(false)
  const [editedFolderName, setEditedFolderName] = useState<string>('')
  const [openMoveToModal, setOpenMoveToModal] = useState<boolean>(false)
  const canCreateVectors = usePermissions('vectors') === 'create'
  const [topicToEdit, setTopicToEdit] = useState<MaybeNull<AssetListObjType>>()
  const [openViewDetails, setOpenViewDetails] = useState(false)
  const [actionLoading, setActionLoading] = useState(false)

  useEffectOnce(() => {
    openFolder({
      name: 'Other influencers',
      id: OTHER,
    })
  })

  useEffect(() => {
    if (triggerReplaceItem) {
      replaceDeletedItem(selectedInfluencer)
      setTriggerReplaceItem(false)
    }
  }, [triggerReplaceItem])

  useEffect(() => {
    if (triggerUpdateItem) {
      onAlertUpdateSuccess(selectedInfluencer, triggerUpdateItem.alertTurnedOff, () => setTriggerUpdateItem(undefined))
    }
  }, [triggerUpdateItem])

  const onChangeSearchItem = (searchItem: NarrativeListDataType) => {
    setSelectedInfluencer(searchItem)

    if (influencerURL.id) {
      navigate('/influencers')
      return
    }

    setSnippetsFilter({
      ...snippetsFilter,
      flags: {
        name: searchItem?.name || '',
        conditions: searchItem?.conditions || {},
      },
      isOverrideConditions: true,
      conditions: [searchItem?.conditions] as ConditionsPayloadType,
    })
  }

  const openFolder = (folder: Folder) => {
    const urlBasedInfluencerId = influencerURL?.id

    if (currentOpenFolder?.name === folder.name) {
      setCurrentOpenFolder({ ...currentOpenFolder, collapsed: !currentOpenFolder.collapsed })
      return
    }
    const params: NarrativeParamsType = {
      sort: 'name:asc',
      page: 1,
      per_page: PER_PAGE,
    }
    if (folder.id !== OTHER) {
      params.folder_id = folder.id
    }
    if (searchItemsDictionary[folder.id]) {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
    } else {
      setCurrentOpenFolder({ name: folder.name, id: folder.id, collapsed: false })
      setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [folder.id]: true })

      fetchWatchlist({ params: params })
        .then(async (data: any) => {
          let modifiedData
          if (!data?.total_count) {
            setSelectedInfluencer(null)
            return
          }

          // @ts-ignore
          let urlIdBasedInfluencer
          if (urlBasedInfluencerId) {
            setSelectedInfluencer(null)
            urlIdBasedInfluencer = await fetchIdsBasedData({ endPoint: 'watchlist', id: urlBasedInfluencerId })
            if (urlIdBasedInfluencer.narrative_number)
              urlIdBasedInfluencer = {
                ...urlIdBasedInfluencer,
                conditions: { narratives: [urlIdBasedInfluencer.narrative_number] },
              }
            else urlIdBasedInfluencer = null
          }
          if (urlIdBasedInfluencer?.id)
            modifiedData = {
              ...data,
              items: [
                urlIdBasedInfluencer,
                // @ts-ignore
                ...data?.items?.filter((item: Unknown) => item.id !== urlIdBasedInfluencer?.id),
              ],
            }
          else modifiedData = data
          setSearchItemsDictionary({
            ...searchItemsDictionary,
            [folder.id]: {
              data: modifiedData,
              page: 1,
              per_page: PER_PAGE,
            },
          })
          if (!urlBasedInfluencerId && !selectedInfluencer) {
            setSelectedInfluencer(data?.items[0])
          }
          if (!urlBasedInfluencerId && selectedInfluencer) {
            setSelectedInfluencer(selectedInfluencer)
          }
          if (urlBasedInfluencerId) {
            setSelectedInfluencer(urlIdBasedInfluencer)
          }
        })
        .finally(() => {
          setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [folder.id]: false })
        })
    }
  }

  const handleDragEnd = (result: any) => {
    if (!result.destination || !currentOpenFolder) return
    const { destination, draggableId } = result
    if (destination.droppableId === OTHER) return

    const requestBody: MoveTopFolderDataType = {
      action: 'add',
      entities: [{ entity_id: draggableId, entity_type: 'WATCHLIST' }],
    }
    setMovingSearchItem(true)
    moveToFolder({ id: destination.droppableId, data: requestBody })
      .then((res) => {
        if (res !== 'error') {
          showToaster({
            iconType: 'success',
            message: 'Successfully added item to your Folder',
          })
          const currentFolderData = searchItemsDictionary[currentOpenFolder.id]
          const filteredItems = currentFolderData.data.items.filter((item) => item.id !== draggableId)
          const newFolderData = { ...currentFolderData, data: { ...currentFolderData.data, items: filteredItems } }

          setSearchItemsDictionary({
            ...searchItemsDictionary,
            [currentOpenFolder.id]: draggableId === OTHER ? currentFolderData : newFolderData,
            [destination.droppableId]: null,
          })
        }
      })
      .finally(() => {
        setMovingSearchItem(false)
      })
  }

  const onLoadMore = () => {
    if (!currentOpenFolder) return
    setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [currentOpenFolder.id]: true })

    const currentFolderData = searchItemsDictionary[currentOpenFolder.id]

    const params: NarrativeParamsType = {
      sort: 'name:asc',
      page: currentFolderData.page + 1,
      per_page: PER_PAGE,
    }
    if (currentOpenFolder.id !== OTHER) {
      params.folder_id = currentOpenFolder.id
    }
    fetchWatchlist({ params: params })
      .then((data: any) => {
        setSearchItemsDictionary({
          ...searchItemsDictionary,
          [currentOpenFolder.id]: {
            data: { ...data, items: [...currentFolderData.data.items, ...data.items] },
            page: params.page as number,
            per_page: PER_PAGE,
          },
        })
      })
      .finally(() => {
        setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [currentOpenFolder.id]: false })
      })
  }

  const navigateToCreateSearch = () => {
    setIsNarrativeCreation(true)
    handleCreateNewInfluencer(true)
  }

  const addActionItems = [
    {
      label: (
        <div onClick={() => setOpenCreateFolderModal(true)} className='action-item'>
          <FolderIcon /> Add new folder
        </div>
      ),
      key: 'add-folder',
    },
    {
      label: (
        <div onClick={navigateToCreateSearch} className='action-item'>
          <SearchIcon />
          Add new topic
        </div>
      ),
      key: 'add-search',
    },
  ]

  const folderActions = [
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenShareFolderModal(true)
          }}
          className='action-item'>
          <ShareIcon /> Share
        </div>
      ),
      key: 'share-folder',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            if (!folderToEdit) return
            setEditedFolderName(folderToEdit.name)
            setIsFolderNameEditOn(true)
          }}
          className='action-item'>
          <EditIcon />
          Rename
        </div>
      ),
      key: 'rename-folder',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenDeleteFolderModal(true)
          }}
          className='action-item'>
          <DeleteIcon />
          Delete
        </div>
      ),
      key: 'delete-folder',
    },
  ]

  const searchItemActions = (searchItem: NarrativeListDataType) => [
    {
      label: (
        <div className='action-item'>
          <ShareIcon /> Share
        </div>
      ),
      onClick: () => {
        setOpenShareSearchItemModal(true)
      },
      key: 'share-item',
    },
    {
      label: (
        <div className='action-item'>
          {searchItemToEdit?.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
          Alerts
        </div>
      ),
      onClick: () => {
        setOpenSearchItemAlertModal(true)
      },
      key: 'alert-item',
    },
    {
      label: (
        <div className='action-item'>
          <EditIcon />
          Edit
        </div>
      ),
      onClick: () => {
        if (searchItem.permission_level !== 'EDITOR') return
        if (!searchItemToEdit) return
        setTopicToEdit(searchItemToEdit as unknown as AssetListObjType)
        setIsNarrativeCreation(true)
      },
      disabled: searchItem.permission_level !== 'EDITOR',
      key: 'rename-item',
    },
    {
      label: (
        <div className='action-item'>
          <InfoIcon />
          View details
        </div>
      ),
      onClick: () => {
        setOpenViewDetails(true)
      },
      key: 'view-item',
    },
    {
      label: (
        <div className='action-item'>
          <DeleteIcon />
          Delete
        </div>
      ),
      onClick: () => {
        if (searchItem.permission_level !== 'EDITOR') return
        setOpenDeleteSearchItemModal(true)
      },
      key: 'delete-item',
      disabled: searchItem.permission_level !== 'EDITOR',
    },
    {
      label: (
        <div
          onClick={(event) => {
            onStopEvent(event)
            setOpenMoveToModal(true)
          }}
          className='action-item'>
          <AddFolderIcon />
          Move to folder
        </div>
      ),
      key: 'move-to-folder',
    },
  ]

  const createFolderHandler = (value: string) => {
    if (value) {
      setActionLoading(true)
      CDUFolder({ method: 'post', data: { name: value } }).then((res) => {
        if (res !== 'error') {
          fetchFolderList({})
          openNotification({ type: 'info', message: `Folder created successfully` })
        }
        setOpenCreateFolderModal(false)
        setActionLoading(false)
      })
    }
  }

  const onStopEvent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  const onFolderMoreOptions = (event: React.MouseEvent<HTMLElement>, folder: FolderListObjType | OtherFolder) => {
    onStopEvent(event)
    setFolderToEdit(folder)
  }

  const onSearchItemMoreActions = (event: React.MouseEvent<HTMLElement>, searchItem: NarrativeListDataType) => {
    onStopEvent(event)
    setSearchItemToEdit(searchItem)
  }

  const onFolderDelete = () => {
    if (!folderToEdit) return
    setActionLoading(true)
    CDUFolder({ method: 'delete', id: folderToEdit.id }).then((res) => {
      if (res !== 'error') {
        fetchFolderList({})
        openNotification({ type: 'info', message: res })
      }
      setFolderToEdit(undefined)
      setOpenDeleteFolderModal(false)
      setActionLoading(false)
    })
  }

  const onSearchItemDelete = () => {
    if (!searchItemToEdit) return
    setActionLoading(true)
    deleteItem({ ...searchItemToEdit, ownerId: searchItemToEdit.owner_id } as ListSelectedRowData).then(
      (res: any | 'error') => {
        if (res !== 'error') {
          openNotification({ type: 'info', message: 'Item deleted successfully' })
          replaceDeletedItem(searchItemToEdit)
          setActionLoading(false)
        }
      },
    )
  }

  const replaceDeletedItem = (searchItemToEdit?: NarrativeListDataType) => {
    if (!searchItemToEdit) return
    if (currentOpenFolder) {
      const currentFolderDetails = searchItemsDictionary[currentOpenFolder.id]
      const filteredItems = currentFolderDetails.data.items.filter((item) => item.id !== searchItemToEdit.id)
      const newFolderDetails = {
        ...currentFolderDetails,
        data: {
          ...currentFolderDetails.data,
          items: filteredItems,
          total_count: currentFolderDetails.data.total_count - 1,
        },
      }
      setSearchItemsDictionary({ ...searchItemsDictionary, [currentOpenFolder.id]: newFolderDetails })

      // see if the current item is deleted and replace it with another search item
      const newItemToSelect = filteredItems[0]
      if (selectedInfluencer?.id === searchItemToEdit.id && newItemToSelect) {
        setSelectedInfluencer(newItemToSelect)
      } else if (selectedInfluencer?.id === searchItemToEdit.id && !newItemToSelect) {
        setSelectedInfluencer(null)
        openFolder({
          name: 'Other influencers',
          id: OTHER,
        })
      }
      setOpenDeleteSearchItemModal(false)
      setSearchItemToEdit(undefined)
    }
  }

  const updateEditedItem = (updatedItem: NarrativeListDataType) => {
    if (currentOpenFolder) {
      const currentFolderDetails = searchItemsDictionary[currentOpenFolder.id]
      const updatedItems = currentFolderDetails.data.items.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem
        }
        return item
      })
      const newFolderDetails = {
        ...currentFolderDetails,
        data: {
          ...currentFolderDetails.data,
          items: updatedItems,
        },
      }
      setSearchItemsDictionary({ ...searchItemsDictionary, [currentOpenFolder.id]: newFolderDetails })
    }
  }

  const onAlertUpdateSuccess = async (
    searchItemToEdit?: NarrativeListDataType,
    alertTurnedOff = false,
    callback: any = null,
  ) => {
    if (searchItemToEdit) {
      const data = await getSearchData(searchItemToEdit?.id)

      if (!currentOpenFolder) return
      const currentFolderDetails = searchItemsDictionary[currentOpenFolder.id]
      const modifiedItems = currentFolderDetails.data.items.map((item) => {
        if (item.id === searchItemToEdit.id) {
          return { ...item, alert_id: data.alert_id }
        }
        return item
      })
      const newFolderDetails = {
        ...currentFolderDetails,
        data: {
          ...currentFolderDetails.data,
          items: modifiedItems,
        },
      }
      setOpenSearchItemAlertModal(false)
      setSearchItemsDictionary({ ...searchItemsDictionary, [currentOpenFolder.id]: newFolderDetails })
      setSearchItemToEdit(undefined)
      showToaster({
        iconType: 'success',
        message: alertTurnedOff
          ? 'You will stop receiving alerts from now'
          : 'You will start receiving new alerts according to the selected preferences',
      })
      if (callback) {
        callback()
        if (searchItemToEdit.id === selectedInfluencer?.id) {
          setSelectedInfluencer({ ...selectedInfluencer, alert_id: data.alert_id })
        }
      }
    }
  }

  const onOpenFolder = (folder: Folder) => {
    if (folderToEdit?.id === folder.id && isFolderNameEditOn) return
    openFolder(folder)
  }

  const onCloseFolderNameEdit = (event: any) => {
    onStopEvent(event)
    setEditedFolderName('')
    setIsFolderNameEditOn(false)
    setFolderToEdit(undefined)
  }

  const onEditFolderName = (event: any) => {
    onStopEvent(event)
    if (!folderToEdit) return
    CDUFolder({ method: 'put', id: folderToEdit.id, data: { name: editedFolderName } }).then((res) => {
      if (res !== 'error') {
        setEditedFolderName('')
        setIsFolderNameEditOn(false)
        setFolderToEdit(undefined)
        fetchFolderList({})
      }
    })
  }

  const customUserFolderList = useMemo(() => {
    const folders = {
      items: [...userFolderList.items, { name: 'Other influencers', id: OTHER }],
      total_count: userFolderList.total_count,
    }
    return folders
  }, [userFolderList])

  const moveToFolderhandler = (id: string) => {
    if (id && searchItemToEdit?.mode !== 'communities') {
      const requestBody: MoveTopFolderDataType = {
        action: 'add',
        entities: [{ entity_id: searchItemToEdit?.id as string, entity_type: 'NARRATIVE' }],
      }
      moveToFolder({ id, data: requestBody }).then((res) => {
        if (res !== 'error') {
          showToaster({
            iconType: 'success',
            message: 'Successfully added items to your Folder',
          })
        }
        setOpenMoveToModal(false)

        const params: NarrativeParamsType = {
          sort: 'name:asc',
          page: 1,
          per_page: PER_PAGE,
        }
        if (id !== OTHER) {
          params.folder_id = id
        }
        setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [id]: true })

        fetchWatchlist({ params: params })
          .then((data: any) => {
            setSearchItemsDictionary({
              ...searchItemsDictionary,
              [id]: { data: data, page: 1, per_page: PER_PAGE },
            })
            if (!selectedInfluencer) {
              setSelectedInfluencer(data?.items[0])
            }
          })
          .finally(() => {
            setSearchItemsDictionaryLoaders({ ...searchItemsDictionaryLoaders, [id]: false })
          })
      })
      TagManager.dataLayer({
        dataLayer: {
          event: 'add_to_folder',
          //@ts-ignore
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          entityId: searchItemToEdit?.id,
          entityType: 'NARRATIVE',
          folderId: id,
        },
      })
    }
  }

  return (
    <Spin spinning={movingSearchItem}>
      <div className='search-list-header'>
        <div className='search-list-header--title'>
          <div className='search-list-header__icon'>
            <MenuAltIcon />
          </div>
          <h5>Influencer Dashboard</h5>
        </div>

        <div className='search-list-header collapse' onClick={toggleIsSidebarOpen}>
          <CollapseIcon />
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='narrativeDroppable' type='narrative'>
          {(provided: any) => (
            <div
              className={classNames('searchItemList', {
                hideSearchItemList: !showSidebar,
              })}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              <div className='fixed'>
                <div className='list-section'>
                  {customUserFolderList.items.map((userFolder: FolderListObjType | OtherFolder) => (
                    <Droppable key={userFolder.id} droppableId={String(userFolder.id)}>
                      {(provided: any, snapshot: any) => (
                        <div className='folder-container' ref={provided.innerRef} {...provided.droppableProps}>
                          <div
                            className={classNames('folder-item', {
                              'folder-item-droppable': snapshot.isDraggingOver,
                              'folder-item-open': currentOpenFolder?.name === userFolder.name,
                            })}
                            onClick={() => onOpenFolder(userFolder)}>
                            {!snapshot.isDraggingOver ? (
                              currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed ? (
                                <FolderOpen />
                              ) : (
                                <FolderIcon />
                              )
                            ) : null}
                            {snapshot.isDraggingOver && <DraggedOver />}
                            {folderToEdit?.id === userFolder.id && isFolderNameEditOn ? (
                              <>
                                <input
                                  className='fpc_lc_c_input'
                                  type='text'
                                  value={editedFolderName}
                                  onChange={(e) => {
                                    setEditedFolderName(e.target.value)
                                  }}
                                />

                                <CloseIcon className='fpc_lc_chunk_dropdown_icon' onClick={onCloseFolderNameEdit} />
                                {editedFolderName ? (
                                  <TickIcon className='fpc_lc_chunk_dropdown_icon' onClick={onEditFolderName} />
                                ) : null}
                              </>
                            ) : (
                              <h6 className='title'>{userFolder.name}</h6>
                            )}
                            {userFolder.id !== OTHER && (
                              <Dropdown
                                menu={{ items: folderActions }}
                                key={'searchListFolderInVectorDashboard'}
                                placement='bottomRight'
                                trigger={['click']}
                                disabled={!canCreateVectors}>
                                <span
                                  className='folder-actions'
                                  onClick={(event) => onFolderMoreOptions(event, userFolder)}>
                                  <MoreItems
                                    className={classNames({
                                      'three-dot-icon-disabled': !canCreateVectors,
                                    })}
                                  />
                                </span>
                              </Dropdown>
                            )}
                          </div>
                          {currentOpenFolder?.name === userFolder.name && !currentOpenFolder.collapsed && (
                            <>
                              <div className='folder-items-list'>
                                {searchItemsDictionary[currentOpenFolder.id]?.data?.items?.map((searchItem, index) => (
                                  <Draggable key={searchItem.id} draggableId={String(searchItem.id)} index={index}>
                                    {(provided: any, snapshot: any) => (
                                      <div
                                        className='item-container'
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <div className='link' />
                                        <div
                                          onClick={(event) => {
                                            event.stopPropagation()
                                            onChangeSearchItem(searchItem)
                                          }}
                                          className={classNames(`brand-item`, {
                                            'brand-item-selected':
                                              selectedInfluencer?.id === searchItem.id || snapshot.isDragging,
                                          })}>
                                          {snapshot.isDragging && <DraggedIcon />}
                                          <h6 className='title'>{searchItem.name}</h6>
                                          <span onClick={(e) => e.stopPropagation()}>
                                            <Dropdown
                                              key={'searchListInVectorDashboard'}
                                              menu={{ items: searchItemActions(searchItem) }}
                                              placement='bottomRight'
                                              trigger={['click']}
                                              disabled={!canCreateVectors}>
                                              <span
                                                className='search-actions'
                                                onClick={(event) => onSearchItemMoreActions(event, searchItem)}>
                                                <MoreItems
                                                  className={classNames({
                                                    'three-dot-icon-disabled': !canCreateVectors,
                                                  })}
                                                />
                                              </span>
                                            </Dropdown>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}

                                {provided.placeholder}
                              </div>
                              {searchItemsDictionaryLoaders[currentOpenFolder.id] && <EmptyState />}
                              {!searchItemsDictionaryLoaders[currentOpenFolder.id] &&
                                searchItemsDictionary[currentOpenFolder.id]?.data?.items.length === 0 && (
                                  <p className='search-items-empty'>No results found</p>
                                )}
                              {!searchItemsDictionaryLoaders[currentOpenFolder.id] &&
                                searchItemsDictionary[currentOpenFolder.id]?.data?.items.length !==
                                  searchItemsDictionary[currentOpenFolder.id]?.data?.total_count && (
                                  <p className='search-items-load-more' onClick={onLoadMore}>
                                    Load more
                                  </p>
                                )}
                            </>
                          )}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
                <div className='action-section'>
                  <Dropdown
                    menu={{ items: addActionItems }}
                    placement='topLeft'
                    trigger={['click']}
                    disabled={!canCreateVectors}>
                    <span className='action-section__add'>Add new</span>
                  </Dropdown>
                </div>
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {openCreateFolderModal && (
        <CreateFolderModal
          onClose={() => setOpenCreateFolderModal(false)}
          onConfirm={createFolderHandler}
          confirmBtnLoading={actionLoading}
        />
      )}
      {folderToEdit && openShareFolderModal && (
        <ShareReportModal
          endpoint={'folder' as ShareReportEndpoint}
          reportId={folderToEdit.id}
          title='Share Folder'
          desc='Share this folder with one or several team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => {
            setOpenShareFolderModal(false)
            setFolderToEdit(undefined)
          }}
        />
      )}
      {searchItemToEdit && openShareSearchItemModal && (
        <ShareReportModal
          endpoint={searchItemToEdit?.mode as ShareReportEndpoint}
          reportId={searchItemToEdit.id}
          title='Share Topic'
          desc='Share this topic with one or several team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => {
            setOpenShareSearchItemModal(false)
            setSearchItemToEdit(undefined)
          }}
        />
      )}
      {folderToEdit && openDeleteFolderModal && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete this folder?'
          desc='This action cannot be undone.'
          onClose={() => {
            setOpenDeleteFolderModal(false)
            setFolderToEdit(undefined)
          }}
          onContinue={onFolderDelete}
          confirmBtnLoading={actionLoading}
          disabledSecondButton={!folderToEdit}
        />
      )}
      {searchItemToEdit && isOpenDeleteSearchItemModal && (
        <DeleteConfirmationModal
          title='Are you sure you want to delete this search?'
          desc='This action cannot be undone.'
          onClose={() => {
            setOpenDeleteSearchItemModal(false)
            setSearchItemToEdit(undefined)
          }}
          onContinue={onSearchItemDelete}
          confirmBtnLoading={actionLoading}
          disabledSecondButton={!searchItemToEdit}
        />
      )}
      {isOpenSearchItemAlertModal && (
        <AlertModal
          entity_id={searchItemToEdit?.id as string}
          entity_type={
            searchItemToEdit?.mode === 'community' ? null : (searchItemToEdit?.mode?.toUpperCase() as EntityTypes)
          }
          onClose={() => setOpenSearchItemAlertModal(false)}
          open={isOpenSearchItemAlertModal}
          alertId={searchItemToEdit?.alert_id as string}
          onSuccess={() => onAlertUpdateSuccess(searchItemToEdit, false)}
          onDeleteSuccess={() => onAlertUpdateSuccess(searchItemToEdit, true)}
        />
      )}
      {openMoveToModal && (
        <MoveToFolderModal
          data={userFolderList.items}
          onClose={() => setOpenMoveToModal(false)}
          onConfirm={moveToFolderhandler}
          onCreate={createFolderHandler}
        />
      )}
      {topicToEdit && (
        <AddBrandWizard
          setSelectedBrand={() => {}}
          editedBrandData={topicToEdit}
          isTopicCreation
          isNarrative
          handleCloseModal={(updatedData: any) => {
            if (updatedData) {
              console.log('updatedData', updatedData)
              updateEditedItem(updatedData)
            }
            setTopicToEdit(null)
          }}
        />
      )}
      {openViewDetails && <TopicDetails details={searchItemToEdit} onClose={() => setOpenViewDetails(false)} open />}
    </Spin>
  )
})

export default InfluencerListVertical
