import React from 'react'
import clsx from 'classnames'
import { Button, ButtonProps } from 'antd'

import './AntdButton.scss'

// Define button types including our custom 'outlined' type
export type ButtonType = ButtonProps['type'] | 'outlined' | 'outlined-light'

// Define button sizes for better consistency
export type ButtonSize = 'small' | 'medium' | 'large'

// Extended props for our custom button
export interface CustomButtonProps extends Omit<ButtonProps, 'type' | 'size'> {
  type?: ButtonType
  size?: ButtonSize
  fullWidth?: boolean
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
}

/**
 * Enhanced Ant Design Button component with consistent styling and additional features
 */
const AntdButton: React.FC<CustomButtonProps> = ({
  children,
  className = '',
  fullWidth = false,
  type = 'primary',
  size = 'medium',
  icon,
  iconPosition = 'left',
  ...rest
}) => {
  // Map our custom size to Ant Design's size
  const antdSize = size === 'small' ? 'small' : size === 'large' ? 'large' : 'middle'

  // Handle icon positioning
  const buttonContent = icon ? (
    <span className={`button-content icon-${iconPosition}`}>
      <span className='button-icon'>{icon}</span>
      {children && <span className='button-text'>{children}</span>}
    </span>
  ) : (
    children
  )

  const buttonType = (type: ButtonType) => {
    switch (type) {
      case 'outlined':
        return 'dashed'
      case 'outlined-light':
        return 'ghost'
      default:
        return type
    }
  }

  return (
    <Button
      className={clsx('antdcb', `antdcb-${size}`, {
        [className]: className,
        fullWidth: fullWidth,
        'with-icon': !!icon,
        [`icon-${iconPosition}`]: !!icon,
      })}
      {...rest}
      size={antdSize}
      type={buttonType(type)}>
      {buttonContent}
    </Button>
  )
}

export default AntdButton
