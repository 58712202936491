import { Skeleton } from 'antd'
import { ReactComponent as DoghnutEmptyState } from 'assets/images/icons/dashboard/doghnut-empty-state.svg'

export default function NewEmptyState() {
  return (
    <>
      <div className='new-share-of-empty-state'>
        <Skeleton.Input active={true} size={'large'} style={{ width: '100%', height: 40, borderRadius: '8px' }} block />
        <div className='doghnut'>
          <DoghnutEmptyState />
        </div>
        <div className='skeleton-paragraph'>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
          <p className='skeleton-paragraph__large'>
            <Skeleton paragraph active />
          </p>
        </div>
      </div>
    </>
  )
}
