import { observer } from 'mobx-react-lite'
import { Avatar, Dropdown } from 'antd'
import { store } from 'store'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import profileImage from 'assets/images/LogoiconMobile.svg'
import classNames from 'classnames'
import { ReactComponent as MoreItems } from 'assets/images/three-dots.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as BanIcon } from 'assets/images/icons/assets/ban.svg'

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { flagsAPI } from 'api/api'
import DeactivatedBrands from './DeactivatedBrands'
import { openNotification } from 'services/Util/openNotification'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'

import { ContactUs } from 'components/common/ContactUs/ContactUs'
import { ReactComponent as MenuAltIcon } from 'assets/images/menu-alt.svg'
import usePermissions from 'hooks/usePermissions'
import { ReactComponent as CollapseIcon } from 'assets/images/icons/dashboard/collapse-icon-arrow.svg'
import './BrandsListVertical.scss'

type PropsType = {
  handleCreateNewBrand: (brand?: AssetListObjType) => void
  selectedBrand: AssetListObjType | undefined
  setSelectedBrand: (brand?: AssetListObjType) => void
  triggerRefetchBrandList: boolean
  brandLoading: boolean
  parentCallController?: (isDeleteAbleBrand: boolean) => void
  setTriggerRefetchBrandList: Dispatch<SetStateAction<boolean>>
}

const BRANDS = 'Brands'

const BrandsListVertical = observer((props: PropsType) => {
  const {
    parentCallController,
    setSelectedBrand,
    selectedBrand,
    brandLoading,
    handleCreateNewBrand,
    triggerRefetchBrandList,
    setTriggerRefetchBrandList,
  } = props
  const { assetsStore } = store
  const { assetsList, assetsCategories, fetchAssetsList, deleteAsset, toggleIsSidebarOpen, setAssetsList } = assetsStore
  const [deactivatedBrands, setDeactivatedBrands] = useState<AssetListObjType[]>([])
  const [isOpenDeactivatedBrandsModal, setIsOpenDeactivatedBrandsModal] = useState(false)
  const [brandIdToDelete, setBrandIdToDelete] = useState<string | undefined>()
  const [openContactModal, setOpenContactModal] = useState(false)
  const userCanCreateAsset = usePermissions('assets') === 'create'
  const [menuKey, setMenuKey] = useState(0)
  const [actionLoading, setActionLoading] = useState(false)

  const getDeactivatedBrands = async (brandCategory: any) => {
    try {
      const params: AssetListParamsType = {
        page: 1,
        per_page: 100,
        sort: 'name:asc',
        is_asset: true,
        is_active: false,
        q: `category_id:eq:${brandCategory?.category_id}`,
      }
      const response = await flagsAPI.getFlagsList({ params })
      setDeactivatedBrands(response.data.items)
    } catch (error) {}
  }

  useEffect(() => {
    if (assetsCategories.length === 0) return
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    getDeactivatedBrands(brandCategory)
  }, [assetsCategories])

  useEffect(() => {
    if (triggerRefetchBrandList) {
      refetchBrandsList(() => setTriggerRefetchBrandList(false))
    }
  }, [triggerRefetchBrandList])

  const onChangeBrandSelection = (brand: AssetListObjType) => setSelectedBrand(brand)

  const refetchBrandsList = (callback?: any) => {
    const brandCategory = assetsCategories.find((item) => item.name === BRANDS)
    if (!brandCategory) return
    getDeactivatedBrands(brandCategory)
    const params: AssetListParamsType = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory?.category_id}`,
    }
    setSelectedBrand(undefined)
    fetchAssetsList({ params: params })
    if (callback) callback()
  }

  const onStopEvent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  const deactivateBrand = async (asset: AssetListObjType, selectedBrand: AssetListObjType) => {
    try {
      setActionLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: [asset.id], status: 'deactivate' })
      openNotification({
        type: 'info',
        message: 'Brand deactivated',
      })
      const newItemsList = assetsList.items.filter((item) => item.id !== asset.id)
      const newAssets = {
        total_count: assetsList.total_count - 1,
        items: newItemsList,
      }
      setAssetsList(newAssets)
      if (selectedBrand.id === asset.id) {
        const newBrandToSelect = newItemsList[0]
        if (newBrandToSelect) setSelectedBrand(newBrandToSelect)
        else setSelectedBrand(undefined)
      }
      setDeactivatedBrands([...deactivatedBrands, asset])
    } catch (error) {
    } finally {
      setActionLoading(false)
    }
  }

  const deleteHandler = (selectedBrand: AssetListObjType) => {
    if (!brandIdToDelete) return
    setActionLoading(true)
    deleteAsset({ id: brandIdToDelete }).then((res: any | 'error') => {
      if (res !== 'error') {
        parentCallController ? parentCallController(brandIdToDelete === selectedBrand?.id) : refetchBrandsList()
      }
      openNotification({
        type: 'info',
        message: 'Brand deleted successfully',
      })
      setBrandIdToDelete(undefined)
      setActionLoading(false)
    })
  }

  const searchItemActions = useMemo(
    () => (asset: AssetListObjType) =>
      [
        {
          label: (
            <div
              onClick={(event) => {
                onStopEvent(event)
                handleCreateNewBrand(asset)
                setMenuKey((prev) => prev + 1)
              }}
              className='action-item'>
              <EditIcon /> Edit
            </div>
          ),
          key: 'edit-item',
          disabled: !userCanCreateAsset,
        },
        {
          label: (
            <div
              onClick={(event) => {
                onStopEvent(event)
                setBrandIdToDelete(asset.id)
                setMenuKey((prev) => prev + 1)
              }}
              className='action-item'>
              <DeleteIcon /> Delete
            </div>
          ),
          key: 'delete-item',
          disabled: !userCanCreateAsset,
        },
        {
          label: (
            <div
              onClick={(event) => {
                onStopEvent(event)
                deactivateBrand(asset as AssetListObjType, selectedBrand as AssetListObjType)
                setMenuKey((prev) => prev + 1)
              }}
              className='action-item'>
              <BanIcon /> Deactivate
            </div>
          ),
          key: 'deactivate-item',
        },
      ],
    [deactivatedBrands, assetsList, selectedBrand],
  )

  return (
    <div className='brandListVertical'>
      <div className='fixed'>
        <div className='brand-list-header'>
          <div className='brand-list-header--title'>
            <div className='brand-list-header__icon'>
              <MenuAltIcon />
            </div>
            <h5>Brand Dashboard</h5>
          </div>

          <div className='brand-list-header collapse' onClick={toggleIsSidebarOpen}>
            <CollapseIcon />
          </div>
        </div>
        {selectedBrand && (
          <>
            {assetsList.items?.map((asset) => (
              <div
                onClick={() => onChangeBrandSelection(asset)}
                className={classNames(`brand-item`, {
                  'brand-item-selected': selectedBrand?.id === asset.id,
                })}
                key={`${asset.id}asset-item`}>
                <Avatar src={asset?.metadata?.brand_url || profileImage} />
                <h6 className='title'>{asset.name}</h6>
                <Dropdown
                  key={menuKey}
                  menu={{ items: searchItemActions(asset) }}
                  placement='bottomRight'
                  trigger={['click']}>
                  <span className='brand-item__search-actions' onClick={(event) => onStopEvent(event)}>
                    <MoreItems
                    // className={classNames({
                    //   'three-dot-icon-disabled': !userCanCreateAsset,
                    // })}
                    />
                  </span>
                </Dropdown>
              </div>
            ))}
          </>
        )}

        {!selectedBrand && !brandLoading && (
          <div className='brandListVertical__no-active-brand-placeholder'>There are no active brands yet</div>
        )}

        <div className='lower-section'>
          <div
            className={classNames('add-new-brand', {
              'add-new-brand-disabled': !userCanCreateAsset,
            })}
            onClick={() => {
              if (!userCanCreateAsset) return
              handleCreateNewBrand()
            }}>
            Add new brand
          </div>
          {deactivatedBrands.length > 0 && (
            <div className='deactivated-brands' onClick={() => setIsOpenDeactivatedBrandsModal(true)}>
              {deactivatedBrands.length} DEACTIVATED BRANDS
            </div>
          )}
        </div>
        {isOpenDeactivatedBrandsModal && (
          <DeactivatedBrands
            onClose={(success) => {
              if (success) {
                parentCallController ? parentCallController(brandIdToDelete === selectedBrand?.id) : refetchBrandsList()
              }
              setIsOpenDeactivatedBrandsModal(false)
            }}
            brandsList={deactivatedBrands}
          />
        )}
        {brandIdToDelete && (
          <DeleteConfirmationModal
            confirmBtnLoading={actionLoading}
            title='Are you sure you want to delete the selected Brand?'
            onClose={() => setBrandIdToDelete(undefined)}
            onContinue={() => deleteHandler(selectedBrand as AssetListObjType)}
          />
        )}
        <ContactUs openModal={openContactModal} setOpenModal={setOpenContactModal} />
      </div>
    </div>
  )
})

export default BrandsListVertical
