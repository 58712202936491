import axios from 'axios'
import millify from 'millify'
import { store } from 'store'
import { reaction } from 'mobx'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Row, Tooltip, Skeleton, notification } from 'antd'

import PulseLeftComponent from './PulseLeftComponent'
import BaseModal from 'components/BaseModal/BaseModal'
import PulseRightComponent from './PulseRightComponent'
import PulseCenterComponent from './PulseCenterComponent'
import AddBrandWizard from 'components/Asset/BrandsDashboard/components/AddBrandWizard/AddBrandWizard'

import { formatConditions } from './utils'
import { isObjectEmpty } from 'utils/helper'
import { ConditionsPayloadType } from 'types/types'
import { PulseContentSelectionTypes, Unknown } from 'utils/commonTypes'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import { ReactComponent as CrossIcon } from 'assets/images/cross_black.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warning-icon_modal.svg'
import { ReactComponent as DiamondWithBoxIcon } from 'assets/images/diamond_with_box.svg'
import { ReactComponent as SearchWithEnvelopIcon } from 'assets/images/search_with_envelop.svg'
import { ReactComponent as SearchWithWorldMapIcon } from 'assets/images/search_with_world_map.svg'

import './PulseDashboard.scss'

export type TabTypes = {
  id: string
  name: string
  tenant_id: string
  owner_id: string
  created: string
  last_updated: string
  last_updated_by: string
  is_enabled: true
  sections: {
    title: string
    summary_prompt: string
    entities: {
      type: string
      id: string
      value: string
      conditions: Unknown
    }[]
    refined_entities: {
      type: string
      id: string
      value: string
      conditions: Unknown
    }[]
    filters: FilterTypes
  }
  snippets_count?: number
}

export const defaultFilters = { source: [], language: [], platform: [], sentiment: [] }
export type FilterTypes = { source: string[]; language: string[]; platform: string[]; sentiment: string[] }

export const defaultSelectedContentItems = {
  brand: [] as Unknown[],
  flags: [] as Unknown[],
  topics: [] as Unknown[],
  followedTopics: [] as Unknown[],
  horizonTopics: [] as Unknown[],
  searches: [] as Unknown[],
  influencers: [] as Unknown[],
  bookmarks: [] as Unknown[],
}

export type DefaultSelectedContentItemsTypes = {
  brand: Unknown[]
  flags: Unknown[]
  topics: Unknown[]
  followedTopics: Unknown[]
  horizonTopics: Unknown[]
  searches: Unknown[]
  influencers: Unknown[]
  bookmarks: Unknown[]
}

type TabsListDataTypes = {
  total_count: number
  items: TabTypes[]
}

const MAX_CARDS = 10
const MIN_CARD_WIDTH = 130

export const getKeyByValue = <T extends Record<string, string>>(value: string, obj: T): keyof T | undefined => {
  return (Object.keys(obj) as (keyof T)[]).find((key) => obj[key] === value)
}

const PulseDashboard = () => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const containerRef = useRef<HTMLDivElement>(null)
  const [activeTab, setActiveTab] = useState<TabTypes>()
  const [tabName, setTabName] = useState<string>('')
  const [editTabId, setEditTabId] = useState<TabTypes>()
  const [cardWidth, setCardWidth] = useState(MIN_CARD_WIDTH)
  const [closedTabId, setClosedTabId] = useState<string>('')
  const [tabsDataLoading, setTabsDataLoading] = useState(false)
  const [deleteTabLoading, setDeleteTabLoading] = useState(false)
  const [createTabLoading, setCreateTabLoading] = useState(false)
  const [isAddNewTabModalOpened, setIsAddNewTabModalOpened] = useState(false)
  const [isCreateNewModalOpened, setIsCreateBewModalOpened] = useState(false)
  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState(false)
  const [isHorizonTopicModalOpen, setIsHorizonTopicModalOpen] = useState(false)
  const [isFollowedTopicModalOpen, setIsFollowedTopicModalOpen] = useState(false)
  const [isSkipSnippetInternalCall, setIsSkipSnippetInternalCall] = useState(true)
  const [tabsListData, setTabsListData] = useState<TabsListDataTypes>({ total_count: 0, items: [] })

  const [api, contextHolder] = notification.useNotification()

  const { snippetsFilter, setSnippetsFilter, defaultPlatforms, setActiveItem, setNavigationInfo } = store.mainStore
  const { fetchSnippets } = store.assetsStore
  const { isLoadingDefaultPlatforms } = store.loaderStore

  const handleFiltersPayload = (filters: FilterTypes) => ({
    filter_platform: filters.platform?.join(',')?.toLowerCase(),
    filter_sentiment: filters.sentiment?.join(',')?.toLowerCase(),
    snippet_source: filters.source?.join(',')?.toLowerCase(),
    filter_language: filters.language?.join(',')?.toLowerCase(),
  })

  const leftComponentRef = useRef<{
    setToggle: (value: boolean) => void
    setFilters: (value: FilterTypes) => void
    setFilters1: (value: FilterTypes) => void
    setSelectedContentItems1: (value: PulseContentSelectionTypes) => void
    setSelectedContentItems2: (value: PulseContentSelectionTypes) => void
    setModalSelectedContentItems1: (value: PulseContentSelectionTypes) => void
    setModalSelectedContentItems2: (value: PulseContentSelectionTypes) => void
  } | null>(null)

  const handleCreateNewClick = useCallback(() => setIsCreateBewModalOpened(true), [])

  const handlePowerInsightsClick = useCallback(() => {
    const state = {
      mode: 'asset',
      tabName: 'pulse',
      conditions: snippetsFilter.conditions,
    }
    const targetRoute = '/pulse/power-insights/metrics'
    setNavigationInfo({ comingFrom: 'pulse', to: '/pulse/power-insights/metrics', pageNumber: 1, pageSize: 10 })
    navigate(targetRoute, { state: JSON.parse(JSON.stringify(state)) })
  }, [snippetsFilter])

  const entityKeys = { asset: 'ASSET', narrative: 'NARRATIVE', watchlist: 'WATCHLIST', flag: 'FLAG' }
  const backendKeys = {
    brand: 'ASSET',
    horizonTopics: 'NARRATIVE',
    influencers: 'WATCHLIST',
    followedTopics: 'FLAG',
  }

  const handleCreateEntity = (entity: 'topic' | 'brand' | 'followedTopic') => {
    setIsCreateBewModalOpened(false)
    switch (entity) {
      case 'brand':
        setIsCreateAssetModalOpen(true)
        break
      case 'topic':
        setIsHorizonTopicModalOpen(true)
        break
      case 'followedTopic':
        setIsFollowedTopicModalOpen(true)
        break

      default:
        break
    }
  }

  let url = `${process.env.REACT_APP_API}/pulse`

  const handleSelectTab = async (tab: TabTypes) => {
    applyActiveTabData(tab)
    setActiveTab(tab)
    if (params?.id) navigate('/pulse', { state: { activeTab: tab } })
  }

  const applySelectedContent = (entities: Unknown, refined_entities: Unknown, filters: FilterTypes) => {
    leftComponentRef.current?.setFilters({ ...defaultFilters, ...filters })
    leftComponentRef.current?.setFilters1({ ...defaultFilters, ...filters })
    leftComponentRef.current?.setSelectedContentItems1({ ...defaultSelectedContentItems, ...entities })
    leftComponentRef.current?.setModalSelectedContentItems1({ ...defaultSelectedContentItems, ...entities })
    leftComponentRef.current?.setSelectedContentItems2({ ...defaultSelectedContentItems, ...refined_entities })
    leftComponentRef.current?.setModalSelectedContentItems2({ ...defaultSelectedContentItems, ...refined_entities })
  }

  const handleSnippetsAPICall = (filters: FilterTypes, conditions: Unknown) => {
    const modifiedConditions = !isObjectEmpty(conditions)
      ? ((Array.isArray(conditions) ? conditions : [conditions]) as Unknown)
      : []

    setIsSkipSnippetInternalCall(false)

    if (params.id) {
      setSnippetsFilter({
        ...snippetsFilter,
        date_key: 'post_uploaded',
        ...handleFiltersPayload({
          ...defaultFilters,
        }),
        days: {
          ...snippetsFilter.days,
          noOfDays: 730,
        },
        conditions: [{ document_ids: [params.id] }] as ConditionsPayloadType,
      })
      setActiveItem({ document_ids: [params.id] } as Unknown)
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        date_key: 'post_uploaded',
        ...handleFiltersPayload({
          ...filters,
          platform: filters.platform?.length ? filters.platform : defaultPlatforms?.split(','),
        }),
        conditions: modifiedConditions,
      })
      setActiveItem(modifiedConditions)
    }
  }

  const getModifiedSectionsVariables = (tabData: TabTypes) => {
    const entities = tabData?.sections?.entities?.map((item) => ({
      ...item,
      name: item.value,
      value: item.id,
      category: getKeyByValue(item.type, backendKeys),
    }))
    const refined_entities = tabData?.sections?.refined_entities?.map((item) => ({
      ...item,
      name: item.value,
      value: item.id,
      category: getKeyByValue(item.type, backendKeys),
    }))
    const contentSelection1Conditions = tabData?.sections?.entities?.flatMap((item) => item.conditions)
    const contentSelection2Conditions = tabData?.sections?.refined_entities?.flatMap((item) => item.conditions)
    const groupByEntities =
      // @ts-ignore
      entities && Object.groupBy(entities, (item) => getKeyByValue(item.type, backendKeys) as string)
    const groupByRefinedEntities =
      // @ts-ignore
      refined_entities && Object.groupBy(refined_entities, (item) => getKeyByValue(item.type, backendKeys) as string)

    return { groupByEntities, groupByRefinedEntities, contentSelection1Conditions, contentSelection2Conditions }
  }

  const applyActiveTabData = (tabData: TabTypes) => {
    if (isEmpty(tabData?.sections)) {
      applySelectedContent(defaultSelectedContentItems, defaultSelectedContentItems, defaultFilters)
      handleSnippetsAPICall(defaultFilters, [])
      return
    }
    const { contentSelection1Conditions, contentSelection2Conditions, groupByEntities, groupByRefinedEntities } =
      getModifiedSectionsVariables(tabData)
    applySelectedContent(groupByEntities, groupByRefinedEntities, tabData?.sections?.filters)
    const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
    handleSnippetsAPICall(tabData?.sections?.filters, conditions)
  }

  const getTabsSnippetsCount = async (data: TabsListDataTypes) => {
    try {
      const allSnippetsPromises = data.items?.map(async (item) => {
        if (!isEmpty(item.sections)) {
          const { contentSelection1Conditions, contentSelection2Conditions } = getModifiedSectionsVariables(item)
          const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)

          const modifiedConditions = !isObjectEmpty(conditions)
            ? ((Array.isArray(conditions) ? conditions : [conditions]) as Unknown)
            : []

          const handleFiltersPayload = (filters: FilterTypes) => ({
            platform: filters.platform?.join(',')?.toLowerCase(),
            sentiment: filters.sentiment?.join(',')?.toLowerCase(),
            source: filters.source?.join(',')?.toLowerCase(),
            language: filters.language?.join(',')?.toLowerCase(),
          })

          const modifiedFilters = handleFiltersPayload({
            ...item?.sections?.filters,
            platform: item?.sections?.filters.platform?.length
              ? item?.sections?.filters.platform
              : defaultPlatforms?.split(','),
          })

          try {
            const snippetsData = await fetchSnippets({
              conditions: modifiedConditions,
              isReset: true,
              filters: modifiedFilters,
            })
            return { ...item, snippets_count: snippetsData?.total_count }
          } catch (error) {
            console.error('Failed to fetch snippets for item:', item, error)
            return { ...item, snippets_count: 0 }
          }
        } else {
          try {
            const snippetsData = await fetchSnippets({
              isReset: true,
            })
            return { ...item, snippets_count: snippetsData?.total_count }
          } catch (error) {
            console.error('Failed to fetch snippets for item:', item, error)
            return { ...item, snippets_count: 0 }
          }
        }
      })

      const results = await Promise.allSettled(allSnippetsPromises)

      const updatedItems = results.map((result, index) =>
        result.status === 'fulfilled' ? result.value : { ...data.items[index], snippets_count: 0 },
      )
      return updatedItems as TabTypes[]
    } catch (error) {}
  }

  const getAllPulseInstances = async (preSelectedTab?: TabTypes) => {
    try {
      setTabsDataLoading(true)
      const response = await axios.get(url)
      const data = response.data as TabsListDataTypes
      const updatedItems = await getTabsSnippetsCount(data)
      setTabsListData({ ...data, items: updatedItems as TabTypes[] })

      if (params?.id) {
        setActiveTab(undefined)
        applyActiveTabData({ sections: [] } as Unknown)
      } else {
        const tabToSet = preSelectedTab || (updatedItems as TabTypes[])[0]
        setActiveTab(tabToSet)
        applyActiveTabData(tabToSet)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setTabsDataLoading(false)
    }
  }

  const createNewPulseInstance = async () => {
    if (!tabName) {
      return
    }
    try {
      setCreateTabLoading(true)

      const isSectionsEmpty = isObjectEmpty(editTabId?.sections)

      const pulse_definition = {
        title: activeTab?.name || '',
        summary_prompt: '',
        entities: editTabId?.sections?.entities,
        refined_entities: editTabId?.sections?.refined_entities,
        filters: editTabId?.sections?.filters,
      }

      if (editTabId?.id && isSectionsEmpty) {
        api['error']({
          message: 'Rename Tab',
          description: 'Cannot update Pulse Tab without content or filter selection',
        })
        setTabName('')
        setEditTabId(undefined)
        return
      }

      const payload = {
        id: editTabId?.id,
        name: tabName,
        pulse_definition: isSectionsEmpty ? {} : pulse_definition,
      }
      const response = await axios.post(url, payload)
      const modifiedDateInEditCase = tabsListData?.items?.map((item) => {
        if (item.id === editTabId?.id) return { ...item, name: tabName }
        else return item
      })

      let newCreatedTab = response.data
      if (response.data) {
        newCreatedTab = await getTabsSnippetsCount({ items: [response.data] } as TabsListDataTypes)
      }

      const modifiedDateInCreateCase = [...tabsListData.items, newCreatedTab[0]]
      setTabsListData({
        total_count: tabsListData.total_count + (editTabId?.id ? 0 : 1),
        items: editTabId?.id ? modifiedDateInEditCase : modifiedDateInCreateCase,
      })
      setTabName('')
      setEditTabId(undefined)
      setIsAddNewTabModalOpened(false)
    } catch (error) {
      console.log('error', error)
    } finally {
      setCreateTabLoading(false)
      setIsAddNewTabModalOpened(false)
    }
  }

  const updatePulseInstance = useCallback(
    async ({
      filters,
      entities,
      refinedEntities,
      isEnableAPICall = true,
      activeTab,
    }: {
      filters: FilterTypes
      isEnableAPICall: boolean
      entities: PulseContentSelectionTypes
      refinedEntities: PulseContentSelectionTypes
      activeTab?: TabTypes
    }) => {
      try {
        setCreateTabLoading(true)

        const modifiedEntities = Object.entries(entities ?? {})?.flatMap(([_, values]) => values)
        const reFinedEntities = Object.entries(refinedEntities ?? {})?.flatMap(([_, values]) => values)

        const contentSelection1Conditions = modifiedEntities?.flatMap((item) => item.conditions)
        const contentSelection2Conditions = reFinedEntities?.flatMap((item) => item.conditions)

        const pulse_definition = {
          title: activeTab?.name || '',
          summary_prompt: '',
          entities: modifiedEntities?.map((item) => ({
            type: item.mode ? entityKeys[item.mode as keyof typeof entityKeys] : item.type,
            id: item.id,
            value: item.name,
            conditions: item.conditions,
          })),
          refined_entities: reFinedEntities?.map((item) => ({
            type: item.mode ? entityKeys[item.mode as keyof typeof entityKeys] : item.type,
            id: item.id,
            value: item.name,
            conditions: item.conditions,
          })),
          filters,
        }

        const payload = {
          id: activeTab?.id,
          name: activeTab?.name,
          pulse_definition,
        }
        if (activeTab) await axios.post(url, payload)

        applySelectedContent(entities, refinedEntities, filters)

        setTabsListData((prev) => ({
          ...prev,
          items: prev.items?.map((tab) =>
            tab.id !== activeTab?.id ? tab : { ...tab, sections: pulse_definition, pulse_definition },
          ) as TabTypes[],
        }))

        if (isEnableAPICall) {
          const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
          handleSnippetsAPICall(filters, conditions)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setCreateTabLoading(false)
        setIsAddNewTabModalOpened(false)
      }
    },
    [activeTab],
  )

  const deletePulseInstance = async () => {
    try {
      setDeleteTabLoading(true)
      await axios.delete(`${url}/${closedTabId}`)

      const updatedList = tabsListData?.items?.filter((item) => item.id !== closedTabId)
      setTabsListData({
        total_count: tabsListData.total_count - 1,
        items: updatedList,
      })
      if (tabsListData.total_count > 1) {
        setActiveTab(updatedList[0])

        const { contentSelection1Conditions, contentSelection2Conditions, groupByEntities, groupByRefinedEntities } =
          getModifiedSectionsVariables(updatedList[0])

        applySelectedContent(groupByEntities, groupByRefinedEntities, updatedList[0]?.sections?.filters)
        const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
        handleSnippetsAPICall(updatedList[0]?.sections?.filters, conditions)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setClosedTabId('')
      setDeleteTabLoading(false)
    }
  }

  useEffect(() => {
    const updateCardSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth
        const newCardWidth = Math.max(containerWidth / MAX_CARDS, MIN_CARD_WIDTH)
        setCardWidth(newCardWidth)
      }
    }
    updateCardSize()
    window.addEventListener('resize', updateCardSize)
    return () => window.removeEventListener('resize', updateCardSize)
  }, [])

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    getAllPulseInstances(location?.state?.activeTab ? location?.state?.activeTab : undefined)
  }, [isLoadingDefaultPlatforms, location.state?.activeTab])

  useEffect(() => {
    const dispose = reaction(
      () => store.mainStore.snippetsTotal,
      (newTotalSnippets) => {
        if (activeTab)
          setTabsListData((prev) => ({
            ...prev,
            items: prev.items?.map((item) => {
              if (item.id === activeTab.id) {
                return { ...item, snippets_count: newTotalSnippets }
              }
              return item
            }),
          }))
      },
    )
    return () => dispose()
  }, [activeTab])

  const isTabsLoading = tabsDataLoading || isLoadingDefaultPlatforms

  return (
    <div className='pulse-dashboard'>
      {contextHolder}
      <div className='pulse-dashboard-tabs--container'>
        <div className='pulse-dashboard-tabs'>
          {!isTabsLoading &&
            tabsListData?.items?.map((tab) => (
              <div
                className={classNames('pulse-dashboard-tabs__tab', { active: activeTab?.id === tab.id })}
                style={{ minWidth: `${cardWidth}px` }}
                onClick={() => handleSelectTab(tab)}
                key={tab.id}>
                <span className='pulse-dashboard-tabs__tab__title'>{tab?.name || ''}</span>
                {tabsListData?.items?.length < 7 && !!tab?.snippets_count && (
                  <span className='pulse-dashboard-tabs__tab__count'>
                    <span className='pulse-dashboard-tabs__tab__count__text'>{millify(tab?.snippets_count || 0)}</span>
                  </span>
                )}

                <Tooltip title='Rename'>
                  <Button
                    className='pulse-dashboard-tabs__tab__edit-icon'
                    icon={<EditIcon />}
                    onClick={(e) => {
                      e.stopPropagation()
                      setTabName(tab?.name)
                      setEditTabId(tab)
                    }}
                  />
                </Tooltip>

                <Button
                  icon={<CrossIcon />}
                  onClick={(e) => {
                    e.stopPropagation()
                    setClosedTabId(tab.id)
                  }}
                />
              </div>
            ))}
          {!isTabsLoading && (
            <Tooltip
              title={
                tabsListData.total_count >= MAX_CARDS
                  ? `You've reached max amount of tabs, close some tabs to open a new one`
                  : ''
              }
              placement='bottomRight'>
              <Button
                disabled={tabsListData.total_count >= MAX_CARDS}
                onClick={() => setIsAddNewTabModalOpened(true)}
                className={classNames('add-tabs-btn', { isLimitExceeded: tabsListData.total_count >= MAX_CARDS })}>
                <PlusIcon />
              </Button>
            </Tooltip>
          )}
          {isTabsLoading && <Skeleton paragraph={{ rows: 1 }} active className='pulse-dashboard-tabs__loader' />}
        </div>
      </div>
      <Row>
        <PulseLeftComponent
          ref={leftComponentRef}
          handleUpdateTabContent={updatePulseInstance}
          id={params?.id}
          activeTab={activeTab}
        />
        <PulseCenterComponent
          tabsLoading={tabsDataLoading}
          skipInternalSnippetCall={isSkipSnippetInternalCall}
          handleAddContentClick={(value) => leftComponentRef.current?.setToggle(value)}
        />
        <PulseRightComponent
          parentLoading={tabsDataLoading}
          handleCreateNewClick={handleCreateNewClick}
          handlePowerInsightsClick={handlePowerInsightsClick}
        />
      </Row>

      <BaseModal
        closable={false}
        open={!!closedTabId}
        className='warning-modal'
        onCancel={() => setClosedTabId('')}
        footer={
          <>
            <Button type='text' onClick={() => setClosedTabId('')}>
              Cancel
            </Button>
            <Button type='primary' onClick={deletePulseInstance} loading={deleteTabLoading}>
              Close
            </Button>
          </>
        }>
        <div className='warning-modal__content'>
          <div className='warning-modal__content__icon'>
            <WarningIcon />
          </div>
          <div className='warning-modal__content__info'>
            <div className='warning-modal__content__info__title'>Are you sure you want to close the tab?</div>
            <div className='warning-modal__content__info__description'>
              Closing the tab will result in loosing all your content selections. This action cannot be undone
            </div>
          </div>
        </div>
      </BaseModal>

      <BaseModal
        closable
        isHeaderBordered
        isFooterBordered
        title={editTabId ? 'Edit tab' : 'Add new tab'}
        destroyOnClose
        className='tab-creation-modal'
        open={isAddNewTabModalOpened || !!editTabId}
        onCancel={() => {
          setIsAddNewTabModalOpened(false)
          setTabName('')
          setEditTabId(undefined)
        }}
        footer={
          <>
            <Button
              type='text'
              onClick={() => {
                setIsAddNewTabModalOpened(false)
                setTabName('')
                setEditTabId(undefined)
              }}>
              Cancel
            </Button>
            <Button
              type='primary'
              className='tab-creation-confirm-btn'
              onClick={createNewPulseInstance}
              loading={createTabLoading}
              disabled={createTabLoading || editTabId?.name === tabName}>
              {editTabId ? 'Update' : 'Confirm'}
            </Button>
          </>
        }>
        <div className='tab-creation-content'>
          <label>
            <span className='tab-creation-content__tab-label'>
              Tab name
              <span className='tab-creation-content__asterisk'>*</span>
            </span>
            <Input
              placeholder='Threatening conversations'
              key={'tab-creation-content__input'}
              value={tabName}
              onChange={(e) => setTabName(e.target.value)}
              required
            />
          </label>
        </div>
      </BaseModal>

      <BaseModal
        closable
        className='create-by-choice-modal'
        title={
          <div className='create-by-choice-modal__title'>
            <div className='create-by-choice-modal__title__text'>Create new item</div>
            <div className='create-by-choice-modal__title__description'>
              Select the type of item you want to create.
            </div>
          </div>
        }
        open={isCreateNewModalOpened}
        onCancel={() => setIsCreateBewModalOpened(false)}
        footer={null}>
        <div className='create-by-choice-modal__content'>
          <div className='option' onClick={() => handleCreateEntity('brand')}>
            <div className='option-icon'>
              <DiamondWithBoxIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Brand</div>
              <div className='option-info__description'>
                Add a new brand to track and analyze related online conversations and brand mentions.
              </div>
            </div>
          </div>
          <div className='option' onClick={() => handleCreateEntity('topic')}>
            <div className='option-icon'>
              <SearchWithWorldMapIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Horizon topic</div>
              <div className='option-info__description'>
                Create a topic that extends beyond your existing data lake by indexing new data sources.
              </div>
            </div>
          </div>
          <div className='option' onClick={() => handleCreateEntity('followedTopic')}>
            <div className='option-icon'>
              <SearchWithEnvelopIcon />
            </div>
            <div className='option-info'>
              <div className='option-info__title'>Followed topic</div>
              <div className='option-info__description'>
                Create a topic that uses data that already exists in your data lake or Library.
              </div>
            </div>
          </div>
        </div>
      </BaseModal>

      {isCreateAssetModalOpen && (
        <AddBrandWizard
          setSelectedBrand={() => null}
          editedBrandData={null}
          isTopicCreation={false}
          handleCloseModal={() => {
            setIsCreateAssetModalOpen(false)
          }}
        />
      )}

      {isHorizonTopicModalOpen && (
        <AddBrandWizard
          setSelectedBrand={() => null}
          editedBrandData={null}
          isNarrative
          isTopicCreation
          handleCloseModal={() => {
            setIsHorizonTopicModalOpen(false)
          }}
        />
      )}

      {isFollowedTopicModalOpen && (
        <AddBrandWizard
          setSelectedBrand={() => null}
          editedBrandData={null}
          isTopicCreation={true}
          handleCloseModal={() => {
            setIsFollowedTopicModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default observer(PulseDashboard)
