import { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import dayjs from 'dayjs'
import DetailViewHeader from 'components/Investigate/Reports/DetailViewHeader/DetailViewHeader'
import DetailViewLeftSection from 'components/Investigate/Reports/DetailViewLeftSection/DetailViewLeftSection'
import { Spin } from 'antd'
import { sectionObj } from 'store/report/valueTypes'
import DetailViewLeftSectionViewHistory from 'components/Investigate/Reports/DetailViewLeftSectionViewHistory/DetailViewLeftSectionViewHistory'
import { ReactComponent as LaunchIcon } from 'assets/images/launch-icon.svg'
import styles from './InvesigateReportsDetailPage.module.scss'
import DetailPanelRightSection from 'components/Investigate/Reports/DetailPanelRightSection/DetailPanelRightSection'
import { ReactComponent as CloseIcon } from 'assets/images/cross_black.svg'
import DetailViewSectionsList from 'components/Investigate/Reports/DetailViewSectionsList/DetailViewSectionsList'

const DATE_FORMAT = 'YYYY-MM-DD'

const InvesigateReportsDetailPage = observer(() => {
  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get('instance_id')
  const navigate = useNavigate()
  const [feedView, setFeedView] = useState(false)

  const { reportMainViewStore, mainStore } = store
  const { fetchUserBookmarkedSnippets, defaultPlatforms } = mainStore
  const {
    reportDetails,
    reportDetailsEntities,
    fetchReportDetailsAndSections,
    fetchReportSummaryHistory,
    isLoadingReports,
    setFeedsFilters,
    setCurrPage,
    setPrevPage,
    setSearchInputFeeds,
    setFeedsSearch,
    getOrDeleteReportHandler,
  } = reportMainViewStore

  const sectionRef = useRef<HTMLDivElement | null>(null)
  const [selectedSection, setSelectedSection] = useState<sectionObj>(
    'sections' in reportDetailsEntities && reportDetailsEntities.sections.length
      ? reportDetailsEntities.sections[0]
      : {},
  )
  const [selectedSubSection, setSelectedSubSection] = useState<string>('')

  const [showViewHistory, setShowViewHistory] = useState<boolean>(false)
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const scrollToMenuItem = (id: string) => {
    sectionRef.current = document.getElementById(id) as HTMLDivElement
    sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' })
  }

  useEffect(() => {
    if (reportId && instanceId) {
      fetchReportDetailsAndSections(reportId, instanceId)
      // to manage feed view
      if (defaultPlatforms.length) {
        setFeedsFilters({ platforms: defaultPlatforms })
      } else {
        setFeedsFilters({})
      }
      setCurrPage(1)
      setPrevPage(0)
      setSearchInputFeeds('')
      setFeedsSearch('')
      fetchUserBookmarkedSnippets()
      getOrDeleteReportHandler('get', reportId, instanceId)
    }
  }, [reportId, instanceId])

  useEffect(() => {
    if (reportId && reportDetailsEntities?.start_date && reportDetailsEntities?.end_date) {
      const requestParams = {
        start_date: dayjs(reportDetailsEntities.start_date).format(DATE_FORMAT),
        end_date: dayjs(reportDetailsEntities.end_date).format(DATE_FORMAT),
      }
      fetchReportSummaryHistory(reportId, requestParams)
    }
  }, [reportId, reportDetailsEntities?.start_date, reportDetailsEntities?.end_date])

  useEffect(() => {
    if (reportDetailsEntities.sections.length) {
      const handleScroll = () => {
        const scrollY = scrollContainerRef.current?.scrollTop
        const containerHeight = scrollContainerRef.current?.clientHeight
        const containerCenter = scrollY && containerHeight && scrollY + containerHeight / 1.5

        for (const section of reportDetailsEntities.sections) {
          const element = document.getElementById(section.id!)
          if (element && scrollContainerRef.current) {
            const offsetTop = element.offsetTop - scrollContainerRef.current.offsetTop
            const offsetBottom = offsetTop + element.clientHeight
            if (containerCenter && containerCenter >= offsetTop && containerCenter < offsetBottom) {
              return setSelectedSection(section)
            }
          }
        }
      }

      scrollContainerRef.current?.addEventListener('scroll', handleScroll)

      return () => {
        scrollContainerRef.current?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [reportDetailsEntities.sections])

  return (
    <div className={styles.irdp_container}>
      <Spin size='large' spinning={isLoadingReports}>
        <DetailViewHeader
          reportId={reportDetails.id}
          instanceId={reportDetailsEntities.instance_id}
          title={reportDetails.name}
          readingTime={reportDetailsEntities.read_time_txt}
          buttonText={feedView ? 'Close feed' : 'View details'}
          buttonIcon={feedView ? CloseIcon : LaunchIcon}
          backHandler={() => {
            navigate('/reports')
          }}
          onClick={() => {
            setFeedView(!feedView)
          }}
        />

        <div className={styles.irdp_c_grid_container}>
          {!feedView && (
            <>
              {showViewHistory ? (
                <div className={styles.gc_left_column_view_history}>
                  <DetailViewLeftSectionViewHistory
                    instanceId={instanceId!}
                    viewHistoryHandler={() => setShowViewHistory(false)}
                  />
                </div>
              ) : (
                <div className={styles.gc_left_column}>
                  <DetailViewLeftSection
                    onClick={scrollToMenuItem}
                    selectedSection={selectedSection}
                    viewHistoryHandler={() => setShowViewHistory(true)}
                  />
                </div>
              )}
            </>
          )}

          <div className={styles.gc_center_column} ref={scrollContainerRef}>
            {'sections' in reportDetailsEntities && reportDetailsEntities.sections.length ? (
              <DetailViewSectionsList
                data={reportDetailsEntities.sections}
                feedView={feedView}
                selectedSubSection={selectedSubSection}
                setSelectedSubSection={setSelectedSubSection}
              />
            ) : null}
          </div>
          {feedView && <DetailPanelRightSection selectedSubSection={selectedSubSection} />}
        </div>
      </Spin>
    </div>
  )
})

export default InvesigateReportsDetailPage
