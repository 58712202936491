import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import UsersList from 'components/UsersList/UsersList'
import CustomModal from 'components/common/CustomModal/CustomModal'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import profileImage from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/copied_icon.svg'
import { ReactComponent as EmailIcon } from 'assets/images/email.svg'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'

import './ShareReportModal.scss'
import useDebounce from 'hooks/useDebounce'
import { UtilService } from 'services/Util/Util'
import {
  ActionType,
  ApiMethodType,
  PermissionLevelType,
  ShareReportEndpoint,
  UserListResponse,
} from 'store/report/valueTypes'
import { Spin, Switch } from 'antd'
import ShareUserDropdown, { ShareUserDataType } from '../ShareUserDropdown/ShareUserDropdown'
import { openNotification } from 'services/Util/openNotification'
import TagManager from 'react-gtm-module'

type UserListObj = {
  id: string
  username: string
  email: string
  teant_id: string
  given_name: string
  family_name: string
  role: string
  avatar_url: string | null
  created: string
  last_updated: string
  status: string
}

type SharedUserListObj = {
  user_id?: string
  username?: string
  given_name?: string
  family_name?: string
  permission_level?: string
  localAdd?: boolean
}

interface ShareData {
  permission_level: string
  user_ids: string[]
  action: 'share' | 'unshare'
}

type ShareReportModalProps = {
  isReport?: boolean
  className?: string
  onClose: () => void
  endpoint: ShareReportEndpoint
  reportId?: string
  instanceId?: string
  icon?: React.ElementType | null
  title?: string
  desc?: string
  type?: 'share' | 'email'
  secondButtonText?: string
  callback?: () => void
  emailSubscriptionDisabled?: boolean
}

type SelectedValueAndUserObj = {
  selectedValue: ShareUserDataType
  selectedUser: SharedUserListObj
}

type SharedUsersListProps = {
  isReport?: boolean
  data?: SharedUserListObj[]
  subscribedUsers: string[]
  className?: string
  type?: string
  onClick: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
  updateSubscribedUsers: (id: string, status: boolean) => void
  emailSubscriptionDisabled?: boolean
}

type SharedUserListChunkProps = {
  isReport?: boolean
  className?: string
  [x: string]: any
  onClick?: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
  updateSubscribedUsers: (id: string, status: boolean) => void
  emailSubscriptionDisabled?: boolean
}

const PER_PAGE = 50

const dropdownData = [
  { id: '0', option: 'can view', value: 'VIEWER' },
  { id: '1', option: 'can edit', value: 'EDITOR' },
]

const SharedUserListChunk = (props: SharedUserListChunkProps) => {
  const { className, onClick, onSelect, isReport, updateSubscribedUsers, emailSubscriptionDisabled, ...rest } = props
  const findObj = dropdownData.find((res) => res.value === rest.permission_level) || dropdownData[0]

  return (
    <div className={`users_list_container ${className}`}>
      <div className='ulc_image_container'>
        <img
          src={UtilService.isImageUrl(rest?.avatar_url) ? rest?.avatar_url : profileImage}
          alt='danger'
          className='ulc_image'
        />
      </div>
      <div className='ulc_content'>
        <div className='ulc_c_name_container'>
          <p className='ulc_c_name'>{rest?.given_name}</p>

          <div className='ulc_c_info'>
            {rest?.type === 'email' ? (
              <p className='ulc_c_remove_email_share' onClick={() => onClick?.(rest?.user_id)}>
                Remove
              </p>
            ) : rest?.permission_level === 'owner' ? (
              <p className='ulc_c_remove ulc_c_owner_share'>Owner</p>
            ) : (
              <ShareUserDropdown
                data={dropdownData}
                closeWhenClick={true}
                value={findObj.option}
                selectedValue={findObj}
                onSelect={(val) => {
                  onSelect?.({ selectedValue: val as ShareUserDataType, selectedUser: rest as SharedUserListObj })
                }}
                onRemove={() => onClick?.(rest?.user_id)}
              />
            )}
            {isReport && (
              <div className='ulc_c_email_container'>
                <EmailIcon />
                <Switch
                  className='mail-switch'
                  checked={rest.isSubscribed}
                  onChange={(value) => {
                    updateSubscribedUsers(rest.user_id, value)
                  }}
                  disabled={emailSubscriptionDisabled}
                />
              </div>
            )}
          </div>
        </div>
        <p className='ulc_c_email'>{rest?.username}</p>
      </div>
    </div>
  )
}

const SharedUsersList = (props: SharedUsersListProps) => {
  const {
    data = [],
    type = 'share',
    onClick,
    onSelect,
    isReport,
    subscribedUsers,
    updateSubscribedUsers,
    emailSubscriptionDisabled,
  } = props
  return (
    <div>
      {data.map((user: any) => {
        const isSubscribed = subscribedUsers?.includes(user.user_id)

        return (
          <SharedUserListChunk
            key={user.user_id}
            {...{ ...user, isSubscribed }}
            onClick={onClick}
            onSelect={onSelect}
            type={type}
            isReport={isReport}
            updateSubscribedUsers={updateSubscribedUsers}
            emailSubscriptionDisabled={emailSubscriptionDisabled}
          />
        )
      })}
    </div>
  )
}

const ShareReportModal = observer((props: ShareReportModalProps) => {
  const {
    isReport = false,
    className = '',
    onClose,
    endpoint,
    reportId = '',
    instanceId = '',
    title = 'Share the report',
    icon,
    desc = "Choose the users you'd like to share this recurring report with and specify who should be subscribed to receive email updates.",
    type = 'share',
    secondButtonText = 'Share Report',
    callback,
    emailSubscriptionDisabled,
  } = props
  const { reportMainViewStore, navViewStore, toasterStore, userStore, tenantsStore } = store
  const { userId, userName, tenantId, roleId } = userStore
  const {
    users,
    shareUsers,
    setShareUsers,
    updateSharedList,
    shareToAll,
    fetchUsersAndShareList,
    fetchUsersWithParams,
    sendReportAsEmail,
    isLoadingSharedUser,
    isConfirmLoading,
    isSendingEmail,
  } = reportMainViewStore
  const { isCorporateCommunicationsTheme } = tenantsStore

  const { isNavOpen } = navViewStore
  const { showToaster } = toasterStore
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedUsers, setSelectedUsers] = useState<SharedUserListObj[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [usersPage, setUsersPage] = useState<number>(2)
  const [isShareToAll, setShareToAll] = useState<boolean>(false)
  const [isPermission, setPermission] = useState('can view')
  const [isPermissionForAPI, setPermissionForAPI] = useState('VIEWER')
  const [subscribedUsers, setSubscribedUsers] = useState<string[]>([])

  const debouncedSearchValue = useDebounce(searchValue)

  const toastTarget = {
    reports: 'Report',
    narrative: isCorporateCommunicationsTheme ? 'Topic' : 'Narrative',
    tag: 'Tag',
    watchlist: isCorporateCommunicationsTheme ? 'Influencer List' : 'Watchlist',
    folder: 'Folder',
    bookmark: 'Bookmark',
  }

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: PER_PAGE,
    }
    fetchUsersAndShareList(endpoint, reportId, requestParams).then((response) => {
      setSubscribedUsers(response?.subscribed_users || [])
      setPermissionForAPI(response?.tenant_level_access || 'VIEWER')
    })
  }, [])

  useEffect(() => {
    setPermission((dropdownData.find((res) => res.value === shareUsers?.tenant_level_access) || dropdownData[0]).option)
    setShareToAll(shareUsers?.tenant_level_access ? true : false)
  }, [reportId, shareUsers?.tenant_level_access])

  const searchDropHandler = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [isOpen],
  )

  const newUsers = useMemo(() => {
    if (users.items?.length && 'shared_users' in shareUsers && shareUsers.shared_users?.length) {
      return users.items.filter(
        (user) => !shareUsers.shared_users.some((res: SharedUserListObj) => res.user_id === user.id),
      )
    }
    return users.items
  }, [users, shareUsers?.shared_users])

  const filterUsers = useMemo(() => {
    if (debouncedSearchValue && users.items?.length) {
      return newUsers.filter(
        (user) =>
          user.given_name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      )
    }
    return newUsers
  }, [debouncedSearchValue, newUsers])

  const sharedUserListHandler = async (
    methodType: ApiMethodType,
    userIds?: string[],
    action?: ActionType,
    permissionLevel?: PermissionLevelType,
    subscribedUsers?: string[],
  ) => {
    if (methodType === 'post') {
      const requestPayload: any = {
        user_ids: userIds ? userIds : [''],
        action: action ? action : '',
        permission_level: permissionLevel ? permissionLevel : '',
      }
      if (subscribedUsers) requestPayload['subscribed_user_ids'] = subscribedUsers

      return updateSharedList(methodType, endpoint, reportId, requestPayload)
    } else {
      return updateSharedList(methodType, endpoint, reportId)
    }
  }

  const ShareWithAll = (props: SharedUserListChunkProps) => {
    const { className, onClick, onSelect, ...rest } = props
    const permissionCheck = (permission: any) => {
      setPermission(permission.option)
      setPermissionForAPI(permission.value)
    }
    return (
      <div>
        {rest?.permission_level === 'viewer' ? (
          <p className='ulc_c_remove ulc_c_owner_share'>can edit</p>
        ) : (
          <ShareUserDropdown
            data={dropdownData}
            closeWhenClick={true}
            value={isPermission}
            onSelect={(val) => {
              permissionCheck(val)
            }}
            ShareAll={true}
          />
        )}
      </div>
    )
  }

  const selectUserHandler = (userInfo: UserListObj) => {
    const haveUser = selectedUsers.some((user) => user.user_id === userInfo.id)
    if (haveUser) {
      const removeUser = selectedUsers.filter((user) => user.user_id !== userInfo.id)
      setSelectedUsers(removeUser)
      searchDropHandler(false)
      return
    }
    const addUser = {
      family_name: userInfo.family_name,
      given_name: userInfo.given_name,
      permission_level: 'VIEWER',
      user_id: userInfo.id,
      username: userInfo.username,
      localAdd: true,
    }

    setSelectedUsers((prevState: SharedUserListObj[]) => [...prevState, addUser as SharedUserListObj])
    searchDropHandler(false)
  }

  const updateShareUsersPermission = ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => {
    sharedUserListHandler('post', [selectedUser.user_id!], 'share', selectedValue.value).then((res) => {
      if (res !== 'error') {
        sharedUserListHandler('get')
      }
    })
  }

  const updateSelectedUsersPermission = ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => {
    setSelectedUsers((prevState: SharedUserListObj[]) =>
      prevState.map((user) =>
        user.user_id === selectedUser.user_id ? { ...user, permission_level: selectedValue.value } : user,
      ),
    )
  }

  const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
    return arr1?.length === arr2?.length && arr1?.every((value) => arr2?.includes(value))
  }

  const isSubscriptionChanged = !areArraysEqual(shareUsers.subscribed_users || [], subscribedUsers)

  const shareUsersHandler = async () => {
    const getUsersByPermission = (level: 'VIEWER' | 'EDITOR'): string[] => {
      return selectedUsers.filter((user) => user.permission_level === level).map((user) => user.user_id!)
    }

    const viewerIds = getUsersByPermission('VIEWER')
    const editorIds = getUsersByPermission('EDITOR')

    const updateSubscriptions = async () => {
      const result = await sharedUserListHandler('post', [], 'share', 'EDITOR', subscribedUsers)
      if (result === 'error') return false

      const updateResult = await sharedUserListHandler('get')
      if (updateResult === 'error') return false

      showToaster({
        iconType: 'success',
        message: 'Email subscription for reports has been successfully updated.',
      })
      return true
    }

    const handleShareToAll = async () => {
      const data: ShareData = {
        permission_level: isPermissionForAPI,
        user_ids: ['*'],
        action: 'share',
      }

      if (isSubscriptionChanged) {
        const success = await updateSubscriptions()
        if (!success) return
      }

      if (viewerIds?.length || editorIds?.length) {
        await handleSpecificUsers()
      }

      if (!shareUsers.tenant_level_access?.length || isPermissionForAPI !== shareUsers.tenant_level_access) {
        const result = await shareToAll(endpoint, reportId, data)
        if (result === 'error') return

        const updateResult = await sharedUserListHandler('get')
        if (updateResult === 'error') return

        showToaster({
          iconType: 'success',
          message: `Successfully shared ${toastTarget[endpoint]} with all team members`,
        })

        logShareEvent(data.user_ids)
      }
    }

    const handleSpecificUsers = async () => {
      let sharePromises = []
      let sharedUserCount = 0

      if (viewerIds?.length) {
        sharePromises.push(sharedUserListHandler('post', viewerIds, 'share', 'VIEWER'))
        sharedUserCount += viewerIds?.length
      }

      if (editorIds?.length) {
        sharePromises.push(sharedUserListHandler('post', editorIds, 'share', 'EDITOR', subscribedUsers))
        sharedUserCount += editorIds?.length
      }

      if (sharePromises?.length) {
        const results = await Promise.all(sharePromises)
        if (results.includes('error')) return

        const updateResult = await sharedUserListHandler('get')
        if (updateResult === 'error') return

        showToaster({
          iconType: 'success',
          message: `Successfully shared ${toastTarget[endpoint]} with ${sharedUserCount} team members`,
        })

        logShareEvent([...viewerIds, ...editorIds])
      }
    }

    const handleUnshareAll = async () => {
      if ((shareUsers.tenant_level_access?.length ?? 0) > 0) {
        const data: ShareData = {
          permission_level: isPermissionForAPI,
          user_ids: ['*'],
          action: 'unshare',
        }
        await shareToAll(endpoint, reportId, data)
      }
    }

    const logShareEvent = (sharedUsers: string[]) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'share',
          entityType: endpoint,
          entityId: reportId,
          user_id: userId,
          user_name: userName,
          tenantId: tenantId,
          roleId: roleId,
          sharedUsers,
        },
      })
    }
    try {
      if (isShareToAll || (shareUsers.tenant_level_access && isPermissionForAPI !== shareUsers.tenant_level_access)) {
        await handleShareToAll()
      } else if (viewerIds?.length || editorIds?.length) {
        await handleSpecificUsers()
      } else if (isSubscriptionChanged) {
        await updateSubscriptions()
      } else {
        await handleUnshareAll()
      }

      setSelectedUsers([])
      setSearchValue('')
      callback?.()
      onClose()
    } catch (error) {
      console.error('Error in shareUsersHandler:', error)
      showToaster({
        iconType: 'error',
        message: 'An error occurred while sharing. Please try again.',
      })
    }
  }

  const removeUserHandler = (id: string) => {
    const ids = [id]
    sharedUserListHandler('post', ids, 'unshare', 'VIEWER').then((res) => {
      if (res !== 'error') {
        sharedUserListHandler('get').then((res) => {
          if (res !== 'error') {
            callback?.()
          }
        })
      }
    })
  }

  const localRemoveUserHandler = (id: string) => {
    const filterSelectedUsers = selectedUsers.filter((user) => user.user_id !== id)
    setSelectedUsers(filterSelectedUsers)
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight
    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (users.total_count !== users.items?.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: usersPage,
          per_page: PER_PAGE,
        }
        fetchUsersWithParams(requestParams).then((res: UserListResponse | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...users.items, ...res.items]?.length !== res.total_count) {
            setUsersPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  const removeEmailUserHandler = (id: string) => {
    const remainingUsers = shareUsers.shared_users.filter((user) => user.user_id !== id)
    setShareUsers({ ...shareUsers, shared_users: remainingUsers })
  }

  const sendEmailHandler = async () => {
    const users = shareUsers.shared_users.map((user) => user.user_id)
    const response = await sendReportAsEmail({
      id: reportId,
      instanceId,
      users: [shareUsers?.owner?.user_id, ...users],
    })
    onClose()
    if (response) {
      openNotification({
        type: 'info',
        placement: 'bottomLeft',
        description: <span className='ren_description'>Report sent successfully</span>,
        className: isNavOpen ? 'report_email_notification ren_margin' : 'ren_notification',
        icon: <SuccessIcon width={24} height={24} />,
      })
    }
  }
  const onChangeShareAll = (checked: boolean) => {
    setShareToAll(checked)
  }

  const updateSubscribedUsers = (id: string, status: boolean) => {
    const hasUser = subscribedUsers.includes(id)
    if (status && !hasUser) {
      setSubscribedUsers((prev) => [...prev, id])
    } else if (!status && hasUser) {
      const otherUsers = subscribedUsers.filter((value: string) => value !== id)
      setSubscribedUsers(otherUsers)
    }
  }

  return (
    <CustomModal
      boxContainerClassName={className}
      onClose={onClose}
      onConfirm={type === 'email' ? sendEmailHandler : shareUsersHandler}
      disabledSecondButton={
        isShareToAll === true
          ? false
          : (shareUsers.tenant_level_access?.length ?? 0) > 0
          ? false
          : type === 'email'
          ? selectedUsers?.length < 1 && shareUsers?.shared_users?.length < 1
          : endpoint === 'reports'
          ? selectedUsers?.length < 1 && !isSubscriptionChanged
          : selectedUsers?.length < 1
      }
      title={title}
      icon={icon}
      secondButtonText={secondButtonText}
      footerClassName='share_report_modal_footer_container'>
      <div className='share_report_modal_container'>
        <p className='srmc_text'>{desc}</p>
        <Spin spinning={isLoadingSharedUser || isConfirmLoading || isSendingEmail}>
          <SearchInput
            placeholder='Search by name or email'
            showIcon={isReport}
            className='srmc_input'
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            onClear={() => setSearchValue('')}
            showDrop={true}
            isOpen={isOpen}
            value={searchValue}
            setIsOpen={searchDropHandler}
            onScroll={handleScroll}>
            <div className='srmc_input_content_container'>
              <UsersList data={filterUsers} className='srmc_input_cc_chunk' onClick={selectUserHandler} />
            </div>
          </SearchInput>
          <div className='share_report_content_container'>
            <div className='users_list_container' style={{ justifyContent: 'space-between' }}>
              <div className='c_name_container' style={{ fontSize: '14px' }}>
                <Switch
                  className='ant-switch amc_switch'
                  onChange={onChangeShareAll}
                  style={{ marginRight: '10px' }}
                  checked={isShareToAll}
                />
                Share with all team members
              </div>
              {type !== 'email' && 'owner' in shareUsers && Object.keys(shareUsers.owner)?.length ? (
                <ShareWithAll
                  permission_level='owner'
                  {...shareUsers.owner}
                  subscribedUsers={subscribedUsers}
                  updateSubscribedUsers={updateSubscribedUsers}
                />
              ) : null}
            </div>
            {!isReport && type !== 'email' && 'owner' in shareUsers && Object.keys(shareUsers.owner)?.length ? (
              <SharedUserListChunk
                permission_level='owner'
                {...shareUsers.owner}
                subscribedUsers={subscribedUsers}
                updateSubscribedUsers={updateSubscribedUsers}
              />
            ) : null}
            {'shared_users' in shareUsers && shareUsers.shared_users?.length ? (
              <SharedUsersList
                isReport={isReport}
                data={shareUsers.shared_users}
                subscribedUsers={subscribedUsers}
                type={type}
                onClick={type === 'email' ? removeEmailUserHandler : removeUserHandler}
                onSelect={updateShareUsersPermission}
                updateSubscribedUsers={updateSubscribedUsers}
                emailSubscriptionDisabled={emailSubscriptionDisabled}
              />
            ) : null}
            {selectedUsers?.length ? (
              <SharedUsersList
                isReport={isReport}
                data={selectedUsers}
                subscribedUsers={subscribedUsers}
                type={type}
                onClick={localRemoveUserHandler}
                onSelect={updateSelectedUsersPermission}
                updateSubscribedUsers={updateSubscribedUsers}
                emailSubscriptionDisabled={emailSubscriptionDisabled}
              />
            ) : null}
            {isReport && type !== 'email' && 'owner' in shareUsers && Object.keys(shareUsers.owner)?.length ? (
              <SharedUserListChunk
                permission_level='owner'
                {...{ ...shareUsers.owner, isSubscribed: subscribedUsers?.includes(shareUsers?.owner?.user_id) }}
                isReport={isReport}
                subscribedUsers={subscribedUsers}
                updateSubscribedUsers={updateSubscribedUsers}
                emailSubscriptionDisabled={emailSubscriptionDisabled}
              />
            ) : null}
          </div>
          {isReport && (
            <div className='email-subscribe-info'>
              <InfoIcon />
              <span className='text'>
                Users will still have access to the report on the platform, even if they are not subscribed to updates.
              </span>
            </div>
          )}
        </Spin>
      </div>
    </CustomModal>
  )
})

export default ShareReportModal
