import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import DetailViewHeader from 'components/Investigate/Reports/DetailViewHeader/DetailViewHeader'
import { ReactComponent as CloseIcon } from 'assets/images/cross_black.svg'
import DetailViewSectionDetail from 'components/Investigate/Reports/DetailViewSectionDetail/DetailViewSectionDetail'
import DetailPanelRightSection from 'components/Investigate/Reports/DetailPanelRightSection/DetailPanelRightSection'
import styles from './ReportDetailsPanel.module.scss'
import { Spin } from 'antd'

const ReportDetailsPanel = observer(() => {
  const { reportId } = useParams()
  const [searchParams] = useSearchParams()
  const instanceId = searchParams.get('instance_id')
  const navigate = useNavigate()

  const { reportMainViewStore, mainStore } = store
  const {
    reportDetailsEntities,
    getOrDeleteReportHandler,
    isLoading,
    isReportFeedsLoading,
    setFeedsFilters,
    setCurrPage,
    setPrevPage,
    setSearchInputFeeds,
    setFeedsSearch,
  } = reportMainViewStore

  const { fetchUserBookmarkedSnippets, defaultPlatforms } = mainStore

  useEffect(() => {
    if (reportId && instanceId) {
      if (defaultPlatforms.length) {
        setFeedsFilters({ platforms: defaultPlatforms })
      } else {
        setFeedsFilters({})
      }
      setCurrPage(1)
      setPrevPage(0)
      setSearchInputFeeds('')
      setFeedsSearch('')
      getOrDeleteReportHandler('get', reportId, instanceId)
      fetchUserBookmarkedSnippets()
    }
  }, [reportId, instanceId, defaultPlatforms])

  const [editableId, setEditableId] = useState<string>('')
  const [selectedTextChunkId, setSelectedTextChunkId] = useState<string>('')
  const [textChunkValue, setTextChunkValue] = useState<string>('')

  return (
    <div className={styles.report_details_panel_container}>
      <Spin size='large' spinning={isLoading}>
        <div>
          <DetailViewHeader
            instanceId={instanceId || ''}
            isDetailPanel={true}
            reportId={reportDetailsEntities.id}
            title={reportDetailsEntities.name}
            readingTime={reportDetailsEntities.read_time_txt}
            buttonText='Close details'
            buttonIcon={CloseIcon}
            className={styles.rdpc_l_header_container}
            onClick={() => {
              navigate(`/reports/${reportId}?instance_id=${instanceId}`)
            }}
          />
          <div className={styles.rdpc_grid_container}>
            <div className={styles.rdpc_gc_left_column}>
              <div className={styles.rdpc_l_cc_section_container}>
                {'sections' in reportDetailsEntities && reportDetailsEntities.sections.length
                  ? reportDetailsEntities.sections.map((section, index) => (
                      <DetailViewSectionDetail
                        currentIndex={index}
                        key={section.id}
                        {...section}
                        sectionData={section}
                        editable={true}
                        editableId={editableId}
                        setEditableId={setEditableId}
                        selectedTextChunkId={selectedTextChunkId}
                        setSelectedTextChunkId={setSelectedTextChunkId}
                        textChunkValue={textChunkValue}
                        setTextChunkValue={setTextChunkValue}
                        startDate={reportDetailsEntities.start_date}
                        endDate={reportDetailsEntities.end_date}
                      />
                    ))
                  : null}
              </div>
            </div>
            <div className={styles.rdpc_right_column}>
              <Spin spinning={isReportFeedsLoading}>
                <DetailPanelRightSection />
              </Spin>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
})

export default ReportDetailsPanel
