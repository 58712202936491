import { Table, TablePaginationConfig, Tooltip } from 'antd'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'

import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import millify from 'millify'
import classNames from 'classnames'
import { store } from 'store'
import { createPortal } from 'react-dom'

import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as DisabledCheckIcon } from 'assets/images/disabled-tick-icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/sort-arrow-icon.svg'
import { ReactComponent as DefaultSortIcon } from 'assets/images/sorter-icon.svg'
import { ReactComponent as ViewIcon } from 'assets/images/icon-eye-normal.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import YoutubeIcon from 'assets/images/youtube-new-icon.svg'
import BitchuteIcon from 'assets/images/bitchute-icon.svg'
import RumbleIcon from 'assets/images/rumble-icon.svg'
import PodcastIcon from 'assets/images/podcast-icon.svg'
import TikTokIcon from 'assets/images/tiktok-icon.svg'
import NewsIcon from 'assets/images/news-icon.svg'
import TwitterIcon from 'assets/images/twitter-x-new-logo.svg'
import RedditIcon from 'assets/images/reddit-icon.svg'
import TruthSocialIcon from 'assets/images/truth-social-grey-icon.svg'
import ChanIcon from 'assets/images/4chan-icon.svg'
import GabIcon from 'assets/images/gab-icon.svg'
import PatriotsIcon from 'assets/images/patriots-grey-icon.svg'
import VkIcon from 'assets/images/vk-icon-current-color.svg'
import InstagramIcon from 'assets/images/instagram-logo.svg'
import ThreadsIcon from 'assets/images/threads-logo.svg'
import TelegramIcon from 'assets/images/telegram-logo.svg'
import CSpanIcon from 'assets/images/cspan-black-icon.svg'
import WebSearchIcon from 'assets/images/web-search-black-icon.svg'
import ConsiliumIcon from 'assets/images/consilium-black-icon.svg'
import BrighteonIcon from 'assets/images/brighteon-logo-icon.svg'
import OdyseeIcon from 'assets/images/odysee-icon.svg'
import SnapchatIcon from 'assets/images/snapchat-logo-icon.svg'
import Linkedin from 'assets/images/linkedin.svg'
import BlueSky from 'assets/images/bluesky.svg'

import { SubStore } from 'types/types'
import { ICreatorData, SnippetFilterPayload, sourcePlatform } from 'models/models'
import { DetailsObjType } from 'types/types'
import { API, monitorAPI } from 'api/api'

import './CreatorsList.scss'
import SkeletonTable from './SkeletonTable'

interface CreatorInfoProps {
  creator: ICreatorData
}

interface SorterProps {
  order: SortOrder
}

interface SelectionOverlayProps {
  count: number
  applyFilter: () => void
  onClearFilter: () => void
}

type IColumn = any
type SortOrder = 'descend' | 'ascend' | null
type SortInfo = {
  columnName: string
  direction: SortOrder
}
type CreatorsData = {
  total_count: number
  items: ICreatorData[]
}

type SimilarCreatorMetrics = {
  [x: string]: {
    snippet: number
    impression: number
  }
}

interface Props {
  data: CreatorsData
  subStore: SubStore
  showSearchedData: boolean
  searchedCreator: ICreatorData[]
  isLoadingPICreators: boolean
  showSelectionCheckBoxes: boolean
  isSimilarCreators?: boolean
  updateSimilarCreatorWatchlistInfo?: (channelInfo: { channel_id: string; status: boolean }[]) => void
  selectedCreators?: any[]
  setSelectedCreators?: any
}

// eslint-disable-next-line no-unused-vars
export const platformIcons: { [K in Lowercase<sourcePlatform>]: string } = {
  youtube: YoutubeIcon,
  bitchute: BitchuteIcon,
  rumble: RumbleIcon,
  podcast: PodcastIcon,
  tiktok: TikTokIcon,
  news: NewsIcon,
  twitter: TwitterIcon,
  reddit: RedditIcon,
  '4chan': ChanIcon,
  gab: GabIcon,
  truthsocial: TruthSocialIcon,
  'patriots.win': PatriotsIcon,
  vk: VkIcon,
  instagram: InstagramIcon,
  threads: ThreadsIcon,
  telegram: TelegramIcon,
  'c-span': CSpanIcon,
  websearch: WebSearchIcon,
  consilium: ConsiliumIcon,
  brighteon: BrighteonIcon,
  snapchat: SnapchatIcon,
  odysee: OdyseeIcon,
  bluesky: BlueSky,
  linkedin: Linkedin,
}

export const CreatorsList = observer((props: Props) => {
  const {
    data,
    subStore,
    showSearchedData,
    searchedCreator,
    isLoadingPICreators,
    showSelectionCheckBoxes,
    isSimilarCreators = false,
    updateSimilarCreatorWatchlistInfo,
    selectedCreators = [],
    setSelectedCreators = () => {},
  } = props
  const { isCorporateCommunicationsTheme } = store.tenantsStore
  const pageSize = 5

  const [sortStates, setSortStates] = useState<SortInfo>({
    columnName: 'followers',
    direction: 'descend',
  })

  const {
    snippetsFilter,
    filterChips,
    activeItem,
    setOpenedCreatorId,
    setOpenedCreatorIdHistory,
    setSnippetsFilter,
    setFilterChips,
    updatePowerInsightsCreatorWatchlistInfo,
    getSnippetFilterPayload,
    fetchPowerInsights,
    fetchSnippetsInfo,
    setIsCreatorModalOpen,
  } = store[`${subStore}Store`]

  const [selectedRows, setSelectedRows] = useState<string[]>(snippetsFilter.creators || [])
  const [showFilterApplied, setShowFilterApplied] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [watchlistFlag, setWatchlistFlag] = useState<boolean>(true)
  const [similarCreatorMetrics, setSimilarCreatorMetrics] = useState<SimilarCreatorMetrics>({})

  const columns = useMemo(
    () => [
      {
        key: 'id',
        dataIndex: 'channel_id',
        width: '5%',
        render: (column: IColumn, item: ICreatorData) => {
          return (
            <div
              className={classNames('creators-list__table__checkbox', {
                'creators-list__table__checkbox__selected':
                  !(snippetsFilter.creators?.length ?? 0) && selectedRows.includes(column),
              })}
              aria-label='Select'
              onClick={(e) => {
                selectCreators(e, item)
              }}>
              {selectedRows.includes(column) &&
                ((snippetsFilter.creators?.length ?? 0) > 0 ? (
                  <DisabledCheckIcon />
                ) : (
                  <CheckIcon aria-label='Select' />
                ))}
            </div>
          )
        },
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Creator
            <Sorter order={sortStates?.columnName === 'creator' ? sortStates?.direction || null : null} />
          </span>
        ),
        dataIndex: 'channel_title',
        key: 'creator',
        width: '50%',
        showSorterTooltip: false,
        sortOrder: sortStates.columnName === 'creator' ? sortStates.direction : null,
        sorter: (a: ICreatorData, b: ICreatorData) => a.channel_title.localeCompare(b.channel_title),
        render: (column: IColumn, item: ICreatorData) => <CreatorInfo creator={item} />,
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Followers
            <Sorter order={sortStates?.columnName === 'followers' ? sortStates?.direction || null : null} />
          </span>
        ),
        dataIndex: 'followers_count',
        key: 'followers',
        width: '15%',
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend', null] as SortOrder[],
        sortOrder: sortStates.columnName === 'followers' ? sortStates.direction : null,
        sorter: (a: ICreatorData, b: ICreatorData) => a.followers_count - b.followers_count,
        render: (column: IColumn) => {
          let followers = 'N/A'
          const count = Number.isSafeInteger(column)
          if (count && Number(column) >= 0) {
            followers = millify(Number(column))
          }
          return <span className='creators-list__count'>{followers}</span>
        },
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Mentions <Sorter order={sortStates?.columnName === 'snippets' ? sortStates?.direction || null : null} />
          </span>
        ),
        dataIndex: 'snippet_count',
        key: 'snippets',
        width: '15%',
        showSorterTooltip: false,
        sortOrder: sortStates.columnName === 'snippets' ? sortStates.direction : null,
        sorter: (a: ICreatorData, b: ICreatorData) => a.snippet_count - b.snippet_count,
        render: (column: IColumn, item: ICreatorData) => {
          let value = 'N/A'
          let snippets = isSimilarCreators ? similarCreatorMetrics?.[item.channel_id]?.snippet : column
          const count = Number.isSafeInteger(snippets)
          if (count && Number(snippets) >= 0) {
            value = millify(Number(snippets))
          }
          return <span className='creators-list__count'>{value}</span>
        },
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Impressions
            <Sorter order={sortStates?.columnName === 'impressions' ? sortStates?.direction || null : null} />
          </span>
        ),
        dataIndex: 'impression_sum',
        key: 'impressions',
        width: '15%',
        showSorterTooltip: false,
        sortOrder: sortStates.columnName === 'impressions' ? sortStates.direction : null,
        sorter: (a: ICreatorData, b: ICreatorData) => a.impression_sum - b.impression_sum,
        render: (column: IColumn, item: ICreatorData) => {
          let value = 'N/A'
          let impressions = isSimilarCreators ? similarCreatorMetrics?.[item.channel_id]?.impression : column
          const count = Number.isSafeInteger(impressions)
          if (count && Number(impressions) >= 0) {
            value = millify(Number(impressions))
          }
          return <span className='creators-list__count'>{value}</span>
        },
      },
    ],
    [selectedRows, sortStates, watchlistFlag],
  )

  useEffect(() => {
    setSelectedRows(snippetsFilter.creators || [])
    if (!snippetsFilter.creators?.length) setShowFilterApplied(false)
  }, [snippetsFilter.creators])

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize

    const currentData = showSearchedData ? searchedCreator : data?.items || []
    if (currentData.length > 0) {
      const currentLoadedCreators = currentData.slice(startIndex, endIndex)
      fetchCreatorsWatchlistsInfo(currentLoadedCreators)
      if (isSimilarCreators) fetchSimilarCreatorsMetricsInfo(currentLoadedCreators)
    }
  }, [currentPage, data?.items, searchedCreator])

  const fetchCreatorsWatchlistsInfo = async (currentLoadedCreators: ICreatorData[]) => {
    try {
      const promises: any[] = []
      currentLoadedCreators.forEach((item) => {
        if (item.hasWatchlist === undefined || item.hasWatchlist === null)
          promises.push(
            API.get({
              route: 'watchlist',
              customAPI: process.env.REACT_APP_API,
              customProps: { channel_id: item.channel_id },
              isPromise: true,
            }),
          )
      })
      const responses = await Promise.all(promises)

      let flag = false
      const info = responses.map((response) => {
        const url = response.config.url.split('channel_id=')
        const { data: responseData } = response
        if (responseData?.items?.length > 0) flag = true
        return {
          channel_id: url[1],
          status: responseData?.items?.length > 0,
        }
      })

      if (flag) setWatchlistFlag((prev) => !prev)
      if (info.length > 0) {
        isSimilarCreators ? updateSimilarCreatorWatchlistInfo?.(info) : updatePowerInsightsCreatorWatchlistInfo(info)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSimilarCreatorsMetricsInfo = async (currentLoadedCreators: ICreatorData[]) => {
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const query = payload.query

      const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`

      const requestParams: { q: string; measure?: string } = {
        q,
        measure: 'impression',
      }
      const impressionPromises: any[] = []
      const snippetsPromises: any[] = []
      currentLoadedCreators.forEach((item) => {
        impressionPromises.push(
          monitorAPI.getInsightsMetrics({
            params: requestParams,
            data: {
              conditions: [
                {
                  channel_ids: [item.channel_id],
                },
              ],
            },
          }),
        )

        snippetsPromises.push(
          monitorAPI.getInsightsMetrics({
            params: { q },
            data: {
              conditions: [
                {
                  channel_ids: [item.channel_id],
                },
              ],
            },
          }),
        )
      })

      Promise.all(impressionPromises).then((responses) => {
        let metricsData: SimilarCreatorMetrics = { ...similarCreatorMetrics }
        responses.forEach((response) => {
          const conditions = JSON.parse(response.config.data)?.conditions
          if (conditions.length > 0) {
            const { data: responseData } = response
            const channelId = conditions?.[0]?.channel_ids?.[0]
            if (metricsData[channelId])
              metricsData[channelId] = {
                ...metricsData[channelId],
                impression: responseData?.total_value,
              }
            else
              metricsData[channelId] = {
                impression: responseData?.total_value,
                snippet: 0,
              }
          }
        })
        setSimilarCreatorMetrics(metricsData)
      })

      Promise.all(snippetsPromises).then((responses) => {
        let metricsData: SimilarCreatorMetrics = { ...similarCreatorMetrics }
        responses.forEach((response) => {
          const conditions = JSON.parse(response.config.data)?.conditions

          if (conditions.length > 0) {
            const { data: responseData } = response
            const channelId = conditions?.[0]?.channel_ids?.[0]
            if (metricsData[channelId])
              metricsData[channelId] = {
                ...metricsData[channelId],
                snippet: responseData?.total_value,
              }
            else
              metricsData[channelId] = {
                snippet: responseData?.total_value,
                impression: 0,
              }
          }
        })
        setSimilarCreatorMetrics(metricsData)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ICreatorData> | SorterResult<ICreatorData>[],
  ) => {
    if (!Array.isArray(sorter) && sorter.columnKey) {
      setSortStates({
        columnName: sorter.columnKey as string,
        direction: sorter.order || null,
      })
    }

    if (pagination?.current) setCurrentPage(pagination.current)
  }
  const selectCreators = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, column: ICreatorData) => {
    try {
      e.stopPropagation()
      if ((snippetsFilter.creators?.length ?? 0) > 0) return

      if (selectedRows.includes(column.channel_id)) {
        let newIds: string[] = []
        newIds = selectedRows.filter((id) => id !== column.channel_id)
        const newCreators = selectedCreators.filter((creator) => creator.channel_id !== column.channel_id)
        setSelectedRows(newIds)
        setSelectedCreators(newCreators)
      } else {
        setSelectedRows((prev) => [...prev, column.channel_id])
        setSelectedCreators((prev: any) => [...prev, column])
      }
      setShowFilterApplied(true)
    } catch (error) {
      console.log(error)
    }
  }

  const applyFilter = () => {
    try {
      if (selectedRows.length > 0) {
        const selectedCreators = data?.items?.filter((item) => selectedRows.includes(item.channel_id)) || []

        if (selectedCreators.length > 0) {
          setFilterChips({
            ...filterChips,
            creators: { ...filterChips['creators'], value: selectedCreators },
          })

          setShowFilterApplied(false)
          setSnippetsFilter({ ...snippetsFilter, creators: selectedRows })
          fetchPowerInsights({ ...activeItem, activeTab: 'creators' } as DetailsObjType)
          fetchSnippetsInfo(activeItem!)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onClearFilter = () => {
    setSelectedRows([])
    setFilterChips({
      ...filterChips,
      creators: {
        ...filterChips.creators,
        value: [],
      },
    })
    setShowFilterApplied(false)
  }

  return (
    <SkeletonTable loading={isLoadingPICreators}>
      <div className='creators-list'>
        <Table
          className='creators-list__table'
          columns={!showSelectionCheckBoxes ? columns.slice(1) : columns}
          dataSource={showSearchedData ? searchedCreator : data?.items || []}
          onChange={handleTableChange}
          rowClassName={(record) =>
            selectedRows.includes(record.channel_id) ? 'creators-list__table__selectedRow' : ''
          }
          pagination={{ pageSize: pageSize, showSizeChanger: false }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (isCorporateCommunicationsTheme) setIsCreatorModalOpen(true)
                setOpenedCreatorId(record.channel_id)
                setOpenedCreatorIdHistory(record.channel_id)
              },
            }
          }}
        />
        {showFilterApplied && selectedRows.length > 0 && (
          <SelectionInfoOverlay count={selectedRows.length} applyFilter={applyFilter} onClearFilter={onClearFilter} />
        )}
      </div>
    </SkeletonTable>
  )
})

const CreatorInfo = (props: CreatorInfoProps) => {
  const { creator } = props
  return (
    <div className='power-insights-creator-info'>
      <div className='power-insights-creator-info__container'>
        <img
          className='power-insights-creator-info__avatar'
          src={creator.channel_thumb_url || Avatar}
          alt='user'
          onError={(e: any) => {
            e.target.src = Avatar
          }}
        />
        <div className='power-insights-creator-info__container2'>
          <span className='power-insights-creator-info__name'>
            {creator.channel_title || creator.channel_name || ''}
          </span>
          <div className='power-insights-creator-info__container3'>
            <img
              className='power-insights-creator-info__platform'
              src={platformIcons[creator.platform as Lowercase<sourcePlatform>]}
              alt='platform'></img>
            {/* <span className='power-insights-creator-info__link'>{creator.channel_url || ''}</span> */}
          </div>
        </div>
      </div>
      {creator.hasWatchlist && (
        <Tooltip title={<div>This creator is on one or more active watchlists</div>}>
          <div className='power-insights-creator-info__view-icon'>
            <ViewIcon />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

const Sorter = (props: SorterProps) => {
  const { order } = props

  return order ? (
    <ArrowIcon className={classNames({ 'creators-list__table__sortIcon': order === 'ascend' })} />
  ) : (
    <DefaultSortIcon />
  )
}

const SelectionInfoOverlay = (props: SelectionOverlayProps) => {
  const { count, applyFilter, onClearFilter } = props

  return createPortal(
    <div className='selection-overlay'>
      <div className='selection-overlay__count'>
        <span>{count}</span>
      </div>
      <div className='selection-overlay__creator-div'>
        <span className='selection-overlay__creator-text'>Creators selected</span>
        <button className='selection-overlay__apply-filters' onClick={applyFilter}>
          Apply filter
        </button>
      </div>
      <div className='selection-overlay__close-icon' onClick={onClearFilter}>
        <CloseIcon />
      </div>
    </div>,
    document.getElementById('custom_modal')!,
  )
}
