import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { store } from 'store'
import { PlatformDistribution } from 'components/Monitor/PowerInsights/Summary/PlatformDistribution/PlatformDistribution'
import { DownloadOption } from '../DownloadOption'
import './styles.scss'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import { anticipatoryIntelligence } from 'api/api'
import { AssetListObjType } from 'store/asset/types'
import { NarrativeListDataType } from 'types/types'

const PlatformDistributionCard = ({
  isVerticalDisplay = false,
  subStore = 'assets',
  selectedBrand,
  openSnippetDiscovery,
}: {
  isVerticalDisplay?: boolean
  subStore?: 'assets' | 'vectors'
  selectedBrand: AssetListObjType | NarrativeListDataType
  openSnippetDiscovery?: (item: AssetListObjType | NarrativeListDataType) => void
}) => {
  const { isLoadingPlatformChart } = store.loaderStore
  const { defaultPlatforms, setPowerInsightsData, powerInsightsData } = store[`${subStore}Store`]

  const { setSnippetsFilter, snippetsFilterInfo, snippetsFilter, getSnippetFilterPayload, setSnippetsFilterInfo } =
    store[`${subStore}ModalStore`]

  const [currentDistributionMetric, setCurrentDistributionMetric] = useState<'posts' | 'impression' | 'engagement'>(
    'posts',
  )

  const selectedPlatforms = useMemo(() => {
    if (snippetsFilter?.filter_platform?.length === 0) return []
    return typeof snippetsFilter.filter_platform === 'string'
      ? snippetsFilter?.filter_platform?.split(',')
      : snippetsFilter?.filter_platform || []
  }, [snippetsFilter?.filter_platform])

  const onPlatformClick = (platforName: string) => {
    if (selectedPlatforms.length === 1 && selectedPlatforms[0] === platforName) {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_platform: defaultPlatforms,
      })
      const filter = { platform: 0 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_platform: platforName.toLowerCase(),
      })
      const filter = { platform: 1 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
      openSnippetDiscovery?.({ ...selectedBrand, filter_platform: platforName.toLowerCase() })
    }
  }

  const onChangePlatformsDistributionMetric = async (value: 'posts' | 'impression' | 'engagement') => {
    setCurrentDistributionMetric(value)
    const payload = await getSnippetFilterPayload()
    anticipatoryIntelligence
      .getPlatformChartData({
        ...payload,
        conditions: [selectedBrand?.conditions],
        platform_distribution_metric: value,
      })
      .then((response) => {
        setPowerInsightsData({
          ...powerInsightsData,
          summary: {
            ...powerInsightsData.summary,
            platformsDistribution: response?.data_points,
          },
        })
      })
  }

  return (
    <div id='platformChart'>
      <Spin spinning={isLoadingPlatformChart}>
        <div className='chart-heading'>
          <span className='monitor-assets__graph__title'>Platforms</span>
          <span className='chart-heading__info'>
            <DownloadOption elementToCapture='#platformChart' />
            {isVerticalDisplay && (
              <InfoTooltip
                text={`This widget helps you identify which platforms are driving the most mentions or impressions about your ${
                  subStore === 'vectors' ? 'topic' : 'brand'
                }.`}
              />
            )}
          </span>
        </div>
        {isVerticalDisplay && (
          <Select
            onChange={(value) => onChangePlatformsDistributionMetric(value)}
            value={currentDistributionMetric}
            className='platform-select-metric'>
            <Select.Option value='posts'>Mentions</Select.Option>
            <Select.Option value='impression'>Impressions</Select.Option>
            <Select.Option value='engagement'>Engagement</Select.Option>
          </Select>
        )}
        <PlatformDistribution
          subStore={subStore}
          isShowLabel={false}
          showEmptyState
          onCellClick={onPlatformClick}
          selectedPlatform={selectedPlatforms.length === 1 ? selectedPlatforms[0] : null}
          isVerticalDisplay={isVerticalDisplay}
        />
      </Spin>
    </div>
  )
}

export default observer(PlatformDistributionCard)
