import { store } from 'store'
import millify from 'millify'
import { uniqBy } from 'lodash'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { memo, useMemo, useState } from 'react'
import { Avatar, Button, Col, Divider, Drawer, Row, Skeleton, Spin, Table, Tooltip } from 'antd'

import TopPosts from './TopPosts'
import ExecutiveSummary from './ExecutiveSummary'
import NewBrandSentiment from './NewBrandSentiment'
import NewPortfolioShareChart from './NewPortfolioShareChart'
import NewMonitorGraph from './NewMonitorGraph/NewMonitorGraph'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import NewPlatformDistributionCard from './NewPlatformDistributionCard'
import NewMentionViewCard from './NewMentionViewCard/NewMentionViewCard'
import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'
import DashboardEmptyState from 'components/DashboardEmptyState/DashboardEmptyState'
import AddBrandWizard from '../BrandsDashboard/components/AddBrandWizard/AddBrandWizard'
import SnippetsDiscovery from '../BrandsDashboard/components/SnippetsDiscovery/SnippetsDiscovery'
import { platformIcons } from 'components/Monitor/PowerInsights/Creators/CreatorsList/CreatorsList'
import BrandsListVertical from '../BrandsDashboard/components/BrandsListVertical/BrandsListVertical'
import WarningModal from 'components/Monitor/SnippetList/MonitorSnippetItem/WarningModal/WarningModal'
import { PreviewModal } from 'components/Monitor/SnippetList/MonitorSnippetItem/PreviewModal/PreviewModal'

import AvatarIcon from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as ChatIcon } from 'assets/images/chat-icon.svg'
import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'

import { isObjectEmpty } from 'utils/helper'
import usePermissions from 'hooks/usePermissions'
import { FlagListObjType } from 'store/flags/types'
import { AssetListObjType } from 'store/asset/types'
import { MaybeNull, Unknown } from 'utils/commonTypes'
import { IBarChartData, ICreator, ISnippet, sourcePlatform } from 'models/models'
import { ConditionsPayloadType, DetailsObjType, NarrativeListDataType } from 'types/types'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/dashboard/alert-filled.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import useBrandManagement from './useBrandManagement'

import './NewBrandDashboard.scss'
import AlertModal from '../BrandsDashboard/components/AlertModal/AlertModal'

type keyTypes = 'impression' | 'engagement' | 'impressions'
type cardTypes = 'Mentions' | 'Impressions' | 'Views' | 'Engagement'

const NewBrandDashboard = () => {
  const [itemToViewType, setItemToViewType] = useState('')
  const [openedWarning, setOpenedWarning] = useState<string>('')
  const [openedMedia, setOpenedMedia] = useState<ISnippet | null>(null)
  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState(false)
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)
  const [editedBrandData, setEditedBrandData] = useState<MaybeNull<AssetListObjType>>(null)
  const [itemToView, setItemToView] = useState<FlagListObjType | IBarChartData | undefined>()
  const [isOpenAlertModal, setIsOpenAlertModal] = useState(false)

  const {
    assetsLoading,
    snippetsFilter,
    categoriesLoading,
    brandDashboardDataLoading,
    isSidebarOpen,
    toggleIsSidebarOpen,
    assetsList,
    setAssetsList,
  } = store.assetsStore
  const { customThemeLoading, sku } = store.tenantsStore
  const { statsLineChartLoading } = store.loaderStore

  const {
    topPosts,
    topVoices,
    activeItem,
    selectedBrand,
    summaryLoading,
    topPostsLoading,
    executiveSummary,
    topVoicesLoading,
    followedVoiceData,
    followedVoiceLoading,
    refreshBrands,
    handleSelectBrand,
    fetchDailyExecutiveSummary,
  } = useBrandManagement()

  const userCanCreateAsset = usePermissions('assets') === 'create'

  const dateFilterDays = [1, 7, 30, 90]

  const brandLoading = assetsLoading || customThemeLoading || brandDashboardDataLoading || categoriesLoading

  const topVoicesData = uniqBy(topVoices, (item) => item?.author?.channel_url)
    ?.filter((item) => !isObjectEmpty(item?.author))
    ?.slice(0, 10)
  const topPostsData = uniqBy(topPosts, (item) => item?.author?.channel_url)?.slice(0, 10)

  const mentionViewCards = useMemo(
    () => [
      {
        type: 'Mentions',
        key: 'Mentions',
        measure: 'impressions',
        tooltipText: 'Represents the total number of times the selected brand was mentioned across all platforms.',
      },
      {
        type: 'Impressions',
        key: 'Views',
        measure: 'impression',
        tooltipText: 'Indicates how many times the selected brand was potentially seen by audiences.',
      },
      {
        type: 'Engagement',
        key: 'engagement',
        measure: 'engagement',
        tooltipText:
          'Shows how actively audiences are interacting with content that mentions the selected brand through likes, comments, shares, and other engagement metrics, depending on the platform.',
      },
    ],
    [],
  )
  const customGraphColors = useMemo(
    () => ({
      shadeColorUp: '#eeeff4',
      lineColorUp: '#A84BD3',
      shadeColorDown: '#eeeff4',
      lineColorDown: '#A84BD3',
      theme: 'purple',
    }),
    [],
  )

  const columns = useMemo(
    () => [
      {
        title: () => <span className='creators-list__table__title'>Creator</span>,
        dataIndex: 'channel_title',
        key: 'creator',
        width: '50%',
        showSorterTooltip: false,
        render: (column: Unknown, item: ISnippet) => <CreatorInfo creator={item?.author} />,
        onCell: () => ({
          style: {
            textAlign: 'left',
          },
        }),
      },
      {
        title: () => <span className='creators-list__table__title'>Followers</span>,
        dataIndex: 'channel_follower_count',
        key: 'channel_follower_count',
        width: '25%',
        showSorterTooltip: false,
        render: (column: Unknown, item: ISnippet) => (
          <div className='creators-list__table__count'>{millify(item?.channel_follower_count || 0)}</div>
        ),
        onCell: () => ({
          style: {
            textAlign: 'center',
          },
        }),
      },
    ],
    [topVoicesData],
  )

  const openSnippetsDiscovery = ({
    watchlist,
    brand,
    currentBrand,
    appliedDate,
  }: {
    watchlist?: NarrativeListDataType | null
    brand?: AssetListObjType | null
    currentBrand: AssetListObjType | null
    appliedDate?: { startDate: string; endDate: string }
  }) => {
    const { setSnippetsFilter } = store.assetsModalStore

    let modifiedFilters = { ...snippetsFilter }

    if (appliedDate) {
      modifiedFilters['days'] = {
        custom: {
          isApplied: true,
          dateRange: [appliedDate.startDate || '', appliedDate.endDate || ''],
        },
        noOfDays: 0,
      }
    }

    const conditions: ConditionsPayloadType[] = []
    if (watchlist?.conditions?.channel_urls) {
      conditions.push({ channel_urls: watchlist.conditions.channel_urls })
    }

    if (brand?.conditions) {
      conditions.push(brand.conditions)
    }

    if (currentBrand?.conditions) {
      conditions.push(currentBrand.conditions)
    }

    setSnippetsFilter({
      ...modifiedFilters,
      filter_sentiment: brand?.filter_sentiment ? brand?.filter_sentiment : modifiedFilters.filter_sentiment,
      filter_platform: brand?.filter_platform ? brand?.filter_platform : modifiedFilters.filter_platform,
      conditions: conditions as ConditionsPayloadType,
      isOverrideConditions: true,
    })
    setIsOpenSnippetsDiscovery(true)
  }

  const closeSnippetsDiscovery = () => {
    if (itemToView) {
      setItemToView(undefined)
      setItemToViewType('')
    }
    const { setSnippetsFilter } = store.assetsModalStore
    setSnippetsFilter(snippetsFilter)
    setIsOpenSnippetsDiscovery(false)
  }

  const onEditSnippetDiscoveryViewItem = (itemToView: any) => {
    setEditedBrandData(itemToView)
  }

  const handleTop10RowClick = (record: ISnippet, selectedBrand: AssetListObjType) => {
    openSnippetsDiscovery({
      watchlist: { conditions: { channel_urls: [record.author.channel_url] } } as Unknown,
      currentBrand: selectedBrand as AssetListObjType,
    })
  }

  return (
    <div className={classNames('bd--container', { loading: brandLoading })}>
      {!sku && brandLoading && <Spin spinning className='bd__loading' />}
      {sku && brandLoading && (
        <DashboardEmptyState
          isSku={true}
          isSkuLoading={true}
          title={'We are building your dashboard'}
          description={
            'We’re currently processing your data and finalizing your dashboard. This usually takes around 5 minutes. '
          }
          canCreate={userCanCreateAsset}
        />
      )}
      <Drawer
        width={337}
        forceRender
        placement={'left'}
        open={isSidebarOpen}
        className='brand-list-drawer'
        closeIcon={null}
        onClose={toggleIsSidebarOpen}>
        <BrandsListVertical
          selectedBrand={selectedBrand}
          brandLoading={brandLoading}
          setSelectedBrand={handleSelectBrand}
          handleCreateNewBrand={(brand) => {
            if (brand) setEditedBrandData(brand)
            setIsCreateAssetModalOpen(true)
          }}
          parentCallController={(isDeleteAbleBrand) => refreshBrands(isDeleteAbleBrand, selectedBrand)}
          triggerRefetchBrandList={false}
          setTriggerRefetchBrandList={() => null}
        />
      </Drawer>
      {!brandLoading && !selectedBrand && (
        <DashboardEmptyState
          isSku={sku}
          title={sku ? 'Welcome to Pendulum' : 'There is nothing here yet'}
          description={
            sku
              ? `We need some information so we can set everything up for you. Let's get started!`
              : 'Start by adding your first brand'
          }
          buttonText='Add new brand'
          onClick={() => setIsCreateAssetModalOpen(true)}
          canCreate={userCanCreateAsset}
        />
      )}
      {selectedBrand && (
        <div className='bd--container-content'>
          <div className='bd__header'>
            <div className='bd__header__left-menu'>
              <Button icon={<ChatIcon />} className='bd__header__left-menu__assistant-btn'>
                Chat with Assistant
              </Button>
            </div>

            <div className='bd__header__right-menu'>
              <DateFilter
                subStore='assets'
                addCustomDateOption={true}
                days={dateFilterDays}
                pageType='list'
                onClick={() => handleSelectBrand(selectedBrand)}
              />
              <Button
                className='bd__header__right-menu__snippets-btn'
                onClick={() => openSnippetsDiscovery({ currentBrand: selectedBrand as AssetListObjType })}>
                Show all snippets
              </Button>
              <div className='alert-icon' onClick={() => setIsOpenAlertModal(true)}>
                {selectedBrand.alert_id ? <FilledNotificationIcon /> : <AlertIcon />}
              </div>
              {isOpenAlertModal && (
                <AlertModal
                  open={isOpenAlertModal}
                  onClose={() => setIsOpenAlertModal(false)}
                  alertId={selectedBrand.alert_id}
                  entity_id={selectedBrand.id}
                  entity_type={'BRAND'}
                  onSuccess={(id?: string) => {
                    if (id) {
                      handleSelectBrand({ ...selectedBrand, alert_id: id })
                      const assets = assetsList.items.map((brand) => {
                        if (brand.id === selectedBrand.id) {
                          return { ...brand, alert_id: id }
                        }
                        return brand
                      })
                      setAssetsList({ total_count: assetsList.total_count, items: assets })
                    }
                    setIsOpenAlertModal(false)
                  }}
                  onDeleteSuccess={() => {
                    setIsOpenAlertModal(false)
                    handleSelectBrand({ ...selectedBrand, alert_id: null })
                    const assets = assetsList.items.map((brand) => {
                      if (brand.id === selectedBrand.id) {
                        return { ...brand, alert_id: null }
                      }
                      return brand
                    })
                    setAssetsList({ total_count: assetsList.total_count, items: assets })
                  }}
                />
              )}
            </div>
          </div>
          <div className='bd__body'>
            <Row gutter={24}>
              <Col span={17}>
                <div className='bd__body--left-section'>
                  <div className='bd__body__brand-overview'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Brand Overview 📊</div>
                      <div className='bd__body__header__description'>
                        Track your brand’s mentions, impressions, and engagements at a glance to measure impact and
                        visibility.
                      </div>
                    </div>

                    <div className='bd__body__brand-overview--matrix'>
                      {mentionViewCards.map((card) => (
                        <div className='bd__body__brand-overview__card' key={card.key}>
                          <NewMentionViewCard
                            selectedItem={selectedBrand}
                            type={card.type as cardTypes}
                            key={card.key}
                            measure={card.measure as keyTypes}
                            subStore={'assets'}
                            tooltipText={card.tooltipText}
                            customGraphColors={customGraphColors}
                            chartWrapperClassName='cards-chart-wrapper'
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <ExecutiveSummary
                    summaryLoading={summaryLoading}
                    executiveSummary={executiveSummary}
                    retryFetchingSummary={() => fetchDailyExecutiveSummary(0, selectedBrand?.conditions)}
                    handleOpenSnippetMedia={(snippet) => setOpenedMedia(snippet)}
                  />
                  <div className='bd__body__influential-voices'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Influential Voices 📢</div>
                      <div className='bd__body__header__description'>
                        Discover key trends, top influencers, and the platforms driving your brand’s conversation.
                      </div>
                    </div>
                    <div className='bd__body__influential-voices--container'>
                      <div className='bd__body__influential-voices--top-voices'>
                        <div className='bd__body__influential-voices__header'>
                          <div className='bd__body__influential-voices__header__title'>Top 10 voices</div>
                          <div className='bd__body__influential-voices__header__info-icon'>
                            <Tooltip
                              title={
                                'Represents the top voices dominating the conversation about your brand, ranked by number of followers.'
                              }>
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        </div>
                        {topVoicesLoading ? (
                          <TopVoicesLoader />
                        ) : topVoicesData?.length ? (
                          <Table
                            virtual
                            // @ts-ignore
                            columns={columns}
                            pagination={false}
                            scroll={{ y: 504 }}
                            onRow={(record) => ({
                              onClick: () => handleTop10RowClick(record, selectedBrand),
                            })}
                            loading={topVoicesLoading}
                            dataSource={topVoicesData || []}
                            className='bd__body__influential-voices__table'
                          />
                        ) : (
                          <BrandDataFetching
                            emptyStateIcon={<EmptyFile />}
                            emptyStateTitle={'No Data to display'}
                            // emptyStateDescription={'Please select any another brand '}
                          />
                        )}
                      </div>
                      <div className='bd__body__influential-voices--followed-voices'>
                        <div className='bd__body__influential-voices__header'>
                          <div className='bd__body__influential-voices__header__title'>Voices you are following</div>
                          <div className='bd__body__influential-voices__header__info-icon'>
                            <Tooltip
                              title={
                                'Represents the influencers you are following who have mentioned your brand in a recent post.'
                              }>
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        </div>

                        <div className='bd__body__influential-voices--avatar-container'>
                          {followedVoiceLoading ? (
                            <FollowingVoicesLoader />
                          ) : followedVoiceData?.length ? (
                            followedVoiceData?.map((watchlist) => (
                              <div
                                key={watchlist.name}
                                className='bd__body__influential-voices__avatar-group'
                                onClick={() =>
                                  openSnippetsDiscovery({
                                    watchlist: watchlist as Unknown,
                                    currentBrand: selectedBrand as AssetListObjType,
                                  })
                                }>
                                <Avatar.Group
                                  maxCount={4}
                                  maxStyle={{
                                    color: '#CCA55A',
                                    backgroundColor: '#F5EDDE',
                                    cursor: 'pointer',
                                    border: '2px solid white',
                                    borderRadius: '20px',
                                    fontSize: '16px',
                                    lineHeight: '18px',
                                    fontFamily: 'Roboto',
                                    fontWeight: 400,
                                    width: '40px',
                                    height: '40px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <Avatar size={40} src={AvatarIcon} />
                                </Avatar.Group>
                                <div className='bd__body__influential-voices__avatar-group__title'>
                                  {watchlist?.name || ''}
                                </div>
                              </div>
                            ))
                          ) : (
                            <BrandDataFetching
                              emptyStateIcon={<InfluencerEmptyStateImage />}
                              emptyStateTitle={'Influential voices'}
                              emptyStateDescription={
                                'There is currently no data intersecting with your influencer lists within the selected time period.'
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bd__body__trending-posts--container'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Top Performing Posts 🔥</div>
                      <div className='bd__body__header__description'>
                        Discover the most engaged and viewed content driving the conversation.
                      </div>
                    </div>
                    <TopPosts isLoadingFeed={topPostsLoading} snippets={topPostsData} />
                  </div>
                </div>
              </Col>
              <Col span={7}>
                <div className='bd__body--right-section'>
                  <div className='bd__body__shareOfVoice'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Share of Voice 🎙</div>
                      <div className='bd__body__header__description'>See who’s dominating the conversation.</div>
                    </div>

                    <div className='bd__body__shareOfVoice__container'>
                      <NewPortfolioShareChart
                        subStore='assets'
                        showMetricSelection
                        isPowerInsightsMode={true}
                        skipBrandIntersection={true}
                        additionalConditions={selectedBrand?.conditions}
                        openSnippetDiscovery={(brand) =>
                          openSnippetsDiscovery({ brand, currentBrand: selectedBrand as AssetListObjType })
                        }
                      />
                    </div>
                  </div>
                  <div className='bd__body__sentiments'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Sentiment 👍👎</div>
                      <div className='bd__body__header__description'>Understand the emotion behind the posts.</div>
                    </div>
                    <div className='bd__body__shareOfVoice__container'>
                      <NewBrandSentiment
                        isFlexLegend
                        subStore='assets'
                        showMetricSelection
                        selectedBrand={selectedBrand as AssetListObjType}
                        openSnippetDiscovery={(brand) =>
                          openSnippetsDiscovery({
                            brand: brand as AssetListObjType,
                            currentBrand: selectedBrand as AssetListObjType,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='bd__body__trendPlatform'>
                    <div className='bd__body__header'>
                      <div className='bd__body__header__title'>Trends and Platforms 📈</div>
                      <div className='bd__body__header__description'>Analyze trends by mentions and engagement.</div>
                    </div>
                    <div className='bd__body__shareOfVoice__container'>
                      <NewMonitorGraph
                        subStore='assets'
                        applyDateRangeFilter={(startDate, endDate) =>
                          openSnippetsDiscovery({
                            currentBrand: selectedBrand as AssetListObjType,
                            appliedDate: { startDate, endDate },
                          })
                        }
                        selectedItem={selectedBrand}
                        resizeChartTrigger={isSidebarOpen}
                        dataLoading={statsLineChartLoading}
                      />
                      <Divider />
                      <NewPlatformDistributionCard
                        isVerticalDisplay
                        selectedBrand={selectedBrand as AssetListObjType}
                        openSnippetDiscovery={(brand) =>
                          openSnippetsDiscovery({
                            brand: brand as AssetListObjType,
                            currentBrand: selectedBrand as AssetListObjType,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {isOpenSnippetsDiscovery && (
        <SnippetsDiscovery
          itemToView={itemToView}
          selectedItem={selectedBrand}
          setItemToView={setItemToView}
          itemToViewType={itemToViewType}
          onClose={closeSnippetsDiscovery}
          activeItem={activeItem as DetailsObjType}
          onEditCurrentItemToView={onEditSnippetDiscoveryViewItem}
        />
      )}
      {openedMedia && (
        <PreviewModal
          openedMedia={openedMedia}
          onOpenSource={(val: string) => setOpenedWarning(val)}
          onModalClose={() => {
            setOpenedMedia(null)
          }}
        />
      )}

      {openedWarning && (
        <WarningModal
          link={openedWarning}
          onContinue={() => {
            setOpenedWarning('')
            window.open(openedWarning || '#', '_blank', 'noopener,noreferrer')
          }}
          onClose={() => {
            setOpenedWarning('')
          }}
        />
      )}

      {isCreateAssetModalOpen && (
        <AddBrandWizard
          setSelectedBrand={(brand) => handleSelectBrand(brand)}
          editedBrandData={editedBrandData}
          isTopicCreation={false}
          handleCloseModal={() => {
            if (editedBrandData) setEditedBrandData(null)
            setIsCreateAssetModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default observer(NewBrandDashboard)

interface CreatorInfoProps {
  creator: ICreator
}

const CreatorInfo = memo(({ creator }: CreatorInfoProps) => {
  return (
    <div className='bd__body__influential-voices-creator'>
      <div className='bd__body__influential-voices-creator__container'>
        <img
          className='bd__body__influential-voices-creator__avatar'
          src={(creator?.channel_thumb_url || AvatarIcon) as string}
          alt='user'
          onError={(e: any) => {
            e.target.src = AvatarIcon
          }}
        />
        <div className='bd__body__influential-voices-creator__container2'>
          <span
            className='bd__body__influential-voices-creator__name'
            title={creator?.channel_name || creator?.channel_title}>
            {(creator?.channel_name === 'N/A'
              ? creator?.channel_title
              : creator?.channel_name
              ? creator?.channel_name
              : creator?.channel_title) || ''}
          </span>
          <div className='bd__body__influential-voices-creator__container3'>
            <img
              className='bd__body__influential-voices-creator__platform'
              src={platformIcons[creator?.platform as Lowercase<sourcePlatform>]}
              alt='platform'></img>
          </div>
        </div>
      </div>
    </div>
  )
})

const TopVoicesLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      <Skeleton.Input active={true} size='large' style={{ width: '100%', height: 40 }} />
      {Array.from({ length: 9 }, (value) => (
        <div key={`${value}loader-`} style={{ display: 'flex', gap: '0px', width: '100%' }}>
          <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 20 }} />
          <Skeleton.Input active={true} size='large' style={{ width: '100%', height: 40 }} />
        </div>
      ))}
    </div>
  )
}

const FollowingVoicesLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      {Array.from({ length: 10 }, (value) => (
        <div key={`${value}loader-`} style={{ display: 'flex', gap: '16px', width: '100%' }}>
          <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 20 }} />
          <Skeleton.Input active={true} size='large' style={{ width: '100%', height: 30 }} />
        </div>
      ))}
    </div>
  )
}
