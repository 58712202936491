import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, Switch } from 'antd'
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react'

import BaseModal from 'components/BaseModal/BaseModal'
import Pagination from 'components/Pagination/Pagination'
import { default as TopicIssueCard } from '../FollowedIssues/IssueCard'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { default as SuggestedIssueCard } from '../SuggestedIssues/IssueCard'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'

import { store } from 'store'
import { SnippetFilterPayload } from 'models/models'
import { openNotification } from 'services/Util/openNotification'
import { getDaysDifference } from 'services/Util/getDaysDifference'

import { flagsAPI } from 'api/api'
import { displayApiError } from 'utils/helper'
import { NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { FlagListObjType, FlagListParamsType, SuggestFlagObject } from 'store/flags/types'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/deleteicon.svg'
import { ReactComponent as SpiralStarIcon } from 'assets/images/spiral_stars.svg'
import { ReactComponent as InfluencerEmptyStateImage } from 'assets/images/icons/dashboard/influencer-empty-state.svg'

import usePermissions from 'hooks/usePermissions'
import { isEmpty } from 'lodash'
import './TopicsListModal.scss'
import AddBrandWizard from '../AddBrandWizard/AddBrandWizard'
import { MaybeNull } from 'utils/commonTypes'

const TopicsListModal = ({
  onSelectFlag,
  selectedBrand,
  handleCloseModal,
  handleTopicCreate,
  subStore = 'assets',
}: {
  handleCloseModal: (isDataChanged: boolean) => void
  subStore?: 'assets' | 'vectors'
  handleTopicCreate?: () => void
  onSelectFlag: (flag: FlagListObjType) => void
  selectedBrand: AssetListObjType | NarrativeListDataType
}) => {
  const { fetchFlagsListWithConditions, flagsLoading, flagsList, deleteFlag } = store[`${subStore}FlagsStore`]
  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    per_page: 5,
  })
  const { snippetsFilter, getSnippetFilterPayload, getDate, fetchInsightsMetrics } = store[`${subStore}Store`]
  const [isFlagsMetricsLoading, setIsFlagsMetricsLoading] = useState(false)
  const [followTopicsLoading, setFollowTopicsLoading] = useState(false)
  const [followTopicLoading, setFollowTopiLoading] = useState<string>('')
  const [newlyFollowedTopic, setNewlyFollowedTopic] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<'topics' | 'suggestion'>('topics')
  const [deleteTopicsLoading, setDeleteTopicsLoading] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [flagsWithMetrics, setFlagsWithMetrics] = useState<any>([])
  const [suggestedTopics, setSuggestedTopics] = useState<any>([])
  const [suggestedTopicsLoading, setSuggestedTopicsLoading] = useState<boolean>(false)
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])
  const [isUnFollowedListActive, setIsUnFollowedListActive] = useState(false)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const [topicToEdit, setTopicToEdit] = useState<MaybeNull<AssetListObjType>>(null)

  const canCreateVectors = usePermissions('vectors') === 'create'

  const requestParams: FlagListParamsType = {
    page: 1,
    per_page: 5,
    sort: 'name:asc',
  }

  useEffect(() => {
    if (isUnFollowedListActive) requestParams.is_active = false
    if (selectedTab === 'topics') fetchFlagsListWithConditions({ params: requestParams })
    else fetchSuggestedIssues()
  }, [selectedTab, isUnFollowedListActive])

  useEffect(() => {
    if (flagsList.items.length > 0 && selectedBrand) {
      fetchAllActiveFlagMetrics(flagsList.items)
    }
  }, [flagsList, selectedBrand, snippetsFilter.days])

  const fetchSuggestedIssues = () => {
    if (!selectedBrand) return
    setSuggestedTopicsLoading(true)
    flagsAPI
      .getSuggestedTopics({ id: selectedBrand.id })
      .then((response) => {
        setSuggestedTopics(response.data.items)
      })
      .catch((error) => {
        displayApiError(error)
      })
      .finally(() => {
        setSuggestedTopicsLoading(false)
      })
  }

  const handleSearchFlags = async (event: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    const query = (event.target as HTMLInputElement).value?.trim()
    if (query) {
      requestParams.q = `name:like:${query}`
    }
    if (isUnFollowedListActive) requestParams.is_active = false
    await fetchFlagsListWithConditions({ params: requestParams })
  }

  const renderConditions = useMemo(() => {
    if (selectedBrand && Object.keys((selectedBrand as AssetListObjType).conditions).length) {
      return [(selectedBrand as AssetListObjType).conditions]
    }
    return []
  }, [selectedBrand])

  const fetchMetricsForFlag = async (flag: FlagListObjType, requestParams: { q: string; measure?: string }) => {
    if (isEmpty(flag.conditions)) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          skip: true,
        },
      }
    }

    const requestData = { conditions: [...renderConditions, flag.conditions] }
    const views = await fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true })

    const viewsCount = views.total_value

    if (viewsCount === 0) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          engagement: 0,
          skip: true,
        },
      }
    }

    return {
      id: flag.id,
      metrics: {
        views: viewsCount,
        impressions: 0,
        engagement: 0,
        skip: false,
      },
    }
  }

  const handleTopicSelection = (flagId: string) => {
    if (selectedTopics?.includes(flagId)) setSelectedTopics((prev) => prev?.filter((item) => item !== flagId))
    else setSelectedTopics((prev) => [...prev, flagId])
  }

  const handleFollowUnFollow = async (flagId: string, isActive: boolean) => {
    const newStatus = isActive ? 'deactivate' : 'activate'
    const actionMessage = isActive ? 'unfollowed' : 'followed'
    try {
      setFollowTopiLoading(flagId)
      await flagsAPI.toggleFlagStatus({ ids: [flagId], status: newStatus })
      openNotification({ type: 'info', message: `Topic ${actionMessage} successfully` })
      if (!isActive) {
        setNewlyFollowedTopic(flagId)
        setTimeout(() => {
          setNewlyFollowedTopic('')
          if (isUnFollowedListActive) requestParams.is_active = false
          requestParams.page = paginationValues.page
          fetchFlagsListWithConditions({ params: requestParams })
          setIsDataChanged(true)
        }, 1000)
      } else {
        if (isUnFollowedListActive) requestParams.is_active = false
        requestParams.page = paginationValues.page
        fetchFlagsListWithConditions({ params: requestParams })
        setIsDataChanged(true)
      }
    } catch (error) {
      openNotification({ type: 'error', message: 'Topic failed to follow' })
    } finally {
      setFollowTopiLoading('')
    }
  }

  const handleFollowTopics = async () => {
    try {
      setFollowTopicsLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: selectedTopics, status: 'activate' })
      openNotification({ type: 'info', message: 'All topics followed successfully' })
      if (isUnFollowedListActive) requestParams.is_active = false
      requestParams.page = paginationValues.page
      fetchFlagsListWithConditions({ params: requestParams })
      setIsDataChanged(true)
    } catch (error) {
      openNotification({ type: 'error', message: 'Some topics failed to follow' })
    } finally {
      setSelectedTopics([])
      setFollowTopicsLoading(false)
    }
  }

  const deleteFlagHandler = async () => {
    try {
      setOpenDeleteModal(false)
      setDeleteTopicsLoading(true)
      const promises = selectedTopics.map((topicId) => deleteFlag({ id: topicId }).catch((error) => {}))
      const results = await Promise.all(promises)
      const failedTopics = results.filter((result) => result?.success === false).map((result) => result.id)
      if (!failedTopics?.length) openNotification({ type: 'info', message: 'All selected Topics deleted successfully' })
    } catch (error) {
    } finally {
      setSelectedTopics([])
      if (isUnFollowedListActive) requestParams.is_active = false
      requestParams.page = paginationValues.page
      fetchFlagsListWithConditions({ params: requestParams })
      setIsDataChanged(true)
      setDeleteTopicsLoading(false)
    }
  }

  const handleDeleteTopics = () => {
    setOpenDeleteModal(true)
  }

  const fetchAllActiveFlagMetrics = async (flags: FlagListObjType[]) => {
    setIsFlagsMetricsLoading(true)
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)

      const requestParams: { q: string; metric?: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }

      const promises = flags.map((flag) => fetchMetricsForFlag(flag, requestParams))
      const results = await Promise.allSettled(promises)

      const metrics: Record<string, any> = {}

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })

      const onlyFlagsWithMetrics = flags.map((flag) => ({ ...flag, metric: metrics[flag.id] }))
      // .filter((flag) => !flag.metric.skip) // Temporary commented

      setFlagsWithMetrics(onlyFlagsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
      setIsFlagsMetricsLoading(false)
    }
  }

  const onEditTopic = (topic: any) => {
    setTopicToEdit(topic)
  }

  return (
    <BaseModal
      open
      closable
      destroyOnClose
      title='Topics'
      isFooterBordered
      className='topic-list-modal'
      onCancel={() => handleCloseModal(isDataChanged)}
      footer={
        selectedTab === 'suggestion' ? null : (
          <div className='topic-list-modal__footer'>
            <Pagination
              totalCount={flagsList.total_count}
              currentPage={paginationValues.page}
              pageSize={paginationValues.per_page}
              onPageChange={(page) => {
                let requestParams: FlagListParamsType = {
                  page,
                  per_page: paginationValues.per_page,
                  sort: 'name:asc',
                }
                setPaginationValues({ page, per_page: paginationValues.per_page })
                if (isUnFollowedListActive) requestParams.is_active = false
                fetchFlagsListWithConditions({ params: requestParams })
              }}
            />
            <div className='topic-list-modal__footer__right-btn'>
              <span>Show unfollowed only</span>
              <Switch
                size='default'
                checked={isUnFollowedListActive}
                onChange={(value) => {
                  setIsUnFollowedListActive(value)
                  setSelectedTopics([])
                }}
              />
            </div>
          </div>
        )
      }>
      <div className='topic-list-modal__content'>
        <div className='topic-list-modal__header'>
          <div className='topic-list-modal__header-left'>
            <div className='topic-list-modal__header-btn topic-list-modal__header-btn--topic'>
              <ButtonImage
                onClick={() => setSelectedTab('topics')}
                className={classNames({ active: selectedTab === 'topics' })}>
                Topics
              </ButtonImage>
            </div>
            <div className='topic-list-modal__header-btn topic-list-modal__header-btn--suggestion'>
              <ButtonImage
                icon={SpiralStarIcon}
                onClick={() => {
                  setSelectedTab('suggestion')
                  setSelectedTopics([])
                }}
                className={classNames({ active: selectedTab === 'suggestion' })}>
                Discovered Topics
              </ButtonImage>
            </div>
          </div>
          <div className='topic-list-modal__header-right'>
            <div className='topic-list-modal__header-btn topic-list-modal__header-btn--create'>
              <ButtonImage icon={PlusIcon} onClick={handleTopicCreate} disabled={!canCreateVectors}>
                Create
              </ButtonImage>
            </div>
            <div className='topic-list-modal__header-search'>
              <Input
                allowClear
                size='large'
                placeholder='Search'
                prefix={<SearchOutlined />}
                className='header-search-input'
                onPressEnter={(e) => handleSearchFlags(e)}
                onChange={(e) => (e.target.value ? null : handleSearchFlags(e))}
              />
            </div>
          </div>
        </div>

        <div className={classNames('topic-list-modal__data', { suggestion_data: selectedTab === 'suggestion' })}>
          {!flagsLoading && !isFlagsMetricsLoading && flagsWithMetrics.length === 0 && selectedTab === 'topics' && (
            <>
              <span className='monitor-assets__graph__title'>Followed topics</span>
              <div className='influencers-empty-state'>
                <InfluencerEmptyStateImage />
                <h5>Nothing to Show</h5>
                <p>
                  It looks like there’s no data available for the time range you selected. Try adjusting the date range
                  or check back later.
                </p>
              </div>
            </>
          )}
          {!suggestedTopicsLoading &&
            !isFlagsMetricsLoading &&
            suggestedTopics.length === 0 &&
            selectedTab === 'suggestion' && (
              <>
                <span className='monitor-assets__graph__title'>Followed topics</span>
                <div className='influencers-empty-state'>
                  <InfluencerEmptyStateImage />
                  <h5>Nothing to Show</h5>
                  <p>
                    It looks like there’s no data available for the time range you selected. Try adjusting the date
                    range or check back later.
                  </p>
                </div>
              </>
            )}
          {!flagsLoading &&
            !isFlagsMetricsLoading &&
            selectedTab === 'topics' &&
            flagsWithMetrics.map((flag: FlagListObjType) => (
              <TopicIssueCard
                flag={flag}
                onSelectFlag={onSelectFlag}
                subStore={subStore}
                selectedTopics={selectedTopics}
                followTopicLoading={followTopicLoading}
                newlyFollowedTopic={newlyFollowedTopic}
                handleFollowUnFollow={handleFollowUnFollow}
                handleTopicSelection={handleTopicSelection}
                onEditTopic={onEditTopic}
              />
            ))}
          {(flagsLoading || isFlagsMetricsLoading) &&
            selectedTab === 'topics' &&
            [1, 2, 3, 4, 5].map(() => <TopicIssueCard emptyState onSelectFlag={() => {}} />)}

          {!suggestedTopicsLoading &&
            selectedTab === 'suggestion' &&
            suggestedTopics.map((topic: SuggestFlagObject) => <SuggestedIssueCard issue={topic} />)}
          {suggestedTopicsLoading &&
            selectedTab === 'suggestion' &&
            [1, 2, 3, 4, 5].map(() => <SuggestedIssueCard emptyState />)}
        </div>
        <Drawer
          height={68}
          mask={false}
          destroyOnClose
          closeIcon={null}
          placement='bottom'
          getContainer={false}
          open={!!selectedTopics?.length}
          headerStyle={{ display: 'none' }}
          style={{ position: 'absolute', bottom: '65px' }}>
          <div className='topic-list-modal__drawer-content'>
            <span className='topic-list-modal__drawer-content__count'>
              {selectedTopics?.length > 1 ? 'Topics ' : 'Topic '}
              {`(${selectedTopics?.length}) `}selected
            </span>
            <div className='topic-list-modal__drawer-content__actions'>
              <Button
                icon={<PlusIcon />}
                onClick={handleFollowTopics}
                loading={followTopicsLoading}
                disabled={followTopicsLoading}
                className='topic-list-modal__drawer-content__actions__follow-btn'>
                Follow
              </Button>
              <Button
                icon={<DeleteIcon />}
                onClick={handleDeleteTopics}
                loading={deleteTopicsLoading}
                disabled={deleteTopicsLoading}
                className='topic-list-modal__drawer-content__actions__delete-btn'>
                Delete
              </Button>
            </div>
          </div>
        </Drawer>
      </div>

      {openDeleteModal && (
        <DeleteConfirmationModal onClose={() => setOpenDeleteModal(false)} onContinue={deleteFlagHandler} />
      )}
      {topicToEdit && (
        <AddBrandWizard
          setSelectedBrand={() => {}}
          editedBrandData={topicToEdit}
          isTopicCreation={true}
          handleCloseModal={() => {
            fetchFlagsListWithConditions({ params: requestParams })
            setTopicToEdit(null)
          }}
        />
      )}
    </BaseModal>
  )
}

export default observer(TopicsListModal)
