import { observer } from 'mobx-react-lite'
import '../BrandsList/BrandsList.scss'
import Table from 'components/common/Table/Table'
import { useEffect, useMemo, useState } from 'react'
import { Avatar, Spin } from 'antd'
import flagIcon from 'assets/images/icons/flag/active_flag.svg'
import { store } from 'store'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import { FlagListObjType, FlagListParamsType } from 'store/flags/types'
import '../BrandsList/BrandsList.scss'
import FlagStats from './FlagStats'
import { AssetListObjType } from 'store/asset/types'
import { SnippetFilterPayload } from 'models/models'
import { getDaysDifference } from 'services/Util/getDaysDifference'
import { openNotification } from 'services/Util/openNotification'

type PropsType = {
  selectedBrand: AssetListObjType | undefined
}

const ActiveFlags = observer(({ selectedBrand }: PropsType) => {
  const { flagsStore, assetsStore } = store
  const { fetchFlagsListWithConditions, flagsLoading, flagsList } = flagsStore
  const [paginationValues, setPaginationValues] = useState({
    page: 1,
    per_page: 50,
  })
  const {
    filterChips,
    snippetsFilter,
    setSnippetsFilter,
    setFilterChips,
    getSnippetFilterPayload,
    getDate,
    fetchInsightsMetrics,
  } = assetsStore
  const [isFlagsMetricsLoading, setIsFlagsMetricsLoading] = useState(false)
  const [flagsWithMetrics, setFlagsWithMetrics] = useState<any>([])

  useEffect(() => {
    const requestParams: FlagListParamsType = {
      page: 1,
      per_page: 50,
      sort: 'name:asc',
      is_active: true,
    }
    fetchFlagsListWithConditions({ params: requestParams })
  }, [])

  useEffect(() => {
    if (flagsList.items.length > 0 && selectedBrand) {
      fetchAllActiveFlagMetrics(flagsList.items)
    }
  }, [flagsList, selectedBrand, snippetsFilter])

  const renderConditions = useMemo(() => {
    if (selectedBrand && Object.keys((selectedBrand as AssetListObjType).conditions).length) {
      return [(selectedBrand as AssetListObjType).conditions]
    }
    return []
  }, [selectedBrand])

  const fetchMetricsForFlag = async (
    flag: FlagListObjType,
    requestParams: { q: string; metric?: string },
    requestParamsForPrev: { q: string; metric?: string },
  ) => {
    if (!flag.conditions) {
      return {
        id: flag.id,
        metrics: {
          currentFlagCount: 0,
          prevFlagCount: 0,
          skip: true,
        },
      }
    }

    const requestData = { conditions: [...renderConditions, flag.conditions] }
    const results = await Promise.allSettled([
      fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
      fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData, fullResponse: true }),
    ])

    const [currentFlagResult, prevFlagResult] = results

    const getResultValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error' ? 0 : result.value.total_value
      }
      return 0
    }

    const currentFlagCount = getResultValue(currentFlagResult)
    const prevFlagCount = getResultValue(prevFlagResult)

    if (currentFlagCount === 0 && prevFlagCount === 0) {
      return {
        id: flag.id,
        metrics: {
          currentFlagCount: 0,
          prevFlagCount: 0,
          skip: true,
        },
      }
    }

    const chartData =
      currentFlagResult.status === 'fulfilled' && currentFlagResult.value.data_points
        ? currentFlagResult.value.data_points.map((point: { metric_value: number }) => ({
            value: point.metric_value,
          }))
        : []

    if (chartData.length === 1) {
      chartData.unshift({ value: 0 })
    }

    return {
      id: flag.id,
      metrics: {
        currentFlagCount,
        prevFlagCount,
        chartData,
        skip: false,
      },
    }
  }

  const fetchAllActiveFlagMetrics = async (flags: FlagListObjType[]) => {
    setIsFlagsMetricsLoading(true)
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)
      const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
      const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

      //use community,platform,sentiment and flags filter,
      const requestParams: { q: string; metric?: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }
      const requestParamsForPrev: { q: string; metric?: string } = {
        q: `start_date:gte:${prevStartDateFormat},end_date:lte:${prevEndDateFormat}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }

      // loop through all flags and fetch metric for each flag
      const promises = flags.map((flag) => fetchMetricsForFlag(flag, requestParams, requestParamsForPrev))
      // Wait for all promises to settle and process results
      const results = await Promise.allSettled(promises)

      const metrics: Record<string, any> = {}

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })

      const onlyFlagsWithMetrics = flags
        .map((flag) => ({ ...flag, metric: metrics[flag.id] }))
        .filter((flag) => !flag.metric.skip)

      setFlagsWithMetrics(onlyFlagsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
      setIsFlagsMetricsLoading(false)
    }
  }

  const columns: any = useMemo(() => {
    return [
      {
        key: 'name',
        title: '',
        render: (_: any, item: FlagListObjType) => {
          return (
            <div className='brand_info'>
              <Avatar src={flagIcon} size='large' />
              <span className='brand_name'>{item.name}</span>
            </div>
          )
        },
      },

      {
        key: 'all_posts',
        title: '',
        width: 500,
        render: (_: any, item: FlagListObjType) => {
          return <FlagStats selectedBrand={selectedBrand} flag={item} key={item.id} />
        },
      },
    ]
  }, [selectedBrand, flagsList, snippetsFilter.days])

  const onRowClick = (flag: FlagListObjType) => {
    if (filterChips?.flags?.value === flag.name) {
      let { flags, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
      setFilterChips({
        ...filterChips,
        flags: {
          ...filterChips.flags,
          value: '',
        },
      })
      return
    }
    setSnippetsFilter({ ...snippetsFilter, flags: { name: flag.name, conditions: flag?.conditions || {} } })
    setFilterChips({
      ...filterChips,
      flags: {
        ...filterChips.flags,
        value: flag.name,
      },
    })
  }

  const selectedFlagId =
    filterChips?.flags?.value && flagsList.items.find((flag) => flag.name === filterChips.flags.value)?.id

  return (
    <Spin spinning={flagsLoading || isFlagsMetricsLoading}>
      <p className='monitor-assets__graph__title'>Flags</p>
      {/* <MentionsViewsSwitch view='mentions' onUpdateView={() => {}} /> */}
      {!flagsLoading && !isFlagsMetricsLoading && flagsWithMetrics.length > 0 && (
        <>
          <Table
            data={flagsWithMetrics}
            columns={columns}
            className='brands_table'
            onRowClick={onRowClick}
            selectedRows={selectedFlagId ? [selectedFlagId] : []}
          />
          <div className='am_dashboard_pagination_container'>
            <DefaultPagination
              className='amc_pagination'
              totalCount={flagsList.total_count}
              currentPage={paginationValues.page}
              pageSize={paginationValues.per_page}
              showPageSizeChanger={false}
              nextIcon={ArrowNextIcon}
              prevIcon={ArrowNextIcon}
              iconHeight={20}
              iconWidth={20}
              onChange={(page, per_page) => {
                let requestParams: FlagListParamsType = {
                  page,
                  per_page: per_page,
                  sort: 'name:asc',
                  is_active: true,
                }
                setPaginationValues({ page, per_page })
                fetchFlagsListWithConditions({ params: requestParams })
              }}
              locale={{ items_per_page: '' }}
            />
          </div>
        </>
      )}
      {!flagsLoading && !isFlagsMetricsLoading && flagsWithMetrics.length === 0 && <p>No data to display</p>}
    </Spin>
  )
})

export default ActiveFlags
