import { Modal, Spin } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { store } from 'store'

import SearchInput from '../Inputs/SearchInput/SearchInput'
import UsersList from 'components/UsersList/UsersList'
import useDebounce from 'hooks/useDebounce'
import { NarrativeParamsType } from 'types/types'
import { reportsAPI } from 'api/api'
import { ROUTES } from 'settings/settings'
import { ReportRoutesType, UserListResponse } from 'store/report/valueTypes'
import { UtilService } from 'services/Util/Util'
import { SharedUserListObj, UserListObj } from 'store/asset/types'
import { ShareUserDataType } from 'components/Investigate/Reports/ShareUserDropdown/ShareUserDropdown'
import profileImage from 'assets/images/LogoiconMobile.svg'

import { transferOwnership } from 'api/tenants/transferOwnership'
import { IUsers } from 'models/models'

import './ChangeOwnerModal.scss'

const PER_PAGE = 50

type SelectedValueAndUserObj = {
  selectedValue: ShareUserDataType
  selectedUser: SharedUserListObj
}

type SharedUserListChunkProps = {
  className?: string
  [x: string]: any
  onClick?: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
  updateSubscribedUsers: (id: string, status: boolean) => void
}

type ChangeOwnerModalProps = {
  owner: IUsers
  entityId: string
  entityType: string
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const ChangeOwnerModal = (props: ChangeOwnerModalProps) => {
  const { owner, entityId, entityType, open, onClose, onSuccess } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [users, setUsers] = useState<UserListResponse>({
    total_count: 0,
    items: [],
  })
  const [usersPage, setUsersPage] = useState<number>(2)
  const debouncedSearchValue = useDebounce(searchValue)
  const [selectedUser, setSelectedUser] = useState<UserListObj | null>(null)

  const { showToaster } = store['toasterStore']

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: PER_PAGE,
    }
    fetchUsersWithParams(requestParams)
  }, [])

  const searchDropHandler = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [isOpen],
  )

  const fetchUsersWithParams = async (params: NarrativeParamsType) => {
    try {
      setIsFetching(true)
      const usersData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.listUsers as ReportRoutesType,
        params,
      })
      setUsers({ total_count: usersData.data.total_count, items: [...users.items, ...usersData.data.items] })
      setIsFetching(false)

      return usersData.data
    } catch (error: any) {
      setIsFetching(false)
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight
    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (users.total_count !== users.items?.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: usersPage,
          per_page: PER_PAGE,
        }
        fetchUsersWithParams(requestParams).then((res: UserListResponse | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...users.items, ...res.items]?.length !== res.total_count) {
            setUsersPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  const newUsers = useMemo(() => {
    return users.items
  }, [users])

  const filterUsers = useMemo(() => {
    if (debouncedSearchValue && users.items?.length) {
      return newUsers.filter(
        (user) =>
          user.given_name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      )
    }
    return newUsers
  }, [debouncedSearchValue, newUsers])

  const selectUserHandler = (userInfo: UserListObj) => {
    setSelectedUser(userInfo)
    searchDropHandler(false)
  }

  const onOk = async () => {
    try {
      if (selectedUser && selectedUser.id?.length) {
        await transferOwnership({
          entity_type: entityType,
          entity_id: entityId,
          new_owner_id: selectedUser?.id,
        })
        showToaster({
          iconType: 'success',
          message: `Owner of ${entityType.toLowerCase()} has been successfully updated.`,
        })
        onSuccess()
      }
    } catch (error) {
      showToaster({
        iconType: 'error',
        message: `something went wrong`,
      })
    }
  }

  return (
    <Modal
      open={open}
      className='change-owner-modal'
      title={
        <div className='header'>
          <span className='header__title'>Change owner</span>
          <span className='header__description'>
            Transfer ownership of this item to another user. The new owner will have full control, including managing
            permissions and making updates.
          </span>
        </div>
      }
      onCancel={() => {
        onClose?.()
      }}
      centered
      okText={'Confirm change'}
      onOk={onOk}>
      <Spin spinning={isFetching}>
        <div className='change-owner-modal___modal_container'>
          <div className='change-owner-modal___modal_container__container'>
            <span className='search-text '>Search</span>
            <SearchInput
              placeholder='Search by name or email'
              showIcon={true}
              className='srmc_input'
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              onClear={() => setSearchValue('')}
              showDrop={true}
              isOpen={isOpen}
              value={searchValue}
              setIsOpen={searchDropHandler}
              onScroll={handleScroll}>
              <div className='srmc_input_content_container'>
                <UsersList data={filterUsers} className='srmc_input_cc_chunk' onClick={selectUserHandler} />
              </div>
            </SearchInput>
          </div>
          <div className='share_report_content_container'>
            {owner ? (
              <SharedUserListChunk permission_level='owner' {...owner} updateSubscribedUsers={() => {}} />
            ) : null}

            {selectedUser ? (
              <SharedUserListChunk
                {...selectedUser}
                key={selectedUser.id}
                onClick={() => {}}
                onSelect={() => {}}
                updateSubscribedUsers={() => {}}
              />
            ) : null}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

const SharedUserListChunk = (props: SharedUserListChunkProps) => {
  const { className, onClick, onSelect, updateSubscribedUsers, ...rest } = props

  return (
    <div className={`users_list_container ${className}`}>
      <div className='ulc_image_container'>
        <img
          src={UtilService.isImageUrl(rest?.avatar_url) ? rest?.avatar_url : profileImage}
          alt='danger'
          className='ulc_image'
        />
      </div>
      <div className='ulc_content'>
        <div className='ulc_c_name_container'>
          <p className='ulc_c_name'>{rest?.given_name}</p>

          <div className='ulc_c_info'>
            {rest?.type === 'email' ? (
              <p className='ulc_c_remove_email_share' onClick={() => onClick?.(rest?.user_id)}>
                Remove
              </p>
            ) : rest?.permission_level === 'owner' ? (
              <p className='ulc_c_remove ulc_c_owner_share'>Owner</p>
            ) : (
              <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
                <path
                  d='M3.7168 8.66675L6.38346 11.3334L13.0501 4.66675'
                  stroke='$color-grey-600'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            )}
          </div>
        </div>
        <p className='ulc_c_email'>{rest?.username}</p>
      </div>
    </div>
  )
}
