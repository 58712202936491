import dayjs from 'dayjs'
import { Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DateFilter } from 'components/common/DateFilter/DateFilter'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import { DefaultPagination } from 'components/common/Pagination/Pagination'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import FolderPanel from 'components/Monitor/FolderPanel/FolderPanel'
import BookmarkListViewSection from './BookmarkListViewSection/BookmarkListViewSection'
import { DeleteModal } from 'pages/Investigate/DeleteModal/DeleteModal'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { CreateBookmarkModal } from 'components/Investigate/Bookmarks/CreateBookmarkModal/CreateBookmarkModal'
import { useEffectOnce } from 'react-use'

import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as AddIcon } from 'assets/images/icons/monitor/add_icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/monitor/close_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-20.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/report/delete-icon-size-20.svg'
import { ReactComponent as ArrowNextIcon } from 'assets/images/icons/monitor/arrow_next_icon.svg'

import { ListSelectedRowData } from 'models/models'

import { store } from 'store'

const BookmarkListing = observer(() => {
  const { state } = useLocation()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [prevSearchValue, setPrevSearchValue] = useState<string>('')
  const [selectedFolderId, setSelectedFolderId] = useState<string>('')
  const [showFolderPanel, setShowFolderPanel] = useState<boolean>(false)
  const [isShowSharedPage, setIsShowSharedPage] = useState<boolean>(false)
  const [isBookmarksModalVisible, setIsBookmarksModalVisible] = useState(false)
  const [isDeleteBookmarkModalVisible, setIsDeleteBookmarkModalVisible] = useState(false)
  const [selectedRowsData, setSelectedRowsData] = useState<ListSelectedRowData[]>([])

  const { bookmarkStore } = store

  const {
    selectedTab,
    bookmarksData,
    snippetsFilter,
    paginationValues,
    setSnippetsFilter,
    fetchBookmarksData,
    setPaginationValues,
    bookmarksDataLoading,
    handleDeleteBookmark,
    deleteBookmarkLoading,
    fetchConditionsPayloadForAssets,
  } = bookmarkStore
  const { isCorporateCommunicationsTheme } = store['tenantsStore']
  const { fetchCategories } = store['assetsStore']

  const isComingFromAssetDetailPage = state?.comingFrom?.includes('/investigate/bookmarks/details')

  const actionButtonsData = [
    { id: '0', name: 'Edit', link: '#', icon: EditIcon, eventName: 'EDIT' },
    { id: '1', name: 'Delete', link: '#', icon: DeleteIcon, eventName: 'DELETE' },
    { id: '2', name: 'Share', link: '#', icon: ShareIcon, eventName: 'SHARE' },
    { id: '3', name: 'Cancel', link: '#', icon: CloseIcon, eventName: 'CANCEL' },
  ]

  const searchHandler = (value: string, prevValue: string) => {
    if (value || prevValue) {
      setPrevSearchValue(searchValue)
      let requestParams = {
        page: 1,
        pageSize: paginationValues.pageSize,
        sort: 'name:asc',
      }
      if (value) {
        // @ts-ignore
        requestParams['filter'] = `name:like:${value}`
      }
      setPaginationValues({ page: 1, pageSize: paginationValues.pageSize })
      fetchBookmarksData(requestParams)
    }
  }

  const getDateRange = (): { startDate: string; endDate: string; numberOfDays: number } => {
    const TIME_FORMAT = 'YYYY-MM-DD'

    const endDate = dayjs()
    const startDate = endDate.subtract(2, 'year')

    const numberOfDays = endDate.diff(startDate, 'day')

    return {
      startDate: startDate.format(TIME_FORMAT),
      endDate: endDate.format(TIME_FORMAT),
      numberOfDays,
    }
  }

  useEffectOnce(() => {
    if (isCorporateCommunicationsTheme) {
      fetchCategories({ is_asset: true }).then((response) => {
        const brand = response?.filter((category: any) => category.name === 'Brands')
        if (brand.length > 0)
          fetchConditionsPayloadForAssets({ is_asset: true, q: `category_id:eq:${brand[0].category_id}` })
      })
    } else fetchConditionsPayloadForAssets({ is_asset: true })
  })

  useEffect(() => {
    setSnippetsFilter({
      ...snippetsFilter,
      days: {
        custom: {
          dateRange: [getDateRange().startDate, getDateRange().endDate],
          isApplied: true,
        },
        noOfDays: getDateRange().numberOfDays,
      },
    })
    if (isComingFromAssetDetailPage) {
      fetchBookmarksData({ pageSize: 10, page: state?.pageNumber })
      setPaginationValues({ page: state?.pageNumber, pageSize: 10 })
    } else fetchBookmarksData({ pageSize: 10, page: 1 })
    return () => {
      setPaginationValues({ page: 1, pageSize: 10 })
    }
  }, [isComingFromAssetDetailPage])

  return (
    <Spin size='large' spinning={bookmarksDataLoading} wrapperClassName='mmp_spinner_wrapper' className='mmp_spinner'>
      <div className='mmp_container'>
        <div className='mmp_header_container'>
          <SearchInput
            className='mmp_hc_search'
            value={searchValue}
            placeholder='Search'
            prefix={SearchIcon}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={() => searchHandler(searchValue, prevSearchValue)}
            onClear={() => {
              setSearchValue('')
              searchHandler('', prevSearchValue)
            }}
          />
          <ButtonImage
            className='mmp_hc_button'
            onClick={() => {
              setSelectedRows([])
              setSelectedRowsData([])
              setIsBookmarksModalVisible(true)
            }}
            icon={AddIcon}>
            Create new List
          </ButtonImage>
        </div>
        <div className={`mmp_content_container${!showFolderPanel ? ' mmp_content_container_add_padding' : ''}`}>
          {showFolderPanel ? (
            <div className='mmp_cc_left_side_container'>
              <FolderPanel
                onClose={() => {
                  setShowFolderPanel(false)
                }}
                selectedId={selectedFolderId}
                setSelectedId={setSelectedFolderId}
              />
            </div>
          ) : null}
          <div className='mmp_cc_main_container'>
            <div className='mmp_cc_mc_sub_header_container'>
              <div className='mmp_shc_left_section'>
                {selectedRows.length ? (
                  <div className='mmp_shc_ls_buttons mmp_shc_ls_action_buttons'>
                    {actionButtonsData.map((action) => (
                      <ButtonImage
                        key={action.id}
                        className={`mmp_cc_mc_shc_ls_button`}
                        iconClassName='mmp_cc_mc_shc_ls_button_icon'
                        onClick={() => {
                          if (action.eventName === 'CANCEL') {
                            setSelectedRows([])
                            setSelectedRowsData([])
                          }
                          if (action.eventName === 'EDIT') {
                            setIsBookmarksModalVisible(true)
                          }
                          if (action.eventName === 'SHARE') {
                            setIsShowSharedPage(true)
                          }
                          if (action.eventName === 'DELETE') {
                            setIsDeleteBookmarkModalVisible(true)
                          }
                        }}
                        icon={action.icon}>
                        {action.name}
                      </ButtonImage>
                    ))}
                  </div>
                ) : null}
              </div>
              <div className='mmp_shc_right_section'>
                <DateFilter days={[1, 7, 30, 90, 730]} pageType={'list'} subStore={'bookmark'} />
              </div>
            </div>

            <div className='mmp_cc_mc_content_container'>
              <BookmarkListViewSection
                selectedTab={selectedTab}
                // @ts-ignore
                listData={bookmarksData?.items || []}
                page={paginationValues}
                subStore={'bookmark'}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setSelectedRowsData={setSelectedRowsData}
              />
            </div>
            <div className='mmp_cc_mc_pagination_container'>
              <DefaultPagination
                className='mmp_cc_mc_pagination'
                totalCount={bookmarksData?.total_count}
                currentPage={paginationValues.page}
                pageSize={paginationValues.pageSize}
                showPageSizeChanger={false}
                nextIcon={ArrowNextIcon}
                prevIcon={ArrowNextIcon}
                iconHeight={20}
                iconWidth={20}
                onChange={(page, pageSize) => {
                  setPaginationValues({ page, pageSize })
                  fetchBookmarksData({ page, pageSize })
                }}
                locale={{ items_per_page: '' }}
              />
            </div>
          </div>
        </div>
      </div>
      {isBookmarksModalVisible && (
        <CreateBookmarkModal
          isModalVisible
          selectedRowsData={selectedRowsData}
          closeModal={() => setIsBookmarksModalVisible(false)}
          onSuccessHandler={async () => {
            await fetchBookmarksData({ pageSize: 10 })
            setSelectedRows([])
          }}
        />
      )}

      <DeleteModal
        mode={'bookmark'}
        deleteButtonLoading={deleteBookmarkLoading}
        isModalVisible={isDeleteBookmarkModalVisible}
        setIsModalVisible={setIsDeleteBookmarkModalVisible}
        deleteFlag={async () => await handleDeleteBookmark(selectedRowsData[0])}
        callback={async () => {
          await fetchBookmarksData({ pageSize: 10 })
          setSelectedRows([])
        }}
      />

      {isShowSharedPage && (
        <ShareReportModal
          endpoint={'bookmark'}
          reportId={selectedRowsData[0]?.id}
          title={'Share Bookmark'}
          desc='Invite team members'
          secondButtonText='Share'
          icon={null}
          callback={() => setSelectedRows([])}
          onClose={() => setIsShowSharedPage(false)}
        />
      )}
    </Spin>
  )
})

export default BookmarkListing
