import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  boolean_query: string
}

export const validateBooleanSearch = (payload: Props) => {
  const url = `${process.env.REACT_APP_API}/${ROUTES.copilotValidate}`
  return axios.post(url, payload)
}
