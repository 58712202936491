import axios from 'axios'
import millify from 'millify'
import { useState } from 'react'
import classNames from 'classnames'
import { Input, Tooltip } from 'antd'
import ReactMarkdown from 'react-markdown'
import { LinkOutlined } from '@ant-design/icons'

import { openNotification } from 'services/Util/openNotification'
import WarningModal from 'components/Monitor/SnippetList/MonitorSnippetItem/WarningModal/WarningModal'

import AvatarIcon from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg'
import { ReactComponent as CopiedIcon } from 'assets/images/copied_icon.svg'
import { ReactComponent as DefaultPlatform } from 'assets/images/unidentified.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'
import { containsHTML, displayApiError } from 'utils/helper'

import './InfluencerPage.scss'

type CreatorTypes = {
  channel_id: string
  channel_title: string
  channel_name: string
  user_name: string
  channel_url: string
  channel_thumb_url: string
  channel_about: string
  channel_country: string
  related_profiles: {
    platform: string
    url: string
  }[]
  channel_type: string
  platform: string
  communities: null
  followers_count: number
  following_count: number
  view_count: number
  updated: null
  score: number
}

type ChannelAPIResponseTypes = {
  total_count: number
  items: CreatorTypes[]
}

const InfluencerPage = () => {
  const [channelUrl, setChannelUrl] = useState('')
  const [searchLoading, setSearchLoading] = useState(false)
  const [copied, setCopied] = useState<string | null>(null)
  const [creatorData, setCreatorData] = useState<ChannelAPIResponseTypes | null>(null)
  const isList = false

  const getCreatorData = async (urls: string[]) => {
    try {
      setSearchLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_SEARCH_API}channel`, {
        urls,
      })

      if (response.data.total_count === 0) {
        setCreatorData(response.data)
        openNotification({
          type: 'info',
          message: 'No data found',
        })
        return
      }
      setCreatorData(response.data)
    } catch (error) {
      setCreatorData(null)
      displayApiError(error)
    } finally {
      setSearchLoading(false)
    }
  }

  const onCreatorSearch = async (url: string) => await getCreatorData([url])

  const copyToClipboard = (event: any, url?: string) => {
    if (!url) return
    event.stopPropagation()
    navigator.clipboard
      .writeText(url)
      .then((res) => {
        setCopied(url)
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard. Please try again.', { error })
      })
  }

  const getPlatformIcon = (platform: string) =>
    PLATFORM_ICONS_GREY.find((item) => item.title.toLowerCase() === platform)?.icon || <DefaultPlatform />

  return (
    <div className='influencer-container'>
      <div className='influencer-container__title'>Discover Creators</div>
      <div className='influencer-container__input-container'>
        {searchLoading ? (
          <div className='influencer-container__input-container__loader'>
            <div className='loader'></div>
            <span>Searching...</span>
          </div>
        ) : (
          <Input
            key={'watchlist-header-url-input'}
            size='large'
            placeholder='Paste the link here...'
            prefix={<LinkOutlined />}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onCreatorSearch((e.target as HTMLInputElement).value)
              }
            }}
          />
        )}
      </div>
      {creatorData?.items?.map((creator) => (
        <div className='influencer-container__card' key={creator?.channel_id}>
          <div className='profile'>
            <div className='profile__image'>
              <img
                src={creator?.channel_thumb_url || AvatarIcon}
                alt='Channel Thumbnail'
                onError={(e) => {
                  e.currentTarget.onerror = null
                  e.currentTarget.src = AvatarIcon
                }}
              />
            </div>
            <div
              className={classNames('profile__info', {
                singleCard: !isList,
              })}>
              <div className='profile__info__name'>{creator?.channel_name || ''}</div>
              {isList ? (
                <div className='profile__info__role'>Content Creator</div>
              ) : (
                <>
                  <Tooltip title={creator?.channel_url} overlayClassName='platforms-tooltip'>
                    <div className='channel-info__handle' title={creator?.channel_url}>
                      Handle: <span>{creator?.channel_url || ''}</span>
                    </div>
                  </Tooltip>
                  <div className='channel-info__handle'>
                    Platform: <span>{creator?.platform || ''}</span>
                  </div>
                  <div className='channel-info__handle'>
                    Followers: <span>{millify(creator?.followers_count || 0)}</span>
                  </div>
                  <div className='channel-info__handle'>
                    Country: <span>{creator?.channel_country}</span>
                  </div>
                </>
              )}
            </div>
          </div>

          {containsHTML(creator?.channel_about || '') ? (
            <div
              className='description'
              title={creator?.channel_about}
              dangerouslySetInnerHTML={{
                __html: creator?.channel_about || '',
              }}
            />
          ) : (
            <div className='description' title={creator?.channel_about}>
              <ReactMarkdown>{creator?.channel_about || ''}</ReactMarkdown>
            </div>
          )}

          {isList && (
            <div className='channel-info'>
              <div className='channel-info__handle' title={creator?.channel_url}>
                Handle:{' '}
                <Tooltip title={creator?.channel_url} placement='top'>
                  <span>{creator?.channel_url || ''}</span>
                </Tooltip>
              </div>
              <div className='channel-info__handle'>
                Platform: <span>{creator?.platform || ''}</span>
              </div>
              <div className='channel-info__handle'>
                Followers: <span>{millify(creator?.followers_count || 0)}</span>
              </div>
            </div>
          )}

          <div className='similar-creators'>
            <div className='similar-creators__title'>Similar creators:</div>
            <div className='similar-creators__platforms'>
              {creator?.related_profiles?.map((item) => (
                <Tooltip title={item.url} overlayClassName='platforms-tooltip'>
                  <div
                    className={classNames(
                      'creator-details__info-container__platform',
                      `${item.platform?.toLowerCase()}`,
                    )}
                    onClick={() => setChannelUrl(item.url || '')}>
                    <span className='icon'>{getPlatformIcon(item.platform?.toLowerCase() || '')}</span>
                    <span
                      className='creator-details__info-container__platform__copy-icon'
                      onClick={(e: any) => {
                        if (!copied) {
                          copyToClipboard(e, item.url)
                        }
                      }}>
                      {copied === item.url ? <CopiedIcon /> : <CopyIcon />}
                    </span>
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        </div>
      ))}

      {channelUrl && (
        <WarningModal
          link={channelUrl}
          onContinue={() => {
            setChannelUrl('')
            window.open(channelUrl || '#', '_blank', 'noopener,noreferrer')
          }}
          onClose={() => {
            setChannelUrl('')
          }}
        />
      )}
    </div>
  )
}

export default InfluencerPage
