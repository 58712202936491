import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { MANAGE_ADMIN_ACTIONS } from 'settings/settings'
import { List } from './List'
import './Manage.scss'

export const Manage = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const key = location?.pathname?.split('/').pop()

  useEffect(() => {
    if (!key) {
      navigate('/settings')
    }
  }, [key])

  return (
    <div className='manage-admin-actions'>
      <Header />
      <List />
    </div>
  )
})
const Header = observer(() => {
  const location = useLocation()
  const key = location?.pathname?.split('/').pop()
  let info = MANAGE_ADMIN_ACTIONS[key as keyof typeof MANAGE_ADMIN_ACTIONS]

  return (
    <div className='header'>
      <span className='header__title'>{info.title}</span>
      <span className='header__description'>{info.description}</span>
    </div>
  )
})
