import { isEmpty } from 'lodash'
import { ReactNode } from 'react'
import { Button, Popover, Tabs, Tag } from 'antd'

import BaseModal from 'components/BaseModal/BaseModal'
import { defaultSelectedContentItems } from './PulseDashboard'

import { ReactComponent as RemoveIcon } from 'assets/images/remove-icon.svg'
import { ReactComponent as AddIcon } from 'assets/images/add-circle-icon.svg'
import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'

import { Unknown } from 'utils/commonTypes'

import './PulseContentSelectionModal.scss'

type PulseContentSelectionModalTypes = {
  items: Unknown
  handleCancel: () => void
  isTabsModalOpen2: boolean
  isTabsModalOpen1: boolean
  isOpenContentModal: boolean
  onChange: (key: string) => void
  handleOpenDropdown1: () => void
  handleOpenDropdown2: () => void
  handleContentFilters: () => void
  handleRemoveTagFromDropdown1: (key: string) => void
  handleRemoveTagFromDropdown2: (key: string) => void
  filterComponent: (contentFilterType: string) => ReactNode
  contentFilterState: (contentFilterType: string) => string[]
  removeFilter: (event: any, contentFilterType: string) => void
  modalSelectedContentItems1: typeof defaultSelectedContentItems
  modalSelectedContentItems2: typeof defaultSelectedContentItems
}

const PulseContentSelectionModal = ({
  items,
  onChange,
  removeFilter,
  handleCancel,
  filterComponent,
  isTabsModalOpen2,
  isTabsModalOpen1,
  contentFilterState,
  handleOpenDropdown2,
  handleOpenDropdown1,
  handleContentFilters,
  isOpenContentModal = false,
  modalSelectedContentItems1,
  modalSelectedContentItems2,
  handleRemoveTagFromDropdown1,
  handleRemoveTagFromDropdown2,
}: PulseContentSelectionModalTypes) => {
  const contentFilters = ['Content source', 'Language', 'Platforms', 'Sentiment']

  const entityKeysToShowOnUI = {
    brand: 'Brands',
    followedTopics: 'Followed Topics',
    horizonTopics: 'Horizon Topics',
    influencers: 'Influencers',
  }

  return (
    <BaseModal
      open={isOpenContentModal}
      destroyOnClose
      closable={false}
      onOk={() => null}
      className='pulse-content-selection-modal'
      footer={
        <div className='pulse-content-selection-modal__footer'>
          <Button type='text' onClick={handleCancel}>
            Cancel
          </Button>
          <Button type='primary' onClick={handleContentFilters}>
            Confirm selection
          </Button>
        </div>
      }>
      <div className='snippets-filters__dropdown-filter-container'>
        <div className='snippets-filters__dropdown-filter-container__title'>Select content</div>
        <div className='snippets-filters__dropdown-filter-container__description'>
          Choose the content sources you want to include in your report. Content from any of these sources will be
          included.
        </div>
        <div className='modal-selected-content-container' onClick={handleOpenDropdown1}>
          {Object.entries(modalSelectedContentItems1)?.map(([key, value]) =>
            isEmpty(value) ? null : (
              <Tag
                className='selected-content-tag'
                onMouseDown={() => null}
                closable={true}
                onClose={() => handleRemoveTagFromDropdown1(key)}
                style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                <span className='category'>{entityKeysToShowOnUI[key as keyof typeof entityKeysToShowOnUI]}</span>
                <span className='category-values'>{value?.map((item) => item.name)?.join(', ')}</span>
              </Tag>
            ),
          )}
          <DropdownIcon className='drop-down-arrow' />
        </div>
        {isTabsModalOpen1 && <Tabs defaultActiveKey='1' items={items} onChange={onChange} key={'tabs-1'} />}
      </div>

      <div className='optional-elements'>
        <div className='optional-elements__linkage'></div>
        <div className='snippets-filters__dropdown-filter-container optional-filter-container'>
          <div className='snippets-filters__dropdown-filter-container__optional'>
            <div className='option-text'>Optional</div>
            <div className='snippets-filters__dropdown-filter-container__title'>Refine your content </div>
          </div>
          <div className='snippets-filters__dropdown-filter-container__description'>
            Narrow down your content by selecting additional criteria below. This ensures your report includes only
            items that match both your selections above and these additional criteria.
          </div>
          <div className='modal-selected-content-container' onClick={handleOpenDropdown2}>
            {Object.entries(modalSelectedContentItems2)?.map(([key, value]) =>
              isEmpty(value) ? null : (
                <Tag
                  className='selected-content-tag'
                  onMouseDown={() => null}
                  closable={true}
                  onClose={() => handleRemoveTagFromDropdown2(key)}
                  style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}>
                  <span className='category'>{entityKeysToShowOnUI[key as keyof typeof entityKeysToShowOnUI]}</span>
                  <span className='category-values'>{value?.map((item) => item.name)?.join(', ')}</span>
                </Tag>
              ),
            )}
            <DropdownIcon className='drop-down-arrow' />
          </div>
          {isTabsModalOpen2 && <Tabs defaultActiveKey='1' items={items} onChange={onChange} key={'tabs-2'} />}
        </div>
        <div className='content-filters-linked'>
          <div className='link' />
          <div className='content-select'>
            <div className='content-select__header'>
              <div className='content-select__header__content'>
                <h6 className='title'>
                  <span>Optional </span> Apply additional filters
                </h6>
                <p className='description'>
                  Apply additional filters to the content, such as keywords, content source and other attributes.
                </p>
                <div className='filters-list'>
                  {contentFilters.map((content) => (
                    <Popover
                      content={filterComponent(content)}
                      trigger='click'
                      overlayClassName='content-filters-list-item__popover'
                      placement='topRight'
                      destroyTooltipOnHide
                      getPopupContainer={(triggerNode: any) => triggerNode.parentNode}>
                      <div className='content-filters-list-item__container'>
                        <div className='content-filters-list-item__info'>
                          <span className='content-filters-list-item__info__title'>{content}</span>
                        </div>
                        <div className='content-filters-list-item__tags'>
                          {contentFilterState(content)?.map((tag: string, index) => (
                            <span>
                              {tag}
                              {contentFilterState(content)?.length - 1 !== index && ','}
                            </span>
                          ))}
                        </div>

                        {contentFilterState(content)?.length > 0 ? (
                          <RemoveIcon onClick={(event) => removeFilter(event, content)} />
                        ) : (
                          <AddIcon />
                        )}
                      </div>
                    </Popover>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

export default PulseContentSelectionModal
