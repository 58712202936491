import millify from 'millify'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { PieChart, Pie, Cell, Label, Legend } from 'recharts'

import { SnippetsFilter } from 'models/models'

import './NewPieChart.scss'
import { GenericDispatch } from 'utils/commonTypes'

interface Props {
  snippetsFilter: SnippetsFilter
  width?: number
  legendWidth?: number
  cx?: string
  cy?: string
  isFlexLegend?: boolean
  height?: number
  data?: any[] | undefined
  colorPalette: string[]
  totalCount: number
  narrativeTotal: number
  onClickCell: (data: any) => void
  showLabelsBasedOnSelection?: boolean
  millifyStats?: boolean
  customLegendWrapperClass?: string
  syncSentimentFilterWithChart?: boolean
  sentimentFilter?: string
  isUsedInModal?: boolean
  hoveredCell: string
  setHoveredCell: GenericDispatch<string>
  selectedIndexLocal: string
}

const NewPieChart = observer((props: Props) => {
  const {
    data,
    width,
    height = 400,
    cx = '50%',
    cy = '45%',
    legendWidth = 400,
    isFlexLegend = false,
    colorPalette,
    totalCount,
    narrativeTotal,
    onClickCell,
    showLabelsBasedOnSelection = false,
    millifyStats,
    customLegendWrapperClass,
    hoveredCell = '',
    setHoveredCell,
    selectedIndexLocal = '',
  } = props

  const baseHeight = 400

  useEffect(() => {
    setHoveredCell('')
  }, [data])

  const renderLegend = ({ payload: item }: any) => {
    if (totalCount > 0 && item && Object.keys(item)?.length > 0) {
      return (
        <div className={classNames('new-pie-chart__list', customLegendWrapperClass, { legend_flex: isFlexLegend })}>
          {item.map((entry: any, index: number) => (
            <div
              className='new-pie-chart__legend'
              onClick={() => {
                if (totalCount > 0) {
                  if (selectedIndexLocal === entry.payload.name) {
                    onClickCell(null)
                  } else
                    onClickCell({
                      conditions: entry.payload.conditions,
                      name: entry.payload.name,
                    })
                }
              }}
              key={`${index}legend`}>
              <div className='new-pie-chart__legend__title'>
                <div
                  className={classNames('new-pie-chart__legend__list')}
                  style={{ background: entry.payload.fill }}></div>
                <span className='new-pie-chart__legend__labels new-pie-chart__legend__margin'>
                  {entry.payload.name?.length > 15 ? entry.payload.name.substring(0, 13) + '...' : entry.payload.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      )
    } else
      return (
        <div className='new-pie-chart__legend__no-data-div'>
          <span className='new-pie-chart__legend__no-data-div__title'>No Data Available</span>
          <span className='new-pie-chart__legend__no-data-div__description'>
            There are no intersections for this category.
          </span>
        </div>
      )
  }

  const getPercentage = () => {
    const count = showLabelsBasedOnSelection ? getTotalCount() : totalCount
    const percentage = (count / narrativeTotal) * 100
    if (isNaN(percentage)) return '0%'
    else return `${percentage.toFixed(2).replace(/[.,]00$/, '')}%`
  }

  const getColor = (entry: any, index: any) => {
    try {
      if (totalCount === 0) return '#EAECF0'
      else {
        if (selectedIndexLocal?.length > 0) {
          return selectedIndexLocal === entry.name ? colorPalette[index % colorPalette?.length] : '#D0D5DD'
        } else if (hoveredCell?.length > 0) {
          return hoveredCell === entry.name ? colorPalette[index % colorPalette?.length] : '#EAECF0'
        } else if (!isEmpty(colorPalette)) return colorPalette[index % colorPalette?.length]
      }
    } catch (error) {
      console.log('error in getColor', error)
    }
  }

  const getGraphComponent = (selectedIndexLocal: string) => {
    const dataValues = totalCount > 0 ? data : [{ name: 'any', total_value: 1 }]
    return dataValues?.map((entry, index) => (
      <Cell
        key={`cell-${index}`}
        fill={getColor(entry, index)}
        cursor={'pointer'}
        onClick={() => {
          if (totalCount > 0) {
            if (selectedIndexLocal === entry.name) {
              onClickCell(null)
            } else {
              onClickCell(entry)
            }
          }
        }}
        onMouseEnter={() => {
          if (totalCount > 0) {
            if (selectedIndexLocal) return
            setHoveredCell(entry.name)
          }
        }}
        onMouseOut={() => {
          if (totalCount > 0) {
            setHoveredCell('')
          }
        }}
      />
    ))
  }

  const getTotalCount = () => {
    if (!showLabelsBasedOnSelection || !selectedIndexLocal) return totalCount
    const count = data?.find((entry) => entry.name === selectedIndexLocal)
    return count.total_value
  }

  return (
    <PieChart width={width} height={height} className='pie-chart-container'>
      <Pie
        data={totalCount > 0 ? data : [{ name: 'any', total_value: 1 }]}
        cx={cx}
        cy={cy}
        innerRadius={(height / baseHeight) * 80}
        outerRadius={(height / baseHeight) * 110}
        fill='#8884d8'
        paddingAngle={3}
        dataKey='total_value'>
        {getGraphComponent(selectedIndexLocal)}
        <Label
          value={millifyStats ? millify(getTotalCount()) : getTotalCount()}
          position='centerTop'
          className='new-pie-chart__label'
          fill={totalCount > 0 ? colorPalette?.[0] || '#000' : '#98a2b3'}
        />
        <Label value={getPercentage()} position='centerBottom' className='new-pie-chart__transform' fontSize='16px' />
      </Pie>
      <Legend
        layout='horizontal'
        verticalAlign='bottom'
        align='center'
        content={renderLegend}
        cy={0}
        wrapperStyle={{
          width: legendWidth,
        }}
      />
    </PieChart>
  )
})

export default NewPieChart
