import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { TopLevelNavigation } from 'components/common/TopLevelNavigation/TopLevelNavigation'

export const SettingsPage = () => {
  const location = useLocation()

  const subNavigation = ['profile', 'users', 'spotlight', 'power-insights', 'api-docs', 'tenants', 'manage']

  const containsSubnav = subNavigation.some((el) => location.pathname.includes(el))

  return (
    <>
      {!containsSubnav && <Navigate to='profile' />}
      {<TopLevelNavigation page='Settings' />}
      <Outlet />
    </>
  )
}
