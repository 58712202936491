import { UtilService } from 'services/Util/Util'
import { Unknown } from './commonTypes'
import { Auth } from 'aws-amplify'
import dayjs, { ManipulateType } from 'dayjs'
import moment from 'moment'
import { isEmpty } from 'lodash'

export const displayApiError = (e: any) => {
  const response = e.response
  UtilService.openError({
    title: response?.title || '',
    requestId: response?.data?.request_id || '',
    statusCode: response?.status || 400,
    message: response?.data?.err_msg || 'An error occurred',
  })
}

export const skipRoles = (skip: string[]) => {
  const roles = [
    'ADMIN',
    'PENDULUM_ADMIN',
    'USER',
    'VIEWER',
    'SCORECARD_USER',
    'DEPLOYMENT_STRATEGIST',
    'BUILDER',
    'ANALYST',
  ]

  return roles?.filter((role) => !skip?.includes(role))
}

export const convertMobXProxiesToJSObject = (proxies: Record<string, Unknown>): Record<string, Unknown> => {
  if (proxies) return JSON.parse(JSON.stringify(proxies))
  return {}
}

export const containsHTML = (text: string): boolean => {
  const htmlPattern = /<[^>]+>/
  return htmlPattern.test(text)
}

export const extractBodyContent = (htmlString: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const bodyContent = doc.body.innerHTML
  return bodyContent
}

export const checkSessionValidity = async (): Promise<boolean> => {
  try {
    await Auth.currentSession()
    return true
  } catch (error) {
    console.error('Session is invalid or expired:', error)
    return false
  }
}

export const getDateRange = (
  count = 3,
  type = 'year',
): { startDate: string; endDate: string; numberOfDays: number } => {
  const TIME_FORMAT = 'YYYY-MM-DD'

  const endDate = dayjs()
  const startDate = endDate.subtract(count, type as ManipulateType)

  const numberOfDays = endDate.diff(startDate, 'day')

  return {
    startDate: startDate.format(TIME_FORMAT),
    endDate: endDate.format(TIME_FORMAT),
    numberOfDays,
  }
}

export const convertDateAndTimeToUTC = (dateString: string) =>
  dayjs(dateString, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
export const convertDateToLocal = (dateString: string) => dayjs(dateString, 'YYYY-MM-DD hh:mm A').format('MMM DD, YYYY')
export const convertTimeToLocal = (dateString: string) => dayjs(dateString, 'YYYY-MM-DD hh:mm A').format('hh:mm A')
export const dateAndTimeToLocalDateAndTimeValue = (date: string) => dayjs(date).utc().local().valueOf()

export const getTime = (date: string | number) => {
  if (isEmpty(date)) return

  const postMoment = moment(date)
  if (!postMoment.isValid()) return 'Invalid date'

  const currentDate = moment()
  const diffInMinutes = currentDate.diff(postMoment, 'minutes')

  if (diffInMinutes < 0) return 'In the future'
  if (diffInMinutes === 0) return 'just now'
  if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`

  const diffInHours = currentDate.diff(postMoment, 'hours')
  if (diffInHours < 24) return `${diffInHours} hours ago`

  const diffInDays = currentDate.diff(postMoment, 'days')
  if (diffInDays < 7) return `${diffInDays} days ago`

  return postMoment.format('MMM D, YYYY')
}

export const mergeArrayDynamic = (inputArray: Record<string, any>[]): any => {
  const result: Record<string, any> = {}

  inputArray?.forEach((item) => {
    Object.keys(item)?.forEach((key) => {
      if (Array.isArray(item[key])) {
        result[key] = result[key] ? [...result[key], ...item[key]] : [...item[key]]
      } else {
        result[key] = result[key] ? `${result[key]}, ${item[key]}` : item[key]
      }
    })
  })

  return result
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const isObjectEmpty = (obj: any): boolean => {
  if (obj === null || obj === undefined) return true
  if (typeof obj !== 'object') return false

  if (Array.isArray(obj)) {
    return obj.length === 0 || obj.every(isObjectEmpty)
  }

  if (Object.keys(obj).length === 0) return true

  return Object.values(obj).every(isObjectEmpty)
}
