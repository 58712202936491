import React from 'react'
import { createPortal } from 'react-dom'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-filled.svg'
import { ReactComponent as Closeicon } from 'assets/images/close-icon.svg'
import AntdButton, { CustomButtonProps } from '../AntdButton/AntdButton'

import './CustomModal.scss'

type CustomModalProps = {
  containerClassName?: string
  boxContainerClassName?: string
  headerClassName?: string
  bodyClassName?: string
  footerClassName?: string
  children: React.ReactNode
  disabledSecondButton?: boolean
  title?: string
  icon?: React.ElementType | null
  iconSize?: number
  closeIcon?: React.ElementType
  firstButtonText?: string
  secondButtonText?: string
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => void
  closeIconClassName?: string
  iconClassName?: string
  titleClassName?: string
  firstButtonProps?: CustomButtonProps
  secondButtonProps?: CustomButtonProps
}

function CustomModal(props: CustomModalProps) {
  const {
    containerClassName = '',
    boxContainerClassName = '',
    headerClassName = '',
    bodyClassName = '',
    footerClassName = '',
    children,
    disabledSecondButton,
    title,
    icon: Icon = ShareIcon,
    iconSize,
    closeIcon: CloseIcon = Closeicon,
    firstButtonText = 'Cancel',
    secondButtonText = 'Share',
    onClose,
    onCancel,
    onConfirm,
    firstButtonProps,
    secondButtonProps,
  } = props

  const closeModalHandler = () => {
    onClose?.()
  }

  const cancelHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onCancel?.()
    closeModalHandler()
  }

  const confirmHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onConfirm?.()
  }

  return createPortal(
    <div className={`custom_modal_container ${containerClassName}`} onClick={closeModalHandler}>
      <div className={`cmc_content_container ${boxContainerClassName}`} onClick={(e) => e.stopPropagation()}>
        <div className={`cmc_cc_header_container ${headerClassName}`}>
          <div className='cmc_cc_hc_left_container'>
            {Icon ? (
              <>
                {iconSize ? (
                  <Icon height={iconSize} width={iconSize} className='cmc_cc_hc_lc_icon' />
                ) : (
                  <Icon className='cmc_cc_hc_lc_icon' />
                )}{' '}
              </>
            ) : null}
            <p className='cmc_cc_hc_lc_heading'>{title}</p>
          </div>
          <CloseIcon className='cmc_cc_hc_close_icon' height={14} width={14} onClick={closeModalHandler} />
        </div>
        <div className={`cmc_cc_main_content ${bodyClassName}`}>{children}</div>
        <div className={`cmc_cc_footer_container ${footerClassName}`}>
          <AntdButton type='outlined' onClick={cancelHandler} {...firstButtonProps}>
            {firstButtonText}
          </AntdButton>
          {secondButtonText && (
            <AntdButton
              onClick={confirmHandler}
              disabled={disabledSecondButton || !!secondButtonProps?.loading}
              {...secondButtonProps}>
              {secondButtonText}
            </AntdButton>
          )}
        </div>
      </div>
    </div>,
    document.getElementById('custom_modal')!,
  )
}

export default CustomModal
