import { observer } from 'mobx-react-lite'
import { useEffect, useState, useCallback } from 'react'
import { store } from 'store'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { CustomPieChart } from 'components/common/PieChart/PieChart'
import { DownloadOption } from '../DownloadOption'
import { SearchConditions, SnippetFilterPayload } from 'models/models'
import EmptyState from '../PortfolioShareChart/EmptyState'

const CompetitorsShareChart = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const {
    competitorsList,
    competitorsLoading,
    graphColorPalette,
    getDate,
    fetchInsightsMetrics,
    fetchCompetitorsList,
    snippetsFilter,
    assetsCategories,
    getSnippetFilterPayload,
  } = store.assetsStore
  const competitorsCategory = assetsCategories.find((item) => item.name === 'Competitors')

  const [chartData, setChartData] = useState<{ name: string; total_value: number }[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const createDataForChart = useCallback(async () => {
    setLoading(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()

    const assetsToFetch = competitorsList.items.filter(
      (asset: AssetListObjType) => Object.keys(asset.conditions).length > 0,
    )

    //use community,platform,sentiment and flags filter,
    const requestParams = {
      q: `start_date:gte:${getDate.startDate},end_date:lte:${getDate.endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    try {
      const responses = await Promise.all(
        assetsToFetch.map((asset) => {
          const conditions = [asset.conditions]
          if (snippetsFilter.flags && Object.keys(snippetsFilter.flags).length > 0) {
            conditions.push(snippetsFilter.flags.conditions as SearchConditions)
          }
          return fetchInsightsMetrics({ params: requestParams, data: { conditions: conditions } })
        }),
      )

      const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
      setChartData(
        assetsToFetch.map((asset, index) => ({
          name: asset.name,
          total_value: responses[index] !== 'error' ? responses[index] : 0,
        })),
      )
      setTotalCount(totalCount)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setLoading(false)
    }
  }, [competitorsList.items, getDate])

  useEffect(() => {
    if (competitorsCategory) {
      const requestParams: AssetListParamsType = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
        is_asset: true,
        is_active: true,
        q: `category_id:eq:${competitorsCategory.category_id}`,
      }
      fetchCompetitorsList({ params: requestParams })
    }
  }, [competitorsCategory])

  useEffect(() => {
    if (!competitorsLoading && competitorsList.total_count > 0) {
      createDataForChart()
    }
  }, [competitorsLoading, competitorsList, snippetsFilter])

  if (competitorsLoading || loading) return <EmptyState title='Competitor Share of Voice' />

  return (
    <div id='competitorChart'>
      <div className='chart-heading'>
        <p className='monitor-assets__graph__title'>Competitor Share of Voice</p>
        <DownloadOption elementToCapture='#competitorChart' contentScrollable />
      </div>

      <CustomPieChart
        height={192}
        width={350}
        data={chartData}
        colorPalette={graphColorPalette.Competitors}
        totalCount={totalCount}
        narrativeTotal={totalCount}
        onClickCell={() => {}}
        showLabelsBasedOnSelection
        millifyStats
        customLegendWrapperClass='custom-pie-chart__scrollable'
        snippetsFilter={snippetsFilter}
      />
    </div>
  )
}

export default observer(CompetitorsShareChart)
