import dayjs from 'dayjs'
import TagManager from 'react-gtm-module'
import { action, makeObservable, observable } from 'mobx'

import { API, bookmarksAPI } from 'api/api'

import { PaginationValuesType } from 'types/types'
import { UtilService } from 'services/Util/Util'
import { displayApiError } from 'utils/helper'
import { IListItem, ListSelectedRowData, route } from 'models/models'

import { MainStore } from 'store/main/main.store'
import { uniqBy } from 'lodash'

export class BookmarkStore extends MainStore {
  bookmarksDataLoading: boolean = false
  deleteBookmarkLoading: boolean = false
  bookmarksData: { items: IListItem[]; total_count: number } = {
    total_count: 0,
    items: [],
  }
  paginationValues: PaginationValuesType = {
    page: 1,
    pageSize: 10,
  }

  constructor() {
    super()

    this.snippetsFilter = {
      ...this.snippetsFilter,
      days: { ...this.snippetsFilter.days, noOfDays: 730 },
    }

    makeObservable(this, {
      bookmarksData: observable,
      paginationValues: observable,
      bookmarksDataLoading: observable,
      deleteBookmarkLoading: observable,
      setBookmarksData: action.bound,
      setPaginationValues: action.bound,
      setBookmarksDataLoading: action.bound,
      setDeleteBookmarkLoading: action.bound,
    })
  }

  setBookmarksDataLoading = (loading: boolean) => {
    this.bookmarksDataLoading = loading
  }

  setDeleteBookmarkLoading = (loading: boolean) => {
    this.deleteBookmarkLoading = loading
  }

  setBookmarksData = (data: { items: IListItem[]; total_count: number }) => {
    this.bookmarksData = data
  }

  setPaginationValues = ({ page, pageSize }: PaginationValuesType) => {
    this.paginationValues = { ...this.paginationValues, page, pageSize }
  }

  get getDate(): { startDate: string; endDate: string } {
    const days = this.snippetsFilter.days.noOfDays
    const dateRange = this.snippetsFilter.days.custom.isApplied ? this.snippetsFilter.days.custom.dateRange : []

    let endDate = dayjs().format('YYYY-MM-DD')
    let startDate = dayjs(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    if (dateRange.length) {
      ;[startDate, endDate] = dateRange
    } else {
      startDate = days ? dayjs(endDate).subtract(days, 'days').format('YYYY-MM-DD') : startDate
    }

    return {
      startDate,
      endDate,
    }
  }

  fetchBookmarksData = async (params: {
    pageSize?: number
    page?: number
    q?: string
    isInfiniteScrolling?: boolean
  }) => {
    try {
      const apiParams = {
        route: 'bookmark' as route,
        sort: 'created:desc',
        getError: true,
        ...params,
      }

      this.setBookmarksDataLoading(true)
      let { data, total } = await API.get(apiParams)

      const items = data.items.map((item: any) => {
        const date = new Date(item.last_update_time.replace(/\s/, 'T'))
        const lastModifiedMonth = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)
        const lastModifiedDate = (date.getDate() < 10 ? '0' : '') + date.getDate()

        const createDate = new Date(item.created.replace(/\s/, 'T'))
        const createdMonth = (createDate.getMonth() < 9 ? '0' : '') + (createDate.getMonth() + 1)
        const createdDate = (createDate.getDate() < 10 ? '0' : '') + createDate.getDate()

        return {
          id: item.id,
          name: item.name,
          idNumber: item.narrative_number,
          impresions: -1,
          impresionsMilified: '-1',
          trend: 'positive',
          narrativeType: item.narrative_type,
          ownerId: item.owner_id,
          lastModifiedBy: item.last_updated_by,
          dateLastModified: `${lastModifiedMonth}/${lastModifiedDate}/${date.getFullYear()}`,
          created: `${createdMonth}/${createdDate}/${createDate.getFullYear()}`,
          description: item.description,
          conditions: { document_ids: item?.entity_info?.flatMap((item: { entities: string[] }) => item.entities) },
          tags: item.tags,
          mode: 'bookmark',
          alertId: item?.alert_id,
          permissionLevel: item?.permission_level,
          entity_info: item?.entity_info?.flatMap((item: { entities: string[] }) => item.entities),
        }
      })

      const bookmarks = params?.isInfiniteScrolling
        ? uniqBy([...this.bookmarksData.items, ...items], (item) => item.id)
        : items

      this.setBookmarksData({ items: bookmarks, total_count: total })
    } catch (error: any) {
      displayApiError(error)
    } finally {
      this.setBookmarksDataLoading(false)
    }
  }

  handleDeleteBookmark = async (selectedBookmark: ListSelectedRowData) => {
    if (!selectedBookmark) {
      return
    }
    try {
      this.setDeleteBookmarkLoading(true)
      // google analytics delete bookmark
      TagManager.dataLayer({
        dataLayer: {
          event: 'delete_item',
          entityId: selectedBookmark.id,
          entityName: selectedBookmark.name,
          entityType: 'bookmark',
          tenantId: '',
          entityOwner: selectedBookmark.ownerId,
        },
      })
      await bookmarksAPI.deleteBookmark(selectedBookmark.id)
    } catch (error: any) {
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      this.setDeleteBookmarkLoading(false)
    }
  }

  fetchAllBookmarksForFeed = async () => {
    try {
      let { data, total_count } = await API.get({
        route: 'bookmark',
        getError: true,
        customProps: { can_edit: true },
      })

      this.setBookmarksData({ items: data?.items, total_count })
    } catch (error: any) {
      displayApiError(error)
    }
  }
}
