/* eslint-disable prettier/prettier */
import { BookmarkStore } from './bookmark/bookmark.store'
import { ResetPasswordStore } from './signIn/resetPassword.store'
import { FeedbackStore } from './feedback/feedback.store'
import { NavViewStore } from './nav/navView.store'
import { LoaderStore } from './loader/loader.store'
import { UsersStore } from './user/users.store'
import { UserStore } from './user/user.store'
import { AttentionFlagsStore } from './attentionFlags/attentionFlags.store'
import { ReportMainViewStore } from './report/reportMainView.store'
import { MonitorExportDownloadsStore } from './export/monitorDownloads.store'
import { TenantsStore } from './tenants/tenants.store'
import { FlagsStore } from './flags/flags.store'
import { AssetsStore } from './asset/asset.store'
import { VectorsStore } from './vectors/vectors.store'
import { ToasterStore } from './toaster/toaster.store'
import { BreadcrumbStore } from './breadcrumb/breadcrumb.store'
import { MainStore } from './main/main.store'
import { AnticipatoryIntelligenceStore } from './anticipatoryIntelligence/anticipatoryIntelligence.store'
import { DefineWatchlistStore } from './define/defineWatchlist.store'
import { DefineNarrativeStore } from './define/defineNarrative.store'
import { assetsFlagsStore } from './flags/assetsFlags.store'
import { vectorsFlagsStore } from './flags/vectorsFlags.store'
import { ManageStore } from './manage/manage'

class RootStore {
  userStore
  usersStore
  mainStore
  flagsStore
  assetsFlagsStore
  vectorsFlagsStore
  assetsStore

  vectorsStore
  anticipatoryIntelligenceStore
  bookmarkStore

  defineNarrativeStore
  defineWatchlistStore
  attentionFlagsStore
  tenantsStore
  navViewStore
  loaderStore
  resetPasswordStore
  feedbackStore

  monitorExportDownloadsStore

  reportMainViewStore
  toasterStore
  breadcrumbStore
  mainModalStore

  assetsModalStore
  vectorsModalStore
  bookmarkModalStore
  reportMainViewModalStore
  anticipatoryIntelligenceModalStore

  manageStore

  constructor() {
    this.userStore = new UserStore()
    this.loaderStore = new LoaderStore()
    this.resetPasswordStore = new ResetPasswordStore()

    this.feedbackStore = new FeedbackStore()

    this.usersStore = new UsersStore()

    this.navViewStore = new NavViewStore()

    this.attentionFlagsStore = new AttentionFlagsStore()
    this.tenantsStore = new TenantsStore()

    this.reportMainViewStore = new ReportMainViewStore()
    this.monitorExportDownloadsStore = new MonitorExportDownloadsStore()
    this.bookmarkStore = new BookmarkStore()
    this.flagsStore = new FlagsStore()
    this.assetsFlagsStore = new assetsFlagsStore()
    this.vectorsFlagsStore = new vectorsFlagsStore()
    this.flagsStore = new FlagsStore()
    this.assetsStore = new AssetsStore()
    this.vectorsStore = new VectorsStore()
    this.toasterStore = new ToasterStore()
    this.breadcrumbStore = new BreadcrumbStore()

    this.mainModalStore = new MainStore()
    this.assetsModalStore = new AssetsStore()
    this.vectorsModalStore = new VectorsStore()
    this.bookmarkModalStore = new BookmarkStore()
    this.reportMainViewModalStore = new ReportMainViewStore()
    this.anticipatoryIntelligenceModalStore = new AnticipatoryIntelligenceStore()

    this.mainStore = new MainStore()
    this.anticipatoryIntelligenceStore = new AnticipatoryIntelligenceStore()
    this.defineNarrativeStore = new DefineNarrativeStore()
    this.defineWatchlistStore = new DefineWatchlistStore()

    this.manageStore = new ManageStore()
  }
}

export const store = new RootStore()
