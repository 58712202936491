import { API, flagsAPI, monitorAPI, reportsAPI } from 'api/api'
import { action, makeObservable, observable } from 'mobx'
import { AdminActionsType, IResponse, IUsers } from 'models/models'
import { ROUTES } from 'settings/settings'
import { ReportRoutesType } from 'store/report/valueTypes'
import { URLType } from 'types/types'

export class ManageStore {
  listData: IResponse = {
    items: [],
    total_count: 0,
  }
  pagination: {
    page: number
    per_page: number
  } = {
    page: 1,
    per_page: 10,
  }
  users: Record<string, IUsers> = {}

  constructor() {
    makeObservable(this, {
      listData: observable,
      pagination: observable,
      users: observable,
      setListData: action.bound,
      setPagination: action.bound,
      setUsers: action.bound,
    })
  }

  setListData = (data: { total_count: number; items: any[] }, isCustomInsight: boolean = false) => {
    const updatedData = data.items.map((item) => ({
      id: item.id,
      name: item.name,
      owner_id: isCustomInsight ? item.created_by : item.owner_id,
      created: item.created,
    }))
    this.listData = { total_count: data.total_count, items: updatedData }
  }

  setPagination = (pagination: { page: number; per_page: number }) => {
    this.pagination = pagination
  }

  setUsers = (data: IUsers[]) => {
    const users: Record<string, IUsers> = {}
    data.forEach((item) => {
      users[item.id] = item
    })
    this.users = { ...this.users, ...users }
  }

  fetchData = async (tab: AdminActionsType) => {
    const params = {
      page: this.pagination.page,
      per_page: this.pagination.per_page,
      sort: 'name:asc',
    }

    switch (tab) {
      case 'followed-topics':
        const { data: topicsList } = await flagsAPI.getFlagsList({ params: { ...params } })
        if (topicsList) {
          this.setListData(topicsList, true)
        }
        break

      case 'horizon-topics':
        const { data: followedTopicsList } = await monitorAPI.getMonitors({ url: ROUTES.narrative as URLType, params })
        if (followedTopicsList) {
          this.setListData(followedTopicsList)
        }
        break

      case 'brands':
        const { data: brandsList } = await flagsAPI.getFlagsList({ params: { ...params, is_asset: true } })
        if (brandsList) {
          this.setListData(brandsList, true)
        }
        break

      case 'influencers':
        const { data: influencersList } = await monitorAPI.getMonitors({ url: ROUTES.watchlist as URLType, params })
        if (influencersList) {
          this.setListData(influencersList)
        }
        break

      case 'bookmarks':
        const { data: bookmarksList } = await monitorAPI.getMonitors({ url: ROUTES.bookmark as URLType, params })
        if (bookmarksList) {
          this.setListData(bookmarksList)
        }
        break

      case 'reports':
        const { data: reportsList } = await reportsAPI.getLists(ROUTES.reports as ReportRoutesType)
        if (reportsList) {
          this.setListData(reportsList)
        }
        break

      default:
        break
    }

    this.fetchCreators()
  }

  fetchCreators = async () => {
    try {
      const filteredId = this.listData.items.filter((item) => !Object.keys(this.users)?.includes(item.owner_id))
      const promises = filteredId.map(async (item) => {
        const { data } = await API.get({ route: 'user', filter: `id:eq:${item.owner_id}`, getError: true })
        return data?.items?.[0] || {}
      })
      const response = await Promise.all(promises)
      this.setUsers(response)
    } catch (error) {
      console.log(error)
    }
  }
}
