import { Avatar, Button } from 'antd'
import BaseModal from 'components/BaseModal/BaseModal'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { store } from 'store'
import UserAvatar from 'assets/images/LogoiconMobile.svg'
import dayjs from 'dayjs'
import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import classNames from 'classnames'
import { ReactComponent as RightArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'
import './TopicDetails.scss'

type NarrativeKeywordInfoProps = {
  type?: 'KEYWORD_ONLY' | 'TEXTUAL' | 'NLP'
  booleanQuery: string
  languages: { [x: string]: string }
}

export default function TopicDetails({ open, onClose, details }: { open: boolean; onClose: () => void; details: any }) {
  const { usersStore } = store
  const { users, getUser, fetchUsers } = usersStore

  useEffectOnce(() => {
    try {
      if (!users.items?.length) {
        fetchUsers()
      }
    } catch (error) {
      console.log(error)
    }
  })

  return (
    <BaseModal
      open={open}
      isHeaderBordered
      isFooterBordered
      title='Topic details'
      onCancel={onClose}
      footer={
        <>
          <Button type='text' onClick={onClose} className='close-btn'>
            Close
          </Button>
        </>
      }>
      <div className='topic-details'>
        <div className='topic-details__box'>
          <h5 className='topic-details__heading'>Topic ID</h5>
          <p className='topic-details__description'>{details.id}</p>
        </div>
        <div className='topic-details__box'>
          <h5 className='topic-details__heading'>Copilot Description</h5>
          <p className='topic-details__description'>{details?.description || 'N/A'}</p>
        </div>
        <div className='topic-details__box'>
          <h5 className='topic-details__heading'>Topic Number</h5>
          <p className='topic-details__description'>{details?.narrative_number || 'N/A'}</p>
        </div>
        <div className='topic-details__editor-info'>
          <div>
            <h5 className='topic-details__heading'>Created by</h5>
            <div className='topic-details__created-by'>
              <Avatar src={UserAvatar} size={40} />
              <div>
                <h5>{getUser(details.owner_id)}</h5>
                <p>{dayjs(details.created).format('DD/MM/YYYY') || ''}</p>
              </div>
            </div>
          </div>
          {details?.last_updated_by && (
            <div>
              <h5 className='topic-details__heading'>Last edited by</h5>
              <div className='topic-details__created-by'>
                <Avatar src={UserAvatar} size={40} />
                <div>
                  <h5>{getUser(details.last_updated_by)}</h5>
                  <p>{dayjs(details.last_update_time).format('DD/MM/YYYY') || ''}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='topic-details__box'>
          <h5 className='topic-details__heading'>Key words</h5>
          <NarrativeKeywordInfo
            booleanQuery={details.boolean_query}
            languages={details.multi_lang_kwd_expression}
            type={details.narrative_type}
          />
        </div>
      </div>
    </BaseModal>
  )
}

const NarrativeKeywordInfo = (props: NarrativeKeywordInfoProps) => {
  const { type, booleanQuery, languages } = props

  const keys = Object.keys(languages || {})

  const [keywordSearchExpression, setKeywordSearchExpression] = useState(booleanQuery)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('Default')
  const [languageRef, setLanguageRef] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setKeywordSearchExpression(booleanQuery)
  }, [booleanQuery])

  const scrollHandler = (scrollOffset: number) => {
    if (languageRef) {
      const currentScroll = languageRef.scrollLeft
      const maxScroll = languageRef.scrollWidth - languageRef.clientWidth

      if (currentScroll < maxScroll) {
        languageRef.scrollLeft += scrollOffset
      } else {
        languageRef.scrollLeft = 0
      }
    }
  }

  const selectLanguageTab = async (key: any) => {
    if (languages && keys.length > 0) {
      key === 'Default'
        ? setKeywordSearchExpression(booleanQuery.replace(/(AND|OR)/gi, '<b>$1</b>'))
        : setKeywordSearchExpression(languages[key])
      setSelectedLanguage(key)
    }
  }

  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setLanguageRef(node)
  }, [])

  return (
    <>
      {booleanQuery.length > 0 && keys.length > 0 ? (
        <div className='topic-details-keyword-info'>
          <div className=' topic-details-keyword-info__nav-container'>
            <nav className='topic-details-keyword-info__languagesBar' ref={listRefCallback}>
              {['Default', ...keys].map((language) => {
                return (
                  <button
                    className={classNames('topic-details-keyword-info__title', {
                      'topic-details-keyword-info__title--active': selectedLanguage === language,
                    })}
                    key={language}
                    onClick={() => selectLanguageTab(language)}>
                    {language === 'Default' ? 'Default' : SUPPORTED_LANGUAGES_CODE[language]}
                  </button>
                )
              })}
            </nav>

            <RightArrowIcon
              onClick={() => {
                scrollHandler(25)
              }}
            />
          </div>
          {type === 'NLP' ? (
            <div className='topic-details-keyword-info__text--ML'>
              <span>
                This Narrative uses a custom developed and trained Machine Learning model to detect the content that
                best represents this narrative. Machine Learning narratives eliminate the need to come up with and
                refine keyword searches, as well as dramatically reducing noise in search results.
              </span>
            </div>
          ) : (
            <div className='topic-details-keyword-info__text--others'>
              <span
                dangerouslySetInnerHTML={{
                  __html: keywordSearchExpression,
                }}
              />
            </div>
          )}
        </div>
      ) : (
        'N/A'
      )}
    </>
  )
}
