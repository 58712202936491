import millify from 'millify'
import { store } from 'store'
import classNames from 'classnames'
import { useEffectOnce } from 'react-use'
import { observer } from 'mobx-react-lite'
import { memo, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Col, Drawer, Radio, Row, Skeleton, Spin, Table, Tooltip } from 'antd'

import FollowedTopicChart from './FollowedTopicsChart'
import AlertModal from 'components/AlertModal/AlertModal'
import Pagination from 'components/Pagination/Pagination'
import AntdButton from 'components/common/AntdButton/AntdButton'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'
import NewBrandSentiment from 'components/Asset/NewBrandDashboard/NewBrandSentiment'
import PulseMetricsCard from 'pages/PulseDashboard/PulseMetricsCard/PulseMetricsCard'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import NewPortfolioShareChart from 'components/Asset/NewBrandDashboard/NewPortfolioShareChart'
import { platformIcons } from 'components/Monitor/PowerInsights/Creators/CreatorsList/CreatorsList'
import AddBrandWizard from 'components/Asset/BrandsDashboard/components/AddBrandWizard/AddBrandWizard'
import InfluencerListVertical from 'components/Asset/BrandsDashboard/components/InfluencerListVertical/InfluencerListVertical'
import PowerInsightTabsListVertical from 'components/Asset/BrandsDashboard/components/PowerInsightTabsListVertical/PowerInsightTabsListVertical'

import AvatarIcon from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'
import { ReactComponent as SpiralIcon } from 'assets/images/spiral_stars.svg'
import { ReactComponent as SubscribeIcon } from 'assets/images/alert-icon.svg'
import { ReactComponent as ListIcon } from 'assets/images/icons/assets/list.svg'
import { ReactComponent as DashboardIcon } from 'assets/images/icons/assets/dashboard.svg'
import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/dashboard/empty-state.svg'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'

import { FlagListObjType } from 'store/flags/types'
import { MaybeNull, Unknown } from 'utils/commonTypes'
import { DetailsObjType, NarrativeListDataType } from 'types/types'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'
import { ISnippet, ICreator, ICreatorData, sourcePlatform, SnippetFilterPayload } from 'models/models'

import useDelayedLoading from 'utils/useDelayedLoading'
import { openNotification } from 'services/Util/openNotification'
import { getDaysDifference } from 'services/Util/getDaysDifference'

import './InfluencerDashboard.scss'

type keyTypes = 'impression' | 'engagement' | 'impressions'
type cardTypes = 'Mentions' | 'Impressions' | 'Views' | 'Engagement'

const InfluencerDashboard = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    getDate,
    setActiveItem,
    isSidebarOpen,
    snippetsFilter,
    fetchFolderList,
    updateFilterChips,
    setSnippetsFilter,
    powerInsightsData,
    selectedInfluencer,
    toggleIsSidebarOpen,
    fetchInsightsMetrics,
    setSelectedInfluencer,
    getSnippetFilterPayload,
    fetchPowerInsightsCreators,
    corporateCommunicationView,
    updateCorporateCommunicationView,
    fetchPowerInsightsForDashboardPage,
  } = store.vectorsStore
  const flagStore = store.flagsStore
  const assetStore = store.assetsStore
  const loaderStore = store.loaderStore

  const [alertId, setAlertId] = useState<string>('')
  const [entityId, setEntityId] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [triggerReplaceItem, setTriggerReplaceItem] = useState(false)
  const [flagsWithMetrics, setFlagsWithMetrics] = useState<Unknown>([])
  const [followedTopicLoading, setFollowedTopicLoading] = useState(true)
  const [isCreateTopicModalOpen, setIsCreateTopicModalOpen] = useState(false)
  const [isNarrativeCreation, setIsNarrativeCreation] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [topicToEdit, setTopicToEdit] = useState<MaybeNull<NarrativeListDataType>>()
  const [selectedPowerInsightTab, setSelectedPowerInsightTab] = useState<string>('metrics')
  const [triggerUpdateItem, setTriggerUpdateItem] = useState<{ alertTurnedOff: boolean } | undefined>()

  const isPowerInsightsMode = location.pathname?.includes('/influencer-dashboard/power-insights')

  const snippetsLoading = useDelayedLoading([loaderStore.isLoadingFeed || loaderStore.isLoadingDefaultPlatforms], 1000)

  const detailObject = {
    name: selectedInfluencer?.name,
    conditions: selectedInfluencer?.conditions,
    mode: 'asset',
    id: selectedInfluencer?.id,
    activeTab: selectedPowerInsightTab,
  }

  const customGraphColors = useMemo(
    () => ({
      shadeColorUp: '#eeeff4',
      lineColorUp: '#A84BD3',
      shadeColorDown: '#eeeff4',
      lineColorDown: '#A84BD3',
      theme: 'purple',
    }),
    [],
  )

  const creatorsData = useMemo(() => {
    if ((powerInsightsData?.creatorsData?.total_count as number) >= 10)
      return powerInsightsData.creatorsData?.items.slice((currentPage - 1) * 10, currentPage * 10)
    return powerInsightsData.creatorsData?.items
  }, [currentPage, powerInsightsData?.creatorsData])

  const tagsData = useMemo(
    () =>
      flagsWithMetrics?.map((item: Unknown) => ({
        name: item.display_label,
        value: item.metric?.impressions,
        conditions: item.conditions,
      })),
    [flagsWithMetrics, selectedInfluencer],
  )

  const columns = useMemo(
    () => [
      {
        title: () => <span className='creators-list__table__title'>Creator</span>,
        dataIndex: 'channel_title',
        key: 'channel_title',
        width: '50%',
        showSorterTooltip: false,
        render: (column: Unknown, item: ISnippet) => <CreatorInfo creator={item as Unknown} />,
        onCell: (item: ICreatorData) => ({
          style: {
            textAlign: 'left',
          },
        }),
      },
      {
        title: () => <span className='creators-list__table__title'>Mentions</span>,
        dataIndex: 'snippet_count',
        key: 'snippet_count',
        width: '25%',
        render: (value: Unknown, item: ISnippet) => (
          <div className='creators-list__table__count'>{millify(value || 0)}</div>
        ),
        onCell: () => ({
          style: {
            textAlign: 'center',
          },
        }),
      },
    ],
    [],
  )

  const mentionViewCards = useMemo(
    () => [
      {
        type: 'Mentions',
        key: 'Mentions',
        measure: 'impressions',
        tooltip: 'Represents the total number of times the selected brand was mentioned across all platforms.',
      },
      {
        type: 'Impressions',
        key: 'Views',
        measure: 'impression',
        tooltip: 'Indicates how many times the selected brand was potentially seen by audiences.',
      },
      {
        type: 'Engagement',
        key: 'engagement',
        measure: 'engagement',
        tooltip:
          'Shows how actively audiences are interacting with content that mentions the selected brand through likes, comments, shares, and other engagement metrics, depending on the platform.',
      },
    ],
    [],
  )

  const handleApplyFilters = ({ creator }: { creator: ICreatorData | null }) => {
    if (creator?.channel_id) {
      updateFilterChips('creators', [creator])
      setSnippetsFilter({ ...snippetsFilter, creators: [creator?.channel_id as string] })
    }
  }

  const handleInfluencerSelection = (influencer?: NarrativeListDataType) => {
    setSelectedInfluencer(influencer as NarrativeListDataType)
    if (influencer) {
      setActiveItem({
        id: influencer.id,
        name: influencer.name,
        conditions: influencer.conditions,
        mode: 'watchlist',
        description: influencer.description || '',
      })
    }
    const { setSnippetsFilter } = store.vectorsModalStore
    setSnippetsFilter(snippetsFilter)
  }

  const getBrands = async () => {
    const categoriesResult = await assetStore.fetchCategories({ is_asset: true })
    const brandCategory = categoriesResult.find((item: AssetListObjType) => item.name === 'Brands')
    if (!brandCategory) return

    const params: AssetListParamsType = {
      page: 1,
      per_page: 100,
      sort: 'name:asc',
      is_asset: true,
      is_active: true,
      q: `category_id:eq:${brandCategory.category_id}`,
    }
    await assetStore.fetchAssetsList({ params: params })
  }

  const getFollowedTopics = async () => {
    try {
      setFollowedTopicLoading(true)
      const requestParams = {
        page: 1,
        per_page: 100,
        sort: 'name:asc',
        is_active: true,
      }
      const data = await flagStore.fetchFlagsListWithConditions({ params: requestParams })
      await fetchAllActiveFlagMetrics(data.items)
    } catch (error) {
    } finally {
      setFollowedTopicLoading(false)
    }
  }

  const fetchMetricsForFlag = async (flag: FlagListObjType, requestParams: { q: string; measure?: string }) => {
    if (!flag.conditions) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          skip: true,
        },
      }
    }
    const payload = await getSnippetFilterPayload()
    const requestData = { conditions: [flag.conditions, ...payload.conditions] }
    const views = await fetchInsightsMetrics({
      params: requestParams,
      data: requestData,
      fullResponse: true,
    })
    let results
    if (views.total_value > 0) {
      results = await Promise.allSettled([
        fetchInsightsMetrics({
          params: { ...requestParams },
          data: requestData,
          fullResponse: true,
        }),
      ])
    }
    const getResultValue = (result: PromiseSettledResult<any>) => {
      if (result.status === 'fulfilled') {
        return result.value === 'error' ? 0 : result.value.total_value
      }
      return 0
    }
    const impressionsCount = views.total_value > 0 && results ? getResultValue(results[0]) : 0
    if (impressionsCount === 0) {
      return {
        id: flag.id,
        metrics: {
          views: 0,
          impressions: 0,
          engagement: 0,
          skip: true,
        },
      }
    }
    return {
      id: flag.id,
      metrics: {
        views: 0,
        impressions: impressionsCount,
        engagement: 0,
        skip: false,
      },
    }
  }

  const fetchAllActiveFlagMetrics = async (flags: FlagListObjType[]) => {
    try {
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate
      const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
      let prevStartDate = new Date(startDate)
      let prevEndDate = new Date(startDate)
      prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
      prevEndDate.setDate(prevEndDate.getDate() - 1)
      const requestParams: { q: string; metric?: string } = {
        q: `start_date:gte:${startDate},end_date:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
      }
      const promises = flags.map((flag) => fetchMetricsForFlag(flag, requestParams))
      const results = await Promise.allSettled(promises)
      const metrics: Record<string, any> = {}
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { id, metrics: flagMetrics } = result.value
          metrics[id] = flagMetrics
        }
      })
      const onlyFlagsWithMetrics = flags
        .map((flag) => ({ ...flag, metric: metrics[flag.id] }))
        .filter((flag) => !flag.metric.skip)

      setFlagsWithMetrics(onlyFlagsWithMetrics)
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to fetch active flags' })
    } finally {
    }
  }

  useEffectOnce(() => {
    if (isPowerInsightsMode) return
    fetchFolderList({ params: {} })
    getBrands()
  })

  useEffect(() => {
    if (selectedInfluencer) {
      getFollowedTopics()
      fetchPowerInsightsCreators('PI', undefined)
      fetchPowerInsightsForDashboardPage(
        {
          name: selectedInfluencer?.name || '',
          conditions: selectedInfluencer?.conditions || {},
          mode: 'watchlist',
          id: selectedInfluencer?.id || '',
        },
        true,
      )
    }
  }, [selectedInfluencer, snippetsFilter])

  return (
    <div className='ids'>
      <div className='ids-header'>
        <div className='ids-header__left-menu'>
          {!isPowerInsightsMode && (
            <AntdButton
              className='power-insights-btn'
              icon={<SpiralIcon />}
              onClick={() => {
                navigate('power-insights/metrics')
              }}>
              Power Insights
            </AntdButton>
          )}
        </div>
        <div className='ids-header__right-menu'>
          <AntdButton
            type='outlined-light'
            onClick={(e) => {
              e.preventDefault()
              setAlertId(selectedInfluencer?.alert_id || '')
              setEntityId(selectedInfluencer?.id || '')
            }}
            icon={selectedInfluencer?.alert_id ? <FilledNotificationIcon /> : <SubscribeIcon />}>
            Alerts
          </AntdButton>
          <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'vectors'} />
          {isPowerInsightsMode && (
            <AntdButton
              type='default'
              onClick={(e) => {
                e.preventDefault()
                navigate('/influencer-dashboard')
              }}
              icon={<LeftArrowIcon />}>
              Back to dashboard
            </AntdButton>
          )}
          <Radio.Group
            value={corporateCommunicationView}
            className='radio_group_view'
            onChange={(e) => {
              updateCorporateCommunicationView(e.target.value)
              if (e.target.value === 'list') navigate('/influencers')
            }}>
            <Radio.Button value='list'>
              <div className='radio_group_icon_wrapper'>
                <ListIcon />
              </div>
            </Radio.Button>
            <Radio.Button value='dashboard'>
              <div className='radio_group_icon_wrapper'>
                <DashboardIcon />
              </div>
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <Drawer
        width={500}
        forceRender
        placement={'left'}
        open={isSidebarOpen}
        className='search-list-drawer'
        onClose={toggleIsSidebarOpen}
        closeIcon={null}>
        <InfluencerListVertical
          selectedInfluencer={selectedInfluencer as NarrativeListDataType}
          setSelectedInfluencer={(influencers) => handleInfluencerSelection(influencers as NarrativeListDataType)}
          showSidebar={isSidebarOpen}
          triggerReplaceItem={triggerReplaceItem}
          setTriggerReplaceItem={setTriggerReplaceItem}
          triggerUpdateItem={triggerUpdateItem}
          setTriggerUpdateItem={setTriggerUpdateItem}
          handleCreateNewInfluencer={setIsCreateTopicModalOpen}
          setIsNarrativeCreation={setIsNarrativeCreation}
        />
      </Drawer>
      {!isPowerInsightsMode && (
        <div className='ids-body'>
          <Row>
            <Col span={6} className='ids-body__ls'>
              <div className='ids-body__ls__card'>
                <div className='bd__body__influential-voices__header'>
                  <div className='bd__body__influential-voices__header__title'>Share of voice</div>
                  <div className='bd__body__influential-voices__header__info-icon'>
                    <Tooltip
                      title={
                        'Represents the percentage of total mentions each brand has compared to others that are currently active. This chart helps you to understand brand visibility and dominance in conversations.'
                      }>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </div>
                <NewPortfolioShareChart
                  subStore='vectors'
                  showMetricSelection
                  isUsedInModal={false}
                  isPowerInsightsMode={true}
                  additionalConditions={selectedInfluencer?.conditions}
                />
              </div>
              <div
                className='ids-body__ls__card'
                style={{
                  width: '100%',
                }}>
                <div className='bd__body__influential-voices__header'>
                  <div className='bd__body__influential-voices__header__title'>Tags</div>
                  <div className='bd__body__influential-voices__header__info-icon'>
                    <Tooltip
                      title={
                        'These topics represent the most viewed content, drawn from the top 1,000 posts with the highest engagement and views.'
                      }>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </div>
                <FollowedTopicChart loading={followedTopicLoading} data={tagsData} subStore='vectors' />
              </div>
              <div className='ids-body__ls__card'>
                <div className='bd__body__influential-voices__header'>
                  <div className='bd__body__influential-voices__header__title'>Sentiment</div>
                  <div className='bd__body__influential-voices__header__info-icon'>
                    <Tooltip
                      title={
                        'Represents the distribution of brand mentions categorized as Positive, Negative, or Neutral. This widget helps you gauge the overall tone of conversations about your brand.'
                      }>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </div>
                <NewBrandSentiment
                  isFlexLegend
                  subStore='vectors'
                  showMetricSelection
                  isUsedInModal={false}
                  selectedBrand={selectedInfluencer as NarrativeListDataType}
                />
              </div>
            </Col>
            <Col span={12} className='ids-body__cs'>
              <SnippetListWithFilters
                detailObject={detailObject as DetailsObjType}
                activePowerInsightTab={selectedPowerInsightTab}
                subStore='vectors'
                isCustomEmptyState
                emptyStateIcon={<EmptyFile />}
                parentAPICallsLoading={snippetsLoading}
                emptyStateTitle={'Your feed is empty'}
                uniqueScrollId='influencers-centered-component'
                emptyStateDescription={'Start by selecting the content you want to follow in this feed.'}
                emptyStateButton={
                  <AntdButton type='text' className='empty-feed-btn' onClick={() => null}>
                    Add Content
                  </AntdButton>
                }
              />
              {snippetsLoading && (
                <div className='pulse_snippets_loader--mask'>
                  <Spin spinning={true} className='pulse_snippets_loader' />
                </div>
              )}
            </Col>
            <Col span={6} className='ids-body__rs'>
              <div className='ids-body__rs--metrics-cards'>
                {mentionViewCards.map((card) => (
                  <div className='bd__body__brand-overview__card ids-body__rs__card' key={card.key}>
                    <PulseMetricsCard
                      parentLoading={loaderStore.isLoadingDefaultPlatforms}
                      selectedItem={selectedInfluencer as NarrativeListDataType}
                      type={card.type as cardTypes}
                      key={`pulse-card${card.key}`}
                      measure={card.measure as keyTypes}
                      subStore={'vectors'}
                      tooltipText={card.tooltip}
                      customGraphColors={customGraphColors}
                    />
                  </div>
                ))}
              </div>
              <div className='bd__body__influential-voices--top-voices'>
                <div className='bd__body__influential-voices__header'>
                  <div className='bd__body__influential-voices__header__title'>Creators</div>
                  <div className='bd__body__influential-voices__header__info-icon'>
                    <Tooltip
                      title={
                        'Represents the top voices dominating the conversation about your brand, ranked by number of followers.'
                      }>
                      <InfoIcon />
                    </Tooltip>
                  </div>
                </div>
                {loaderStore.isLoadingPICreators ? (
                  <CreatorLoader />
                ) : powerInsightsData.creatorsData?.total_count ? (
                  <div className='bd__body__influential-voices__table--wrapper'>
                    <Table
                      virtual
                      // @ts-ignore
                      columns={columns}
                      scroll={{ y: 504 }}
                      loading={false}
                      onRow={(record: Unknown) => {
                        return {
                          onClick: () => handleApplyFilters({ creator: record }),
                        }
                      }}
                      pagination={false}
                      dataSource={creatorsData as Unknown}
                      className='bd__body__influential-voices__table'
                    />

                    <Pagination
                      totalCount={powerInsightsData.creatorsData.total_count}
                      currentPage={currentPage}
                      pageSize={10}
                      onPageChange={(page) => {
                        setCurrentPage(page)
                      }}
                    />
                  </div>
                ) : (
                  <BrandDataFetching emptyStateIcon={<EmptyFile />} emptyStateTitle={'No Data to display'} />
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}

      {isPowerInsightsMode && (
        <Row
          gutter={24}
          justify={'start'}
          align={'top'}
          className={classNames('dashboard-widgets', { powerinsight_dashboard: isPowerInsightsMode })}>
          <Col style={{ width: '226', minWidth: '221px' }}>
            <PowerInsightTabsListVertical
              isDashboard
              setSelectedPowerInsightTab={setSelectedPowerInsightTab}
              selectedPowerInsightTab={selectedPowerInsightTab}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row gutter={[24, 24]} className='power-insights-and-snippets'>
              <Col span={14} className='power-insights-container'>
                <Row>
                  <Col span={24} className='power-insights-components'>
                    <Outlet
                      context={{
                        selectedBrand: selectedInfluencer,
                        subStore: 'vectors',
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={10} className='snippet-section-column'>
                <Spin spinning={loaderStore.isLoadingFeed} wrapperClassName='snippet-section-loader'>
                  <Row>
                    <Col span={24} className='dashboard-snippet-section'>
                      <SnippetListWithFilters
                        detailObject={detailObject as DetailsObjType}
                        activePowerInsightTab={selectedPowerInsightTab}
                        subStore='vectors'
                        isCustomEmptyState
                        emptyStateDescription={''}
                        emptyStateIcon={<EmptyStateIcon />}
                        uniqueScrollId='influencers-dashboard__id'
                        emptyStateTitle={'No snippets found'}
                      />
                    </Col>
                  </Row>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isCreateTopicModalOpen && (
        <AddBrandWizard
          //@ts-ignore
          setSelectedBrand={setSelectedInfluencer}
          editedBrandData={null}
          isTopicCreation
          handleCloseModal={() => {
            setIsCreateTopicModalOpen(false)
          }}
          isNarrative={isNarrativeCreation}
        />
      )}

      {topicToEdit && (
        <AddBrandWizard
          setSelectedBrand={() => {}}
          editedBrandData={topicToEdit as Unknown}
          isNarrative={isNarrativeCreation}
          isTopicCreation
          handleCloseModal={(updatedData: any) => {
            setTopicToEdit(null)
            setIsNarrativeCreation(false)
          }}
        />
      )}

      {openAlertSuccessModal && (
        <SuccessModal
          title='Alert Created Successfully'
          desc='You will start receive new alerts according to the selected preferences'
          onClose={() => setOpenAlertSuccessModal(false)}
          closeButtonText='Continue'
        />
      )}

      <AlertModal
        entity_id={entityId}
        entity_type={'WATCHLIST'}
        open={!!entityId || !!alertId}
        alertId={alertId}
        onClose={() => {
          setEntityId('')
          setAlertId('')
        }}
        onSuccess={() => {
          setOpenAlertSuccessModal(true)
        }}
        onDeleteSuccess={() => null}
      />
    </div>
  )
}

export default observer(InfluencerDashboard)

const CreatorLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      <Skeleton.Input active={true} size='large' style={{ width: '100%', height: 40 }} />
      {Array.from({ length: 9 }, (value) => (
        <div key={`${value}loader-`} style={{ display: 'flex', gap: '0px', width: '100%' }}>
          <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 20 }} />
          <Skeleton.Input active={true} size='large' style={{ width: '100%', height: 40 }} />
        </div>
      ))}
    </div>
  )
}

interface CreatorInfoProps {
  creator: ICreator
}

const CreatorInfo = memo(({ creator }: CreatorInfoProps) => {
  return (
    <div className='bd__body__influential-voices-creator'>
      <div className='bd__body__influential-voices-creator__container'>
        <div className='bd__body__influential-voices-creator__avatar'>
          <img
            src={(creator?.channel_thumb_url || AvatarIcon) as string}
            alt='user'
            onError={(e: any) => {
              e.target.src = AvatarIcon
            }}
          />
        </div>

        <div className='bd__body__influential-voices-creator__container2'>
          <span
            className='bd__body__influential-voices-creator__name'
            title={creator?.channel_name || creator?.channel_title}>
            {(creator?.channel_name === 'N/A'
              ? creator?.channel_title
              : creator?.channel_name
              ? creator?.channel_name
              : creator?.channel_title) || ''}
          </span>
          <div className='bd__body__influential-voices-creator__container3'>
            <img
              className='bd__body__influential-voices-creator__platform'
              src={platformIcons[creator?.platform as Lowercase<sourcePlatform>]}
              alt='platform'></img>
          </div>
        </div>
      </div>
    </div>
  )
})
