import { API } from 'api/api'
import classNames from 'classnames'
import { Avatar, Skeleton } from 'antd'
import { memo, useEffect, useState } from 'react'

import Carousel from 'components/common/Carousel/Carousel'
import AntdButton from 'components/common/AntdButton/AntdButton'

import AvatarIcon from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'

import { ISnippet } from 'models/models'
import { PLATFORM_ICONS_GREY } from 'settings/settings'
import { CreatorInfoTypes } from 'pages/Vectors/CreateEditWatchListStepper/types'
import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'

export type SectionTypes = {
  summary: string
  subheading: string
  snippets: ISnippet[]
}

export type ExecutiveSummaryTypes = {
  title: string
  sections: SectionTypes[]
  error: boolean
}

const ExecutiveSummary = ({
  summaryLoading,
  executiveSummary,
  retryFetchingSummary,
  handleOpenSnippetMedia,
}: {
  summaryLoading: boolean
  retryFetchingSummary: () => void
  executiveSummary: ExecutiveSummaryTypes
  handleOpenSnippetMedia: (snippet: ISnippet) => void
}) => {
  return (
    <div className='bd__body__executive-summary'>
      <div className='bd__body__header'>
        <div className='bd__body__header__title'>Executive Summary 📝</div>
        <div className='bd__body__header__description'>
          Track your brand’s mentions, impressions, and engagements at a glance to measure impact and visibility.
        </div>
      </div>
      <Carousel
        items={executiveSummary?.sections || []}
        emptyItem={() => (
          <div className='bd__body__executive-summary--empty-container'>
            {summaryLoading ? (
              <LoadingState />
            ) : (
              <>
                {executiveSummary?.error ? (
                  <div className='bd__body__executive-summary__error'>
                    <span className='bd__body__executive-summary__error-text'>
                      Failed to load the summary. Please try again now or later.
                    </span>
                    <AntdButton onClick={retryFetchingSummary}>Retry</AntdButton>
                  </div>
                ) : (
                  <div className='bd__body__executive-summary__error'>
                    <BrandDataFetching emptyStateIcon={<EmptyFile />} emptyStateTitle={'No Data to display'} />
                  </div>
                )}
              </>
            )}
          </div>
        )}
        renderItem={(item: SectionTypes) => (
          <div className={classNames('bd__body__executive-summary--container', { loading: summaryLoading })}>
            {summaryLoading ? (
              <LoadingState />
            ) : (
              <div className='bd__body__executive-summary--content'>
                <div className='bd__body__executive-summary__heading'>{executiveSummary?.title || ''}</div>
                <div className='bd__body__executive-summary__sub-heading'>{item?.subheading || ''}</div>
                <div className='bd__body__executive-summary__description'>{item?.summary || ''}</div>
                <div className='bd__body__executive-summary__profile-list'>
                  {item.snippets?.map((snippet, index) => (
                    <SummaryCreatorInfo
                      key={`${snippet.channelId}-${index}`}
                      channel_id={snippet.channelId}
                      handleSummaryCreatorClick={(creator) =>
                        snippet && handleOpenSnippetMedia({ ...snippet, author: creator })
                      }
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        showArrows={true}
      />
    </div>
  )
}

export default memo(ExecutiveSummary)

const SummaryCreatorInfo = ({
  channel_id,
  handleSummaryCreatorClick,
}: {
  channel_id: string
  handleSummaryCreatorClick: (creator: CreatorInfoTypes) => void
}) => {
  const [creatorInfo, setCreatorInfo] = useState<CreatorInfoTypes | undefined>()

  const fetchCreator = async () => {
    try {
      const { data } = await API.get({
        route: 'channel',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: channel_id,
      })

      if (data?.items?.length > 0) {
        setCreatorInfo(data.items[0])
      }
    } catch (error) {
      console.error('Error fetching creator info:', error)
    } finally {
    }
  }

  const platformDetails = PLATFORM_ICONS_GREY?.find(
    (item) => item?.title?.toLowerCase() === creatorInfo?.platform?.toLowerCase(),
  )

  useEffect(() => {
    if (!channel_id) return
    fetchCreator()
  }, [channel_id])

  return (
    <div
      className='bd__body__executive-summary__profile-content'
      onClick={() => creatorInfo && handleSummaryCreatorClick(creatorInfo)}>
      <div className='bd__body__executive-summary__profile-content__profile'>
        <Avatar size={'small'} src={creatorInfo?.channel_thumb_url || AvatarIcon} />
        <div className='bd__body__executive-summary__profile-content__profile__channel-icon'>
          {creatorInfo?.platform && platformDetails?.icon}
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5' fill='none'>
        <circle cx='2' cy='2.59961' r='2' fill='#F2F4F7' />
      </svg>
    </div>
  )
}

const LoadingState = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      <Skeleton.Input active={true} size={'large'} style={{ width: '60%', height: 20 }} block />
      <Skeleton.Input active={true} size={'large'} style={{ width: '80%', height: 20 }} block />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', alignItems: 'flex-start', width: '100%' }}>
        <Skeleton.Input active={true} size={'large'} block style={{ width: '20%', height: 20 }} />
        <Skeleton.Input active={true} size={'large'} block style={{ height: 20 }} />
        <Skeleton.Input active={true} size={'large'} block style={{ height: 20 }} />
        <Skeleton.Input active={true} size={'large'} block style={{ width: '80%', height: 20 }} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
        }}>
        <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 50 }} />
        <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 50 }} />
        <Skeleton avatar paragraph={{ rows: 0 }} active style={{ width: 50 }} />
      </div>
    </div>
  )
}
