import React, { useState, useMemo } from 'react'
import { ReactComponent as NegativeSentiment } from 'assets/images/sentiment-negative-icon.svg'
import { ReactComponent as NormalSentiment } from 'assets/images/sentiment-normal-icon.svg'
import { ReactComponent as PositiveSentiment } from 'assets/images/sentiment-positive-icon.svg'
import { ReactComponent as LikeIcon } from 'assets/images/like-thumb-icon.svg'
import { ReactComponent as DislikeIcon } from 'assets/images/dislike-thumb-icon.svg'
import { ReactComponent as SolidLikeIcon } from 'assets/images/like-thumb-solid-icon.svg'
import { ReactComponent as SolidDislikeIcon } from 'assets/images/dislike-thumb-solid-icon.svg'
import { ReactComponent as BookmarkIcon } from 'assets/images/icons/bookmark.svg'
import { ReactComponent as OpenIcon } from 'assets/images/open-icon.svg'
import { ReactComponent as BookmarkOutlinedIcon } from 'assets/images/icons/bookmark-outlined.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/youtube-new-icon.svg'
import { ReactComponent as BitchuteIcon } from 'assets/images/bitchute-icon.svg'
import { ReactComponent as RumbleIcon } from 'assets/images/rumble-icon.svg'
import { ReactComponent as PodcastIcon } from 'assets/images/podcast-icon.svg'
import { ReactComponent as TikTokIcon } from 'assets/images/tiktok-icon.svg'
import { ReactComponent as NewsIcon } from 'assets/images/news-icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as RedditIcon } from 'assets/images/reddit-icon.svg'
import { ReactComponent as TruthSocialIcon } from 'assets/images/truth-social-grey-icon.svg'
import { ReactComponent as ChanIcon } from 'assets/images/4chan-icon.svg'
import { ReactComponent as GabIcon } from 'assets/images/gab-icon.svg'
import { ReactComponent as PatriotsIcon } from 'assets/images/patriots-grey-icon.svg'
import { ReactComponent as VkIcon } from 'assets/images/vk-icon-current-color.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/instagram-logo.svg'
import { ReactComponent as ThreadsIcon } from 'assets/images/threads-logo.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/telegram-logo.svg'
import { ReactComponent as CSpanIcon } from 'assets/images/cspan-black-icon.svg'
import { ReactComponent as WebSearchIcon } from 'assets/images/web-search-black-icon.svg'
import { ReactComponent as ConsiliumIcon } from 'assets/images/consilium-black-icon.svg'
import { ReactComponent as SnapchatIcon } from 'assets/images/snapchat-grey-icon.svg'
import { ReactComponent as OdyseeIcon } from 'assets/images/odysee-grey-icon.svg'

import UserAvatar from 'assets/images/LogoiconMobile.svg'
import './DetailPanelFeeds.scss'
import millify from 'millify'
import dayjs from 'dayjs'
import { AddSnippetToBookmarksListModal } from '../../Bookmarks/AddSnippetToBookmarksListModal/AddSnippetToBookmarksListModal'
import { RemoveSnippetFromBookmarkListsModal } from '../../Bookmarks/RemoveSnippetFromBookmarkListsModal/RemoveSnippetFromBookmarkLists'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { UtilService } from 'services/Util/Util'
import { PreviewModal } from 'components/Monitor/SnippetList/MonitorSnippetItem/PreviewModal/PreviewModal'
import WarningModal from 'components/Monitor/SnippetList/MonitorSnippetItem/WarningModal/WarningModal'
import { ISnippet } from 'models/models'
import { ReportFeedsFeedback } from 'store/report/valueTypes'

const DATE_FORMATE = 'MMM DD, YYYY'

const sentimentIcons = {
  Positive: <PositiveSentiment height={15} width={15} className='dpfc_f_ls_sc_icon' />,
  Neutral: <NormalSentiment height={15} width={15} className='dpfc_f_ls_sc_icon' />,
  Negative: <NegativeSentiment height={15} width={15} className='dpfc_f_ls_sc_icon' />,
}

const iconsObj = {
  youtube: <YoutubeIcon />,
  bitchute: <BitchuteIcon />,
  rumble: <RumbleIcon />,
  podcast: <PodcastIcon />,
  tiktok: <TikTokIcon />,
  news: <NewsIcon />,
  twitter: <TwitterIcon />,
  reddit: <RedditIcon />,
  '4chan': <ChanIcon />,
  gab: <GabIcon />,
  truthsocial: <TruthSocialIcon />,
  'patriots.win': <PatriotsIcon />,
  vk: <VkIcon />,
  instagram: <InstagramIcon />,
  threads: <ThreadsIcon />,
  telegram: <TelegramIcon />,
  'c-span': <CSpanIcon />,
  websearch: <WebSearchIcon />,
  consilium: <ConsiliumIcon />,
  snapchat: <SnapchatIcon />,
  odysee: <OdyseeIcon />,
}

const THUMBS_UP = 'THUMBS_UP'
const THUMBS_DOWN = 'THUMBS_DOWN'

const DetailPanelFeeds = observer((props: any) => {
  const {
    channel_id,
    snippet_text,
    platform,
    author,
    impression_count,
    upload_date,
    sentiment,
    document_id,
    offset,
    post_id,
    data,
  } = props
  const [showFullText, setShowFullText] = useState<boolean>(false)
  const [isBookmarkModalVisible, setIsBookmarkModalVisible] = useState<boolean>(false)
  const [isRemoveBookmarkModalVisible, setIsRemoveBookmarkModalVisible] = useState<boolean>(false)
  const [openedMedia, setOpenedMedia] = useState<ISnippet | null>(null)
  const [openedWarning, setOpenedWarning] = useState<string>('')
  const [thumbsUpFeedback, setThumbsUpFeedback] = useState<boolean>(false)
  const [thumbsDownFeedback, setThumbsDownFeedback] = useState<boolean>(false)
  const { loaderStore, mainStore, reportMainViewStore } = store
  const { isLoadingBookmarks } = loaderStore
  const { reportFeedsFeedbackHandler, feedsFilters } = reportMainViewStore
  const { userBookmarkedSnippets, fetchUserBookmarkedSnippets } = mainStore
  const isSnippetTooLong = snippet_text.length > 500

  const renderFeedDesc = useMemo(() => {
    if (isSnippetTooLong) {
      if (showFullText) {
        return `${snippet_text}`
      }
      return `${snippet_text.substring(0, 500)}...`
    }
    return snippet_text
  }, [showFullText, isSnippetTooLong, snippet_text])

  const onPreviewClick = () => {
    setOpenedMedia(data)
  }

  const feedbackHandler = (feedback: 'THUMBS_UP' | 'THUMBS_DOWN') => {
    const requestBody: ReportFeedsFeedback = {
      channel_id,
      feedback_value: feedback,
      offset,
      platform,
      post_id,
      snippet_filters: {
        //   filter_platform?: string
        // filter_sentiment?: string
        sort: 'Upload Date',
        sort_mode: 'Descending',
      },
    }

    if ('platforms' in feedsFilters && feedsFilters.platforms) {
      requestBody.snippet_filters.filter_platform = feedsFilters.platforms
    }
    if ('sentiments' in feedsFilters && feedsFilters.sentiments) {
      requestBody.snippet_filters.filter_sentiment = feedsFilters.sentiments
    }

    reportFeedsFeedbackHandler(requestBody).then((res) => {
      if (res !== 'error') {
        feedback === THUMBS_UP ? setThumbsUpFeedback(true) : setThumbsDownFeedback(true)
      }
    })
  }

  return (
    <>
      <div className='detail_panel_feeds_container'>
        <p className='dpfc_desc'>
          <span
            dangerouslySetInnerHTML={{
              __html: renderFeedDesc,
            }}
          />
          {isSnippetTooLong ? (
            <span className='dpfc_desc_more_text' onClick={() => setShowFullText((prevState) => !prevState)}>
              {isSnippetTooLong && showFullText ? 'less' : 'more'}
            </span>
          ) : null}
        </p>
        <div className='dpfc_footer'>
          <div className='dpfc_f_left_section'>
            {iconsObj[platform.toLowerCase() as keyof typeof iconsObj]}

            <div className='dpfc_f_ls_divider'></div>
            {author && Object.keys(author).length ? (
              <>
                <div className='dpfc_author_details'>
                  <div className='dpfc_ad_content'>
                    <div className='dpfc_ad_c_image_container'>
                      <img
                        className='dpfc_ad_c_image'
                        src={UtilService.isImageUrl(author.channel_thumb_url) ? author.channel_thumb_url : UserAvatar}
                        alt='author'
                      />
                    </div>
                    {author?.channel_title ? <p className='dpfc_ad_c_author_name'>{author?.channel_title}</p> : null}
                  </div>
                </div>

                <div className='dpfc_f_ls_divider'></div>
              </>
            ) : null}
            <p className='dpfc_f_ls_views'>
              {impression_count && millify(impression_count)} {impression_count > 1 ? 'views' : 'view'}
            </p>
            <div className='dpfc_f_ls_divider'></div>
            <p className='dpfc_f_ls_date'>{dayjs(upload_date).format(DATE_FORMATE)}</p>
            <div className='dpfc_f_ls_divider'></div>

            {sentiment && (
              <>
                <div className='dpfc_f_ls_divider'></div>
                <div className='dpfc_f_ls_sentiments_container'>
                  {sentimentIcons[sentiment as keyof typeof sentimentIcons]}
                </div>
              </>
            )}
          </div>
          <div className='dfpc_f_right_section'>
            <div className='dfpc_f_rs_icon_container'>
              {userBookmarkedSnippets.includes(document_id) ? (
                <BookmarkIcon
                  height={18}
                  width={17}
                  className='dpfc_f_ic_icon'
                  onClick={() => setIsRemoveBookmarkModalVisible(true)}
                />
              ) : (
                <BookmarkOutlinedIcon
                  className='dpfc_f_ic_icon'
                  onClick={() => {
                    setIsBookmarkModalVisible(true)
                  }}
                />
              )}
            </div>
            <div className='dfpc_f_rs_icon_container' onClick={onPreviewClick}>
              <OpenIcon height={16} width={16} className='dpfc_f_ic_icon' />
            </div>
            <div className='dfpc_f_rs_like_dislike_icon_container'>
              <div className='dpfc_ld_icon_container'>
                {thumbsUpFeedback ? (
                  <SolidLikeIcon className='dpfc_ld_ic_icon' />
                ) : (
                  <LikeIcon
                    className='dpfc_ld_ic_icon'
                    onClick={(e) => {
                      e.stopPropagation()
                      feedbackHandler(THUMBS_UP)
                    }}
                  />
                )}
              </div>
              <div className='dpfc_ld_icon_container'>
                {thumbsDownFeedback ? (
                  <SolidDislikeIcon className='dpfc_ld_ic_icon' />
                ) : (
                  <DislikeIcon
                    className='dpfc_ld_ic_icon'
                    onClick={(e) => {
                      e.stopPropagation()
                      feedbackHandler(THUMBS_DOWN)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isBookmarkModalVisible && (
        <AddSnippetToBookmarksListModal
          snippets={[{ ...data, documentId: data.document_id }]}
          isModalVisible
          closeModal={() => {
            fetchUserBookmarkedSnippets()
            setIsBookmarkModalVisible(false)
          }}
        />
      )}

      {isRemoveBookmarkModalVisible && (
        <RemoveSnippetFromBookmarkListsModal
          snippet={{ ...data, documentId: data.document_id }}
          isModalVisible
          closeModal={(shouldRefresh: boolean = false) => {
            if (shouldRefresh) {
              fetchUserBookmarkedSnippets()
            }
            setIsRemoveBookmarkModalVisible(false)
          }}
          isLoading={isLoadingBookmarks}
        />
      )}

      {openedMedia && (
        <PreviewModal
          openedMedia={openedMedia}
          onOpenSource={(val: string) => setOpenedWarning(val)}
          onModalClose={() => {
            setOpenedMedia(null)
          }}
        />
      )}
      {openedWarning && (
        <WarningModal
          link={openedWarning}
          onContinue={() => {
            setOpenedWarning('')
            window.open(openedWarning || '#', '_blank', 'noopener,noreferrer')
          }}
          onClose={() => {
            setOpenedWarning('')
          }}
        />
      )}
    </>
  )
})

export default DetailPanelFeeds
