import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Avatar, Checkbox, Modal, Spin } from 'antd'
import profileImage from 'assets/images/LogoiconMobile.svg'

import { API, watchlistAPIs } from 'api/api'
import { useEffectOnce } from 'react-use'
import { CreatorInfo } from 'components/Monitor/PowerInsights/Creators/CreatorDetailsPage/CreatorDetailsPage'
import { Unknown } from 'utils/commonTypes'
import { UtilService } from 'services/Util/Util'
import { store } from 'store'
import './AddToWatchlist.scss'

type AlertModalProps = {
  open: boolean
  onClose: () => void
  creatorsInfo: CreatorInfo[]
}

const AddToWatchlist: React.FC<AlertModalProps> = ({ open, onClose, creatorsInfo = [] }) => {
  const [isAddingToWatchlist, setIsAddingToWatchlist] = useState(false)
  const [watchlists, setWatchlists] = useState([])
  const [loadingWatchlists, setLoadingWatchlists] = useState(false)
  const [watchlistToInclude, setWatchlistToInclude] = useState<String[]>([])
  const { showToaster } = store.toasterStore
  const { isCorporateCommunicationsTheme } = store.tenantsStore

  const fetchWatchlistData = async () => {
    setLoadingWatchlists(true)
    try {
      const response = await API.get({
        route: 'watchlist',
        page: 1,
        pageSize: 100,
        getError: true,
        sort: 'name',
      })

      setWatchlists(
        response.data.items
          .map((data: any) => {
            return {
              ...data,
              name: data?.name || '',
              description: data?.description || '',
              addedURLs: data?.channels?.flatMap((item: Unknown) =>
                item?.channel_by_platform?.map((channel: Unknown) => ({ ...channel, platform: item.platform })),
              ),
            }
          })
          .filter((data: any) => data?.permission_level === 'EDITOR'),
      )
    } catch (error) {
    } finally {
      setLoadingWatchlists(false)
    }
  }

  useEffectOnce(() => {
    fetchWatchlistData()
  })

  const selectWatchlist = (id: String) => {
    setWatchlistToInclude((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const onConfirm = async () => {
    if (isAddingToWatchlist || loadingWatchlists) return
    if (watchlistToInclude.length === 0) {
      onClose()
      return
    }
    setIsAddingToWatchlist(true)
    try {
      const promises = watchlists
        .filter((watchlist: any) => watchlistToInclude.includes(watchlist.id))
        .map((watchlist: any) => {
          return watchlistAPIs.EditWatchlist(
            {
              name: watchlist.name,
              description: watchlist.description,
              channels: [
                ...watchlist.addedURLs?.map((item: any) => ({
                  platform: item.platform,
                  channel_urls: [item.channel_url],
                })),
                ...creatorsInfo.map((creator) => ({
                  platform: creator?.platform,
                  channel_urls: [creator?.channel_url],
                })),
              ],
            },
            watchlist.id,
          )
        })
      await Promise.all(promises)
      showToaster({
        iconType: 'success',
        message: `${creatorsInfo.length > 1 ? 'Creators' : 'Creator'} added successfully`,
      })
      onClose()
    } catch (error: any) {
      const response = error?.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
    } finally {
      setIsAddingToWatchlist(false)
    }
  }

  return (
    <Modal
      title={`Select ${isCorporateCommunicationsTheme ? 'Influencer List' : 'Watchlist'}`}
      open={open}
      className='alert-modal'
      onCancel={onClose}
      destroyOnClose
      footer={
        <div className='feed-export__footer'>
          <button className='feed-export__footer__cancel' onClick={onClose}>
            Cancel
          </button>
          <button className='feed-export__footer__send' onClick={onConfirm}>
            Confirm
          </button>
        </div>
      }>
      <Spin spinning={loadingWatchlists || isAddingToWatchlist}>
        <div className='alert-modal-body'>
          <div className='alert-modal-body-header'>
            <div className='alert-modal-body-header-info'>
              <div className='alert-modal-toggle-button-title'>
                Choose one or more {`${isCorporateCommunicationsTheme ? 'Influencer list' : 'Watchlist'}`} where you'd
                like to add selected creators. Only valid links will be added.
              </div>
            </div>
          </div>
          {watchlists.length > 0 && (
            <div className='watchlist-wrapper'>
              {watchlists.map((watchlist: { id: String; name: String }) => {
                return (
                  <div className='watchlist-item'>
                    <Checkbox onClick={() => selectWatchlist?.(watchlist.id)} />
                    <Avatar src={profileImage} size={'large'} style={{ minWidth: 'max-content' }} />
                    <div className='watchlist-item__name'>{watchlist.name || ''}</div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  )
}

export default observer(AddToWatchlist)
