const HiveHoverState = () => {
  return (
    <div className='hive-hover-card'>
      <div className='hive-hover-card--body'>
        <div className='hive-hover-card__title'>Influencer hive</div>
        <div className='hive-hover-card__description'>Visualize individual content impact to the overall trend.</div>
        <div className='hive-hover-card__icon'>
          <svg width='266' height='125' viewBox='0 0 266 125' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M120.938 83.5176C120.938 93.6567 112.719 101.876 102.58 101.876C92.4405 101.876 84.2212 93.6567 84.2212 83.5176C84.2212 73.3785 92.4405 65.1592 102.58 65.1592C112.719 65.1592 120.938 73.3785 120.938 83.5176Z'
              fill='#F5DFCC'
              stroke='white'
              stroke-width='2'
            />
            <circle cx='177.248' cy='105.088' r='18.9115' fill='#E7F3E0' stroke='white' stroke-width='2' />
            <circle cx='110.323' cy='40.3762' r='26.1018' fill='#F1E1E9' stroke='white' stroke-width='2' />
            <circle cx='27.3584' cy='40.3762' r='26.1018' fill='#E7F3E0' stroke='white' stroke-width='2' />
            <circle cx='30.677' cy='97.8986' r='26.1018' fill='#F1E1E9' stroke='white' stroke-width='2' />
            <circle cx='172.27' cy='27.1018' r='26.1018' fill='#F5DFCC' stroke='white' stroke-width='2' />
            <circle cx='183.885' cy='67.4781' r='22.2301' fill='#E7F3E0' stroke='white' stroke-width='2' />
            <circle cx='147.381' cy='57.5219' r='14.4867' fill='#F2DF6B' stroke='white' stroke-width='2' />
            <circle cx='140.19' cy='90.155' r='19.4646' fill='#E7F3E0' stroke='white' stroke-width='2' />
            <circle cx='71.053' cy='23.2296' r='15.5929' fill='#E2EBFA' stroke='white' stroke-width='2' />
            <circle cx='67.1814' cy='64.7126' r='19.4646' fill='#E2EBFA' stroke='white' stroke-width='2' />
            <circle cx='71.6061' cy='101.217' r='15.0398' fill='#F5DFCC' stroke='white' stroke-width='2' />
            <circle cx='215.411' cy='97.8986' r='26.1018' fill='#F1E1E9' stroke='white' stroke-width='2' />
            <circle cx='232.557' cy='39.8226' r='32.1858' fill='#F1E1E9' stroke='white' stroke-width='2' />
          </svg>
        </div>
      </div>
      <div className='hive-hover-card--footer'>
        <div className='hive-hover-card-footer__text'>Timeline</div>
        <div className='hive-hover-card-footer__tags'>
          <div className='hive-hover-card-footer__tags__tag'>Platform 1</div>
          <div className='hive-hover-card-footer__tags__tag'>Platform 2</div>
          <div className='hive-hover-card-footer__tags__tag'>Platform 3</div>
        </div>
      </div>
    </div>
  )
}

export default HiveHoverState
