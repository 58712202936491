import { AssetListObjType } from 'store/asset/types'
import { store } from 'store'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType, NarrativeListDataType, SubStore } from 'types/types'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Skeleton, Tooltip } from 'antd'
import millify from 'millify'
import { SnippetFilterPayload } from 'models/models'
import './PulseMetricsCard.scss'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { isObjectEmpty } from 'utils/helper'

type PropTypes = {
  measure?: 'impression' | 'engagement' | 'impressions' | null
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  type: 'Mentions' | 'Views' | 'Engagement' | 'Impressions'
  customGraphColors?: {
    shadeColorUp: string
    lineColorUp: string
    shadeColorDown: string
    lineColorDown: string
    theme?: string
  }
  chartWrapperClassName?: string
  subStore?: SubStore
  tooltipText?: string
  parentLoading: boolean
}

const PulseMetricsCard = observer((props: PropTypes) => {
  const { selectedItem, measure = null, type, subStore = 'assets', tooltipText = null, parentLoading } = props

  const { fetchInsightsMetrics, getDate, snippetsFilter, getSnippetFilterPayload } = store[`${subStore}Store`]
  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

  const insightsMetricsHandler = async () => {
    try {
      setIsLoadingData(true)
      const payload: SnippetFilterPayload = await getSnippetFilterPayload()
      const startDate = getDate.startDate
      const endDate = getDate.endDate

      const dateKey = payload.query.date?.includes('post_uploaded')

      const requestParams: { q: string; measure?: string } = {
        q: `${dateKey ? 'post_uploaded' : 'start_date'}:gte:${startDate},${
          dateKey ? 'post_uploaded' : 'end_date'
        }:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}${
          payload.query.risk
        }${payload.query.source}`,
      }
      if (measure === 'impression') {
        requestParams.measure = 'impression'
      }
      if (measure === 'engagement') {
        requestParams.measure = 'engagement'
      }

      const requestData: ConditionsDataType = {
        conditions: [selectedItem?.conditions, ...payload.conditions]?.filter(
          (item) => !isObjectEmpty(item),
        ) as ConditionsPayloadType[],
      }

      const [currentFlag] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
      ])
      setPostsCount({
        currentFlagCount: currentFlag === 'error' ? 0 : currentFlag.total_value,
        prevFlagCount: 0,
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoadingData(false)
    }
  }

  useEffect(() => {
    if (parentLoading) return
    insightsMetricsHandler()
  }, [snippetsFilter, selectedItem, parentLoading])

  if (isLoadingData || parentLoading) {
    return (
      <>
        <span className='monitor-assets__graph__title'>{type}</span>
        <div className='mention-view-card'>
          <div className='mention-view-card__empty-state'>
            <p className='skeleton-paragraph__large'>
              <Skeleton paragraph active />
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='mention-view-card__title'>
        <span className='monitor-assets__graph__title'>{type}</span>
        {tooltipText && (
          <Tooltip title={tooltipText}>
            <InfoIcon />
          </Tooltip>
        )}
      </div>

      <div className='mention-view-card'>
        <div className='mention-view-card__status'>
          <div className='mention-view-card__status__counts'>{millify(postsCount?.currentFlagCount || 0)}</div>
        </div>
      </div>
    </>
  )
})

export default PulseMetricsCard
