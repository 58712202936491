import { observer } from 'mobx-react-lite'
import { Form, Input, Progress, Spin, Tooltip } from 'antd'
import { store } from 'store'
import millify from 'millify'
import classNames from 'classnames'
import { useUnmount } from 'react-use'

import { ReactComponent as InfoIcon } from 'assets/images/icons/monitor/info_icon_24.svg'
import { ReactComponent as ExecutiveSummaryIcon } from 'assets/images/icons/assets/executive-summary.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/short_down_arrow.svg'

import { ConditionsDataType, ConditionsPayloadParamsType, DetailsObjType, SubStore } from 'types/types'
import { SnippetFilterPayload } from 'models/models'
import { useCallback, useEffect, useState } from 'react'
import { anticipatoryIntelligence } from 'api/api'
import { evaluateNarrative } from 'api/defineNarrative/evaluateNarrative.api'
import AddBrandTextArea from './AddBrandTextArea'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { AddBrandFormDataType } from './types'
import { GenericDispatch } from 'utils/commonTypes'
import ReactMarkdown from 'react-markdown'
import { containsHTML } from 'utils/helper'
import './AddBrandWizard.scss'

type MetricStats = {
  mentions: number
  impression: number
  engagement: number
}
const Metrics = { mentions: 'Mentions', impression: 'Impressions', engagement: 'Engagement' }
interface StatsInfoBlockProps {
  metric: string
  value: number
}
interface TopicsInfoStatsProps {
  subStore: SubStore
  type?: 'follow' | 'create'
  form: AddBrandFormDataType
  fetchFlag?: boolean
  setDataObj?: GenericDispatch<DetailsObjType | null>
  fetchInsightsMetrics: ({
    params,
    data,
    fullResponse,
  }: {
    params: ConditionsPayloadParamsType
    data: ConditionsDataType
    fullResponse?: boolean
  }) => Promise<any>
}
const TopicsInfoStats = (props: TopicsInfoStatsProps) => {
  const [qualityLoading, setQualityLoading] = useState(false)
  const { type = 'create', form, subStore, setDataObj, fetchInsightsMetrics, fetchFlag } = props
  const [statsLoading, setStatsLoading] = useState(false)
  const [stats, setStats] = useState<MetricStats>({
    mentions: 0,
    impression: 0,
    engagement: 0,
  })
  const [summaryLoading, setSummaryLoading] = useState(false)
  const [summary, setSummary] = useState('')
  const [showFullSummary, setShowFullSummary] = useState(false)
  const [evaluationResult, setEvaluationResult] = useState<number>(0)
  const [showBoolean, setShowBoolean] = useState(false)

  const { snippetsFilter, fetchSnippetsInfo, getSnippetFilterPayload, resetSnippetFilters } =
    store[`${subStore}ModalStore`]

  useEffect(() => {
    if (form['booleanSearch'].length) fetchData()
  }, [fetchFlag, snippetsFilter])

  const fetchData = useCallback(async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload('', false)
    fetchDataForMetric(payload)
    fetchSummary(payload)
    evaluateTopic(payload)
  }, [form['brandName'], form.booleanSearch, snippetsFilter])

  useUnmount(() => {
    resetSnippetFilters()
  })

  const fetchSummary = async (payload: SnippetFilterPayload) => {
    try {
      setSummaryLoading(true)
      const data = { ...payload }
      data.conditions = [{ keywords_expression: form['booleanSearch'] }, ...payload.conditions]
      const response = await anticipatoryIntelligence.getSummary(data)
      if (response && response?.summary_text) setSummary(response.summary_text)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setSummaryLoading(false)
    }
  }

  const fetchDataForMetric = async (payload: SnippetFilterPayload) => {
    try {
      setStatsLoading(true)
      const query = payload.query

      const responses = await Promise.all(
        Object.keys(Metrics).map((metric) => {
          const requestParams: any = {
            q: `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`,
          }
          if (metric !== 'mentions') requestParams.measure = metric
          return fetchInsightsMetrics({
            params: requestParams,
            data: {
              conditions: [{ keywords_expression: form['booleanSearch'] }, ...payload.conditions],
            },
          })
        }),
      )

      setStats({
        mentions: responses[0] || 0,
        impression: responses[1] || 0,
        engagement: responses[2] || 0,
      })
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setStatsLoading(false)
    }
  }

  const evaluateTopic = async (payload: SnippetFilterPayload) => {
    try {
      setQualityLoading(true)
      const response = await evaluateNarrative({
        ...payload,
        copilot_text: form['booleanSearch'],
      })
      setEvaluationResult(Number(Number(response?.evaluation_score || 0).toFixed(0)) || 0)
    } catch (error) {
      console.error('Failed to evaluate topic', error)
    } finally {
      setQualityLoading(false)
    }
  }

  const getScoreQuality = () => {
    if (evaluationResult === 0) return 'Not enough data'
    else if (evaluationResult > 70) return 'Great'
    else if (evaluationResult >= 40) return 'Average'
    else if (evaluationResult < 40) return 'Poor'
  }

  const getSummaryText = () => {
    const content = showFullSummary ? summary : summary.substring(0, 600)
    if (summary.length > 600) {
      return (
        <div className='summary__text'>
          {containsHTML(content) ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <ReactMarkdown>{content}</ReactMarkdown>
          )}
          <span className='summary__toggle-text' onClick={() => setShowFullSummary((prev) => !prev)}>
            {showFullSummary ? ' read less' : '...read more'}
          </span>
        </div>
      )
    } else return <p className='summary__text'>{summary}</p>
  }

  const handleSearchClick = async (data?: DetailsObjType) => {
    const detailObj: DetailsObjType = {
      comingFrom: '',
      conditions: { keywords_expression: form.booleanSearch },
      mode: 'asset',
      name: form.brandName,
    }

    setDataObj?.((prev) => {
      detailObj.additionalConditions = prev?.additionalConditions
      return { ...prev, ...detailObj }
    })

    fetchSnippetsInfo(data?.conditions ? data : detailObj, false)
    fetchData()
  }
  return (
    <div className='creation-flow__info-section'>
      {type === 'create' && <span className='title'>{form['brandName'] || ''}</span>}
      <label className={classNames('creation-flow__brand-name', { hide: type === 'create' })}>
        {type === 'follow' && (
          <span className='input-label'>
            Topic Name <span className='asterisk'>*</span>
          </span>
        )}
        <Form.Item
          hidden={type === 'create'}
          name='brandName'
          rules={[{ required: true, message: `Please input topic name` }]}
          shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input placeholder='Add name' value={form.brandName} />
        </Form.Item>
      </label>
      <Spin spinning={statsLoading}>
        <div className='stats-box'>
          {Object.keys(Metrics).map((item) => (
            <StatsInfoBlock
              metric={Metrics[item as keyof typeof Metrics]}
              value={stats[item as keyof typeof Metrics]}
            />
          ))}
        </div>
      </Spin>
      <Spin spinning={qualityLoading}>
        <div className='quality'>
          <div className='quality__title-container'>
            <span className='quality__title-container__text'>Match quality</span>
            {!qualityLoading && <span className='quality__title-container__tag'>{getScoreQuality()}</span>}{' '}
            {!qualityLoading && evaluationResult < 40 && (
              <Tooltip
                title={
                  'Your search criteria returned results that quite broad. Further refine your search with additional keywords and filters to improve your analysis.'
                }
                placement='bottom'>
                <InfoIcon />
              </Tooltip>
            )}
          </div>
          <div className='quality__bar'>
            <Progress percent={evaluationResult} strokeWidth={28} showInfo={false} />
            <span
              className='progress-text quality__bar__text'
              style={{
                right: evaluationResult > 10 ? 100 - evaluationResult + 1 + '%' : '90%',
                color: evaluationResult > 10 ? 'white' : 'black',
              }}>
              {evaluationResult}%
            </span>
          </div>
        </div>
      </Spin>
      {(summaryLoading || summary.length > 0) && (
        <Spin spinning={summaryLoading}>
          <div className='quality summary'>
            <div className='quality__title-container'>
              <ExecutiveSummaryIcon />
              <span className='quality__title-container__text'>Executive summary</span>
            </div>
            {getSummaryText()}
          </div>
        </Spin>
      )}
      {type === 'follow' && (
        <div
          className='brand-creation-modal__content--left-section__follow-flow__edit-boolean-div'
          onClick={() => setShowBoolean((prev) => !prev)}>
          <span>Show boolean keyword search</span>
          <ArrowIcon className={classNames({ rotate: showBoolean })} />
        </div>
      )}
      <AddBrandTextArea
        label='Boolean Keyword Search'
        name='booleanSearch'
        isRequiredField
        hidden={!showBoolean || type === 'create'}
      />
      {type === 'follow' && showBoolean && (
        <ButtonImage
          loading={false}
          disabled={form.booleanSearch.length === 0}
          onClick={() => handleSearchClick()}
          type='button'
          className='follow-modal__search-btn'>
          Search
        </ButtonImage>
      )}
    </div>
  )
}

const StatsInfoBlock = (props: StatsInfoBlockProps) => {
  const { metric, value } = props
  return (
    <div className='stats-box__container'>
      <span className='stats-box__metric-title'>{metric}</span>
      <span className='stats-box__metric-value'>{millify(value || 0)}</span>
    </div>
  )
}
export default observer(TopicsInfoStats)
