import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Form, Modal, Spin } from 'antd'
import { store } from 'store'
import { useEffectOnce } from 'react-use'

import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/dashboard/empty-state.svg'

import { DetailsObjType, SubStore } from 'types/types'
import { AddBrandFormDataType } from '../AddBrandWizard/types'
import { FlagListParamsType, SuggestFlagObject } from 'store/flags/types'
import { GenericDispatch } from 'utils/commonTypes'
import TopicsInfoStats from '../AddBrandWizard/TopicsInfoStats'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import { AssetType } from 'store/asset/types'
import { topicsAPI } from 'api/api'
import { displayApiError } from 'utils/helper'
import './SuggestedIssues.scss'
import { validateBoolean } from '../../hooks/useHandleCreateBrand'

type TopicFollowModalProps = {
  issue: SuggestFlagObject
  subStore: SubStore
  setOpenFollowModal: GenericDispatch<string>
}

const TopicFollowModal = (props: TopicFollowModalProps) => {
  const { issue, subStore, setOpenFollowModal } = props

  const [form] = Form.useForm()
  const [formData, setFormData] = useState<AddBrandFormDataType>({
    url: '',
    logoURL: '',
    edit_url: '',
    addedURLs: [],
    brandName: issue.title,
    previousURL: '',
    booleanSearch: '',
    platform: undefined,
    userSearchString: '',
  })
  const [dataObj, setDataObj] = useState<DetailsObjType | null>(null)
  const [createBrandLoading, setCreateBrandLoading] = useState(false)
  const [fetchFlag, setFetchFlag] = useState(false)
  const [loading, setLoading] = useState(false)

  const { activeItem } = store[`${subStore}Store`]
  const { fetchInsightsMetrics, fetchBooleanSearchText, setActiveItem } = store[`${subStore}ModalStore`]

  const { isLoadingFeed } = store.loaderStore
  const { tenantId } = store.userStore
  const { fetchFlagsListWithConditions } = store.flagsStore
  const { showToaster } = store.toasterStore

  useEffectOnce(() => {
    fetchBoolean()
  })

  const fetchBoolean = async () => {
    try {
      setLoading(true)
      const response = await fetchBooleanSearchText({ data: { prompt_text: issue.summary } })
      const detailObj: DetailsObjType = {
        comingFrom: '',
        conditions: { keywords_expression: response.prompt_response },
        mode: 'asset',
        name: issue.title,
      }
      if (activeItem?.conditions) detailObj.additionalConditions = [activeItem.conditions]

      setActiveItem(detailObj)
      setFormData((prev) => ({ ...prev, brandName: issue.title, booleanSearch: response.prompt_response }))
      form.setFieldValue('brandName', issue.title)
      form.setFieldValue('booleanSearch', response.prompt_response)
      setFetchFlag((prev) => !prev)

      setDataObj?.(detailObj)
    } catch (error) {
      displayApiError(error)
    } finally {
      setLoading(false)
    }
  }
  const handleValuesChange = (values: Record<string, any>) => {
    setFormData((prev) => ({ ...prev, ...values }))
  }

  const createAndFollowTopic = async () => {
    const isValid = await validateBoolean(formData.booleanSearch)
    if (!isValid) return

    const values = form.getFieldsValue()
    let requestBody: any = {
      description: '',
      is_asset: false,
      tenant_ids: [tenantId!],
      name: values.brandName,
      insight_type: 'SEARCH_TERM' as AssetType,
      display_label: values.brandName,
      insight_condition: values.booleanSearch,
    }

    try {
      setCreateBrandLoading(true)
      const { data: response } = await topicsAPI.createTopic({
        data: requestBody,
        method: 'post',
        id: issue.id,
      })

      if (response && response.id) {
        const requestParams: FlagListParamsType = {
          page: 1,
          per_page: 5,
          sort: 'name:asc',
          is_active: true,
        }
        fetchFlagsListWithConditions({ params: requestParams })
        setOpenFollowModal('')
      }
      showToaster({
        iconType: 'success',
        message: `You have successfully created and followed topic`,
      })
    } catch (error) {
      displayApiError(error)
    } finally {
      setCreateBrandLoading(false)
    }
  }

  return (
    <Modal
      open={true}
      destroyOnClose
      onOk={() => null}
      closable={true}
      title={'Follow topic'}
      onCancel={() => setOpenFollowModal('')}
      className='pulse-date-selection-modal brand-creation-modal'
      footer={
        <div className={'pulse-content-selection-modal__footer brand-creation-modal__footer'}>
          <div>
            <Button type='text' onClick={() => setOpenFollowModal('')}>
              Cancel
            </Button>
            <Button
              type='primary'
              loading={createBrandLoading}
              onClick={() => createAndFollowTopic()}
              className={'continue-btn'}>
              Follow topic
            </Button>
          </div>
        </div>
      }>
      <Spin spinning={loading}>
        <div className='brand-creation-modal__content'>
          <div className='brand-creation-modal__content--left-section'>
            <Form form={form} layout='vertical' initialValues={formData} onValuesChange={handleValuesChange}>
              <TopicsInfoStats
                type='follow'
                form={formData}
                fetchInsightsMetrics={fetchInsightsMetrics}
                fetchFlag={fetchFlag}
                setDataObj={setDataObj}
                subStore={subStore}
              />
            </Form>
          </div>
          <div className='brand-creation-modal__content--right-section right-section'>
            <Spin spinning={isLoadingFeed} className='right-section--spinner' />
            {dataObj?.conditions && (
              <SnippetListWithFilters
                containFiltersInComponent
                uniqueScrollId='add-new-brand__id'
                isCustomEmptyState
                emptyStateDescription={''}
                emptyStateIcon={<EmptyStateIcon />}
                emptyStateTitle={'No snippets found'}
                isForModal
                noOfDays={90}
                detailObject={dataObj}
              />
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default observer(TopicFollowModal)
