import axios from 'axios'
import React, { useState, useCallback, useRef, useEffect } from 'react'

interface VideoPlayerProps {
  url: string
  fallbackApiEndpoint?: string
  onError?: (error: Error) => void
}

interface VideoConfig {
  type: 'youtube' | 'tiktok' | 'bitchute' | 'instagram' | 'unknown'
  embedUrl: string
  videoId: string
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, fallbackApiEndpoint, onError }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [retryCount, setRetryCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const getVideoConfig = useCallback((videoUrl: string): VideoConfig => {
    try {
      let urlObj: URL
      try {
        urlObj = new URL(videoUrl)
      } catch (e) {
        return {
          type: 'unknown',
          videoId: '',
          embedUrl: videoUrl,
        }
      }

      const extractQueryParams = (url: URL): URLSearchParams => {
        const params = new URLSearchParams()
        const commonParams = ['start', 'end', 'autoplay', 't', 'time_continue']

        commonParams.forEach((param) => {
          if (url.searchParams.has(param)) {
            params.append(param, url.searchParams.get(param) || '')
          }
        })

        return params
      }

      const buildEmbedUrl = (baseUrl: string, params: URLSearchParams): string => {
        const queryString = params.toString()
        return queryString ? `${baseUrl}?${queryString}` : baseUrl
      }

      if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
        let videoId = ''
        let baseEmbedUrl = ''

        if (videoUrl.includes('/embed/')) {
          videoId = urlObj.pathname.split('/embed/')[1] || ''
          return {
            type: 'youtube',
            videoId,
            embedUrl: videoUrl,
          }
        } else if (videoUrl.includes('youtu.be')) {
          videoId = urlObj.pathname.split('/').filter(Boolean).pop() || ''
        } else {
          videoId = urlObj.searchParams.get('v') || ''
        }

        const params = extractQueryParams(urlObj)
        baseEmbedUrl = `https://www.youtube.com/embed/${videoId}`

        return {
          type: 'youtube',
          videoId,
          embedUrl: buildEmbedUrl(baseEmbedUrl, params),
        }
      } else if (videoUrl.includes('tiktok.com')) {
        let videoId = ''
        if (videoUrl.includes('/embed/')) {
          videoId = urlObj.pathname.split('/embed/')[1]?.split('/')[0] || ''
          return {
            type: 'tiktok',
            videoId,
            embedUrl: videoUrl,
          }
        }
        const matches = videoUrl.match(/\/video\/(\d+)/) || videoUrl.match(/\/v\/(\w+)/)
        videoId = matches ? matches[1] : urlObj.pathname.split('/').filter(Boolean).pop() || ''
        const embedUrl = `https://www.tiktok.com/embed/v3/${videoId}?embedType=singleVideo&hideLogo=1&hideVideoInfo=1&hideCommentBox=1&hideSharingButtons=1&hideFollowButton=1&hideCustom=1&relatedVideoEndscreen=0&showMoreVideos=0`
        return {
          type: 'tiktok',
          videoId,
          embedUrl,
        }
      } else if (videoUrl.includes('bitchute.com')) {
        let videoId = ''
        if (videoUrl.includes('/embed/')) {
          videoId = urlObj.pathname.split('/embed/')[1]?.split('/')[0] || ''
          return {
            type: 'bitchute',
            videoId,
            embedUrl: videoUrl,
          }
        }
        const matches = videoUrl.match(/\/video\/([a-zA-Z0-9]+)/)
        videoId = matches ? matches[1] : urlObj.pathname.split('/').filter(Boolean).pop() || ''
        return {
          type: 'bitchute',
          videoId,
          embedUrl: `https://www.bitchute.com/embed/${videoId}/`,
        }
      } else if (videoUrl.includes('instagram.com')) {
        let videoId = ''

        if (videoUrl.includes('/reel/')) {
          const matches = videoUrl.match(/\/reel\/([a-zA-Z0-9_-]+)/)
          videoId = matches ? matches[1] : ''
        } else if (videoUrl.includes('/p/')) {
          const matches = videoUrl.match(/\/p\/([a-zA-Z0-9_-]+)/)
          videoId = matches ? matches[1] : ''
        } else {
          const pathParts = urlObj.pathname.split('/').filter(Boolean)
          if (pathParts.length > 1) {
            videoId = pathParts[1]
          }
        }

        return {
          type: 'instagram',
          videoId,
          embedUrl: `https://www.instagram.com/p/${videoId}/embed/?cr=1&v=13&wp=1080&rd=https%3A%2F%2Fwww.instagram.com&hidecaption=1`,
        }
      }
      return {
        type: 'unknown',
        videoId: '',
        embedUrl: videoUrl,
      }
    } catch (error) {
      console.error('Error parsing video URL:', error)
      return {
        type: 'unknown',
        videoId: '',
        embedUrl: videoUrl,
      }
    }
  }, [])

  const handleFallback = useCallback(async () => {
    if (fallbackApiEndpoint && retryCount < 3) {
      try {
        const response = await axios.get(fallbackApiEndpoint)
        if (!response) {
          throw new Error('Fallback API failed')
        }
        if (response.data.alternativeUrl) {
          setRetryCount((prev) => prev + 1)
          setHasError(false)
          return response.data.alternativeUrl
        }
      } catch (error) {
        if (error instanceof Error) {
          onError?.(error)
        }
      }
    }
    return false
  }, [url, fallbackApiEndpoint, retryCount, onError])

  const checkVideoAvailability = useCallback(
    async (videoConfig: VideoConfig) => {
      try {
        if (videoConfig.type === 'youtube') {
          const response = await fetch(
            `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoConfig.videoId}&format=json`,
          )
          if (!response.ok) {
            throw new Error('Video unavailable')
          }
        } else if (videoConfig.type === 'tiktok') {
          const response = await fetch(
            `https://www.tiktok.com/oembed?url=https://www.tiktok.com/video/${videoConfig.videoId}`,
          )
          if (!response.ok) {
            throw new Error('Video unavailable')
          }
        }
        setHasError(false)
      } catch (error) {
        setHasError(true)
        if (error instanceof Error) {
          onError?.(error)
          const fallbackSuccess = await handleFallback()
          if (!fallbackSuccess) {
            setHasError(true)
          }
          if (fallbackSuccess && retryCount < 3) checkVideoAvailability(fallbackSuccess)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [handleFallback, onError],
  )

  useEffect(() => {
    const videoConfig = getVideoConfig(url)
    checkVideoAvailability(videoConfig)

    const handleMessage = (event: MessageEvent) => {
      if (event.data && typeof event.data === 'string') {
        try {
          const data = JSON.parse(event.data)
          if (data.event === 'onError' || data.event === 'error') {
            setHasError(true)
            onError?.(new Error('Video playback error'))
            handleFallback()
          }
        } catch (e) {}
      }
    }

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const videoConfig = getVideoConfig(url)

  const getIframeStyles = () => {
    const baseStyles = {
      borderRadius: '12px',
      border: 'none',
      display: hasError ? 'none' : 'block',
    }

    if (videoConfig.type === 'tiktok') {
      return {
        ...baseStyles,
        maxHeight: '400px',
      }
    }

    return baseStyles
  }

  if (isLoading) {
    return (
      <div className='w-full h-[326px] bg-gray-100 rounded-xl flex items-center justify-center'>
        <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900' />
      </div>
    )
  }

  if (hasError) {
    return (
      <div className='w-full h-[326px] bg-gray-100 rounded-xl flex items-center justify-center flex-col gap-2'>
        <p className='text-gray-600'>Video unavailable</p>
        {retryCount < 3 && fallbackApiEndpoint && (
          <button
            className='px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors'
            onClick={() => handleFallback()}>
            Try again
          </button>
        )}
      </div>
    )
  }

  return (
    <iframe
      title={videoConfig.embedUrl}
      ref={iframeRef}
      width='100%'
      allowFullScreen
      style={getIframeStyles()}
      src={videoConfig.embedUrl}
      height={videoConfig.type === 'tiktok' ? '900' : '400'}
      allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    />
  )
}

export default VideoPlayer
