import { store } from 'store'
import { Tooltip } from 'antd'
import { useEffectOnce } from 'react-use'
import { useNavigate } from 'react-router-dom'
import React, { memo, useEffect, useMemo, useState } from 'react'

import ExpandListChunk from '../ExpandListChunk/ExpandListChunk'
import SnippetsDiscovery from 'components/Asset/BrandsDashboard/components/SnippetsDiscovery/SnippetsDiscovery'
import Table, { IColumnType } from 'components/common/Table/Table'

import FlagPosts from '../FlagPosts/FlagPosts'
import AllPostSection from '../AllPostSection/AllPostSection'
import { ReactComponent as CheckIcon } from 'assets/images/icons/monitor/tick_icon.svg'
import { ReactComponent as NotificationIcon } from 'assets/images/icons/monitor/notification_icon.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'

import {
  ConditionsPayloadType,
  DetailsObjType,
  NarrativeListDataType,
  NarrativesResponseType,
  PaginationValuesType,
  SelectedTabType,
} from 'types/types'
import { Mode, SubStore } from 'types/types'
import { ListSelectedRowData } from 'models/models'
import { FlagListObjType } from 'store/flags/types'
import { GenericDispatch, Unknown } from 'utils/commonTypes'

import './ListViewSection.scss'

type ListViewSectionType = {
  selectedRows: string[]
  setSelectedRows: GenericDispatch<string[]>
  listData: NarrativesResponseType
  selectedTab: SelectedTabType
  page: PaginationValuesType
  subStore: SubStore
  setAlertId: GenericDispatch<string>
  setEntityId: GenericDispatch<string>
  setSelectedRowsData: GenericDispatch<ListSelectedRowData[]>
}

const ListViewSection = (props: ListViewSectionType) => {
  const navigate = useNavigate()
  const {
    selectedRows,
    setSelectedRows,
    listData,
    selectedTab,
    page,
    subStore,
    setAlertId,
    setEntityId,
    setSelectedRowsData,
  } = props
  const [itemToViewType, setItemToViewType] = useState('')
  const [clickedRow, setClickedRow] = useState<{ name: string } | null>(null)
  const [itemToView, setItemToView] = useState<FlagListObjType | undefined>()
  const [isOpenSnippetsDiscovery, setIsOpenSnippetsDiscovery] = useState(false)

  const [expandedRows, setExpandedRows] = useState<(string | number)[]>([])
  const { tenantsStore } = store

  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme, sku } = tenantsStore
  const { setNavigationInfo } = store.mainStore
  const { fetchCategories, snippetsFilter, activeItem } = store['assetsStore']

  const { fetchConditionsPayloadForAssets } = store[`${subStore}Store`]
  const expandedRowsHandler = (item: NarrativeListDataType) => {
    setExpandedRows((prevState) =>
      prevState.includes(item.id) ? prevState.filter((res) => res !== item.id) : [...prevState, item.id],
    )
  }

  const expandedRowsCondition = (item: NarrativeListDataType) => {
    return expandedRows.includes(item.id)
  }

  useEffectOnce(() => {
    if (isCorporateCommunicationsTheme) {
      fetchCategories({ is_asset: true }).then((response) => {
        const brand = response?.filter((category: any) => category.name === 'Brands')
        if (brand.length > 0)
          fetchConditionsPayloadForAssets({ is_asset: true, q: `category_id:eq:${brand[0].category_id}` })
      })
    } else fetchConditionsPayloadForAssets({ is_asset: true })
  })

  useEffect(() => {
    setExpandedRows([])
    setSelectedRows([])
    setSelectedRowsData([])
  }, [selectedTab, page.page])

  const columns: IColumnType<NarrativeListDataType>[] = useMemo(() => {
    return [
      {
        key: 'checkbox',
        title: () => null,
        width: 44,
        render: (column, item) => {
          const { id } = item
          return (
            <Tooltip placement='bottom' title='Select'>
              <div
                className={`lvsc_th_checkbox_container${
                  selectedRows.includes(id) ? ' lvsc_th_checkbox_container_selected' : ''
                }`}
                aria-label='Select'
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedRows([id])
                  setSelectedRowsData([
                    {
                      name: item.name,
                      conditions: item.conditions,
                      mode: item.mode as Mode,
                      id: item.id,
                      permission_level: item.permission_level,
                      alert_id: item.alert_id,
                      ownerId: item.owner_id,
                    },
                  ])
                }}>
                {selectedRows.includes(id) && <CheckIcon aria-label='Select' />}
              </div>
            </Tooltip>
          )
        },
      },
      {
        key: 'name',
        title: 'Name',
        width: 485,
        render: (column, item) => {
          const { name } = item

          return (
            <div className='lvsc_name_container'>
              <span className='lvsc_nc_text'>{name}</span>
            </div>
          )
        },
      },
      {
        key: 'all_posts',
        title: isCorporateCommunicationsTheme ? 'Mentions' : 'All Posts',
        width: 150,
        render: (column, item) => {
          return <AllPostSection itemData={item} subStore={subStore} />
        },
      },
      {
        key: 'flag_posts',
        title: isCorporateCommunicationsTheme
          ? selectedTab === 'watchlists'
            ? 'Impressions'
            : 'Followed Topics Posts'
          : 'Flagged Posts',
        width: 150,
        render: (column, item) => {
          return selectedTab === 'watchlists' && isCorporateCommunicationsTheme ? (
            <AllPostSection itemData={item} subStore={subStore} measure='impression' />
          ) : (
            <FlagPosts itemData={item} subStore={subStore} />
          )
        },
      },
      {
        key: 'assets_posts',
        title: isCorporateCommunicationsTheme
          ? selectedTab === 'watchlists'
            ? 'Engagement'
            : 'Brand Mentions'
          : 'Assets Posts',
        width: 150,
        render: (column, item) => {
          return selectedTab === 'watchlists' && isCorporateCommunicationsTheme ? (
            <AllPostSection itemData={item} subStore={subStore} measure='engagement' />
          ) : (
            <FlagPosts itemData={item} isAssetsFlags={true} subStore={subStore} />
          )
        },
      },
      {
        key: 'notification',
        title: '',
        width: 68,
        onCell: (record) => ({
          onClick: (e: any) => {
            e.stopPropagation()
            if (changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme) === 'communities') return
            setAlertId(record?.alert_id || '')
            setEntityId(record?.id)
          },
          style: {
            textAlign: 'center',
            cursor:
              changeLabelTextBasedOnTheme(selectedTab, isCorporateCommunicationsTheme) === 'communities'
                ? 'not-allowed'
                : 'pointer',
          },
        }),
        render: (_, record) => (
          <Tooltip placement='bottom' title='Turn On/Off'>
            {record?.alert_id ? (
              <FilledNotificationIcon aria-label='Turn On/Off' />
            ) : (
              <NotificationIcon className='amc_bell_icon' aria-label='Turn On/Offs' />
            )}
          </Tooltip>
        ),
      },
    ]
  }, [listData.items, selectedRows, selectedTab, page.page, isCorporateCommunicationsTheme])

  const renderRowSubComponent = React.useCallback((records: NarrativeListDataType) => {
    return <ExpandListChunk data={records} subStore={subStore} />
  }, [])

  const rowClickHandler = (item: NarrativeListDataType) => {
    const state = {
      name: item.name,
      conditions: item.conditions,
      mode: item.mode,
      id: item?.id,
      permission_level: item.mode === 'watchlist' ? 'NOT_SPECIFIED' : item.permission_level,
      alert_id: item.alert_id,
      ownerId: item.owner_id,
      tabName: selectedTab,
      selectedSearchedItem: item,
    }

    if (selectedTab === 'watchlists' && sku && isCorporateCommunicationsTheme) {
      setClickedRow({ name: item.name })
      const { setSnippetsFilter, setActiveItem } = store.assetsModalStore
      setActiveItem(item.conditions as Unknown)
      setSnippetsFilter({ ...snippetsFilter, conditions: [item.conditions] as ConditionsPayloadType })
      setIsOpenSnippetsDiscovery(true)
      return
    }
    const destination = () => {
      if (isCorporateCommunicationsTheme) {
        if (selectedTab === 'narratives') {
          const setSelectedSearchItem = store.vectorsStore.setSelectedSearchItem
          setSelectedSearchItem(item)
        }
        return ['watchlists', 'communities'].includes(selectedTab) ? 'power-insights/metrics' : '/topics'
      }
      return 'details'
    }

    setNavigationInfo({ comingFrom: selectedTab, to: destination(), pageNumber: page.page, pageSize: page.pageSize })
    navigate(destination(), { state: structuredClone(state) })
  }

  const closeSnippetsDiscovery = () => {
    if (itemToView) {
      setItemToView(undefined)
      setItemToViewType('')
    }
    const { setSnippetsFilter } = store.assetsModalStore
    setSnippetsFilter(snippetsFilter)
    setIsOpenSnippetsDiscovery(false)
  }

  return (
    <div className='list_view_section_container'>
      <Table
        data={listData.items}
        columns={columns}
        className='lvsc_table'
        onRowClick={rowClickHandler}
        {...(!isCorporateCommunicationsTheme && {
          expandable: {
            expandedRowRender: renderRowSubComponent,
            setExpandedRows: expandedRowsHandler,
            expandedRowsCondition: expandedRowsCondition,
          },
        })}
        // disabledRow={disabledRowHandler}
      />

      {isOpenSnippetsDiscovery && (
        <SnippetsDiscovery
          subStore='assets'
          itemToView={itemToView}
          selectedItem={clickedRow as Unknown}
          setItemToView={setItemToView}
          itemToViewType={itemToViewType}
          onClose={closeSnippetsDiscovery}
          activeItem={activeItem as DetailsObjType}
        />
      )}
    </div>
  )
}

export default memo(ListViewSection)
