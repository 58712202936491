import 'antd/dist/antd.css'
import { store } from 'store'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, useMemo } from 'react'

import BottomDropdown from 'components/common/dropdown/dropdown'
import ActionDropDown from 'components/common/dropdown/ActionDropDown'
import { DateFilter } from 'components/common/DateFilter/DateFilter'
import PendulumLoader from 'components/PendulumLoader/PendulumLoader'

import img from 'assets/images/icons/colorIcon.svg'
import mapIcon from 'assets/images/icons/mapIcon.svg'
import { ReactComponent as ListViewIcon } from 'assets/images/listview-icon.svg'
import { ReactComponent as ChordChartIcon } from 'assets/images/chord-chart-icon.svg'
import { ReactComponent as ThreeDIcon } from 'assets/images/riskwand/3d_chart_icon.svg'
import { ReactComponent as ElectionChordChartIcon } from 'assets/images/elecion-icon.svg'
import { LandingPage as ThreeJsLandingPage } from '3js/pages/landing/Landing.page'

import usePermissions from 'hooks/usePermissions'
import useDelayedLoading from 'utils/useDelayedLoading'
import * as DLandscapeData from 'api/pendulum3DAPI/3dLandscapeData'

import { getDateRange } from 'utils/helper'

import './ThreeDChart.scss'

const ThreeDChart = observer(() => {
  const { anticipatoryIntelligenceStore, loaderStore } = store
  const { chartName, setChartName, snippetsFilter } = anticipatoryIntelligenceStore
  const colorItem = [
    {
      key: '1',
      text: 'Sentiment',
      value: 'Sentiment',
    },
    {
      key: '2',
      text: 'Toxicity',
      value: 'Toxicity',
    },
  ]

  const { landscape3DLoading, statsSummaryLoading } = loaderStore

  const isLoading = useDelayedLoading([landscape3DLoading, statsSummaryLoading])

  const [mapValue, setMapValue] = useState('Default Maps')
  const [selectedValue, setSelectedValue] = useState('Default Maps')
  const canCreateNewMap = usePermissions('riskWand') === 'full_access'

  const [landscapeData, setLandscapeData] = useState<any[]>([])
  const [allMapsResponse, setAllMapsResponse] = useState<any[]>([])
  const [mapCrudView, setMapCrudView] = useState('')
  const [defaultMap, setDefaultMap] = useState<any[]>([])
  const [seconddefaultMap, setSecondDefaultMap] = useState<any[]>([])

  const [mapValues, setMapValues] = useState('')
  const [colorValue, setColorValue] = useState('')
  console.log(colorValue)

  const resetMapFilters = async () => {
    if (mapValue === 'Default Maps') fetchData()
    else MapClickHandler({ e: { target: mapValue } }, mapValues)
  }

  const dateRange = useMemo(() => {
    if (snippetsFilter.days.custom.isApplied)
      return {
        startDate: snippetsFilter.days.custom.dateRange[0],
        endDate: snippetsFilter.days.custom.dateRange[1],
      }

    return getDateRange(snippetsFilter.days.noOfDays, 'days')
  }, [snippetsFilter])

  const fetchData = async () => {
    if (mapValue === 'Default Maps') {
      try {
        setMapValue('Default Maps')
        setMapValues('')
        const response = await DLandscapeData.getLandscape()
        const allMapResponse = await DLandscapeData.getAllMaps()
        setAllMapsResponse(allMapResponse)
        const promises = response?.nodes.map(async (node: any) => {
          const queryCondition = node?.query_condition || []
          const apiResponses = await Promise.all([
            DLandscapeData.searchData(queryCondition, dateRange.startDate, dateRange.endDate),
          ])
          return apiResponses.map((apiResponse) => ({ ...node, ...apiResponse }))
        })
        const resultArrays = await Promise.all(promises)
        const reformattedData = resultArrays.flat()
        setLandscapeData(reformattedData)
        setDefaultMap(reformattedData)
      } catch (error) {
        console.error('Error fetching landscape data:', error)
      }
    } else {
      setLandscapeData(seconddefaultMap)
      const allMapResponse = await DLandscapeData.getAllMaps()
      setAllMapsResponse(allMapResponse)
      setMapValue(mapValue)
      setMapValues(mapValues)
    }
  }
  useEffect(() => {
    if (mapCrudView === '') {
      fetchData()
      setMapValue('Default Maps')
    }
    setMapValues(mapValues)
  }, [mapCrudView, dateRange])

  const MapClickHandler = async (e: any, id: any) => {
    if (id === 'default') {
      setLandscapeData(defaultMap)
      setMapValue('Default Maps')
    } else {
      setMapValue(e?.target?.value || selectedValue)
      setMapValues(id)
      try {
        const response = await DLandscapeData.getLandscapeMapsData(id)
        const promises = response?.nodes.map(async (node: any) => {
          const queryCondition = node?.query_condition || []
          const apiResponses = await Promise.all([
            DLandscapeData.searchData(queryCondition, dateRange.startDate, dateRange.endDate),
          ])
          return apiResponses.map((apiResponse) => ({ ...node, ...apiResponse }))
        })
        const resultArrays = await Promise.all(promises)
        const reformattedData = resultArrays.flat()
        setLandscapeData(reformattedData)
        setSecondDefaultMap(reformattedData)
      } catch (error) {
        console.error('Error fetching landscape data:', error)
        setLandscapeData(defaultMap)
      }
    }
  }

  useEffect(() => {
    if (selectedValue === 'Default Maps') {
      fetchData()
    } else {
      MapClickHandler(selectedValue, mapValues)
    }
  }, [mapValues, dateRange])

  const handleCreateMap = async (payload: any) => {
    try {
      const data = await DLandscapeData.getCreateAllMaps(payload)
      setMapValues(data?.id)
      setMapValue(data?.name)
      setSelectedValue(data?.name)
      return data
    } catch (error) {}
  }

  const DeleteApifunc = async () => {
    await DLandscapeData.getDeleteCrud(mapValues)
    const allMapResponse = await DLandscapeData.getAllMaps()
    setAllMapsResponse(allMapResponse)
    setSelectedValue('Default Maps')
    setMapValue('Default Value')
    setLandscapeData(defaultMap)
  }

  return (
    <div className='three_d_chart_container'>
      {mapCrudView !== 'edit' && mapCrudView !== 'create' && (
        <div className='tdcc_header'>
          <div className='tdcc_header_links_container'>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'list',
              })}
              onClick={() => setChartName('list')}>
              <Tooltip title='List View' placement='bottom'>
                <ListViewIcon aria-label='List View' className='tdcc_header_lc_l_icon' />
              </Tooltip>
              {chartName === 'list' && <span className={classNames('tdcc_header_lc_al_title')}>List View</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'chord',
              })}
              onClick={() => setChartName('chord')}>
              <Tooltip title='Chord Chart' placement='bottom'>
                <ChordChartIcon aria-label='Chord Chart' className='tdcc_header_lc_l_icon' />
              </Tooltip>
              {chartName === 'chord' && <span className={classNames('tdcc_header_lc_al_title')}>Chord Chart</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === '3d',
              })}
              onClick={() => setChartName('3d')}>
              <Tooltip title='3D Chart' placement='bottom'>
                <ThreeDIcon className='tdcc_header_lc_l_icon tdcc_header_lc_l_3d_icon' aria-label='3D Chart' />
              </Tooltip>

              {chartName === '3d' && <span className={classNames('tdcc_header_lc_al_title')}>3D Charts</span>}
            </div>
            <div
              className={classNames('tdcc_header_lc_link', {
                tdcc_header_lc_link_active: chartName === 'election',
              })}
              onClick={() => setChartName('election')}>
              <Tooltip title='Election Chord Chart' placement='bottom'>
                <ElectionChordChartIcon className='tdcc_header_lc_l_icon' aria-label='Election Chord Chart' />
              </Tooltip>
              {chartName === 'election' && (
                <span className={classNames('tdcc_header_lc_al_title')}>Election Chord Chart</span>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '1' }}>
            <BottomDropdown
              icon={mapIcon}
              text={mapValue}
              items={allMapsResponse}
              setValues={MapClickHandler}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            <BottomDropdown
              icon={img}
              text='Color'
              items={colorItem}
              setValues={setColorValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />

            <DateFilter days={[1, 7, 30, 90]} pageType={'list'} subStore={'anticipatoryIntelligence'} />

            {canCreateNewMap && (
              <ActionDropDown
                text='Actions'
                items={[
                  {
                    key: '1',
                    text: 'Create new map',
                    value: 'create',
                  },
                  {
                    key: '2',
                    text: 'Share map',
                    value: 'share',
                  },
                  {
                    key: '3',
                    text: 'Edit map',
                    value: 'edit',
                  },
                  {
                    key: '4',
                    text: 'Delete map',
                    value: 'delete',
                  },
                ]}
                DeleteApifunc={DeleteApifunc}
                setMapCrudView={setMapCrudView}
                mapValues={mapValues}
                mapValue={mapValue}
              />
            )}
          </div>
        </div>
      )}
      <div className='tdcc_3d_graph'>
        {isLoading && <PendulumLoader overLay height='100%' text='Pendulum is gathering the data' />}
        <ThreeJsLandingPage
          resetMapFilters={resetMapFilters}
          landscapeData={landscapeData}
          isLoadingSummary={isLoading}
          getExecutiveSummary={DLandscapeData.getExecutiveSummary}
          getTrendsPosts={DLandscapeData.getTrendsPosts}
          getTrendsImpression={DLandscapeData.getTrendsImpression}
          getPostSentiment={DLandscapeData.getPostSentiment}
          getImpressionsSentiment={DLandscapeData.getImpressionsSentiment}
          getPostPlatforms={DLandscapeData.getPostPlatforms}
          getImpressionsPlatforms={DLandscapeData.getImpressionsPlatforms}
          getPlatformDistribution={DLandscapeData.getPlatformDistribution}
          getAllMaps={DLandscapeData.getAllMaps}
          getCreateAllMaps={handleCreateMap}
          searchData={DLandscapeData.searchData}
          getNarrativeCrud={DLandscapeData.getNarrativeCrud}
          getNarrativeSearchCrud={DLandscapeData.getNarrativeSearchCrud}
          getFlagsCrud={DLandscapeData.getFlagsCrud}
          getFlagsSearchCrud={DLandscapeData.getFlagsSearchCrud}
          getAssetsCrud={DLandscapeData.getAssetsCrud}
          getAssetsSearchCrud={DLandscapeData.getAssetsSearchCrud}
          getDeleteCrud={DLandscapeData.getDeleteCrud}
          setMapCrudView={setMapCrudView}
          mapCrudView={mapCrudView}
          mapValues={mapValues}
          getShareUserCrud={DLandscapeData.getShareUserCrud}
          getShareUserData={DLandscapeData.getShareUserData}
          getSharePostUserData={DLandscapeData.getSharePostUserData}
          getLandscapeEditMaps={DLandscapeData.getLandscapeEditMaps}
          getWatchListCrud={DLandscapeData.getWatchListCrud}
          getWatchListSearchCrud={DLandscapeData.getWatchListSearchCrud}
          getUpdateEditMap={DLandscapeData.getUpdateEditMap}
          getShareUserSearch={DLandscapeData.getShareUserSearch}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          mapValue={mapValue}
        />
      </div>
    </div>
  )
})

export default ThreeDChart
