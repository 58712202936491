import { useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import Sections from './Sections/Sections'
import ModalFooter from './ModalFooter/ModalFooter'
import { ReactComponent as PlusIcon } from 'assets/images/icons/sectionplus.svg'
import { Button, Input, Spin } from 'antd'
import classNames from 'classnames'
import styles from './ReportModal.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import Recurring from './Recurring/Recurring'
import { ReactComponent as MinusIcon } from 'assets/images/icons/sectionminus.svg'
import { ReactComponent as WhiteMinusIcon } from 'assets/images/icons/whiteminusicon.svg'
import useClickAway from 'hooks/useClickAway'
import {
  CreateReportSectionObj,
  CreateReportObj,
  EntitiesObj,
  sectionObj,
  ReportType as ReportTypeString,
  ReportDayType,
} from 'store/report/valueTypes'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import ReportType from './ReportType/ReportType'

type ReportModalBodyProps = {
  isSection?: boolean
  sectionData?: sectionObj
  isDelete: boolean
  reportId?: string
  editMode?: boolean
  closeDeleteAndOpenSuccessModal: () => void
  closeCreateAndOpenSuccessModal: (data: CreateReportObj) => void
  description: string
  cancelHandler: () => void
  footerButtonText: string
}

const RANGE_DATE_FORMATE = 'YYYY-MM-DD'

const reportType = {
  ADHOC: 'ADHOC',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
}

const ReportModalBody = observer((props: ReportModalBodyProps) => {
  const {
    isSection,
    sectionData,
    isDelete,
    reportId,
    editMode,
    closeDeleteAndOpenSuccessModal,
    closeCreateAndOpenSuccessModal,
    description,
    cancelHandler,
    footerButtonText,
  } = props

  const { reportMainViewStore } = store
  const { reportDetails, getOrDeleteReportHandler, fetchAllLists, isConfirmLoading } = reportMainViewStore

  const [name, setName] = useState('')
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const calendarRef = useRef<any>()

  //...........report type........
  const [selectedReportType, setSelectedReportType] = useState<ReportTypeString>('')
  const [selectedDay, setSelectedDay] = useState<ReportDayType>('')
  const [isRecurring, setIsRecurring] = useState(false)

  const [sectionEdit, setSectionEdit] = useState<CreateReportSectionObj>({
    id: Date.now().toString(),
    title: '',
    summary_prompt: '',
    entities: [],
  })

  const [sections, setSections] = useState<CreateReportSectionObj[]>([
    { id: Date.now().toString(), title: '', summary_prompt: '', entities: [] },
  ])

  console.log('sections', sections)

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
    }
    const assetParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
      is_asset: true,
    }
    fetchAllLists(requestParams, assetParams)
  }, [])

  useEffect(() => {
    if (editMode && reportId) {
      getOrDeleteReportHandler('get', reportId)
    }
  }, [editMode, reportId])

  useEffect(() => {
    if (isSection && sectionData?.id) {
      setSectionEdit({
        id: sectionData.id,
        title: sectionData.title!,
        summary_prompt: sectionData.summary_prompt!,
        entities: sectionData.entities!,
      })
    }
  }, [isSection, sectionData?.id])

  useEffect(() => {
    if (editMode && reportDetails.id) {
      const newSections = reportDetails.sections.map((section) => ({
        id: section.id,
        title: section.title,
        summary_prompt: section.summary_prompt,
        entities: section.entities,
      }))
      setName(reportDetails.name)
      if (reportDetails.report_type! === reportType.ADHOC && reportDetails.report_instance_history.length) {
        setStartDate(new Date(reportDetails.report_instance_history[0].start_date!))
        setEndDate(new Date(reportDetails.report_instance_history[0].end_date!))
      }

      if (reportDetails.report_type! === reportType.WEEKLY) {
        setSelectedDay(reportDetails.weekly_report_day)
      }
      setIsRecurring(reportDetails.is_enabled)
      setSelectedReportType(reportDetails.report_type!)
      setSections(newSections as CreateReportSectionObj[])
    }
  }, [editMode, reportDetails.id])

  const brifNameOnChange = (event: any) => {
    setName(event.target.value)
  }

  //..............for closing the calendar when clicked outside it.....................
  useClickAway(calendarRef, () => setIsCalendarOpen(false))

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const addSection = () => {
    const newSection = {
      id: Date.now().toString(),
      title: '',
      summary_prompt: '',
      entities: [],
    }

    setSections((prevSections) => [...prevSections, newSection as CreateReportSectionObj])
  }
  const deleteSection = (sectionData: any) => {
    if (sections.length > 1) setSections((prevSections) => prevSections.filter((section) => section.id !== sectionData))
  }

  const sectionTitleOnChange = (id: string, newValue: string) => {
    setSections((prevSections) =>
      prevSections.map((section) => (section.id === id ? { ...section, title: newValue } : section)),
    )
  }
  const summarizationInstructionsOnChange = (id: string, newValue: string) => {
    setSections((prevSections) =>
      prevSections.map((section) => (section.id === id ? { ...section, summary_prompt: newValue } : section)),
    )
  }

  const entitiesOnChange = (id: string, entities: EntitiesObj[]) => {
    setSections((prevSections) =>
      prevSections.map((section) => (section.id === id ? { ...section, entities } : section)),
    )
  }

  const entitiesEditOnChange = (entities: EntitiesObj[]) => {
    setSectionEdit((prevSections) => ({ ...prevSections, entities }))
  }

  //...........section edit....................
  const sectionTitleEditOnChange = (event: any) => {
    setSectionEdit((prevSectionEdit) => ({
      ...prevSectionEdit,
      title: event.target.value,
    }))
  }

  const summarizationInstructionsEditOnChange = (event: any) => {
    setSectionEdit((prevSectionEdit) => ({
      ...prevSectionEdit,
      summary_prompt: event.target.value,
    }))
  }

  const isButtonDisabled: boolean = useMemo(() => {
    return sections.some((section) => section.title?.trim() === '')
  }, [sections])

  const isSectionUpdateButtonDisabled: boolean = useMemo(() => {
    return sectionEdit.title?.trim() === ''
  }, [sectionEdit.title])

  const isFormNotFilled = useMemo(() => {
    if (name === '') {
      return true
    } else if (selectedReportType === reportType.ADHOC && (startDate === null || endDate === null)) {
      return true
    } else if (selectedReportType === reportType.WEEKLY && selectedDay === '') {
      return true
    } else if (selectedReportType === '') {
      return true
    }
    return false
  }, [name, startDate, endDate, selectedDay, selectedReportType])

  const generateHandler = () => {
    if (!isConfirmLoading) {
      if (isDelete) {
        closeDeleteAndOpenSuccessModal()
      } else {
        if (isSection && isSectionUpdateButtonDisabled) {
          return
        }
        if (isFormNotFilled || isButtonDisabled) {
          return
        }
        let body: CreateReportObj = {
          name,
          report_type: selectedReportType,
          sections: sections.map((section) => ({
            title: section.title,
            summary_prompt: section.summary_prompt,
            entities: section.entities,
          })),
        }

        if (editMode) {
          body.is_enabled = isRecurring
        }

        if (selectedReportType === reportType.ADHOC) {
          body.start_date = dayjs(startDate).format(RANGE_DATE_FORMATE)
          body.end_date = dayjs(endDate).format(RANGE_DATE_FORMATE)
        } else if (selectedReportType === reportType.WEEKLY) {
          body.weekly_report_day = selectedDay
        }
        if (isSection) {
          const newSections: CreateReportSectionObj[] = JSON.parse(JSON.stringify(sections))
          const findIndex = newSections.findIndex((section) => section.id === sectionEdit.id)
          newSections[findIndex] = sectionEdit
          body.sections = newSections.map((section) => ({
            title: section.title,
            summary_prompt: section.summary_prompt,
            entities: section.entities,
          }))
        }

        closeCreateAndOpenSuccessModal(body)
      }
    }
  }

  return (
    <div className={styles.movable} tabIndex={-1} id='report_modal_body'>
      <Spin spinning={isConfirmLoading}>
        <div className={styles.formModalBody}>
          <div className={styles.bodyHeader}>
            <p className={styles.description}>{description}</p>
          </div>

          <div className={styles.movable}>
            {!isSection ? (
              <>
                <div className={styles.reportListForm}>
                  <div className={styles.floatingInputWithStaticLabel}>
                    <label className={styles.briefNameInputLabel}>Brief name</label>
                    <Input
                      autoComplete='off'
                      name='name'
                      className={styles.briefNameinput}
                      value={name}
                      onChange={brifNameOnChange}
                      placeholder='Enter brief name'
                    />
                  </div>
                </div>

                {editMode && <Recurring value={isRecurring} onChange={setIsRecurring} />}

                <ReportType
                  isCalendarOpen={isCalendarOpen}
                  calendarRef={calendarRef}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onDateChange}
                  setIsCalendarOpen={setIsCalendarOpen}
                  selectedReportType={selectedReportType}
                  setSelectedReportType={setSelectedReportType}
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                />

                {sections.map((section) => {
                  const hasMultipleSections: Boolean = sections.length > 1
                  return (
                    <div className={styles.reportListForm} key={section.id}>
                      <div className={classNames(styles.section, { [styles.sectionNotES]: !isSection })}>
                        <Button
                          icon={
                            hasMultipleSections ? (
                              <WhiteMinusIcon
                                className={classNames(
                                  styles.sectionButtonIcon,
                                  hasMultipleSections ? styles.sectionButtonDelete : '',
                                )}
                              />
                            ) : (
                              <MinusIcon className={styles.sectionButtonIcon} />
                            )
                          }
                          className={styles.sectionButton}
                          onClick={(e) => {
                            deleteSection(section.id)
                          }}>
                          SECTION
                        </Button>

                        <Sections
                          key={section.id}
                          editMode={editMode}
                          title={section.title}
                          sectionTitleOnChange={(e: any) => sectionTitleOnChange(section.id!, e.target.value)}
                          summary_prompt={section.summary_prompt}
                          entities={section.entities}
                          summarizationInstructionsOnChange={(e: any) =>
                            summarizationInstructionsOnChange(section.id!, e.target.value)
                          }
                          entitiesOnChange={(entitiesList: any) => entitiesOnChange(section.id!, entitiesList)}
                          isSection={isSection}></Sections>
                      </div>
                    </div>
                  )
                })}
                <div className={styles.reportListForm}>
                  <div className={styles.section}>
                    <Button
                      icon={<PlusIcon className={styles.sectionButtonIcon} />}
                      className={classNames(styles.sectionButton, { [styles.sectionButtonDisabled]: isButtonDisabled })}
                      disabled={isButtonDisabled}
                      onClick={addSection}>
                      ADD SECTION
                    </Button>
                  </div>
                </div>
              </>
            ) : null}

            {isSection && (
              <div className={styles.reportListForm}>
                <div className={classNames(styles.section, { [styles.sectionES]: isSection })}>
                  <Sections
                    key={sectionEdit.id}
                    editMode={editMode}
                    title={sectionEdit.title}
                    sectionTitleOnChange={sectionTitleEditOnChange}
                    summary_prompt={sectionEdit.summary_prompt}
                    entities={sectionEdit.entities}
                    summarizationInstructionsOnChange={summarizationInstructionsEditOnChange}
                    entitiesOnChange={(entitiesList: any) => entitiesEditOnChange(entitiesList)}
                    isSection={isSection}></Sections>
                </div>
              </div>
            )}
          </div>
        </div>
      </Spin>
      <ModalFooter
        isDelete={isDelete}
        onCancel={cancelHandler}
        onClick={generateHandler}
        disabled={(isSection && isSectionUpdateButtonDisabled) || isFormNotFilled || isButtonDisabled}
        buttonText={footerButtonText}
      />
    </div>
  )
})
export default ReportModalBody
