import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation, useOutletContext } from 'react-router-dom'

import MentionViewCard from '../Asset/BrandsDashboard/components/MentionViewCard/MentionViewCard'

import { AssetListObjType } from 'store/asset/types'
import { SubStore } from 'types/types'

import './DashboardMetrics.scss'

const DashboardMetrics = () => {
  const { selectedBrand, subStore = 'assets' }: { selectedBrand: AssetListObjType; subStore: SubStore } =
    useOutletContext()
  const location = useLocation()

  const selectedBrandData = useMemo(() => ({ ...selectedBrand, ...location.state }), [selectedBrand, location.state])

  const customGraphColors = useMemo(
    () => ({
      shadeColorUp: '#eeeff4',
      lineColorUp: '#A84BD3',
      shadeColorDown: '#eeeff4',
      lineColorDown: '#A84BD3',
      theme: 'purple',
    }),
    [],
  )

  const mentionViewCards = useMemo(
    () => [
      { type: 'Mentions', key: 'Mentions', measure: 'impressions' },
      { type: 'Impressions', key: 'Views', measure: 'impression' },
      { type: 'Engagement', key: 'engagement', measure: 'engagement' },
    ],
    [],
  )

  type cardTypes = 'Mentions' | 'Impressions' | 'Views' | 'Engagement'
  type keyTypes = 'impression' | 'engagement' | 'impressions'

  return (
    <div className='dashboard-metrics'>
      <div className='dashboard-metrics__header'>
        <div className='dashboard-metrics__header__title'>Metrics</div>
        <div className='dashboard-metrics__header__description'>Measure and track key performance indicators.</div>
      </div>
      <div className='dashboard-widgets__card__sub-row'>
        {mentionViewCards.map((card) => (
          <div className='dashboard-widgets__card mention-view-widgets' key={card.key}>
            <MentionViewCard
              selectedItem={selectedBrandData}
              type={card.type as cardTypes}
              key={card.key}
              measure={card.measure as keyTypes}
              subStore={subStore}
              customGraphColors={customGraphColors}
              chartWrapperClassName='cards-chart-wrapper'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(DashboardMetrics)
