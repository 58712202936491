import { useMemo } from 'react'
import { Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link, useLocation } from 'react-router-dom'
import { store } from 'store'

import '../SecondaryNavigation.scss'

export const InvestigateSecondaryNavigation = observer(() => {
  const { pathname } = useLocation()
  const { currentRole } = store.userStore

  const NAVIGATION_TABS = useMemo(() => {
    return [
      {
        key: 'bookmarks',
        label: 'Bookmarks',
        path: 'bookmarks',
        show: true,
      },
      {
        key: 'downloads',
        label: 'Downloads',
        path: 'downloads',
        show: true,
      },
    ]
  }, [currentRole])

  let selectedKeys: string[] = ['0']
  if (pathname.startsWith('/investigate/bookmarks')) {
    selectedKeys = ['bookmarks']
  } else if (pathname.startsWith('/investigate/downloads')) {
    selectedKeys = ['downloads']
  }

  return (
    <nav className='secondary-navigation'>
      <div className='secondary-navigation__container'>
        <Menu mode='horizontal' selectedKeys={selectedKeys} triggerSubMenuAction='click'>
          {NAVIGATION_TABS.filter((tab) => tab.show).map((tab) => {
            return (
              <Menu.Item key={tab.key} id={tab.key}>
                <Link to={tab.path}>{tab.label}</Link>
              </Menu.Item>
            )
          })}
        </Menu>
      </div>
    </nav>
  )
})
