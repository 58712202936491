/* eslint-disable prettier/prettier */
import { get } from './common/get.api'
import { getUsers } from './users/getUsers.api'
import { getRoles } from './users/getRoles.api'
import { saveUser } from './users/saveUser.api'
import { resetUser } from './users/resetUser.api'
import { deleteUser } from './users/deleteUser.api'
import { saveUserInSnowflake } from './users/saveUserInSnowflake.api'
import { updateUserRole } from './users/updateUserRole.api'
import { publishNarrative } from './defineNarrative/publishNarrative.api'
import { EditWatchlist } from './watchlist/publishWatchlist.api'
import { saveNarrative } from './defineNarrative/saveNarrative.api'
import { getNarrativeCount } from './defineNarrative/getNarrativeCount.api'

import { getGrowth } from './defineNarrative/getGrowth'
import { getMatchingResultsNew } from './defineNarrative/getMatchingResultsNew.api'
import { getBooleanSearchTextFromCopilot } from './defineNarrative/getBooleanSearchTextFromCopilot.api'
import { getBooleanSearchTextTranslation } from './defineNarrative/getBooleanSearchTextTranslation.api'
import { evaluateNarrative } from './defineNarrative/evaluateNarrative.api'

import { submitCommunity } from './communityAPI/submitCommunity'

import { saveWatchlist, verifyChannelURL, getSupportedPlatforms } from './watchlist/submitWatchlist'

import { loadFeed } from './monitor/feeds.api'
import { editNarrative } from './defineNarrative/editNarrative.api'
import { deleteNarrative } from './defineNarrative/deleteNarrartive.api'
import { genericShare } from './common/genericShare'
import { genericEdit } from './common/genericEdit'
import { genericDelete } from './common/genericDelete'
import { getDashboardUrl } from './analyze/getDashboardUrl'
import { getMetricsUsingDimension } from './common/getMetricsUsingDimension.api'
import { getPreviewImage } from './monitor/getPreviewImage'
import { getPreviewTranscript } from './monitor/getPreviewTranscript'

import { submitFeedback } from './feedbackAPI/submitFeedback'

import { getMonitorSearchResults } from './monitor/monitorSearch.api'
import { createFlag } from './monitor/powerInsights/attentionFlags/createFlag.api'
import { editFlag } from './monitor/powerInsights/attentionFlags/editFlag.api'
import { deleteFlag } from './monitor/powerInsights/attentionFlags/deleteFlag.api'
import { getFlags } from './monitor/powerInsights/attentionFlags/getFlags.api'
import { getBeeSwarmData } from './monitor/powerInsights/creatorBeeSwarm/getBeeSwarmData.api'
import { getPowerInsightsSummaryStatistics } from './monitor/powerInsights/summary/getPowerInsightsSummaryStatistics.api'
import { getLineChartData } from './monitor/powerInsights/lineChart/getLineChartData.api'
import { getTopicsRequestId } from './monitor/getTopicsRequestId.api'
import { getTreeMapData } from './monitor/powerInsights/treeMap/getTreeMapData.api'
import { getPeopleData } from './monitor/powerInsights/people/getPeopleData'
import { getPoliticianData } from './monitor/powerInsights/politician/getPoliticianData'
import { getCompaniesData } from './monitor/powerInsights/companies/getCompaniesData'
import { getCountriesMapData } from './monitor/powerInsights/countryMap/getCountriesMapData'
import { getLanguagesData } from './monitor/powerInsights/languages/getLanguages'

import { getTwitterPosts } from './defineNarrative/getTwitterPosts.api'
import { getNewsArticles } from './defineNarrative/getNewsArticles.api'

import { saveAlert } from './alertsAPI/saveAlert.api'
import { getAlertUsers } from './alertsAPI/getAlert.api'
import { editAlert } from './alertsAPI/editAlert.api'
import { deleteAlert } from './alertsAPI/deleteAlert.api'
import { createAlert } from './alertsAPI/createAlert.api'
import { updatedAlert } from './alertsAPI/updateAlert.api'
import { getAndSearchUsers } from './alertsAPI/getAndSearchUsers.api'

import { getRisks } from './anticipatoryIntelligence/risks/getRisks.api'
import { getThreatTopics } from './anticipatoryIntelligence/threatTopics/getThreatTopics.api'
import { getScorecardsAttentionFlags } from './anticipatoryIntelligence/closeRange/getScorecardsAttentionFlags.api'
import { getIssues } from './anticipatoryIntelligence/election/getIssues.api'
import { getRaces } from './anticipatoryIntelligence/election/getRaces.api'
import { getPostsCount } from './anticipatoryIntelligence/closeRange/getPostsCount.api'
import { getAttentionFlags } from './anticipatoryIntelligence/closeRange/getFlags.api'
import { getTopicChartData } from './anticipatoryIntelligence/details/getTopicsChartData'
import { getPlatformChartData } from './anticipatoryIntelligence/details/getPlatformChartData'
import { getSummary } from './anticipatoryIntelligence/details/getSummary'

import { recordSnippetFeedback } from './snippetFeedbackAPI/recordSnippetFeedback'
import { deleteWatchlist } from './watchlist/deleteWatchlist.api'

import { getSpotlight } from './spotlight/getSpotlight.api'
import { listSpotlights } from './spotlight/listSpotlights.api'
import { sendSpotlight } from './spotlight/sendSpotlight.api'
import { spotlightActions } from './spotlight/spotlightActions.api'
import { createBookmark } from './bookmarks/createBookmark.api'
import { updateBookmark } from './bookmarks/updateBookmark.api'
import { deleteBookmark } from './bookmarks/deleteBookmark.api'
import { addSnippetToBookmark } from './bookmarks/addSnippetToBookmark.api'
import { removeSnippetFromBookmark } from './bookmarks/removeSnippetFromBookmark.api'
import { getUserBookmarkedSnippets } from './bookmarks/getUserBookmarkedSnippets.api'
import { getLists } from './reports/getLists.api'
import { getListsWithParams } from './reports/getListsWithParams.api'
import { createReport } from './reports/createReport.api'
import { updateReport } from './reports/updateReport.api'
import { shareReport } from './reports/shareReport.api'
import { getOrDeleteReport } from './reports/getOrDeleteReport.api'
import { getReportSummary } from './reports/getReportSummary.api'
import { fetchReportFeeds } from './reports/fetchReportFeeds.api'
import { fetchReportStats } from './reports/fetchReportStats.api'
import { reportFeedsFeedback } from './reports/reportFeedsFeedback.api'
import { sendReportAsEmail } from './reports/sendReportAsEmail.api'

import { exportFeed } from './monitor/export/export.api'
import { getAllExportFeed } from './monitor/export/getExport.api'
import { downloadExportedFeed } from './monitor/export/downloadExport.api'
import { createClipReport } from './monitor/powerInsights/clipReport/createClipReport'
import { getAllClipsReport } from './monitor/powerInsights/clipReport/getClipReports.api'

import { getMonitors } from './monitor/folderView/getMonitors.api'
import { getSearchPayload } from './monitor/folderView/getSearchPayload.api'
import { getInsightsMetrics } from './monitor/folderView/getInsightsMetrics.api'
import { getLineChartStats } from './monitor/folderView/getLineChartStats.api'
import { folderCRUD } from './monitor/folderView/folderCRUD.api'
import { moveToFolder } from './monitor/folderView/moveToFolder.api'

import { getCategories } from './flags/getCategories.api'
import { getFlagsList } from './flags/getFlagsList.api'
import { getBooleanSearchText } from './flags/getBooleanSearchText.api'
import { getIdsBasedData } from './flags/getIdsBasedData.api'
import { createFlag as postFlag } from './flags/createFlag.api'
import { deleteFlag as removeFlag } from './flags/deleteFlag.api'
import { toggleFlagStatus } from './flags/toggleFlagStatus.api'
import { getInsightsListForAlert } from './flags/getFlagListForAlerts.api'

import { createTenant } from './tenants/createTenant'
import { getTenant, getTenantPagination } from './tenants/getTenant.api'
import { getDefaultPlatforms } from './tenants/defaultPlatforms.api'
import { getCustomTheme } from './tenants/getCustomTheme.api'
import { getTenantDetails } from './tenants/getTenantDetails.api'
import { deleteTenant } from './tenants/deleteTenant.api'
import { editTenant } from './tenants/editTenant'
import { searchTenant } from './tenants/searchTenant'
import { getTenantConfiguration } from './tenants/getTenantConfiguration.api'
import { getTenantDefaultPlatforms } from './tenants/getTenantPlatforms.api'
import { updateTenantConfiguration } from './tenants/updateTenantConfiguration.api'
import { getAdditionalPlatforms } from './tenants/additionalPlatforms.api'
import { transferOwnership } from './tenants/transferOwnership'

import { fetchDefaultFile, fetchSelectedFile } from './common/docs.api'

import { getAgreementStatus } from './agreement/getAgreementStatus.api'
import { verifyAgreement } from './agreement/verifyAgreement.api'
import { getCreatorsData } from './monitor/powerInsights/creators/getCreatorsData'
import { getSummaryV2 } from './monitor/powerInsights/summary/getSummaryV2'
import { getSubjectSentimentMetrics } from './monitor/folderView/getSubjectSentimentMetrics'
import { getSummarySubscriptionStatus } from './flags/getSummarySubscriptionStatus.api'
import { createSummarySubscription } from './flags/createSummarySubscription.api'
import { deleteSummarySubscription } from './flags/deleteSummarySubscription.api'
import { getSuggestedTopics } from './flags/getSuggestedTopics.api'
import { followIssue } from './flags/followIssue.api'
import { loginMetric } from './userMetrics/loginMetric.api'
import { createTopic } from './topics/createTopic'
import { getHashtagData } from './monitor/powerInsights/getHashtagData'
import { getCashTagData } from './monitor/powerInsights/getCashtagData'
import { getHandlesData } from './monitor/powerInsights/getHandlesData'

export const API = {
  get,
  getUsers,
  getRoles,
  saveUser,
  resetUser,
  deleteUser,
  updateUserRole,
  saveUserInSnowflake,
  genericShare,
  genericEdit,
  genericDelete,
  getMetricsUsingDimension,
}

export const userAPI = {
  getUsers,
  getRoles,
  saveUser,
  resetUser,
  deleteUser,
  updateUserRole,
}

export const defineNarrativeAPI = {
  publishNarrative,
  saveNarrative,
  getMatchingResultsNew,
  editNarrative,
  getNewsArticles,
  getTwitterPosts,
  getNarrativeCount,
  getGrowth,
  getBooleanSearchTextFromCopilot,
  getBooleanSearchTextTranslation,
  evaluateNarrative,
}

export const communityAPI = {
  submitCommunity,
}

export const watchlistAPIs = {
  saveWatchlist,
  EditWatchlist,
  verifyChannelURL,
  getSupportedPlatforms,
}

export const monitorAPI = {
  loadFeed,
  getTopicsRequestId,
  getMonitorSearchResults,
  deleteNarrative,
  deleteWatchlist,
  getMonitors,
  getLineChartStats,
  getSearchPayload,
  getInsightsMetrics,
  folderCRUD,
  moveToFolder,
  getSubjectSentimentMetrics,
  getPreviewImage,
  getPreviewTranscript,
}

export const exportAPI = {
  exportFeed,
  getAllExportFeed,
  downloadExportedFeed,
  createClipReport,
  getAllClipsReport,
}

export const analyzeAPI = {
  getDashboardUrl,
}

export const feedbackAPI = {
  submitFeedback,
}

export const alertAPI = {
  saveAlert,
  getAlertUsers,
  editAlert,
  deleteAlert,
  createAlert,
  updatedAlert,
  getAndSearchUsers,
}

export const anticipatoryIntelligence = {
  getThreatTopics,
  getRisks,
  getScorecardsAttentionFlags,
  getIssues,
  getRaces,
  getPostsCount,
  getAttentionFlags,
  getTopicChartData,
  getPlatformChartData,
  getSummary,
}

export const snippetFeedbackAPI = {
  recordSnippetFeedback,
}

export const attentionFlagsAPI = {
  createFlag,
  editFlag,
  deleteFlag,
  getFlags,
}

export const reportsAPI = {
  getLists,
  createReport,
  updateReport,
  shareReport,
  getOrDeleteReport,
  getReportSummary,
  fetchReportFeeds,
  fetchReportStats,
  reportFeedsFeedback,
  getListsWithParams,
  sendReportAsEmail,
}

export const flagsAPI = {
  getCategories,
  getFlagsList,
  postFlag,
  removeFlag,
  getBooleanSearchText,
  getIdsBasedData,
  toggleFlagStatus,
  getInsightsListForAlert,
  getSummarySubscriptionStatus,
  createSummarySubscription,
  deleteSummarySubscription,
  getSuggestedTopics,
  followIssue,
}

export const powerInsightsAPI = {
  summary: {
    getPowerInsightsSummaryStatistics,
    getSummaryV2,
  },
  lineChart: {
    getLineChartData,
  },
  treeMap: {
    getTreeMapData,
  },
  beeSwarm: {
    getBeeSwarmData,
  },
  people: {
    getPeopleData,
  },
  politician: {
    getPoliticianData,
  },
  companies: {
    getCompaniesData,
  },
  countries: {
    getCountriesMapData,
  },
  languages: {
    getLanguagesData,
  },

  creators: {
    getCreatorsData,
  },
  hashtag: {
    getHashtagData,
  },
  cashtag: {
    getCashTagData,
  },
  handles: {
    getHandlesData,
  },
}

export const spotlightAPI = {
  getSpotlight,
  listSpotlights,
  spotlightActions,
  sendSpotlight,
}

export const bookmarksAPI = {
  createBookmark,
  updateBookmark,
  deleteBookmark,
  addSnippetToBookmark,
  removeSnippetFromBookmark,
  getUserBookmarkedSnippets,
}

export const tenantsAPI = {
  createTenant,
  getTenant,
  getTenantPagination,
  getDefaultPlatforms,
  getCustomTheme,
  getTenantDetails,
  deleteTenant,
  editTenant,
  searchTenant,
  getTenantConfiguration,
  getTenantDefaultPlatforms,
  updateTenantConfiguration,
  getAdditionalPlatforms,
  transferOwnership,
}

export const docsAPI = {
  fetchDefaultFile,
  fetchSelectedFile,
}

export const Agreement = {
  getAgreementStatus,
  verifyAgreement,
}

export const metricsAPI = {
  loginMetric,
}
export const topicsAPI = {
  createTopic,
}
