import classNames from 'classnames'

import { betaTag } from 'components/Monitor/SnippetFilter/SnippetFilter'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

import { ReactComponent as WarningIcon } from 'assets/images/warningicon.svg'

const Platform = ({ platform = '', hideTitle = false }: { platform: string; hideTitle?: boolean }) => {
  const platformDetails = PLATFORM_ICONS_GREY?.find((item) => item?.title?.toLowerCase() === platform?.toLowerCase())
  return (
    <span
      className={classNames('platform-batch', platformDetails?.title?.toLowerCase(), {
        invalid: !platformDetails?.title,
      })}>
      <span className='icon'>{platformDetails?.icon || <WarningIcon />}</span>
      {!hideTitle && <span className='title'>{platformDetails?.title || 'Invalid link'}</span>}
      {betaTag[platformDetails?.title?.toLowerCase() as keyof typeof betaTag] || null}
    </span>
  )
}

export default Platform
