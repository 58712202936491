import React, { useEffect, useMemo, useState } from 'react'
import CustomModal from 'components/common/CustomModal/CustomModal'
import ButtonImage from '../../../../common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as AlertIcon } from 'assets/images/icons/alertTriangle.svg'
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg'
import { ReactComponent as CopiedIcon } from 'assets/images/copied_icon.svg'
import './WarningModal.scss'

type DeleteReportModalProps = {
  onContinue: () => void
  onClose: () => void
  link: string
}

const WarningModal = (props: DeleteReportModalProps) => {
  const { onClose, onContinue, link } = props
  const [copied, setCopied] = useState<boolean>(false)
  const continueHandler = () => {
    onContinue()
  }

  const getBaseUrl = useMemo(() => {
    if (link) {
      const match = link.match(/^https?:\/\/[^#?/]+/)
      if (match) {
        return match[0]
      }
    }
    return ''
  }, [link])

  const copyToClipboard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    navigator.clipboard
      .writeText(link)
      .then((res) => {
        console.log('URL copied to clipboard!', { res })
        setCopied(true)
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard. Please try again.', { error })
      })
  }

  useEffect(() => {
    let copiedRef: NodeJS.Timeout | undefined
    if (copied) {
      copiedRef = setTimeout(() => {
        setCopied(false)
      }, 3000)
    }

    return () => {
      if (copiedRef) {
        clearTimeout(copiedRef)
      }
    }
  }, [copied])

  return (
    <CustomModal
      icon={AlertIcon}
      iconSize={48}
      title='External Link Warning'
      secondButtonText='Continue'
      onClose={onClose}
      onConfirm={continueHandler}
      containerClassName='elw_containerClassName'
      boxContainerClassName='elw_box_container'
      bodyClassName='elw_body_container'>
      <div className='elw_modal_body_container'>
        <p className='elw_follow_text'>The following link:</p>

        <div className='elw_mbc_clipboard_container'>
          <input className='elw_mbc_cc_input' type='text' value={link} readOnly />
          <ButtonImage
            className={`elw_mbc_cc_button${copied ? ' elw_mbc_cc_button_copied' : ''}`}
            icon={copied ? CopiedIcon : CopyIcon}
            onClick={(e) => {
              if (!copied) {
                copyToClipboard(e)
              }
            }}>
            {copied ? 'Copied' : 'Copy'}
          </ButtonImage>
        </div>
        <p className='elw_link_text'>
          is taking you to another site: <span className={'elw_link'}>{getBaseUrl}</span>
          <br />
          Are you sure you want to continue?
        </p>
      </div>
    </CustomModal>
  )
}

export default WarningModal
