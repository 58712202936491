import dayjs from 'dayjs'
import millify from 'millify'
import * as echarts from 'echarts'
import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import CustomSwitch from '../MentionsViewsSwitch/MentionsViewsSwitch'

import { store } from 'store'
import { sourcePlatform } from 'models/models'

import { SubStore } from 'types/types'
import { Unknown } from 'utils/commonTypes'
import { NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'

import styles from './MonitorGraph.module.scss'

interface Props {
  subStore: SubStore
  dataLoading: boolean
  resizeChartTrigger: boolean
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
}

interface LineChartData {
  posts: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
  impressions: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
  engagement: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
}

export const MonitorGraph = observer(({ subStore, dataLoading = false, resizeChartTrigger }: Props) => {
  const { fetchPowerInsightsLineChartDataForBrandPage, snippetsFilter, activeItem } = store[`${subStore}Store`]
  const [lineChartData, setLineChartData] = useState<LineChartData>({
    posts: {
      all: { data_points: [] },
      sentiment: { data_points: [] },
    },
    impressions: {
      all: { data_points: [] },
      sentiment: { data_points: [] },
    },
    engagement: {
      all: { data_points: [] },
      sentiment: { data_points: [] },
    },
  })
  const {
    days: { noOfDays },
  } = snippetsFilter

  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions' | 'engagement'>('posts')
  const [activePivot, setActivePivot] = useState<'all' | 'sentiment'>('all')
  const [disabledChartLines, setDisabledChartLines] = useState<
    Array<Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative'>
  >([])

  const chartRef = useRef<HTMLDivElement | null>(null)

  const fetchData = () => {
    fetchPowerInsightsLineChartDataForBrandPage().then((data) => {
      setLineChartData(data)
    })
  }

  useEffect(() => {
    if (activeItem) {
      fetchData()
    }
  }, [activeItem])

  useEffect(() => {
    setDisabledChartLines([])
  }, [activeMetric, activePivot])

  useEffect(() => {
    if (!chartRef.current) return

    const chartInstance = echarts.init(chartRef.current)

    const commonSeries = {
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 12,
      connectNulls: true,
      emphasis: {
        focus: 'series',
      },
    }

    const series = []

    if (activePivot === 'all') {
      const dataPoints = lineChartData[activeMetric][activePivot].data_points

      const major = dataPoints.map((item, index) => (index <= dataPoints.length - noOfDays ? item.metric_value : null))
      const filtered = dataPoints.map((item, index) =>
        index >= dataPoints.length - noOfDays ? item.metric_value : null,
      )

      series.push(
        {
          ...commonSeries,
          data: major,
          name: 'Metric Value',
          lineStyle: {
            color: '#CCA55A',
          },
          itemStyle: { color: '#CCA55A' },
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: filtered,
          name: 'Metric Value',
          lineStyle: {
            color: '#A84BD3',
          },
          itemStyle: { color: '#A84BD3' },
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: lineChartData[activeMetric][activePivot].data_points.map((item) =>
            item.metric_running_average?.toFixed(2),
          ),
          name: 'Running Average',
          lineStyle: { type: 'dashed', color: '#8598AD' },
          itemStyle: { color: '#8598AD' },
          symbol: 'none',
        },
      )
    } else if (activePivot === 'sentiment') {
      const dataPoints = lineChartData[activeMetric][activePivot].data_points
      const majorPositive = dataPoints.map((item, index) =>
        index <= dataPoints.length - noOfDays ? item.Positive : null,
      )
      const filteredPositive = dataPoints.map((item, index) =>
        index >= dataPoints.length - noOfDays ? item.Positive : null,
      )

      const majorNegative = dataPoints.map((item, index) =>
        index <= dataPoints.length - noOfDays ? item.Negative : null,
      )

      const filteredNegative = dataPoints.map((item, index) =>
        index >= dataPoints.length - noOfDays ? item.Negative : null,
      )

      const majorNeutral = dataPoints.map((item, index) =>
        index <= dataPoints.length - noOfDays ? item.Neutral : null,
      )
      const filteredNeutral = dataPoints.map((item, index) =>
        index >= dataPoints.length - noOfDays ? item.Neutral : null,
      )

      series.push(
        {
          ...commonSeries,
          data: majorPositive,
          name: 'Positive',
          lineStyle: { color: noOfDays === 1 ? '#18B368' : 'rgba(24, 179, 104, 0.5)' },
          itemStyle: { color: '#18B368' },
          hidden: disabledChartLines.includes('Positive'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: filteredPositive,
          name: 'Positive',
          lineStyle: { color: '#18B368' },
          itemStyle: { color: '#18B368' },
          hidden: disabledChartLines.includes('Positive'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: majorNeutral,
          name: 'Neutral',
          lineStyle: { color: noOfDays === 1 ? '#18A0FB' : 'rgba(24, 160, 251, 0.5)' },
          itemStyle: { color: '#18A0FB' },
          hidden: disabledChartLines.includes('Neutral'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: filteredNeutral,
          name: 'Neutral',
          lineStyle: { color: '#18A0FB' },
          itemStyle: { color: '#18A0FB' },
          hidden: disabledChartLines.includes('Neutral'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: majorNegative,
          name: 'Negative',
          lineStyle: { color: noOfDays === 1 ? '#DE4E4E' : 'rgba(222, 78, 78, 0.5)' },
          itemStyle: { color: '#DE4E4E' },
          hidden: disabledChartLines.includes('Negative'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
        {
          ...commonSeries,
          data: filteredNegative,
          name: 'Negative',
          lineStyle: { color: '#DE4E4E' },
          itemStyle: { color: '#DE4E4E' },
          hidden: disabledChartLines.includes('Negative'),
          showSymbol: false,
          emphasis: {
            scale: true,
            focus: 'series',
          },
          hoverAnimation: true,
        },
      )
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: Unknown) {
          let validData = params.filter((item: Unknown) => item.data !== null && item.data !== undefined)

          if (validData.length > 0) {
            let date = params[0].axisValue
            let tooltipContent =
              `<strong>${date}</strong><br/>` +
              validData.map((item: Unknown) => `${item.marker}${item.seriesName}: ${item.data}`).join('<br/>')
            return tooltipContent
          }

          return null
        },
      },
      legend: {
        data: series.map((s) => s.name),
        // @ts-ignore
        selected: series.reduce((acc, s) => ({ ...acc, [s.name]: !s.hidden }), {}),
        orient: 'horizontal',
        left: 'left',
        bottom: 0,
        icon: 'circle',
      },
      xAxis: {
        type: 'category',
        data: lineChartData[activeMetric][activePivot].data_points.map((item) => dayjs(item.date).format('YYYY-MM-DD')),
        boundaryGap: false,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          interval: 'auto',
          formatter: (value: string, index: number) => {
            const dates = lineChartData[activeMetric][activePivot].data_points.map((item) => item.date)
            const startDate = dayjs(dates[0])
            const endDate = dayjs(dates[dates.length - 1])
            const daysDifference = endDate.diff(startDate, 'day')

            if (daysDifference <= 31) {
              return dayjs(value).format('DD MMM YYYY')
            } else {
              return dayjs(value).format('MMM YYYY')
            }
          },
        },
      },
      grid: {
        top: 30,
        bottom: 60,
        left: 40,
        right: 40,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          // @ts-ignore
          formatter: (value) => (Number.isFinite(value) ? millify(value || 0) : '0'),
        },
      },
      series,
    }

    chartInstance.setOption(option)

    const handleResize = () => {
      chartInstance.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      chartInstance.dispose()
    }
  }, [activeMetric, activePivot, disabledChartLines, lineChartData, resizeChartTrigger, noOfDays])

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.description}>Review longitudinal changes in the data to spot important patterns</div>
      <br />
      <Spin spinning={dataLoading} style={{ minHeight: '350px' }}>
        <div className={styles.headerWrapper}>
          <div className={styles.chartFilters}>
            <div className={styles.metric}>
              <span>Metric</span>
              <Select onChange={(value) => setActiveMetric(value)} value={activeMetric} className={styles.selectMetric}>
                <Select.Option value='posts'>Mentions</Select.Option>
                <Select.Option value='impressions'>Impressions</Select.Option>
                <Select.Option value='engagement'>Engagement</Select.Option>
              </Select>
            </div>

            <div className={styles.pivot}>
              <span>Pivot</span>
              <CustomSwitch
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Sentiment', value: 'sentiment' },
                ]}
                onUpdateView={(event: any) => setActivePivot(event.target.value)}
                view={activePivot}
              />
            </div>
          </div>
        </div>

        <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
      </Spin>
    </div>
  )
})
