import { store } from 'store'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Button, Col, Divider, Modal, Select, TabsProps, Tag } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { ReactComponent as DropdownIcon } from 'assets/images/dropdown-icon.svg'

import PulseDDListOptions from './PulseDDListOptions'
import DateTimeRangeFilter from './DateTimeRangeFilter'
import PulseContentSelectionModal from './PulseContentSelectionModal'
import Language from 'pages/Investigate/CreateReport/components/ContentFilters/Language'
import Sentiment from 'pages/Investigate/CreateReport/components/ContentFilters/Sentiment'
import {
  defaultFilters,
  defaultSelectedContentItems,
  DefaultSelectedContentItemsTypes,
  FilterTypes,
  TabTypes,
} from './PulseDashboard'
import ContentSource from 'pages/Investigate/CreateReport/components/ContentFilters/ContentSource'
import PlatformDistribution from 'pages/Investigate/CreateReport/components/ContentFilters/PlatformDistribution'

import { formatConditions } from './utils'
import { PulseContentSelectionTypes, PulseFilterSelectionsTypes, Unknown } from 'utils/commonTypes'
import { convertDateAndTimeToUTC, convertDateToLocal, convertTimeToLocal, getTime, isObjectEmpty } from 'utils/helper'
import dayjs from 'dayjs'

export type OptionTypes = { value: string; name: string; category: CategoriesTypes; id?: string }
export type CategoriesTypes =
  | 'topics'
  | 'horizonTopics'
  | 'followedTopics'
  | 'searches'
  | 'brand'
  | 'flags'
  | 'influencers'
  | 'bookmarks'

type PulseLeftComponentPropsTypes = {
  id?: string
  activeTab?: TabTypes
  handleUpdateTabContent: ({
    filters,
    entities,
    refinedEntities,
    isEnableAPICall,
    activeTab,
  }: {
    isEnableAPICall: boolean
    filters: PulseFilterSelectionsTypes
    entities: PulseContentSelectionTypes
    refinedEntities: PulseContentSelectionTypes
    activeTab?: TabTypes
  }) => Promise<void>
}

type RefTypes = {
  setToggle: (value: boolean) => void
}

const PulseLeftComponent = observer(
  forwardRef<RefTypes, PulseLeftComponentPropsTypes>(({ handleUpdateTabContent, id, activeTab }, ref) => {
    const [filters, setFilters] = useState<FilterTypes>(defaultFilters)
    const [filters1, setFilters1] = useState<FilterTypes>(defaultFilters)
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
    const [isTabsModalOpen1, setIsTabsModalOpen1] = useState<boolean>(false)
    const [isTabsModalOpen2, setIsTabsModalOpen2] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<CategoriesTypes>('brand')
    const [isOpenContentModal, setIsOpenContentModal] = useState<boolean>(false)
    const [selectedContentItems1, setSelectedContentItems1] = useState(defaultSelectedContentItems)
    const [selectedContentItems2, setSelectedContentItems2] = useState(defaultSelectedContentItems)
    const [modalSelectedContentItems1, setModalSelectedContentItems1] = useState(defaultSelectedContentItems)
    const [modalSelectedContentItems2, setModalSelectedContentItems2] = useState(defaultSelectedContentItems)
    const [updatedDateAndTime, setUpdatedDateAndTime] = useState({ startDateAndTime: '', endDateAndTime: '' })

    const {
      fetchNarratives,
      fetchWatchlist,
      narrativesListData,
      watchlistListData,
      narrativeLoading,
      paginationValues: searchPagination,
      setPaginationValues: setSearchPagination,
    } = store.vectorsModalStore
    const {
      assetsList,
      assetsLoading,
      fetchAssetsList,
      fetchCategories,
      assetsCategories,
      paginationValues: brandPagination,
      setPaginationValues: setBrandPagination,
    } = store.assetsStore

    const {
      flagsList,
      flagsLoading,
      fetchFlagsListWithConditions,
      paginationValues: flagsPagination,
      setPaginationValues: setFlagsPagination,
    } = store.flagsStore

    const { snippetsFilter, snippetsWithSurprises, setSnippetsFilter, defaultPlatforms, setActiveItem } =
      store.mainStore

    const onChange = (key: string) => {
      setSearchPagination({ ...searchPagination, page: 1 })
      setFlagsPagination({ ...flagsPagination, page: 1 })
      setBrandPagination({ ...brandPagination, page: 1 })
      setSelectedTab(key as CategoriesTypes)
    }

    const firstCallRequestParams = {
      page: 1,
      per_page: 10,
      sort: 'name:asc',
    }

    const formattedDate = dayjs(snippetsWithSurprises[0]?.date + 'Z')
      .utc()
      .local()
      .format('MMM DD, YYYY HH:mm')

    const brandCategory = assetsCategories?.find((item) => item.name === 'Brands')

    const filterKeys = { language: 'Language', platform: 'Platforms', sentiment: 'Sentiment', source: 'Content source' }

    const handleFiltersPayload = (filters: FilterTypes) => ({
      filter_platform: filters.platform?.join(',')?.toLowerCase(),
      filter_sentiment: filters.sentiment?.join(',')?.toLowerCase(),
      snippet_source: filters.source?.join(',')?.toLowerCase(),
      filter_language: filters.language?.join(',')?.toLowerCase(),
    })

    const onScroll = async () => {
      if (selectedTab === 'brand' && assetsList.total_count > assetsList.items?.length) {
        await fetchAssetsList({
          params: {
            ...firstCallRequestParams,
            page: brandPagination.page + 1,
            is_asset: true,
            is_active: true,
            q: `category_id:eq:${brandCategory?.category_id}`,
          },
          isInfiniteScrolling: true,
        })
        setBrandPagination({ ...brandPagination, page: brandPagination.page + 1 })
      }

      if (selectedTab === 'followedTopics' && flagsList.total_count > flagsList.items?.length) {
        await fetchFlagsListWithConditions({
          params: { ...firstCallRequestParams, page: flagsPagination.page + 1, is_active: true },
          isInfiniteScrolling: true,
        })
        setFlagsPagination({ ...flagsPagination, page: flagsPagination.page + 1 })
      }

      if (selectedTab === 'influencers' && watchlistListData.total_count > watchlistListData.items?.length) {
        await fetchWatchlist({
          params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
          isInfiniteScrolling: true,
        })
        setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
      }

      if (selectedTab === 'horizonTopics' && narrativesListData.total_count > narrativesListData.items?.length) {
        await fetchNarratives({
          params: { ...firstCallRequestParams, page: searchPagination.page + 1 },
          isInfiniteScrolling: true,
        })
        setSearchPagination({ ...searchPagination, page: searchPagination.page + 1 })
      }
    }

    const modifySelectionState = (existingData: OptionTypes[], newData: OptionTypes) => {
      const isAlreadyExist = existingData?.some((item) => item.value === newData.value)
      if (isAlreadyExist) return existingData?.filter((item) => item.value !== newData.value)
      return [...existingData, newData]
    }

    const handleModalSelectedContentItems = (values: OptionTypes) => {
      if (isTabsModalOpen1)
        setModalSelectedContentItems1({
          ...modalSelectedContentItems1,
          [values.category]: modifySelectionState(modalSelectedContentItems1[values.category] as OptionTypes[], values),
        })
      else
        setModalSelectedContentItems2({
          ...modalSelectedContentItems2,
          [values.category]: modifySelectionState(modalSelectedContentItems2[values.category] as OptionTypes[], values),
        })
    }

    const contentSelection1Conditions = Object.values(modalSelectedContentItems1)?.flatMap((item) =>
      item?.map((item) => item.conditions),
    )

    const contentSelection2Conditions = Object.values(modalSelectedContentItems2)?.flatMap((item) =>
      item?.map((item) => item.conditions),
    )

    const handleSnippetsAPICall = (filters: FilterTypes, conditions: Unknown) => {
      const modifiedConditions = !isObjectEmpty(conditions)
        ? ((Array.isArray(conditions) ? conditions : [conditions]) as Unknown)
        : []
      setSnippetsFilter({
        ...snippetsFilter,
        date_key: 'post_uploaded',
        ...handleFiltersPayload({
          ...filters,
          platform: filters.platform?.length ? filters.platform : defaultPlatforms?.split(','),
        }),
        conditions: modifiedConditions,
      })
      setActiveItem(modifiedConditions)
    }

    const handleContentFilters = ({
      filters,
      modalSelectedContentItems1,
      modalSelectedContentItems2,
      contentSelection1Conditions,
      contentSelection2Conditions,
      activeTab,
    }: {
      filters: FilterTypes
      modalSelectedContentItems1: DefaultSelectedContentItemsTypes
      modalSelectedContentItems2: DefaultSelectedContentItemsTypes
      contentSelection1Conditions: Unknown[]
      contentSelection2Conditions: Unknown[]
      activeTab?: TabTypes
    }) => {
      setFilters1(filters)
      setSelectedContentItems1(modalSelectedContentItems1)
      setSelectedContentItems2(modalSelectedContentItems2)
      setIsOpenContentModal(false)

      handleUpdateTabContent({
        filters: filters,
        isEnableAPICall: false,
        entities: modalSelectedContentItems1,
        refinedEntities: modalSelectedContentItems2,
        activeTab,
      })
      const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
      handleSnippetsAPICall(filters, conditions)
    }

    const handleApplyDate = () => {
      setSnippetsFilter({
        ...snippetsFilter,
        days: {
          ...snippetsFilter.days,
          custom: {
            isApplied: true,
            dateRange: [
              convertDateAndTimeToUTC(updatedDateAndTime.startDateAndTime),
              convertDateAndTimeToUTC(updatedDateAndTime.endDateAndTime),
            ],
          },
        },
        date_key: 'post_uploaded',
      })
      setIsCalendarOpen(false)
    }

    const clearDateFilter = () => {
      setSnippetsFilter({
        ...snippetsFilter,
        days: {
          ...snippetsFilter.days,
          custom: {
            isApplied: false,
            dateRange: [],
          },
        },
        date_key: 'post_uploaded',
      })
      setIsCalendarOpen(false)
    }

    const filterComponent = (contentFilterType: string) => {
      const onChangeContentSource = (event: any, value: string) => {
        if (!event.target.checked && filters.source.includes(value)) {
          const newSources = filters.source.filter((item: any) => value !== item)
          setFilters({ ...filters, source: newSources })
        } else {
          const newSources = [...filters.source, value]
          setFilters({ ...filters, source: newSources })
        }
      }

      const onChangeSentimentFilter = (event: any, value: string) => {
        if (!event.target.checked && filters.sentiment.includes(value)) {
          const newSources = filters.sentiment.filter((filter: any) => filter !== value)
          setFilters({ ...filters, sentiment: newSources })
        } else {
          const newSources = [...filters.sentiment, value]
          setFilters({ ...filters, sentiment: newSources })
        }
      }

      const onChangePlatformFilter = (event: any, value: string) => {
        if (!event.target.checked && filters.platform.includes(value)) {
          const newSources = filters.platform.filter((filter: any) => filter !== value)
          setFilters({ ...filters, platform: newSources })
        } else {
          const newSources = [...filters.platform, value]
          setFilters({ ...filters, platform: newSources })
        }
      }

      const onChangeLanguageFilter = (event: any, value: string) => {
        if (!event.target.checked && filters.language.includes(value)) {
          const newSources = filters.language.filter((filter) => value !== filter)
          setFilters({ ...filters, language: newSources })
        } else {
          const newSources = [...filters.language, value]
          setFilters({ ...filters, language: newSources })
        }
      }

      switch (contentFilterType) {
        case 'Content source':
          return <ContentSource selected={filters['source']} setSelected={onChangeContentSource} />
        case 'Sentiment':
          return <Sentiment selected={filters['sentiment']} setSelected={onChangeSentimentFilter} />
        case 'Platforms':
          return <PlatformDistribution selected={filters['platform']} setSelected={onChangePlatformFilter} />
        case 'Language':
          return <Language selected={filters['language']} setSelected={onChangeLanguageFilter} />
        default:
          return null
      }
    }

    const contentFilterState = (contentFilterType: string) => {
      switch (contentFilterType) {
        case 'Content source':
          return filters['source']
        case 'Sentiment':
          return filters['sentiment']
        case 'Platforms':
          return filters['platform']
        case 'Language':
          return filters['language']
        default:
          return []
      }
    }

    const handleRemoveFilters = (filters: FilterTypes, activeTab?: TabTypes) => {
      setFilters(filters)
      setFilters1(filters)
      handleUpdateTabContent({
        filters: filters,
        isEnableAPICall: false,
        entities: modalSelectedContentItems1,
        refinedEntities: modalSelectedContentItems2,
        activeTab,
      })
      const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
      handleSnippetsAPICall(filters, conditions)
    }

    const removeFilter = (event: any, contentFilterType: string, activeTab?: TabTypes, isUpdate?: boolean) => {
      event.stopPropagation()
      const filterFunctions = isUpdate ? handleRemoveFilters : setFilters
      const alreadyAppliedFilters = isUpdate ? filters1 : filters
      switch (contentFilterType) {
        case 'Content source':
          filterFunctions({ ...alreadyAppliedFilters, source: [] }, activeTab)
          break
        case 'Sentiment':
          filterFunctions({ ...alreadyAppliedFilters, sentiment: [] }, activeTab)
          break
        case 'Platforms':
          filterFunctions({ ...alreadyAppliedFilters, platform: [] }, activeTab)
          break
        case 'Language':
          filterFunctions({ ...alreadyAppliedFilters, language: [] }, activeTab)
          break
        default:
          return null
      }
    }

    const handleOpenDropdown2 = () => {
      setIsTabsModalOpen2(!isTabsModalOpen2)
      setIsTabsModalOpen1(false)
    }

    const handleOpenDropdown1 = () => {
      setIsTabsModalOpen1(!isTabsModalOpen1)
      setIsTabsModalOpen2(false)
    }

    const handleCancel = () => {
      setFilters(filters1)
      setModalSelectedContentItems1(selectedContentItems1)
      setModalSelectedContentItems2(selectedContentItems2)
      setIsOpenContentModal(false)
    }

    const handleRemoveTagFromDropdown1 = (key: string) =>
      setModalSelectedContentItems1({ ...modalSelectedContentItems1, [key]: [] })
    const handleRemoveTagFromDropdown2 = (key: string) =>
      setModalSelectedContentItems2({ ...modalSelectedContentItems2, [key]: [] })

    const removeTag = (category: CategoriesTypes, isContentGroup1: boolean, activeTab?: TabTypes) => {
      const updatedModalSelectedContentItems1 = isContentGroup1
        ? { ...modalSelectedContentItems1, [category]: [] }
        : modalSelectedContentItems1
      const updatedModalSelectedContentItems2 = !isContentGroup1
        ? { ...modalSelectedContentItems2, [category]: [] }
        : modalSelectedContentItems2

      setModalSelectedContentItems1(updatedModalSelectedContentItems1)
      setModalSelectedContentItems2(updatedModalSelectedContentItems2)

      setSelectedContentItems1(updatedModalSelectedContentItems1)
      setSelectedContentItems2(updatedModalSelectedContentItems2)

      const contentSelection1Conditions = Object.values(updatedModalSelectedContentItems1).flatMap((item) =>
        item?.map((item) => item.conditions),
      )
      const contentSelection2Conditions = Object.values(updatedModalSelectedContentItems2).flatMap((item) =>
        item?.map((item) => item.conditions),
      )
      handleUpdateTabContent({
        filters: filters,
        isEnableAPICall: false,
        entities: updatedModalSelectedContentItems1,
        refinedEntities: updatedModalSelectedContentItems2,
        activeTab,
      })
      const conditions = formatConditions(contentSelection1Conditions, contentSelection2Conditions)
      handleSnippetsAPICall(filters, conditions)
    }

    const assetsOptions = assetsList?.items?.map((asset) => ({ label: asset.name, value: asset.id, ...asset }))
    const narrativeOptions = narrativesListData?.items?.map((narrative) => ({
      label: narrative.name,
      value: narrative.id,
      ...narrative,
    }))
    const watchlistOptions = watchlistListData?.items?.map((watchlist) => ({
      label: watchlist.name,
      value: watchlist.id,
      ...watchlist,
    }))
    const followedTopicsOptions = flagsList?.items?.map((flag) => ({
      label: flag.name,
      value: flag.id,
      mode: 'flag',
      ...flag,
    }))

    const entityKeysToShowOnUI = {
      brand: 'Brands',
      followedTopics: 'Followed Topics',
      horizonTopics: 'Horizon Topics',
      influencers: 'Influencers',
    }

    const isContentSelected2 = Object.values(selectedContentItems2)?.some((item) => item?.length)
    const isContentSelected = Object.values(selectedContentItems1)?.some((item) => item?.length)
    const isFiltersSelected = Object.values(filters1)?.some((item) => item?.length)
    const isAnyContentSelected = isContentSelected || isContentSelected2 || isFiltersSelected
    const modalSelectedContentItems = isTabsModalOpen1 ? modalSelectedContentItems1 : modalSelectedContentItems2
    const isDateFilterApplied = snippetsFilter.days.custom.isApplied

    const items: TabsProps['items'] = [
      {
        key: 'brand',
        label: (
          <div className='custom-tab-label'>
            <span className='custom-tab-label__label'>Brands</span>
            {!isEmpty(modalSelectedContentItems.brand) && (
              <span className='custom-tab-label__counter'>{modalSelectedContentItems.brand?.length}</span>
            )}
          </div>
        ),
        children: (
          <PulseDDListOptions
            category='brand'
            onScroll={onScroll}
            loading={assetsLoading}
            options={assetsOptions || []}
            modalSelectedContentItems={modalSelectedContentItems}
            handleModalSelectedContentItems={handleModalSelectedContentItems}
          />
        ),
      },
      {
        key: 'horizonTopics',
        label: (
          <div className='custom-tab-label'>
            <span className='custom-tab-label__label'>Horizon Topics</span>
            {!isEmpty(modalSelectedContentItems.horizonTopics) && (
              <span className='custom-tab-label__counter'>{modalSelectedContentItems.horizonTopics?.length}</span>
            )}
          </div>
        ),
        children: (
          <PulseDDListOptions
            category='horizonTopics'
            onScroll={onScroll}
            loading={narrativeLoading}
            options={narrativeOptions || []}
            modalSelectedContentItems={modalSelectedContentItems}
            handleModalSelectedContentItems={handleModalSelectedContentItems}
          />
        ),
      },
      {
        key: 'followedTopics',
        label: (
          <div className='custom-tab-label'>
            <span className='custom-tab-label__label'>Followed Topics</span>
            {!isEmpty(modalSelectedContentItems.followedTopics) && (
              <span className='custom-tab-label__counter'>{modalSelectedContentItems.followedTopics?.length}</span>
            )}
          </div>
        ),
        children: (
          <PulseDDListOptions
            onScroll={onScroll}
            loading={flagsLoading}
            options={followedTopicsOptions}
            category='followedTopics'
            modalSelectedContentItems={modalSelectedContentItems}
            handleModalSelectedContentItems={handleModalSelectedContentItems}
          />
        ),
      },
      {
        key: 'influencers',
        label: (
          <div className='custom-tab-label'>
            <span className='custom-tab-label__label'>Influencers</span>
            {!isEmpty(modalSelectedContentItems.influencers) && (
              <span className='custom-tab-label__counter'>{modalSelectedContentItems.influencers?.length}</span>
            )}
          </div>
        ),
        children: (
          <PulseDDListOptions
            onScroll={onScroll}
            loading={narrativeLoading}
            options={watchlistOptions}
            category='influencers'
            modalSelectedContentItems={modalSelectedContentItems}
            handleModalSelectedContentItems={handleModalSelectedContentItems}
          />
        ),
      },
    ]

    useImperativeHandle(ref, () => ({
      setFilters: (value: FilterTypes) => setFilters(value),
      setFilters1: (value: FilterTypes) => setFilters1(value),
      setToggle: (value: boolean) => setIsOpenContentModal(value),
      setSelectedContentItems1: (values: PulseContentSelectionTypes) => setSelectedContentItems1(values as Unknown),
      setSelectedContentItems2: (values: PulseContentSelectionTypes) => setSelectedContentItems2(values as Unknown),
      setModalSelectedContentItems1: (values: PulseContentSelectionTypes) =>
        setModalSelectedContentItems1(values as Unknown),
      setModalSelectedContentItems2: (values: PulseContentSelectionTypes) =>
        setModalSelectedContentItems2(values as Unknown),
    }))

    useEffect(() => {
      const requestParams = {
        is_asset: true,
      }
      fetchCategories(requestParams)
    }, [])

    useEffect(() => {
      if (!isOpenContentModal) return
      const firstCallRequestParams = {
        page: 1,
        per_page: 10,
        sort: 'name:asc',
      }

      if (selectedTab === 'horizonTopics') {
        fetchNarratives({ params: firstCallRequestParams })
        setSearchPagination({ ...searchPagination, page: 1 })
      }

      if (selectedTab === 'followedTopics')
        fetchFlagsListWithConditions({ params: { ...firstCallRequestParams, is_active: true } })
      setFlagsPagination({ ...flagsPagination, page: 1 })

      if (selectedTab === 'influencers' && isOpenContentModal) {
        fetchWatchlist({ params: firstCallRequestParams })
        setSearchPagination({ ...searchPagination, page: 1 })
      }

      if (selectedTab === 'brand')
        fetchAssetsList({
          params: {
            ...firstCallRequestParams,
            is_asset: true,
            is_active: true,
            q: `category_id:eq:${brandCategory?.category_id}`,
          },
        })
    }, [isOpenContentModal, selectedTab])

    return (
      <Col span={6} className='pulse-dashboard__filters-container'>
        <div className='snippets-filters'>
          {!id && (
            <div className='snippets-filters__dropdown-filter-container'>
              <div className='snippets-filters__dropdown-filter-container__title'>Select your content</div>
              {!isAnyContentSelected ? (
                <Select
                  showArrow
                  size='large'
                  mode='tags'
                  open={false}
                  options={[]}
                  placeholder='Select content'
                  style={{ width: '100%' }}
                  suffixIcon={<DropdownIcon />}
                  onClick={() => setIsOpenContentModal(!isOpenContentModal)}
                  className='snippets-filters__dropdown-filter-container__select'
                />
              ) : (
                <>
                  <div className='selected-content-tag-container'>
                    {Object.entries(selectedContentItems1)?.map(([key, value], index) =>
                      isEmpty(value) ? null : (
                        <Tag
                          className='selected-content-tag'
                          onMouseDown={() => null}
                          closable={true}
                          onClose={() => removeTag(key as CategoriesTypes, true, activeTab)}
                          style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}
                          key={`content-selection${index}`}>
                          <span className='category'>
                            {entityKeysToShowOnUI[key as keyof typeof entityKeysToShowOnUI]}
                          </span>
                          {/* @ts-ignore */}
                          <span className='category-values'>{value?.map((item) => item.name).join(', ')}</span>
                        </Tag>
                      ),
                    )}
                  </div>
                  {isContentSelected2 && (
                    <>
                      {isContentSelected && <Divider style={{ border: 'none', color: '#D0D5DD' }}>AND</Divider>}
                      <div className='selected-content-tag-container'>
                        {Object.entries(selectedContentItems2)?.map(([key, value], index) =>
                          isEmpty(value) ? null : (
                            <Tag
                              className='selected-content-tag'
                              onMouseDown={() => null}
                              closable={true}
                              onClose={() => removeTag(key as CategoriesTypes, false)}
                              style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}
                              key={`refined-selection${index}`}>
                              <span className='category'>
                                {entityKeysToShowOnUI[key as keyof typeof entityKeysToShowOnUI]}
                              </span>
                              {/* @ts-ignore */}
                              <span className='category-values'>{value?.map((item) => item.name).join(', ')}</span>
                            </Tag>
                          ),
                        )}
                      </div>
                    </>
                  )}
                  <div className='selected-content-tag-container'>
                    {Object.entries(filters1)?.map(([key, value], index) =>
                      isEmpty(value) ? null : (
                        <Tag
                          className='selected-content-tag'
                          onMouseDown={() => null}
                          closable={true}
                          onClose={(e) => removeFilter(e, filterKeys[key as keyof typeof filterKeys], activeTab, true)}
                          style={{ marginInlineEnd: 4, borderStyle: 'dotted', borderRadius: '12px' }}
                          key={`filters${index}`}>
                          <span className='category'>{key}</span>
                          {/* @ts-ignore */}
                          <span className='category-values'>{value?.join(', ')}</span>
                        </Tag>
                      ),
                    )}
                  </div>
                </>
              )}

              {isAnyContentSelected && (
                <div className='filter-action-buttons'>
                  <Button type='primary' onClick={() => setIsOpenContentModal(true)}>
                    Edit Selection
                  </Button>
                  <Button
                    type='text'
                    onClick={() => {
                      handleUpdateTabContent({
                        isEnableAPICall: true,
                        filters: defaultFilters,
                        entities: defaultSelectedContentItems,
                        refinedEntities: defaultSelectedContentItems,
                        activeTab,
                      })
                    }}>
                    Clear All
                  </Button>
                </div>
              )}
            </div>
          )}
          {isAnyContentSelected && (
            <div className='snippets-filters__dropdown-filter-container'>
              <div className='snippets-filters__dropdown-filter-container__title'>Filter by date & time</div>
              {!isDateFilterApplied ? (
                <Select
                  showArrow
                  size='large'
                  mode='tags'
                  open={false}
                  options={[]}
                  placeholder='Select date & time'
                  style={{ width: '100%' }}
                  suffixIcon={<DropdownIcon />}
                  onClick={() => setIsCalendarOpen(!isOpenContentModal)}
                  className='snippets-filters__dropdown-filter-container__select'
                />
              ) : (
                <div className='date-selection'>
                  <div className='date-selection__container'>
                    <div className='date-selection__container__title'>Start date</div>
                    <div className='date-selection__container__value'>
                      {/* @ts-ignore */}
                      <div className='date-selection__container__value__date'>
                        {convertDateToLocal(updatedDateAndTime.startDateAndTime)}
                      </div>
                      <div className='date-selection__container__value__time'>
                        at {convertTimeToLocal(updatedDateAndTime.startDateAndTime)}
                      </div>
                    </div>
                  </div>
                  <div className='date-selection__container'>
                    <div className='date-selection__container__title'>End date</div>
                    <div className='date-selection__container__value'>
                      {/* @ts-ignore */}
                      <div className='date-selection__container__value__date'>
                        {convertDateToLocal(updatedDateAndTime.endDateAndTime)}
                      </div>
                      <div className='date-selection__container__value__time'>
                        at {convertTimeToLocal(updatedDateAndTime.endDateAndTime)}
                      </div>
                    </div>
                  </div>
                  <div className='filter-action-buttons'>
                    <Button type='primary' onClick={() => setIsCalendarOpen(true)}>
                      Edit
                    </Button>
                    <Button type='text' onClick={clearDateFilter}>
                      Clear Selection
                    </Button>
                  </div>
                </div>
              )}
              <Modal
                open={isCalendarOpen}
                destroyOnClose
                onOk={() => null}
                closable={true}
                onCancel={() => setIsCalendarOpen(false)}
                className='pulse-date-selection-modal'
                footer={
                  <div className='pulse-content-selection-modal__footer'>
                    <Button type='text' onClick={() => setIsCalendarOpen(false)}>
                      Cancel
                    </Button>
                    <Button type='primary' onClick={handleApplyDate}>
                      Confirm
                    </Button>
                  </div>
                }>
                <DateTimeRangeFilter setUpdatedDateAndTime={setUpdatedDateAndTime} subStore='main' />
              </Modal>
            </div>
          )}
        </div>
        <div className='snippets-stats'>
          {formattedDate && (
            <div className='snippets-stats__container'>
              <div className='snippets-stats__container__title'>Pendulum last update</div>
              <div className='snippets-stats__container__value'>{getTime(formattedDate)}</div>
            </div>
          )}
        </div>

        <PulseContentSelectionModal
          items={items}
          onChange={onChange}
          handleCancel={handleCancel}
          removeFilter={(event, content) => removeFilter(event, content, activeTab, false)}
          filterComponent={filterComponent}
          isTabsModalOpen2={isTabsModalOpen2}
          isTabsModalOpen1={isTabsModalOpen1}
          isOpenContentModal={isOpenContentModal}
          contentFilterState={contentFilterState}
          handleOpenDropdown2={handleOpenDropdown2}
          handleOpenDropdown1={handleOpenDropdown1}
          handleContentFilters={() =>
            handleContentFilters({
              filters,
              modalSelectedContentItems1,
              modalSelectedContentItems2,
              contentSelection1Conditions,
              contentSelection2Conditions,
              activeTab,
            })
          }
          modalSelectedContentItems1={modalSelectedContentItems1}
          modalSelectedContentItems2={modalSelectedContentItems2}
          handleRemoveTagFromDropdown1={handleRemoveTagFromDropdown1}
          handleRemoveTagFromDropdown2={handleRemoveTagFromDropdown2}
        />
      </Col>
    )
  }),
)

export default PulseLeftComponent
