import { Spin, Tooltip } from 'antd'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate, useLocation } from 'react-router-dom'

import { ReactComponent as AddIcon } from 'assets/images/icons/monitor/add_icon.svg'
import { ReactComponent as AlertIcon } from 'assets/images/icons/monitor/alert_icon.svg'
import { ReactComponent as EditIcon } from 'assets/images/icons/monitor/edit_box_icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/icons/monitor/delete_icon.svg'
import { ReactComponent as ReportIcon } from 'assets/images/icons/monitor/report_icon.svg'
import { ReactComponent as ShareIcon } from 'assets/images/icons/report/share-icon-size-16.svg'
import { ReactComponent as FilledNotificationIcon } from 'assets/images/icons/monitor/filled_notification_icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'

import AlertModal from 'components/AlertModal/AlertModal'
import { DataTranslating } from 'components/common/DataFetching/DataTranslating'
import SuccessModal from 'components/Flags/SuccessModal/SuccessModal'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import CreateNarrativeForm from '../CreateNarrative/CreateNarrativeForm/CreateNarrativeForm.page'
import ShareReportModal from 'components/Investigate/Reports/ShareReportModal/ShareReportModal'
import { SnippetList } from 'components/Monitor/SnippetList/SnippetList'
import { PowerInsights } from 'components/Monitor/PowerInsights/PowerInsights'
import { SnippetFilter } from 'components/Monitor/SnippetFilter/SnippetFilter'

import { store } from 'store'

import { IFilterChips, ListSelectedRowData } from 'models/models'
import { UtilService } from 'services/Util/Util'
import { EntityTypes } from 'store/asset/types'
import { Mode, SubStore, PageType, Page } from 'types/types'
import { ShareReportEndpoint } from 'store/report/valueTypes'
import { DetailsObjType } from 'types/types'
import DeleteConfirmationModal from 'components/Flags/DeleteConfirmationModal/DeleteConfirmationModal'
import { FilterChips } from 'components/Monitor/FilterChips/FilterChips'

import './CombineDetailsView.scss'
import { useUnmount } from 'react-use'
import { FeedsNoDataMessage } from 'components/Monitor/PowerInsights/NoDataMessages/NoDataMessages'
import { NarrativeInfoModal } from '../NarrativeInfo/NarrativeInfoModal'
import { CreatorDetailsPage } from 'components/Monitor/PowerInsights/Creators/CreatorDetailsPage/CreatorDetailsPage'

type ButtonEventType = 'ADD' | 'SHARE' | 'EDIT' | 'ALERT' | 'REPORT' | 'DELETE'

type ActionButtonObjType = {
  id: string
  title: string
  icon: React.ElementType
  eventName: ButtonEventType
  disabled: boolean
  elementTitle?: string
}

type CombineDetailsViewProps = {
  pageType: PageType
  subStore: SubStore
  page: Page
  className?: string
  isWatchListCreateMode?: boolean
}

export type ListNameType = '' | 'All' | 'Followed' | 'User' | 'Suggestions' | 'NarrativeLibrary'

const modeType = {
  narrative: 'Narrative',
  watchlist: 'Watchlist',
  community: 'Community',
  asset: 'Asset',
  channel: 'Channel',
  item: 'Item',
  bookmark: 'Bookmark',
  report: 'Report',
}

export const listName = {
  narrative: 'NarrativeLibrary',
  watchlist: 'Suggestions',
  community: 'Suggestions',
}

const shareModalTitle = {
  narrative: 'Share Narrative',
  watchlist: 'Share Watchlist',
}

const BreadcrumbTitle = (props: { title: string }) => {
  const { title } = props
  return <span className='cdvc_bt_text'>{title}</span>
}

const CombineDetailsView = observer((props: CombineDetailsViewProps) => {
  const { subStore, page, pageType, isWatchListCreateMode = false } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [alertId, setAlertId] = useState<string>('')
  const [entityId, setEntityId] = useState<string>('')
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openInfoModal, setOpenInfoModal] = useState<boolean>(false)
  const [updateConditions, setUpdateConditions] = useState<string>('')
  const [activePowerInsightTab, setActivePowerInsightTab] = useState('')
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [openAlertSuccessModal, setOpenAlertSuccessModal] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const {
    loaderStore,
    attentionFlagsStore,
    userStore,
    breadcrumbStore,
    defineNarrativeStore,
    defineWatchlistStore,
    tenantsStore,
  } = store
  const {
    openedCreatorId,
    setTenant,
    fetchPowerInsights,
    fetchSnippetsInfo,
    activeItem,
    snippetsTotal,
    snippetsFilter,
    setSnippetsFilter,
    deleteItem,
    setSelectedCommunityInfo,
    isTranslatingFeed,
    setSelectedExecutiveCategory,
    setIsTranslatingFeed,
    resetSnippetFilters,
    fetchSurpriseVideos,
    fetchUserBookmarkedSnippets,
    setIsFeedEmpty,
    setFilterChips,
    resetStore,
  } = store[`${subStore}Store`]

  const { tenantId } = userStore
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { setUserTenantId, fetchFlags } = attentionFlagsStore
  const detailObj = location.state as DetailsObjType | null

  const { isLoadingFeed } = loaderStore
  const { breadcrumbData, setBreadcrumbData } = breadcrumbStore

  const {
    formData,
    addFormData,
    getData,
    narrativeDetailsData,
    getSnippetFilterPayload,
    resetStore: reseNarrativeAndtWatchlistStore,
  } = detailObj?.mode === 'narrative' ? defineNarrativeStore : defineWatchlistStore

  const buttonHandler = (event: ButtonEventType) => {
    if (event === 'SHARE') {
      setIsShareModalOpen(true)
    }
    if (event === 'EDIT') {
      if (detailObj?.mode === 'watchlist' && detailObj?.id) {
        if (location.pathname === `/vectors/details`) {
          navigate(`/vectors/watchlist_builder/${detailObj?.id}`)
        } else {
          navigate(`/influencers/watchlist_builder/${detailObj?.id}`)
        }
      }

      setOpenEditModal(true)
    }
    if (event === 'ALERT') {
      setEntityId(narrativeDetailsData?.id || '')
      setAlertId(narrativeDetailsData?.alert_id || '')
    }
    if (event === 'DELETE') {
      setOpenDeleteModal(true)
    }
  }

  const actionButtonsArr: ActionButtonObjType[] = [
    { id: '0', title: 'Add to Folder', icon: AddIcon, eventName: 'ADD', disabled: true },
    { id: '1', title: 'Share', icon: ShareIcon, eventName: 'SHARE', disabled: false },
    { id: '2', title: 'Edit', icon: EditIcon, eventName: 'EDIT', disabled: false },
    {
      id: '3',
      title: 'Alerts',
      icon: narrativeDetailsData?.alert_id ? FilledNotificationIcon : AlertIcon,
      eventName: 'ALERT',
      disabled: false,
      elementTitle: 'Create an Alert',
    },
    {
      id: '4',
      title: 'Report',
      icon: ReportIcon,
      eventName: 'REPORT',
      disabled: true,
      elementTitle: 'Create an AI-Assisted Report',
    },
    {
      id: '5',
      title: 'Delete',
      icon: DeleteIcon,
      eventName: 'DELETE',
      disabled: false,
    },
  ]

  const combineDetailsViewDefaultFeedsFilters: IFilterChips = {
    attentionFlags: {
      label: 'Attention flags',
      value: '',
    },
    watchlist: { label: 'Watchlist', value: '' },
    community: { label: 'Community', value: '' },
    languageRisk: { label: 'Language Risk', value: '' },
    people: { label: 'People', value: '' },
    politician: { label: 'Politician', value: '' },
    country: { label: 'Country', value: '' },
    company: { label: 'Company', value: '' },
    hashtag: { label: 'Hash Tag', value: '' },
    cashtag: { label: 'Cash Tag', value: '' },
    handle: { label: 'Handle', value: '' },
    language: { label: 'Language', value: '' },
    assets: { label: 'Asset', value: '' },
    flags: { label: 'Flag', value: '' },
    creators: { label: 'Creators', value: [] },
  }

  const resetFilter = async () => {
    setSelectedCommunityInfo(undefined)
    setSelectedExecutiveCategory('All')
    resetSnippetFilters()
    setFilterChips(combineDetailsViewDefaultFeedsFilters)
    fetchSnippetsInfo(detailObj as DetailsObjType, false)
    if (detailObj) fetchPowerInsights({ ...detailObj, activeTab: activePowerInsightTab }, false)
  }

  const deleteHandler = () => {
    detailObj?.id &&
      deleteItem(detailObj as ListSelectedRowData).then((res: any | 'error') => {
        if (res !== 'error') {
          setOpenDeleteModal(false)
          navigate('../', { replace: true })
        }
      })
  }

  useEffect(() => {
    if (detailObj?.name && detailObj?.name !== 'Power Insights') {
      const updatedBreadCrumbs = breadcrumbData.filter((item) => item.titleKey !== detailObj?.name)
      setBreadcrumbData([
        ...updatedBreadCrumbs,
        {
          id: UtilService.generateUuid(),
          title: <BreadcrumbTitle title={detailObj?.name} />,
          titleKey: detailObj?.name,
        },
      ])
    }
  }, [detailObj?.name])

  useEffect(() => {
    if (detailObj === null) {
      navigate('../', { replace: true })
    }
    if (detailObj?.name) {
      if (detailObj?.languageKey) {
        setSnippetsFilter({
          ...snippetsFilter,
          filter_language: detailObj.languageKey === 'Default' ? 'en' : detailObj.languageKey,
        })
      }
      setSelectedCommunityInfo && setSelectedCommunityInfo(undefined)
      fetchSnippetsInfo(detailObj, false)
      fetchPowerInsights(detailObj, false)
    }
  }, [detailObj?.name, detailObj?.languageKey, JSON.stringify(detailObj?.conditions), updateConditions])

  useEffect(() => {
    setUserTenantId(tenantId || '')
    setTenant(tenantId || '')
  }, [tenantId])

  useEffect(() => {
    fetchFlags(true)
    fetchSurpriseVideos()
    fetchUserBookmarkedSnippets()
    return () => {
      setIsFeedEmpty(true)
      setFilterChips(combineDetailsViewDefaultFeedsFilters)
      resetStore()
      reseNarrativeAndtWatchlistStore()
    }
  }, [])

  useEffect(() => {
    if (!isLoadingFeed && isTranslatingFeed) setIsTranslatingFeed(false)
  }, [isLoadingFeed])

  useEffect(() => {
    if (detailObj?.id) {
      if (detailObj?.mode === 'narrative' || detailObj?.mode === 'watchlist') {
        getData(detailObj?.id)
      }
    }
  }, [detailObj?.mode, detailObj?.id])

  useEffect(() => {
    // wait for formData to populate before opening edit modal
    if (detailObj?.editMode && Object.keys(formData).length > 1) {
      setOpenEditModal(true)
    }
  }, [detailObj, formData])

  useUnmount(() => {
    const updatedBreadCrumbs = breadcrumbData.filter((item) => item.titleKey !== detailObj?.name)
    setBreadcrumbData(updatedBreadCrumbs)
    resetFilter()
  })

  /*  eslint-disable no-useless-computed-key */

  return (
    <div
      className={classNames('combine_details_view_container', {
        cdvc_main_container: page !== 'anticipatoryIntelligence',
        anticipatory_intelligence_container: page === 'anticipatoryIntelligence',
      })}>
      <div className='cdvc_left_container'>
        {openedCreatorId.length > 0 ? (
          <CreatorDetailsPage
            subStore={subStore}
            setActivePowerInsightTab={setActivePowerInsightTab}
            parentDetailObj={detailObj}
          />
        ) : (
          <div className='cdvc_lc_inner_container'>
            <div className='cdvc_lc_header'>
              <div className='cdvc_lc_h_title_container'>
                <p className='cdvc_lc_h_t_c_text'>{activeItem?.name}</p>
                {activeItem?.mode === 'narrative' && <InfoIcon onClick={() => setOpenInfoModal(true)} />}
              </div>
              {detailObj?.mode && detailObj?.mode !== 'asset' ? (
                <p className={`cdvc_lc_h_module_text cdvc_lc_h_module_text_${detailObj.mode}`}>
                  {changeLabelTextBasedOnTheme(modeType[detailObj.mode], isCorporateCommunicationsTheme)}
                </p>
              ) : null}
            </div>
            <div className='cdvc_lc_action_buttons'>
              {actionButtonsArr.map((btn) => (
                <Tooltip title={btn?.elementTitle || ''} placement='bottom'>
                  <ButtonImage
                    key={btn.id}
                    className='cdvc_lc_ab_button'
                    icon={btn.icon}
                    aria-label={btn?.elementTitle || ''}
                    onClick={() => {
                      buttonHandler(btn.eventName)
                    }}
                    disabled={
                      btn.disabled ||
                      (btn.eventName === 'EDIT' && detailObj?.disabledEditButton) ||
                      ((btn.eventName === 'EDIT' || btn.eventName === 'SHARE') &&
                        detailObj?.permission_level !== 'NOT_SPECIFIED' &&
                        detailObj?.permission_level !== 'EDITOR') ||
                      !detailObj?.id ||
                      !detailObj?.mode ||
                      (detailObj?.mode && detailObj.mode === 'community')
                    }>
                    {btn.title}
                  </ButtonImage>
                </Tooltip>
              ))}
            </div>
            <PowerInsights
              detailObj={detailObj as DetailsObjType}
              page={page}
              subStore={subStore}
              isWatchListCreateMode={isWatchListCreateMode}
              activePowerInsightTab={activePowerInsightTab}
              setActivePowerInsightTab={setActivePowerInsightTab}
            />
          </div>
        )}
      </div>
      <div
        className={classNames('cdvc_right_container', {
          ['cdvc_width']: page !== 'anticipatoryIntelligence',
        })}
        id='power_insights_snippet_container'>
        <div className='cdvc_rc_header'>
          <p className='cdvc_rc_h_title'>Snippets Feed</p>
          <p className='cdvc_rc_h_desc'>Cross-platform collection of content that represents a topic of interest.</p>
        </div>
        {/* eslint-enable*/}

        <FilterChips subStore={subStore} detailObj={detailObj} activePowerInsightTab={activePowerInsightTab} />
        <div className='cdvc_rc_snippet_container'>
          <SnippetFilter
            mode={detailObj?.mode as Mode}
            activeTab={activePowerInsightTab}
            page={page}
            pageType={pageType}
            subStore={subStore}
            searchPlaceholder='Search Posts...'
          />
          {isTranslatingFeed && isLoadingFeed && <DataTranslating />}
          {!isTranslatingFeed ? (
            <>
              <Spin
                className='cdvc_rc_sc_loader'
                wrapperClassName='cdvc_rc_sc_spin_wrapper'
                spinning={isLoadingFeed}
                size='large'>
                {!snippetsTotal && isWatchListCreateMode && detailObj?.mode === 'watchlist' ? (
                  <FeedsNoDataMessage />
                ) : (
                  <SnippetList
                    mode={detailObj?.mode as Mode}
                    showSearchChip={(snippetsFilter?.filter_keyword || '').length > 0}
                    searchedDataResults={snippetsTotal}
                    subStore={subStore}
                    uniqueScrollId='old-power-insights__id'
                  />
                )}
              </Spin>
            </>
          ) : null}
        </div>
      </div>
      {isShareModalOpen && detailObj?.id && (
        <ShareReportModal
          endpoint={detailObj?.mode as ShareReportEndpoint}
          reportId={detailObj?.id}
          title={shareModalTitle[detailObj?.mode as 'narrative' | 'watchlist']}
          desc='Invite team members'
          secondButtonText='Share'
          icon={null}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
      {openEditModal && detailObj?.mode === 'narrative' && detailObj?.id && (
        <div className='cdvc_narrative_form'>
          <CreateNarrativeForm
            onBackClick={() => {
              setOpenEditModal(false)
            }}
            onSearchClick={async (callback) => {
              const conditions = await getSnippetFilterPayload()
              const modifiedConditions: any = {}

              conditions.conditions.forEach((item: any) => {
                const key = Object.keys(item)[0]
                modifiedConditions[key] = item[key]
              })
              if (conditions.query.language) {
                const regex = /\[([^)]+)\]/
                const matches = regex.exec(conditions.query.language)
                if (matches && matches.length > 1) {
                  const contentInsideBrackets = matches[1]
                  const state = {
                    name: detailObj.name,
                    conditions: modifiedConditions,
                    mode: detailObj?.mode,
                    id: detailObj?.id,
                    editMode: detailObj?.editMode,
                    comingFrom: detailObj?.comingFrom,
                    pageNumber: detailObj?.pageNumber,
                    pageSize: detailObj?.pageSize,
                    languageKey: contentInsideBrackets,
                  }

                  navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
                  setUpdateConditions(`${JSON.stringify(modifiedConditions)}_${contentInsideBrackets}`)
                }
              } else {
                const state = {
                  name: detailObj.name,
                  conditions: modifiedConditions,
                  mode: detailObj?.mode,
                  id: detailObj?.id,
                  pageNumber: detailObj?.pageNumber,
                  pageSize: detailObj?.pageSize,
                  editMode: detailObj?.editMode,
                  comingFrom: detailObj?.comingFrom,
                }

                navigate(location.pathname, { state: JSON.parse(JSON.stringify(state)) })
                setUpdateConditions(JSON.stringify(modifiedConditions))
              }
              callback()
            }}
            formData={formData}
            addFormData={addFormData}
            onEditMode={true}
            narrativeID={detailObj?.id}
          />
        </div>
      )}

      {openInfoModal && (
        <div className='cdvc_narrative_info'>
          <NarrativeInfoModal onClose={setOpenInfoModal} />
        </div>
      )}
      {openAlertSuccessModal && (
        <SuccessModal
          title='Alert Created Successfully'
          desc='You will start receive new alerts according to the selected preferences'
          onClose={() => setOpenAlertSuccessModal(false)}
          closeButtonText='Continue'
        />
      )}

      <AlertModal
        entity_id={entityId}
        entity_type={detailObj?.mode === 'community' ? null : (detailObj?.mode?.toUpperCase() as EntityTypes)}
        open={!!entityId || !!alertId}
        alertId={alertId}
        onClose={() => {
          setEntityId('')
          setAlertId('')
        }}
        onSuccess={async () => {
          setOpenAlertSuccessModal(true)
          if (detailObj?.mode === 'narrative' || detailObj?.mode === 'watchlist') {
            getData(narrativeDetailsData?.id)
          }
        }}
        onDeleteSuccess={() => {
          if (detailObj?.mode === 'narrative' || detailObj?.mode === 'watchlist') {
            getData(narrativeDetailsData?.id)
          }
        }}
      />

      {openDeleteModal && detailObj?.id && (
        <DeleteConfirmationModal
          title={`Are you sure you want to delete selected ${modeType[detailObj?.mode || 'item'].toLowerCase()}?`}
          onClose={() => setOpenDeleteModal(false)}
          onContinue={deleteHandler}
        />
      )}
    </div>
  )
})

export default CombineDetailsView
