import { store } from 'store'
import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import EmptyState from './NewEmptyState'
import NewPieChart from './NewPieChart'

import { AssetListObjType } from 'store/asset/types'
import { NarrativeListDataType } from 'types/types'
import { ReactComponent as ArrowIcon } from 'assets/images/downArrow-icon.svg'

const NewBrandSentiment = ({
  subStore = 'assets',
  openSnippetDiscovery,
  selectedBrand,
  isFlexLegend,
  showMetricSelection,
  isUsedInModal = true,
}: {
  subStore?: 'assets' | 'vectors'
  selectedBrand: AssetListObjType | NarrativeListDataType
  openSnippetDiscovery?: (item: AssetListObjType | NarrativeListDataType) => void
  isFlexLegend?: boolean
  showMetricSelection?: boolean
  isUsedInModal?: boolean
}) => {
  const [hoveredCell, setHoveredCell] = useState('')
  const [selectedIndexLocal, setSelectedIndexLocal] = useState('')
  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions' | 'engagement'>('posts')
  const { graphColorPalette, powerInsightsData } = store[`${subStore}Store`]

  const { setSnippetsFilter, snippetsFilter, updateFilterChips, filterChips } =
    store[`${subStore}${isUsedInModal ? 'ModalStore' : 'Store'}`]

  const sentiments = powerInsightsData.lineChartData?.[activeMetric]?.sentiment.data_points
  const { statsLineChartLoading } = store.loaderStore

  const selectedSentiment = useMemo(() => {
    if (snippetsFilter?.filter_sentiment?.length === 0) return []
    return typeof snippetsFilter.filter_sentiment === 'string'
      ? snippetsFilter?.filter_sentiment?.split(',')
      : snippetsFilter?.filter_sentiment || []
  }, [snippetsFilter?.filter_sentiment])

  const sentimentsBreakDown = useMemo(() => {
    let totalPositive = 0
    let totalNegative = 0
    let totalNeutral = 0

    sentiments?.forEach((item) => {
      totalPositive += item.Positive ?? 0
      totalNegative += item.Negative ?? 0
      totalNeutral += item.Neutral ?? 0
    })
    return {
      data: [
        { name: 'Positive', total_value: totalPositive },
        { name: 'Negative', total_value: totalNegative },
        { name: 'Neutral', total_value: totalNeutral },
      ],
      totalCount: totalPositive + totalNegative + totalNeutral,
    }
  }, [sentiments, activeMetric])

  const onSentimentClicked = (sentiment: { name: string; total_value: number }) => {
    if (!sentiment) {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: '',
      })

      setSelectedIndexLocal('')
      if (!isUsedInModal) {
        openSnippetDiscovery?.({ ...selectedBrand, filter_sentiment: '' })
        updateFilterChips('languageRisk', '')
      }
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: sentiment.name,
      })
      setSelectedIndexLocal(sentiment.name)
      if (!isUsedInModal) updateFilterChips('languageRisk', sentiment.name)
      openSnippetDiscovery?.({ ...selectedBrand, filter_sentiment: sentiment.name })
    }

    setHoveredCell('')
  }

  useEffect(() => {
    if (!filterChips.languageRisk.value) {
      setSelectedIndexLocal('')
    }
  }, [filterChips, snippetsFilter])

  if (statsLineChartLoading) {
    return <EmptyState />
  }

  return (
    <div id='newBrandSentiment'>
      {showMetricSelection && (
        <Select
          onChange={(value) => setActiveMetric(value)}
          value={activeMetric}
          className='selectMetric'
          suffixIcon={<ArrowIcon />}>
          <Select.Option value='posts'>Mentions</Select.Option>
          <Select.Option value='impressions'>Impressions</Select.Option>
          <Select.Option value='engagement'>Engagement</Select.Option>
        </Select>
      )}

      <NewPieChart
        height={400}
        width={300}
        isFlexLegend={isFlexLegend}
        legendWidth={250}
        data={sentimentsBreakDown.data}
        colorPalette={graphColorPalette.Sentiment}
        totalCount={sentimentsBreakDown.totalCount}
        narrativeTotal={sentimentsBreakDown.totalCount}
        onClickCell={onSentimentClicked}
        showLabelsBasedOnSelection
        millifyStats
        hoveredCell={hoveredCell}
        setHoveredCell={setHoveredCell}
        selectedIndexLocal={selectedIndexLocal}
        syncSentimentFilterWithChart
        isUsedInModal={isUsedInModal}
        sentimentFilter={selectedSentiment.length === 1 ? selectedSentiment[0] : ''}
        snippetsFilter={snippetsFilter}
      />
    </div>
  )
}

export default observer(NewBrandSentiment)
