import { Modal, Spin, Switch } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'

import SearchInput from '../Inputs/SearchInput/SearchInput'
import { NarrativeParamsType } from 'types/types'
import UsersList from 'components/UsersList/UsersList'
import useDebounce from 'hooks/useDebounce'
import { reportsAPI } from 'api/api'
import { ROUTES } from 'settings/settings'
import { ReportRoutesType, ShareUsersResponse, UserListResponse } from 'store/report/valueTypes'
import { UtilService } from 'services/Util/Util'
import { SharedUserListObj, UserListObj } from 'store/asset/types'
import ShareUserDropdown, {
  ShareUserDataType,
} from 'components/Investigate/Reports/ShareUserDropdown/ShareUserDropdown'
import profileImage from 'assets/images/LogoiconMobile.svg'

import './SubscriptionModal.scss'

const PER_PAGE = 50
const dropdownData = [
  { id: '0', option: 'Daily', value: 'DAILY' },
  { id: '1', option: 'Weekly', value: 'WEEKLY' },
  { id: '2', option: 'Monthly', value: 'MONTHLY' },
  { id: '3', option: 'Yearly', value: 'YEARLY' },
]

const shareUsers: ShareUsersResponse = {
  owner: {
    family_name: 'Smith',
    given_name: 'Clara',
    user_id: 'id1',
    username: 'clara.smith@pendulum.co',
  },
  shared_users: [
    {
      family_name: '2',
      given_name: 'user',
      user_id: 'id2',
      username: 'user2@pendulum.co',
    },
    {
      family_name: '3',
      given_name: 'user',
      user_id: 'id3',
      username: 'user3@pendulum.co',
    },
    {
      family_name: '4',
      given_name: 'user',
      user_id: 'id4',
      username: 'user4@pendulum.co',
    },
  ],
  subscribed_users: [],
  total_count: 3,
}

type SubscriptionModalProps = {
  open: boolean
  onClose: () => void
}

export const SubscriptionModal = (props: SubscriptionModalProps) => {
  const { open, onClose } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [users, setUsers] = useState<UserListResponse>({
    total_count: 0,
    items: [],
  })
  const [usersPage, setUsersPage] = useState<number>(2)
  const debouncedSearchValue = useDebounce(searchValue)
  const [subscribedUsers, setSubscribedUsers] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<SharedUserListObj[]>([])

  useEffect(() => {
    const requestParams = {
      page: 1,
      per_page: PER_PAGE,
    }
    fetchUsersWithParams(requestParams)
  }, [])

  const searchDropHandler = useCallback(
    (val: boolean) => {
      setIsOpen(val)
    },
    [isOpen],
  )

  const fetchUsersWithParams = async (params: NarrativeParamsType) => {
    try {
      setIsFetching(true)
      const usersData = await reportsAPI.getListsWithParams({
        endpoint: ROUTES.listUsers as ReportRoutesType,
        params,
      })
      setUsers(usersData.data)
      setIsFetching(false)

      return usersData.data
    } catch (error: any) {
      setIsFetching(false)
      const response = error.response
      UtilService.openError({
        requestId: response?.data?.request_id || '',
        statusCode: response?.status || 400,
        message: response?.data?.err_msg || '',
      })
      return 'error'
    }
  }

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    const sumScroll = scrollTop + clientHeight
    if (sumScroll === scrollHeight || Math.ceil(sumScroll) === scrollHeight || Math.floor(sumScroll) === scrollHeight) {
      if (users.total_count !== users.items?.length && !isFetching) {
        setIsFetching(true)
        const requestParams = {
          page: usersPage,
          per_page: PER_PAGE,
        }
        fetchUsersWithParams(requestParams).then((res: UserListResponse | 'error') => {
          setIsFetching(false)
          if (res !== 'error' && [...users.items, ...res.items]?.length !== res.total_count) {
            setUsersPage((prevPage) => prevPage + 1)
          }
        })
      }
    }
  }

  const newUsers = useMemo(() => {
    if (users.items?.length && 'shared_users' in shareUsers && shareUsers.shared_users?.length) {
      return users.items.filter(
        (user) => !shareUsers.shared_users.some((res: SharedUserListObj) => res.user_id === user.id),
      )
    }
    return users.items
  }, [users, shareUsers?.shared_users])

  const filterUsers = useMemo(() => {
    if (debouncedSearchValue && users.items?.length) {
      return newUsers.filter(
        (user) =>
          user.given_name.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      )
    }
    return newUsers
  }, [debouncedSearchValue, newUsers])

  const updateSubscribedUsers = (id: string, status: boolean) => {
    const hasUser = subscribedUsers.includes(id)
    if (status && !hasUser) {
      setSubscribedUsers((prev) => [...prev, id])
    } else if (!status && hasUser) {
      const otherUsers = subscribedUsers.filter((value: string) => value !== id)
      setSubscribedUsers(otherUsers)
    }
  }

  const selectUserHandler = (userInfo: UserListObj) => {
    const haveUser = selectedUsers.some((user) => user.user_id === userInfo.id)
    if (haveUser) {
      const removeUser = selectedUsers.filter((user) => user.user_id !== userInfo.id)
      setSelectedUsers(removeUser)
      searchDropHandler(false)
      return
    }
    const addUser = {
      family_name: userInfo.family_name,
      given_name: userInfo.given_name,
      permission_level: 'VIEWER',
      user_id: userInfo.id,
      username: userInfo.username,
      localAdd: true,
    }

    setSelectedUsers((prevState: SharedUserListObj[]) => [...prevState, addUser as SharedUserListObj])
    searchDropHandler(false)
  }

  return (
    <Modal
      open={open}
      className='subscription-modal'
      title={
        <div className='header'>
          <span className='header__title'>Subscribe members</span>
          <span className='header__description'>Subscribe members to receive email notiffications.</span>
        </div>
      }
      centered
      onCancel={() => {
        onClose?.()
      }}
      okText={'Confirm'}>
      <Spin spinning={isFetching} className='subscription-modal___modal_container'>
        <div className='subscription-modal___modal_container'>
          <div className='subscription-modal___modal_container__container'>
            <span className='search-text '>Search</span>
            <SearchInput
              placeholder='Search by name or email'
              showIcon={true}
              className='srmc_input'
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              onClear={() => setSearchValue('')}
              showDrop={true}
              isOpen={isOpen}
              value={searchValue}
              setIsOpen={searchDropHandler}
              onScroll={handleScroll}>
              <div className='srmc_input_content_container'>
                <UsersList data={filterUsers} className='srmc_input_cc_chunk' onClick={selectUserHandler} />
              </div>
            </SearchInput>
          </div>

          <div className='share_report_content_container'>
            {'shared_users' in shareUsers && shareUsers.shared_users?.length ? (
              <SharedUsersList
                data={shareUsers.shared_users}
                subscribedUsers={subscribedUsers}
                onClick={() => {}}
                onSelect={() => {}}
                updateSubscribedUsers={updateSubscribedUsers}
              />
            ) : null}
            {selectedUsers?.length ? (
              <SharedUsersList
                data={selectedUsers}
                subscribedUsers={subscribedUsers}
                onClick={() => {}}
                onSelect={() => {}}
                updateSubscribedUsers={updateSubscribedUsers}
              />
            ) : null}
          </div>
        </div>
      </Spin>
    </Modal>
  )
}
type SelectedValueAndUserObj = {
  selectedValue: ShareUserDataType
  selectedUser: SharedUserListObj
}

type SharedUsersListProps = {
  isReport?: boolean
  data?: SharedUserListObj[]
  subscribedUsers: string[]
  className?: string
  type?: string
  onClick: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
  updateSubscribedUsers: (id: string, status: boolean) => void
}

type SharedUserListChunkProps = {
  isReport?: boolean
  className?: string
  [x: string]: any
  onClick?: (id: string) => void
  onSelect?: ({ selectedValue, selectedUser }: SelectedValueAndUserObj) => void
  updateSubscribedUsers: (id: string, status: boolean) => void
}

const SharedUsersList = (props: SharedUsersListProps) => {
  const { data = [], type = 'share', onClick, onSelect, isReport, subscribedUsers, updateSubscribedUsers } = props
  return (
    <div>
      {data.map((user: any) => {
        const isSubscribed = subscribedUsers?.includes(user.user_id)

        return (
          <SharedUserListChunk
            key={user.user_id}
            {...{ ...user, isSubscribed }}
            onClick={onClick}
            onSelect={onSelect}
            type={type}
            isReport={isReport}
            updateSubscribedUsers={updateSubscribedUsers}
          />
        )
      })}
    </div>
  )
}

const SharedUserListChunk = (props: SharedUserListChunkProps) => {
  const { className, onClick, onSelect, isReport, updateSubscribedUsers, ...rest } = props
  const findObj = dropdownData.find((res) => res.value === rest.permission_level) || dropdownData[0]

  return (
    <div className={`users_list_container ${className}`}>
      <div className='ulc_image_container'>
        <img
          src={UtilService.isImageUrl(rest?.avatar_url) ? rest?.avatar_url : profileImage}
          alt='danger'
          className='ulc_image'
        />
      </div>
      <div className='ulc_content'>
        <div className='ulc_c_name_container'>
          <p className='ulc_c_name'>{rest?.given_name}</p>

          <div className='ulc_c_info'>
            <ShareUserDropdown
              data={dropdownData}
              closeWhenClick={true}
              value={findObj.option}
              selectedValue={findObj}
              onSelect={(val) => {
                onSelect?.({ selectedValue: val as ShareUserDataType, selectedUser: rest as SharedUserListObj })
              }}
              onRemove={() => onClick?.(rest?.user_id)}
            />
            <Switch
              size={'small'}
              className='mail-switch'
              checked={rest.isSubscribed}
              onChange={(value) => {
                updateSubscribedUsers(rest.user_id, value)
              }}
            />
          </div>
        </div>
        <p className='ulc_c_email'>{rest?.username}</p>
      </div>
    </div>
  )
}
