import axios from 'axios'
import { ROUTES } from 'settings/settings'

interface Props {
  entity_type: string
  entity_id: string
  new_owner_id: string
}

export const transferOwnership = async (body: Props) => {
  const { data } = await axios.post(ROUTES.transferOwnership, body)
  return data
}
