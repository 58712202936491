import { useEffect, useState } from 'react'
import { Button, Modal, Skeleton, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { ReactComponent as CloseIcon } from 'assets/images/icon-close.svg'
import issueImage from 'assets/images/icons/dashboard/snippet-discovery-bg.png'

import Stats from './Stats'
import { AssetListObjType } from 'store/asset/types'
import { FlagListObjType } from 'store/flags/types'
import { flagsAPI, powerInsightsAPI } from 'api/api'
import { IBarChartData, SnippetFilterPayload } from 'models/models'
import { DetailsObjType, NarrativeListDataType } from 'types/types'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import classNames from 'classnames'
import { openNotification } from 'services/Util/openNotification'
import usePermissions from 'hooks/usePermissions'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'
import TopicDetails from '../TopicDetails/TopicDetails'
import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/dashboard/empty-state.svg'
import './SnippetsDiscovery.scss'

type PropsType = {
  onClose: () => void
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  itemToView?: AssetListObjType | FlagListObjType | IBarChartData | undefined
  subStore?: 'assets' | 'vectors'
  itemToViewType?: string
  setItemToView?: any
  onEditCurrentItemToView?: any
  activeItem: DetailsObjType
}
const SnippetsDiscovery = (props: PropsType) => {
  const [summary, setSummary] = useState<string>('')
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)
  const [summaryFetchingError, setSummaryFetchingError] = useState(false)
  const [followTopicLoading, setFollowTopicLoading] = useState(false)
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const canCreateTopic = usePermissions('flags') === 'create'

  const {
    activeItem,
    onClose,
    selectedItem,
    itemToView,
    subStore = 'assets',
    itemToViewType,
    setItemToView = () => {},
    onEditCurrentItemToView,
  } = props

  const { getSnippetFilterPayload, snippetsFilter, setActiveItem } = store[`${subStore}ModalStore`]
  const { isLoadingFeed } = store.loaderStore

  const getExecutiveSummary = async () => {
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    if (!payload) return
    try {
      payload.conditions = payload.conditions?.filter((item) => item)
      const shortSummary = true
      const { data } = await powerInsightsAPI.summary.getSummaryV2(payload, shortSummary)
      if (!data.response_code) {
        setSummary(data.sections[0].summary)
      } else if (data.response_code === 'NO_DATA') {
        setSummary('No summary to display')
      } else {
        setSummaryFetchingError(true)
      }
    } catch (error) {
      setSummaryFetchingError(true)
    } finally {
      setIsLoadingSummary(false)
    }
  }
  useEffect(() => {
    setActiveItem(activeItem)
    getExecutiveSummary()
  }, [snippetsFilter])

  const retryFetchingSummary = () => {
    setIsLoadingSummary(true)
    setSummaryFetchingError(false)
    getExecutiveSummary()
  }

  const handleFollowUnFollow = async (flagId: string, isActive: boolean) => {
    const newStatus = isActive ? 'deactivate' : 'activate'
    const actionMessage = isActive ? 'unfollowed' : 'followed'
    try {
      setFollowTopicLoading(true)
      await flagsAPI.toggleFlagStatus({ ids: [flagId], status: newStatus })
      setItemToView({ ...itemToView, is_active: !isActive })
      openNotification({ type: 'info', message: `Topic ${actionMessage} successfully` })
    } catch (error) {
      openNotification({ type: 'error', message: 'Failed to follow topic' })
    } finally {
      setFollowTopicLoading(false)
    }
  }

  return (
    <Modal
      open={true}
      className='snippets-discovery'
      footer={null}
      closable={false}
      destroyOnClose
      style={{ top: 0, bottom: 0, margin: 0, padding: 0, right: 0 }}
      width='calc(100% - 70px)'>
      <div className='content'>
        <div className='header'>
          <h4>Overview</h4>
          <div className='collapse-option' onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className='body'>
          <div className='summary-section'>
            <div className='summary-image'>
              <img src={issueImage} alt='display' />
              <div className='summary-image__content'>
                <h4>{itemToView ? itemToView?.name : selectedItem?.name}</h4>
                {itemToViewType === 'flag' && (
                  <>
                    <h5 onClick={() => setIsOpenDetails(true)}>
                      View details <InfoIcon width={15} height={15} />
                    </h5>
                    {isOpenDetails && (
                      <TopicDetails
                        open={isOpenDetails}
                        onClose={() => setIsOpenDetails(false)}
                        details={{
                          ...itemToView,
                          boolean_query: itemToView?.insight_condition || '',
                          multi_lang_kwd_expression: {
                            en: itemToView?.insight_condition || '',
                          },
                          narrative_type: 'SEARCH_TERM',
                          owner_id: itemToView?.created_by,
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div className='summary-section__details'>
              <Stats selectedItem={selectedItem} subStore={subStore} isForModal />
              {!isLoadingSummary && !summaryFetchingError && <div className='summary'>{summary}</div>}
              {isLoadingSummary && <Skeleton paragraph active className='summary-empty-state' />}
              {!isLoadingSummary && summaryFetchingError && (
                <div className='summary-error'>
                  <span className='summary-error-text'>Failed to load the summary. Please try again now or later.</span>
                  <button className='summary-retry-error' onClick={retryFetchingSummary}>
                    Retry
                  </button>
                </div>
              )}
              <Spin spinning={followTopicLoading}>
                {itemToViewType === 'flag' && (
                  <div className='flag-actions'>
                    {itemToView?.is_active && (
                      <Button
                        className='followed-issue-card__follow-btn'
                        onClick={() => onEditCurrentItemToView(itemToView)}
                        disabled={!canCreateTopic}
                        style={{ cursor: !canCreateTopic ? 'not-allowed' : 'pointer' }}>
                        <EditIcon /> Edit
                      </Button>
                    )}
                    <Button
                      className={classNames('followed-issue-card__follow-btn', {
                        'followed-issue-card__unfollow-btn': itemToView?.is_active,
                      })}
                      onClick={(e) => {
                        if (itemToView?.id || itemToView?.is_active) {
                          handleFollowUnFollow?.(itemToView.id, Boolean(itemToView.is_active))
                        }
                      }}
                      disabled={followTopicLoading}>
                      {itemToView?.is_active ? 'Unfollow' : 'Follow'}
                    </Button>
                  </div>
                )}
              </Spin>
            </div>
          </div>
          <div className='snippets-section'>
            <Spin spinning={isLoadingFeed}>
              <div className='snippets-section__snippets'>
                <SnippetListWithFilters
                  activePowerInsightTab='channel'
                  subStore={subStore}
                  uniqueScrollId='snippets_discovery_id'
                  emptyStateDescription={''}
                  emptyStateIcon={<EmptyStateIcon />}
                  emptyStateTitle={'No snippets found'}
                  isCustomEmptyState
                  isForModal
                />
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default observer(SnippetsDiscovery)
