import dayjs from 'dayjs'
import millify from 'millify'
import * as echarts from 'echarts'
import { Select, Spin } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'

import InfoTooltip from 'components/Asset/BrandsDashboard/components/InfoTooltip/InfoTooltip'
import CustomSwitch from 'components/Asset/BrandsDashboard/components/MentionsViewsSwitch/MentionsViewsSwitch'

import { store } from 'store'
import { sourcePlatform } from 'models/models'

import { ReactComponent as ArrowIcon } from 'assets/images/downArrow-icon.svg'

import { SubStore } from 'types/types'
import { Unknown } from 'utils/commonTypes'
import { NarrativeListDataType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'

import './NewMonitorGraph.scss'

interface Props {
  subStore: SubStore
  dataLoading: boolean
  resizeChartTrigger: boolean
  applyDateRangeFilter: (startDate: string, endDate: string) => void
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
}

interface LineChartData {
  posts: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
  impressions: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
  engagement: {
    all: { data_points: any[] }
    sentiment: { data_points: any[] }
  }
}

const NewMonitorGraph = observer(
  ({ subStore, dataLoading = false, resizeChartTrigger, applyDateRangeFilter }: Props) => {
    const { fetchPowerInsightsLineChartDataForBrandPage, snippetsFilter, activeItem } = store[`${subStore}Store`]
    const [lineChartData, setLineChartData] = useState<LineChartData>({
      posts: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
      },
      impressions: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
      },
      engagement: {
        all: { data_points: [] },
        sentiment: { data_points: [] },
      },
    })
    const {
      days: { noOfDays },
    } = snippetsFilter

    const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions' | 'engagement'>('posts')
    const [activePivot, setActivePivot] = useState<'all' | 'sentiment'>('all')
    const [disabledChartLines, setDisabledChartLines] = useState<
      Array<Lowercase<sourcePlatform> | 'Positive' | 'Neutral' | 'Negative'>
    >([])

    const chartRef = useRef<HTMLDivElement | null>(null)

    const fetchData = () => {
      fetchPowerInsightsLineChartDataForBrandPage().then((data) => {
        setLineChartData(data)
      })
    }

    const getTransparentColor = (hex: string, alpha: number = 0): string => {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)

      return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    useEffect(() => {
      if (activeItem) {
        fetchData()
      }
    }, [activeItem])

    useEffect(() => {
      setDisabledChartLines([])
    }, [activeMetric, activePivot])

    useEffect(() => {
      if (!chartRef.current) return

      const chartInstance = echarts.init(chartRef.current)

      const commonSeries = {
        type: 'line',
        // smooth: true,
        symbolSize: 12,
        connectNulls: true,
        smooth: 1,
        emphasis: {
          focus: 'series',
        },
      }

      const series = []

      if (activePivot === 'all') {
        const dataPoints = lineChartData[activeMetric][activePivot].data_points

        const major = dataPoints.map((item, index) =>
          index <= dataPoints.length - noOfDays ? item.metric_value : null,
        )
        const filtered = dataPoints.map((item, index) =>
          index >= dataPoints.length - noOfDays ? item.metric_value : null,
        )

        series.push(
          {
            ...commonSeries,
            data: major,
            name: 'Past',
            lineStyle: {
              color: '#EE46BC',
            },
            itemStyle: { color: '#EE46BC' },
            showSymbol: false,
            emphasis: {
              focus: 'series',
              itemStyle: {
                opacity: 1,
              },
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#EE46BC' },
                { offset: 1, color: getTransparentColor('#EE46BC') },
              ]),
            },
          },
          {
            ...commonSeries,
            data: filtered,
            name: 'Current period',
            lineStyle: { color: '#7A5AF8' },
            itemStyle: { color: '#7A5AF8' },
            showSymbol: false,
            emphasis: {
              focus: 'series',
              itemStyle: {
                opacity: 1,
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#7A5AF8' },
                { offset: 1, color: getTransparentColor('#7A5AF8') },
              ]),
            },
          },
        )
      } else if (activePivot === 'sentiment') {
        const dataPoints = lineChartData[activeMetric][activePivot].data_points
        const majorPositive = dataPoints.map((item, index) =>
          index <= dataPoints.length - noOfDays ? item.Positive : null,
        )
        const filteredPositive = dataPoints.map((item, index) =>
          index >= dataPoints.length - noOfDays ? item.Positive : null,
        )

        const majorNegative = dataPoints.map((item, index) =>
          index <= dataPoints.length - noOfDays ? item.Negative : null,
        )

        const filteredNegative = dataPoints.map((item, index) =>
          index >= dataPoints.length - noOfDays ? item.Negative : null,
        )

        const majorNeutral = dataPoints.map((item, index) =>
          index <= dataPoints.length - noOfDays ? item.Neutral : null,
        )
        const filteredNeutral = dataPoints.map((item, index) =>
          index >= dataPoints.length - noOfDays ? item.Neutral : null,
        )

        series.push(
          {
            ...commonSeries,
            data: majorPositive,
            name: 'Positive',
            lineStyle: { color: noOfDays === 1 ? '#17B26A' : '#EE46BC' },
            itemStyle: { color: '#17B26A' },
            hidden: disabledChartLines.includes('Positive'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#17B26A' },
                { offset: 1, color: 'rgba(204, 165, 90, 0)' },
              ]),
            },
          },
          {
            ...commonSeries,
            data: filteredPositive,
            name: 'Positive',
            lineStyle: { color: '#17B26A' },
            itemStyle: { color: '#17B26A' },
            hidden: disabledChartLines.includes('Positive'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#17B26A' },
                { offset: 1, color: getTransparentColor('#18B368') },
              ]),
            },
          },
          {
            ...commonSeries,
            data: majorNeutral,
            name: 'Neutral',
            lineStyle: { color: noOfDays === 1 ? '#D0D5DD' : '#EE46BC' },
            itemStyle: { color: '#D0D5DD' },
            hidden: disabledChartLines.includes('Neutral'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#D0D5DD' },
                { offset: 1, color: getTransparentColor('#D0D5DD') },
              ]),
            },
          },
          {
            ...commonSeries,
            data: filteredNeutral,
            name: 'Neutral',
            lineStyle: { color: '#D0D5DD' },
            itemStyle: { color: '#D0D5DD' },
            hidden: disabledChartLines.includes('Neutral'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#D0D5DD' },
                { offset: 1, color: getTransparentColor('#D0D5DD') },
              ]),
            },
          },
          {
            ...commonSeries,
            data: majorNegative,
            name: 'Negative',
            lineStyle: { color: noOfDays === 1 ? '#F04438' : '#EE46BC' },
            itemStyle: { color: '#F04438' },
            hidden: disabledChartLines.includes('Negative'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#F04438' },
                { offset: 1, color: getTransparentColor('#F04438') },
              ]),
            },
          },
          {
            ...commonSeries,
            data: filteredNegative,
            name: 'Negative',
            lineStyle: { color: '#F04438' },
            itemStyle: { color: '#F04438' },
            hidden: disabledChartLines.includes('Negative'),
            showSymbol: false,
            emphasis: {
              scale: true,
              focus: 'series',
            },
            hoverAnimation: true,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#F04438' },
                { offset: 1, color: getTransparentColor('#F04438') },
              ]),
            },
          },
        )
      }

      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params: Unknown) {
            let validData = params.filter((item: Unknown) => item.data !== null && item.data !== undefined)

            if (validData.length > 0) {
              let date = params[0].axisValue
              let tooltipContent =
                `<strong>${date}</strong><br/>` +
                validData.map((item: Unknown) => `${item.marker}${item.seriesName}: ${item.data}`).join('<br/>')
              return tooltipContent
            }

            return null
          },
        },
        legend: {
          data: series.map((s) => s.name),
          // @ts-ignore
          selected: series.reduce((acc, s) => ({ ...acc, [s.name]: !s.hidden }), {}),
          orient: 'horizontal',
          left: 'left',
          bottom: 0,
          icon: 'circle',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 24,
          textStyle: {
            fontfamily: 'Roboto',
            fontSize: 14,
            color: '#3B3934',
            fontWeight: 500,
            padding: [0, 0, 0, 4],
          },
        },
        xAxis: {
          type: 'category',
          data: lineChartData[activeMetric][activePivot].data_points.map((item) =>
            dayjs(item.date).format('YYYY-MM-DD'),
          ),
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              height: 1,
              color: '#EAECF0',
            },
          },
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: (value: string, index: number) => {
              const dates = lineChartData[activeMetric][activePivot].data_points.map((item) => item.date)
              const startDate = dayjs(dates[0])
              const endDate = dayjs(dates[dates.length - 1])
              const daysDifference = endDate.diff(startDate, 'day')

              if (daysDifference <= 31) {
                return dayjs(value).format('DD MMM YYYY')
              } else {
                return dayjs(value).format('MMM YYYY')
              }
            },
            color: '#8598AD',
            fontfamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
          },
        },
        grid: {
          top: 30,
          bottom: 60,
          left: 30,
          right: 0,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: '#EAECF0',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#EAECF0',
              width: 1,
            },
          },
          type: 'value',
          axisLabel: {
            formatter: (value: number) => (Number.isFinite(value) ? millify(value || 0) : '0'),
            color: '#8598AD',
            fontfamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
          },
        },
        series,
      }

      chartInstance.setOption(option)

      chartInstance.on('click', (params) => {
        if (params.componentType === 'series') {
          const clickedDate = params.name
          applyDateRangeFilter(clickedDate, clickedDate)
        }
      })

      const handleResize = () => {
        chartInstance.resize()
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        chartInstance.dispose()
      }
    }, [activeMetric, activePivot, disabledChartLines, resizeChartTrigger, lineChartData, noOfDays])

    return (
      <div className={`componentWrapper new-trend-graph`}>
        <div className='chart-heading'>
          <span className='monitor-assets__graph__title'>Trends</span>
          <span className='chart-heading__info'>
            <InfoTooltip
              text='This widget helps you track the volume of your mentions, impressions and engagements and impressions over time. You can also filter this widget by sentiment, which lets you view the volume of your mentions over time broken down by whether those mentions are positive, neutral and negative.'
              width={20}
              height={20}
            />
          </span>
        </div>
        <br />
        <Spin spinning={dataLoading} style={{ minHeight: '350px' }}>
          <div className={'headerWrapper'}>
            <div className={'chartFilters'}>
              <div className={'metric'}>
                <Select
                  onChange={(value) => setActiveMetric(value)}
                  value={activeMetric}
                  className={'selectMetric'}
                  suffixIcon={<ArrowIcon />}>
                  <Select.Option value='posts'>Mentions</Select.Option>
                  <Select.Option value='impressions'>Impressions</Select.Option>
                  <Select.Option value='engagement'>Engagement</Select.Option>
                </Select>
              </div>

              <div className={'pivot'}>
                <CustomSwitch
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Sentiment', value: 'sentiment' },
                  ]}
                  onUpdateView={(event: any) => setActivePivot(event.target.value)}
                  view={activePivot}
                />
              </div>
            </div>
          </div>

          <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
        </Spin>
      </div>
    )
  },
)

export default NewMonitorGraph
