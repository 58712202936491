import React from 'react'
import { Helmet } from 'react-helmet'
import { store } from 'store'

const BeaconIntegration: React.FC = () => {
  const authToken = store.userStore.authToken

  if (!authToken)
    return (
      <Helmet>
        <script type='text/javascript'>{`window?.Beacon('destroy', '10ad3ee9-81a3-46ba-b494-c9b08d7d2714')`}</script>
      </Helmet>
    )

  return (
    <>
      <Helmet>
        <script type='text/javascript'>
          {`
            !function(e,t,n){
              function a(){
                var e=t.getElementsByTagName("script")[0],
                    n=t.createElement("script");
                n.type="text/javascript",
                n.async=!0,
                n.src="https://beacon-v2.helpscout.net",
                e.parentNode.insertBefore(n,e);
              }
              if(e.Beacon=n=function(t,n,a){
                e.Beacon.readyQueue.push({method:t,options:n,data:a})
              },n.readyQueue=[],"complete"===t.readyState)
                return a();
              e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1);
            }(window,document,window?.Beacon||function(){});
          `}
        </script>
        <script type='text/javascript'>{`window?.Beacon('init', '10ad3ee9-81a3-46ba-b494-c9b08d7d2714')`}</script>
      </Helmet>
    </>
  )
}

export default BeaconIntegration
