import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import { Modal, Radio, Switch, Spin } from 'antd'

import { alertAPI } from 'api/api'
import { displayApiError } from 'utils/helper'

import './AlertModal.scss'
import { defaultSelectedContentItems } from 'pages/PulseDashboard/PulseDashboard'
import ContentSelector from 'pages/Investigate/CreateReport/components/ContentSelector/ContentSelector'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import { openNotification } from 'services/Util/openNotification'

type AlertModalProps = {
  open: boolean
  alertId: string | null
  entity_id: string
  onClose: () => void
  onSuccess: (id?: string) => void
  onDeleteSuccess: () => void
  entity_type: 'BRAND' | 'TOPIC' | null
}

const AlertModal: React.FC<AlertModalProps> = ({
  open,
  onClose,
  alertId,
  entity_type,
  entity_id,
  onSuccess,
  onDeleteSuccess,
}) => {
  const [alertType, setAlertType] = useState<'dashboard' | 'content'>('dashboard')
  const [alertFrequencey, setAlertFrequencey] = useState('1')
  const [isActiveItems, setIsActiveItems] = useState(false)
  const [contentItems, setContentItems] = useState<any>(defaultSelectedContentItems)
  const [isLoading, setIsLoading] = useState(false)

  const toggleAlerts = async (isChecked: boolean) => {
    setIsActiveItems(isChecked)
  }

  const getAlertData = async () => {
    if (!alertId) return
    setIsLoading(true)
    try {
      const data = await alertAPI.getAlertUsers(alertId)
      if (Object.keys(data.condition).length > 0) {
        const flags = data.condition?.FLAG
        const influencers = data.condition?.WATCHLIST

        const newContentItems = {
          ...contentItems,
        }
        if (flags && flags.length > 0) {
          newContentItems.flags = flags.map((flag: any) => ({
            label: flag.name,
            value: flag.id,
            name: flag.name,
          }))
        }
        if (influencers && influencers.length > 0) {
          newContentItems.influencers = influencers.map((influencer: any) => ({
            label: influencer.name,
            value: influencer.id,
            name: influencer.name,
          }))
        }
        setContentItems(newContentItems)
        setAlertType('content')
      }
    } catch (error) {
      displayApiError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteAlert = async () => {
    if (!alertId) return
    setIsLoading(true)
    try {
      await alertAPI.deleteAlert(alertId)
      openNotification({ type: 'info', message: 'Alert removed!' })
      onDeleteSuccess()
    } catch (error) {
      displayApiError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAlertAction = async (action: 'create' | 'update') => {
    setIsLoading(true)
    try {
      if (!isActiveItems && alertId) {
        handleDeleteAlert()
        return
      }

      const payload: any = {
        entity_id,
        entity_type,
      }
      if (alertType === 'content') {
        payload.condition = {
          FLAG: contentItems.flags.map((flag: any) => flag.value),
          WATCHLIST: contentItems.influencers.map((infl: any) => infl.value),
        }
      }
      if (action === 'create') {
        const response = await alertAPI.createAlert({ payload })
        openNotification({ type: 'info', message: 'Alert created!' })
        onSuccess(response?.data?.id)
      } else {
        await alertAPI.updatedAlert({ payload, id: alertId as string })
        openNotification({ type: 'info', message: 'Alert updated!' })
        onClose()
      }
    } catch (error) {
      displayApiError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (alertId) {
      getAlertData()
      setIsActiveItems(true)
    }
  }, [])

  return (
    <Modal
      title={entity_type === 'BRAND' ? 'Brand Alerts' : 'Topic Alerts'}
      open={open && !!entity_type}
      className='alert-modal-new'
      onCancel={onClose}
      destroyOnClose
      footer={
        <div className='feed-export__footer'>
          <button className='feed-export__footer__cancel' onClick={onClose}>
            Cancel
          </button>
          <button
            className='feed-export__footer__send'
            onClick={() => handleAlertAction(alertId ? 'update' : 'create')}
            disabled={isLoading}>
            Confirm
          </button>
        </div>
      }
      width={623}>
      <Spin spinning={isLoading}>
        <div className='alert-modal-body'>
          <div className='alert-modal-body-header'>
            <div className='alert-modal-body-header-info'>
              <div className='alert-modal-title'>Receive Alerts</div>
            </div>
            <Switch className='amc_switch' checked={isActiveItems} onChange={toggleAlerts} />
          </div>
          <div className='alert-conditions-wrapper'>
            <div className='alert-conditions-title' aria-disabled={!isActiveItems}>
              Select alerts type:
            </div>

            <div className='alert-conditions'>
              <div
                className='alert-condition'
                aria-disabled={!isActiveItems}
                onClick={() => {
                  if (!isActiveItems) return
                  setAlertType('dashboard')
                }}>
                <Radio className='alert-radio' checked={alertType === 'dashboard'} disabled={!isActiveItems} />
                <div className='alert-condition-info'>
                  <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                    {entity_type === 'BRAND' ? 'Brand' : 'Topic'} dashboard
                  </div>
                  <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                    You will receive alerts for all mentions within this dashboard. If you expect a high volume of data,
                    use the option below for more refined alerts
                  </div>
                </div>
              </div>
              <div>
                <div
                  className='alert-condition'
                  aria-disabled={!isActiveItems}
                  onClick={() => {
                    if (!isActiveItems) return
                    setAlertType('content')
                  }}>
                  <Radio className='alert-radio' checked={alertType === 'content'} disabled={!isActiveItems} />
                  <div className='alert-condition-info'>
                    <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                      Followed Topics and Influential Voices
                    </div>
                    <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                      You will receive an alert containing intersections with Followed Topics and Influential Voices.
                    </div>
                  </div>
                </div>
                {isActiveItems && alertType === 'content' && (
                  <div className='content-select'>
                    <div className='content-select__header'>
                      <div className='content-select__header__content'>
                        <h6 className='title'>Select content</h6>
                        <p className='description'>
                          Choose the Topics and Influential Voices you want to receive alerts about.
                        </p>
                        <ContentSelector
                          key='mainSelector'
                          items={contentItems}
                          setItems={(items) => {
                            setContentItems(items)
                          }}
                          showOnly={['influencers', 'flags']}
                          categoriesFetched
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='alert-conditions-wrapper'>
            <div className='alert-conditions-title' aria-disabled={!isActiveItems}>
              Select alerts frequency:
            </div>

            <div className='alert-conditions'>
              <div
                className='alert-condition'
                aria-disabled={!isActiveItems}
                onClick={() => {
                  if (!isActiveItems) return
                  setAlertFrequencey('1')
                }}>
                <Radio className='alert-radio' checked={alertFrequencey?.includes('1')} disabled={!isActiveItems} />
                <div className='alert-condition-info'>
                  <div className='alert-condition-title' aria-disabled={!isActiveItems}>
                    Hourly alert digest
                  </div>
                  <div className='alert-modal-radio-button-title' aria-disabled={!isActiveItems}>
                    You will receive a selection of posts every hour.
                  </div>
                </div>
              </div>
              <div className='alert-condition' aria-disabled={true}>
                <Radio className='alert-radio' checked={alertFrequencey?.includes('2')} disabled={true} />
                <div className='alert-condition-info'>
                  <div className='alert-condition-title' aria-disabled={true}>
                    All mentions {isActiveItems && <InfoTooltip text={'To enable this option, please contact us.'} />}
                  </div>
                  <div className='alert-modal-radio-button-title' aria-disabled={true}>
                    You will receive an alert each time a post is published.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default observer(AlertModal)
