import { Tooltip } from 'antd'
import { ReactComponent as InfoIcon } from 'assets/images/summary-info-icon.svg'

export default function InfoTooltip({ text = '', width = 15, height = 15 }) {
  return (
    <Tooltip title={<span>{text}</span>} placement='topRight'>
      <InfoIcon height={height} width={width} style={{ cursor: 'pointer' }} />
    </Tooltip>
  )
}
