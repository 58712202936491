import { store } from 'store'
import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Col } from 'antd'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as SpiralIcon } from 'assets/images/spiral_stars.svg'

import PulseMetricsCard from './PulseMetricsCard/PulseMetricsCard'
import AntdButton from 'components/common/AntdButton/AntdButton'

const PulseRightComponent = ({
  parentLoading,
  handleCreateNewClick,
  handlePowerInsightsClick,
}: {
  parentLoading: boolean
  handleCreateNewClick: () => void
  handlePowerInsightsClick: () => void
}) => {
  const { activeItem } = store.mainStore
  const { isLoadingFeed } = store.loaderStore

  const customGraphColors = useMemo(
    () => ({
      shadeColorUp: '#eeeff4',
      lineColorUp: '#A84BD3',
      shadeColorDown: '#eeeff4',
      lineColorDown: '#A84BD3',
      theme: 'purple',
    }),
    [],
  )

  const mentionViewCards = useMemo(
    () => [
      {
        type: 'Mentions',
        key: 'Mentions',
        tooltip: 'Represents the total number of times the selected brand was mentioned across all platforms.',
      },
      {
        type: 'Impressions',
        key: 'Views',
        measure: 'impression',
        tooltip: 'Indicates how many times the selected topic was potentially seen by audiences.',
      },
      {
        type: 'Engagement',
        key: 'engagement',
        measure: 'engagement',
        tooltip:
          'Shows how actively audiences are interacting with content that mentions the selected topic through likes, comments, shares, and other engagement metrics, depending on the platform.',
      },
    ],
    [],
  )

  type cardTypes = 'Mentions' | 'Impressions' | 'Views' | 'Engagement'
  type keyTypes = 'impression' | 'engagement' | 'impressions'

  return (
    <Col span={6} className='pulse-dashboard__searches-container'>
      <div className='searches-container__metrics-container'>
        <div className='pulse-actions'>
          <AntdButton icon={<SpiralIcon />} onClick={handlePowerInsightsClick}>
            Power Insights
          </AntdButton>
          <AntdButton type='outlined' icon={<PlusIcon />} onClick={handleCreateNewClick}>
            Create new
          </AntdButton>
        </div>

        <div className='searches-container__metrics-container'>
          {mentionViewCards.map((card) => (
            <div className='searches-container__metrics-container__card' key={card.key}>
              <PulseMetricsCard
                parentLoading={parentLoading || isLoadingFeed}
                selectedItem={{ conditions: activeItem?.conditions } as any}
                type={card.type as cardTypes}
                key={`pulse-card${card.key}`}
                measure={card.measure as keyTypes}
                subStore={'main'}
                tooltipText={card.tooltip}
                customGraphColors={customGraphColors}
              />
            </div>
          ))}
        </div>
      </div>
    </Col>
  )
}

export default observer(PulseRightComponent)
