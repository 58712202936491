import { store } from 'store'
import { Select } from 'antd'
import { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { DownloadOption } from '../DownloadOption'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import EmptyState from '../PortfolioShareChart/EmptyState'
import { CustomPieChart } from 'components/common/PieChart/PieChart'

import { AssetListObjType } from 'store/asset/types'
import { NarrativeListDataType } from 'types/types'

import './styles.scss'

const BrandSentiment = ({
  subStore = 'assets',
  openSnippetDiscovery,
  selectedBrand,
  showMetricSelection,
}: {
  subStore?: 'assets' | 'vectors'
  selectedBrand: AssetListObjType | NarrativeListDataType
  openSnippetDiscovery?: (item: AssetListObjType | NarrativeListDataType) => void
  showMetricSelection?: boolean
}) => {
  const [activeMetric, setActiveMetric] = useState<'posts' | 'impressions' | 'engagement'>('posts')
  const { graphColorPalette, powerInsightsData } = store[`${subStore}Store`]

  const { setSnippetsFilter, snippetsFilterInfo, snippetsFilter, setSnippetsFilterInfo } =
    store[`${subStore}ModalStore`]

  const sentiments = powerInsightsData.lineChartData?.[activeMetric]?.sentiment.data_points
  const { statsLineChartLoading } = store.loaderStore

  const selectedSentiment = useMemo(() => {
    if (snippetsFilter?.filter_sentiment?.length === 0) return []
    return typeof snippetsFilter.filter_sentiment === 'string'
      ? snippetsFilter?.filter_sentiment?.split(',')
      : snippetsFilter?.filter_sentiment || []
  }, [snippetsFilter?.filter_sentiment])

  const sentimentsBreakDown = useMemo(() => {
    let totalPositive = 0
    let totalNegative = 0
    let totalNeutral = 0

    sentiments?.forEach((item) => {
      totalPositive += item.Positive ?? 0
      totalNegative += item.Negative ?? 0
      totalNeutral += item.Neutral ?? 0
    })
    return {
      data: [
        { name: 'Positive', total_value: totalPositive },
        { name: 'Negative', total_value: totalNegative },
        { name: 'Neutral', total_value: totalNeutral },
      ],
      totalCount: totalPositive + totalNegative + totalNeutral,
    }
  }, [sentiments, activeMetric])

  const onSentimentClicked = (sentiment: { name: string; total_value: number }) => {
    if (selectedSentiment.length === 1 && selectedSentiment[0] === sentiment.name) {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: '',
      })
      const filter = { sentiment: 0 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
    } else {
      setSnippetsFilter({
        ...snippetsFilter,
        filter_sentiment: sentiment.name,
      })
      const filter = { sentiment: 1 }
      setSnippetsFilterInfo({ ...snippetsFilterInfo, ...filter })
      openSnippetDiscovery?.({ ...selectedBrand, filter_sentiment: sentiment.name })
    }
  }

  if (statsLineChartLoading) {
    return <EmptyState title='Sentiment' />
  }

  return (
    <div id='brandSentiment'>
      <div className='chart-heading'>
        <span className='monitor-assets__graph__title'>Sentiment</span>

        <span className='chart-heading__info'>
          <DownloadOption elementToCapture='#brandSentiment' contentScrollable />
          {showMetricSelection && (
            <InfoTooltip
              text={`Represents the distribution of ${
                subStore === 'vectors' ? 'topic' : 'brand'
              } mentions categorized as Positive, Negative, or Neutral. This widget helps you gauge the overall tone of conversations about your ${
                subStore === 'vectors' ? 'topic' : 'brand'
              }.`}
            />
          )}
        </span>
      </div>

      <CustomPieChart
        height={192}
        width={350}
        cx={65}
        data={sentimentsBreakDown.data}
        colorPalette={graphColorPalette.Sentiment}
        totalCount={sentimentsBreakDown.totalCount}
        narrativeTotal={sentimentsBreakDown.totalCount}
        onClickCell={onSentimentClicked}
        showLabelsBasedOnSelection
        millifyStats
        syncSentimentFilterWithChart
        sentimentFilter={selectedSentiment.length === 1 ? selectedSentiment[0] : ''}
        snippetsFilter={snippetsFilter}
      />
      {showMetricSelection && (
        <Select onChange={(value) => setActiveMetric(value)} value={activeMetric} className='selectMetric'>
          <Select.Option value='posts'>Mentions</Select.Option>
          <Select.Option value='impressions'>Impressions</Select.Option>
          <Select.Option value='engagement'>Engagement</Select.Option>
        </Select>
      )}
    </div>
  )
}

export default observer(BrandSentiment)
