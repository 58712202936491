import { ReactComponent as MenuAltIcon } from 'assets/images/menu-alt.svg'
import './TopLevelNavigation.scss'

export default function MenuItem({
  onClick,
  title,
  selectedItem = '',
}: {
  onClick: () => void
  title: string
  selectedItem: string
}) {
  return (
    <div className='top-menu-item'>
      <div onClick={onClick} className='top-menu-item__toggler'>
        <MenuAltIcon />
      </div>
      <div className='top-menu-item__content'>
        <h4>{selectedItem}</h4>
        <h5>{title}</h5>
      </div>
    </div>
  )
}
