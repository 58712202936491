import { Spin } from 'antd'
import { store } from 'store'
import { Object } from 'core-js'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEffectOnce, useUnmount } from 'react-use'

import { CustomPieChart } from 'components/common/PieChart/PieChart'

import useDelayedLoading from 'utils/useDelayedLoading'

import { flagsAPI } from 'api/api'
import { SubStore } from 'types/types'
import { AssetListObjType, AssetListParamsType } from 'store/asset/types'

import './MonitorAssets.scss'
interface Props {
  subStore: SubStore
}

export const MonitorAssets = observer(({ subStore }: Props) => {
  const [narrativeStats, setNarrativeStats] = useState<number>(0)
  const { assetsStore, loaderStore, tenantsStore } = store
  const {
    graphColorPalette,
    assetCategoriesGraphData,
    hasCategoriesConditionsFetched,
    setAssetTypeGraphData,
    setHasCategoriesConditionsFetched,
    setAssetCategoriesGraphData,
    fetchCategoriesForGraph,
    fetchDataForObject,
  } = assetsStore
  const {
    activeItem,
    snippetsFilter,
    filterChips,
    setSnippetsFilter,
    setFilterChips,
    fetchStats,
    fetchPowerInsights,
    fetchSnippetsInfo,
  } = store[`${subStore}Store`]
  const location = useLocation()
  const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore
  const { isLoadingAttentionFlags, isLoadingNoOfPosts } = loaderStore

  const isLoading = useDelayedLoading([isLoadingAttentionFlags, isLoadingNoOfPosts])

  useEffectOnce(() => {
    fetchCategoriesForGraph({ is_asset: true })
  })

  useEffect(() => {
    if (Object.keys(assetCategoriesGraphData)?.length > 0) {
      fetchNarrativeStats()
      fetchAssetTypesData()
    }
  }, [assetCategoriesGraphData, snippetsFilter])

  useEffect(() => {
    if (Object.keys(assetCategoriesGraphData)?.length > 0 && hasCategoriesConditionsFetched) {
      fetchCategoryStats()
    }
  }, [assetCategoriesGraphData, snippetsFilter, hasCategoriesConditionsFetched])

  useUnmount(() => {
    setHasCategoriesConditionsFetched(false)
  })

  const fetchNarrativeStats = async () => {
    const response = await fetchStats(activeItem?.conditions || {}, activeItem?.name, true)
    setNarrativeStats(response?.total_value || 0)
  }

  const fetchCategoryStats = async () => {
    const promises = Object.keys(assetCategoriesGraphData)?.map((key) =>
      fetchStats(assetCategoriesGraphData[key]?.conditions || {}, key, true),
    )
    const responseArray = await Promise.allSettled(promises)

    responseArray.forEach((result) => {
      if (result.status === 'fulfilled') {
        const response = result.value
        setAssetCategoriesGraphData(response?.name, { total_value: response?.total_value })
      }
    })
  }

  const fetchAssetTypesData = async () => {
    try {
      const promises = Object.keys(assetCategoriesGraphData)?.map(async (key) => {
        const category = assetCategoriesGraphData[key]
        let requestParams: AssetListParamsType = {
          q: `category_id:eq:${category.category_id}`,
          is_asset: true,
        }

        const response = await flagsAPI.getFlagsList({ params: requestParams })
        const updatedItems = await Promise.all(
          response.data?.map(async (obj: AssetListObjType) => {
            return await fetchDataForObject(obj, 'flag')
          }) || [],
        )
        const activeAssets = updatedItems.filter((item) => Object.keys(item.conditions).length > 0)

        const graphData = await Promise.all(
          activeAssets.map((item) => {
            return fetchStats(item.conditions, item.name, true)
          }),
        )

        setAssetTypeGraphData(
          category.name,
          graphData.filter((data) => data?.total_value > 0),
        )
      })

      Promise.all(promises)
    } catch (error: any) {
      console.log(error)
    }
  }

  const onPieChartClick = (data?: any) => {
    if (snippetsFilter.assets?.name === data.name) {
      let { assets, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
      setFilterChips({
        ...filterChips,
        assets: {
          ...filterChips.assets,
          value: '',
        },
      })
    } else {
      setSnippetsFilter({ ...snippetsFilter, assets: { name: data?.name, conditions: data?.conditions } })
      setFilterChips({
        ...filterChips,
        assets: {
          ...filterChips.assets,
          value: data.name,
        },
      })
    }

    fetchPowerInsights(activeItem!)
    fetchSnippetsInfo(activeItem!)
  }

  return (
    <Spin spinning={isLoading}>
      <div className='monitor-assets__container'>
        <div className='monitor-assets__header-div'>
          <span
            className={classNames('monitor-assets__header-div__title', {
              dashboard_assets: location.pathname?.includes('/power-insights/assets'),
            })}>
            {changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme)}
          </span>
          <span className='monitor-assets__header-div__description'>
            Represent the context around your Brand like product/brand portfolio, competitors, suppliers and executives
          </span>
        </div>
        <div className='monitor-assets__body'>
          {Object.keys(assetCategoriesGraphData).map((key) => {
            return (
              <div className='monitor-assets__graph-container'>
                <span className='monitor-assets__graph__title'>{key}</span>
                <CustomPieChart
                  height={192}
                  width={350}
                  cx={65}
                  data={assetCategoriesGraphData[key]?.subCategories || []}
                  colorPalette={graphColorPalette[key]}
                  totalCount={assetCategoriesGraphData[key].total_value}
                  narrativeTotal={narrativeStats}
                  onClickCell={onPieChartClick}
                  snippetsFilter={snippetsFilter}
                />
              </div>
            )
          })}
        </div>
      </div>
    </Spin>
  )
})
