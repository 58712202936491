import { store } from 'store'
import millify from 'millify'
import { useEffect } from 'react'
import { Button, Col, Spin } from 'antd'
import { observer } from 'mobx-react-lite'

import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'
import { ReactComponent as ArrowUpIcon } from 'assets/images/icons/dashboard/arrow-up.svg'

import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'

import useDelayedLoading from 'utils/useDelayedLoading'

import './PulseDashboard.scss'

const PulseCenterComponent = ({
  tabsLoading,
  handleAddContentClick,
  skipInternalSnippetCall,
}: {
  tabsLoading: boolean
  skipInternalSnippetCall: boolean
  handleAddContentClick: (value: boolean) => void
}) => {
  const { mainStore, loaderStore } = store
  const { isLoadingFeed, isLoadingDefaultPlatforms } = loaderStore
  const {
    snippetsFilter,
    setSnippetsFilter,
    refetchSnippetsForActiveItem,
    newSnippetsFound,
    fetchSnippetsTotalCount,
    setStartPollingForSnippetsCountUpdate,
    defaultPlatforms,
  } = mainStore

  const snippetsLoading = useDelayedLoading([isLoadingFeed || isLoadingDefaultPlatforms || tabsLoading], 1000)

  const FIVE_MINUTES_IN_MILLISECONDS = 300000

  useEffect(() => {
    if (isLoadingDefaultPlatforms) return
    const intervalId = setInterval(() => {
      fetchSnippetsTotalCount()
    }, FIVE_MINUTES_IN_MILLISECONDS)

    return () => {
      clearInterval(intervalId)
      setSnippetsFilter({ ...snippetsFilter, date_key: '' })
      setStartPollingForSnippetsCountUpdate(false)
    }
  }, [isLoadingDefaultPlatforms, defaultPlatforms])

  return (
    <Col span={12} flex={'auto'} className='pulse-dashboard__snippets-container'>
      <SnippetListWithFilters
        subStore='main'
        isCustomEmptyState
        emptyStateIcon={<EmptyFile />}
        parentAPICallsLoading={snippetsLoading}
        emptyStateTitle={'Your feed is empty'}
        uniqueScrollId='pulse-centered-component'
        skipInternalAPICall={skipInternalSnippetCall}
        emptyStateDescription={'Start by selecting the content you want to follow in this feed.'}
        emptyStateButton={
          <Button type='text' className='empty-feed-btn' onClick={() => handleAddContentClick(true)}>
            Add Content
          </Button>
        }
      />
      {snippetsLoading && (
        <div className='pulse_snippets_loader--mask'>
          <Spin spinning={true} className='pulse_snippets_loader' />
        </div>
      )}
      {newSnippetsFound > 0 && (
        <div className='new-snippets-banner' onClick={refetchSnippetsForActiveItem}>
          <ArrowUpIcon /> {millify(newSnippetsFound || 0)} new posts
        </div>
      )}
    </Col>
  )
}

export default observer(PulseCenterComponent)
