import { useState, useEffect } from 'react'

const useDelayedLoading = (loadingStates: boolean[], delay: number = 1000, defaultLoading = true): boolean => {
  const [isLoading, setIsLoading] = useState<boolean>(defaultLoading)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (loadingStates.some((state) => state)) {
      setIsLoading(true)
      if (timeoutId) clearTimeout(timeoutId)
    } else {
      const id: NodeJS.Timeout = setTimeout(() => {
        setIsLoading(false)
      }, delay)
      setTimeoutId(id)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, loadingStates)

  return isLoading
}

export default useDelayedLoading
