import { Form } from 'antd'
import { uniqBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { DetailsObjType } from 'types/types'
import { GenericDispatch, MaybeNull, Unknown } from 'utils/commonTypes'
import { AddBrandFormDataType } from '../components/AddBrandWizard/types'
import { ChannelDataType, SupportedPlatformTypes } from 'pages/Vectors/CreateEditWatchListStepper/types'
import { AssetListObjType, AssetListParamsType, AssetType, CopilotResponseType } from 'store/asset/types'

import { store } from 'store'
import { defineNarrativeAPI, flagsAPI, watchlistAPIs } from 'api/api'
import { dateAndTimeToLocalDateAndTimeValue, displayApiError } from 'utils/helper'
import { FlagListParamsType } from 'store/flags/types'
import { validateBooleanSearch } from 'api/defineNarrative/validateBoolean.api'
import { openNotification } from 'services/Util/openNotification'

export const validateBoolean = async (booleanQuery: string) => {
  let isValid = true
  try {
    await validateBooleanSearch({ boolean_query: booleanQuery })
  } catch (error: any) {
    openNotification({
      type: 'error',
      message: error?.response?.data?.err_msg || 'There is an error in the boolean query.',
      duration: 10,
    })
    isValid = false
    return false
  }
  return isValid
}

const useHandleCreateBrand = (
  setSelectedBrand: (brand: AssetListObjType) => void,
  isTopicCreation: boolean,
  isNarrative: boolean,
  isEditMode?: boolean,
) => {
  const defaultFormData: AddBrandFormDataType = {
    url: '',
    logoURL: '',
    edit_url: '',
    addedURLs: [],
    brandName: '',
    previousURL: '',
    booleanSearch: '',
    platform: undefined,
    userSearchString: '',
  }

  const [activeStep, setActiveStep] = useState(1)
  const [showSnippets, setShowSnippets] = useState(false)
  const [formData, setFormData] = useState(defaultFormData)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [isBooleanActive, setIsBooleanActive] = useState(false)
  const [validateURLLoading, setValidateURLLoading] = useState(false)
  const [createBrandLoading, setCreateBrandLoading] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [supportedPlatform, setSupportedPlatform] = useState<SupportedPlatformTypes[]>([])
  const [filteredData, setFilteredData] = useState<ChannelDataType[]>(defaultFormData.addedURLs)
  const [corporateMode, setCorporateMode] = useState<'search' | 'stats'>('search')

  const [form] = Form.useForm()

  const { tenantId } = store.userStore
  const { isCorporateCommunicationsTheme, isAdditionalPlatformsFetching, hasTwitterAsAdditionalPlatform } =
    store.tenantsStore
  const { showToaster } = store.toasterStore
  const { isLoadingFeed } = store.loaderStore
  const { setSelectedSearchItem, selectedSearchItem } = store.vectorsStore
  const { fetchFlagsListWithConditions } = store.flagsStore
  const { fetchBooleanSearchText, booleanSearchLoading } = store.flagsStore
  const { fetchAssetsList, assetsList, setAssetsList } = store.assetsStore
  const { fetchSnippetsInfo, fetchPowerInsights, fetchInsightsMetrics, setSnippetsFilter, snippetsFilter } =
    store.assetsModalStore
  const { booleanSearchTranslation } = store.defineNarrativeStore

  const totalSteps = isTopicCreation ? 1 : 3
  const invalidLinkCount = formData?.addedURLs?.filter((item) => item.isInvalid)?.length

  useEffect(() => {
    const filter = {
      days: {
        custom: {
          isApplied: false,
          dateRange: [],
        },
        noOfDays: 90,
      },
    }
    setSnippetsFilter({ ...snippetsFilter, ...filter })
  }, [])

  useEffect(() => {
    if (isTopicCreation && !isAdditionalPlatformsFetching && hasTwitterAsAdditionalPlatform && !isEditMode) {
      const updatedData = { ...formData, twitter_expansion: true }
      form.setFieldsValue(updatedData)
      setFormData(updatedData)
    }
  }, [isAdditionalPlatformsFetching, hasTwitterAsAdditionalPlatform, isTopicCreation])

  const handleBulkDeleteURLs = useCallback(() => {
    const filteredFormDataURLs = formData?.addedURLs.filter((item) => !selectedRowKeys.includes(item.channel_url))
    setFormData((prevData) => ({
      ...prevData,
      addedURLs: filteredFormDataURLs,
    }))
    setFilteredData((prev) => prev.filter((item) => !selectedRowKeys?.includes(item.channel_url)))
    setSelectedRowKeys([])
  }, [filteredData, formData, selectedRowKeys])

  const handleContinueButton = () => {
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    if (activeStep < totalSteps) {
      setActiveStep((prev) => prev + 1)
    }
  }

  const createAssetHandler = async (
    handleCloseModal: GenericDispatch<boolean>,
    brandData: MaybeNull<AssetListObjType>,
    formData: AddBrandFormDataType,
  ) => {
    const isValid = await validateBoolean(formData.booleanSearch)
    if (!isValid) {
      setActiveStep(1)
      return
    }

    let requestBody = {
      description: '',
      is_asset: true,
      tenant_ids: [tenantId!],
      name: formData.brandName,
      category_id: '0211af89-63e5-4924-a5d2-399eda5973bd',
      insight_type: 'SEARCH_TERM' as AssetType,
      display_label: formData.brandName,
      insight_condition: formData.booleanSearch,
      metadata: {
        brand_url: formData.logoURL || '',
        brand_media_links:
          formData?.addedURLs?.map((item) => ({ platform: item.platform, url: item.channel_url })) || [],
      },
    }
    try {
      setCreateBrandLoading(true)
      const response = await flagsAPI.postFlag({
        data: requestBody,
        method: brandData?.id ? 'put' : 'post',
        id: brandData?.id ? brandData?.id : '',
      })

      if (!brandData?.id) {
        await flagsAPI.toggleFlagStatus({ ids: [response?.data?.id], status: 'activate' })
        const params: AssetListParamsType = {
          page: 1,
          per_page: 100,
          sort: 'name:asc',
          is_asset: true,
          is_active: true,
          q: `category_id:eq:0211af89-63e5-4924-a5d2-399eda5973bd`,
        }
        const assets = await fetchAssetsList({ params: params })
        const newlyCreatedAsset = assets?.items?.sort(
          (a, b) => dateAndTimeToLocalDateAndTimeValue(b.created) - dateAndTimeToLocalDateAndTimeValue(a.created),
        )
        if (newlyCreatedAsset?.length) setSelectedBrand(newlyCreatedAsset[0])
        handleCloseModal(false)
        showToaster({
          iconType: 'success',
          message: `You have successfully added a new brand`,
        })
        return
      }
      const modifiedAssetsList = assetsList?.items?.map((item) => {
        if (item.id === brandData?.id) return { ...item, ...response?.data }
        return item
      })
      setAssetsList({ ...assetsList, items: modifiedAssetsList })
      setSelectedBrand({ ...brandData, ...response?.data })
      handleCloseModal(false)
      showToaster({
        iconType: 'success',
        message: `You have successfully updated brand`,
      })
    } catch (error) {
      displayApiError(error)
    } finally {
      setCreateBrandLoading(false)
    }
  }

  const createTopicHandler = async (
    handleCloseModal: GenericDispatch<boolean>,
    brandData: MaybeNull<AssetListObjType>,
  ) => {
    const formValues = form.getFieldsValue()

    const isValid = await validateBoolean(formValues['booleanSearch'])
    if (!isValid) {
      setCorporateMode('search')
      return
    }

    // if horizon topic (narrative creation)
    if (isNarrative) {
      try {
        setCreateBrandLoading(true)
        let payload: any = {
          name: formValues['brandName'],
          type: 'keyword',
          description: '',
          keywords_expression: formValues['booleanSearch'],
          tenant_id: tenantId,
          twitter_expansion: formValues['twitter_expansion'],
        }
        if (Object.keys(booleanSearchTranslation)?.length) {
          const languageTranslation = booleanSearchTranslation
          delete languageTranslation['Default']
          payload['multi_lang_kwd_expression'] = languageTranslation
        }

        if (!brandData?.id) {
          const response = await defineNarrativeAPI.publishNarrative(payload)
          handleCloseModal(false)
          showToaster({
            iconType: 'success',
            message: 'You have successfully created the Horizon topic',
          })
          setSelectedSearchItem({
            ...response,
            conditions: { narratives: [response.narrative_number] },
          })
        } else {
          const response = await defineNarrativeAPI.publishNarrative(payload, brandData?.id)
          if (selectedSearchItem?.id === brandData.id) {
            setSelectedSearchItem({
              ...brandData,
              ...response,
              conditions: { narratives: [response.narrative_number] },
            })
          }
          handleCloseModal({ ...brandData, ...response, conditions: { narratives: [response.narrative_number] } })
          showToaster({
            iconType: 'success',
            message: 'You have successfully updated the Horizon topic',
          })
        }
      } catch (e: any) {
        displayApiError(e)
      } finally {
        setCreateBrandLoading(false)
      }
    } else {
      // if flag creation or updation
      let payload: any = {
        description: '',
        is_asset: false,
        tenant_ids: [tenantId!],
        name: formValues.brandName,
        insight_type: 'SEARCH_TERM' as AssetType,
        display_label: formValues.brandName,
        insight_condition: formValues.booleanSearch,
      }

      const requestParams: FlagListParamsType = {
        page: 1,
        per_page: 5,
        sort: 'name:asc',
        is_active: true,
      }

      try {
        setCreateBrandLoading(true)
        const response = await flagsAPI.postFlag({
          data: payload,
          method: brandData?.id ? 'put' : 'post',
          id: brandData?.id ? brandData?.id : '',
        })

        if (!brandData?.id) {
          await flagsAPI.toggleFlagStatus({ ids: [response?.data?.id], status: 'activate' })
          requestParams.q = `name:like:${formValues.brandName}`
        }
        const { q, ...newParams } = requestParams
        await fetchFlagsListWithConditions({ params: newParams })
        handleCloseModal({ ...brandData, ...response.data })
        showToaster({
          iconType: 'success',
          message: `You have successfully ${brandData?.id ? 'updated' : 'created and followed'} topic`,
        })
      } catch (error) {
        displayApiError(error)
      } finally {
        setCreateBrandLoading(false)
      }
    }
  }

  const handleBackStep = () => {
    setActiveStep((prev) => prev - 1)
  }

  const handleNextStep = useCallback(
    async (handleCloseModal: GenericDispatch<any>, brandData: MaybeNull<AssetListObjType>) => {
      try {
        await form.validateFields()
        if (selectedRowKeys.length === 0) {
          if (activeStep === 1 && isTopicCreation) {
            // on horizon topic creation take user to stats if not already else create the topic
            if (!isEditMode && corporateMode === 'search') {
              handleSearchClick()
              return
            }
            createTopicHandler(handleCloseModal, brandData)
          } else if (activeStep === 3) createAssetHandler(handleCloseModal, brandData, formData)
          else handleContinueButton()
        } else {
          handleBulkDeleteURLs()
        }
      } catch (error) {
        console.error('Validation Failed:', error)
      }
    },
    [activeStep, selectedRowKeys, corporateMode, booleanSearchTranslation, formData],
  )

  const handleEditURL = useCallback(
    (url: string, platform?: string) => {
      setFormData((prevData) => ({
        ...prevData,
        edit_url: url,
        platform: platform,
        previousURL: url,
      }))
    },
    [formData],
  )

  const handleDeleteURL = useCallback(
    (url: string) => {
      setFormData((prevData) => ({
        ...prevData,
        addedURLs: prevData.addedURLs.filter((item) => item.channel_url !== url),
      }))
      setFilteredData((prev) => prev.filter((item) => item.channel_url !== url))
      setSelectedRowKeys((prev) => prev?.filter((item) => item !== url))
    },
    [filteredData, selectedRowKeys, formData],
  )

  const handleSelectedRows = (channel_url: string) => {
    if (selectedRowKeys?.includes(channel_url))
      setSelectedRowKeys((prev) => prev?.filter((item) => item !== channel_url))
    else setSelectedRowKeys((prev) => [...prev, channel_url])
  }

  const handleBulkRowsSelection = () => {
    if (selectedRowKeys?.length === filteredData?.length) setSelectedRowKeys([])
    else setSelectedRowKeys(filteredData?.map((item) => item.channel_url))
  }

  const handleValuesChange = (values: Record<string, any>) => {
    if (!values.hasOwnProperty('twitter_expansion')) {
      const isUserSearchStringActive = !!values?.userSearchString?.trim()
      const isBooleanKeywordActive = !!values?.booleanSearch?.trim()
      setIsBooleanActive(isUserSearchStringActive)
      setIsSearchActive(isBooleanKeywordActive)
    }

    setFormData((prev) => ({ ...prev, ...values }))
  }

  const copilotSearchHandler = () => {
    const userSearchString = form.getFieldValue('userSearchString')
    const requestBody = {
      prompt_text: userSearchString,
    }
    fetchBooleanSearchText({ data: requestBody }).then((res: CopilotResponseType | 'error') => {
      if (res !== 'error') {
        form.setFieldValue('booleanSearch', res.prompt_response)
        form.validateFields()
        const values = form.getFieldsValue()
        setFormData((prevData) => ({ ...prevData, ...values }))
        const isBooleanKeywordActive = !!res.prompt_response?.trim()
        setIsSearchActive(isBooleanKeywordActive)
      }
    })
  }

  const handleVerifyURLInCaseError = (
    error: Unknown,
    previousURL: string,
    previousURLs: ChannelDataType[],
    url: string,
  ) => {
    const errorDetails = error?.response?.data?.error_details
    setFormData((prevData) => ({
      ...prevData,
      url: '',
      platform: '',
      edit_url: '',
      previousURL: '',
      addedURLs: uniqBy(
        [
          ...previousURLs,
          {
            channel_url: errorDetails?.channel_url || url,
            platform: errorDetails?.platform || undefined,
            isInvalid: true,
          },
        ],
        'channel_url',
      ),
    }))
    setFilteredData(
      uniqBy(
        [
          ...previousURLs,
          {
            channel_url: errorDetails?.channel_url || url,
            platform: errorDetails?.platform || undefined,
            isInvalid: true,
          },
        ],
        'channel_url',
      ),
    )
  }

  const handleMultipleURLsAndVerify = async (url: string) => {
    const arrayOfLinks = url?.split(/[\s,]+/)
    const accumulatedURLs: ChannelDataType[] = []

    await Promise.all(
      arrayOfLinks.map(async (item) => {
        if (item)
          try {
            setValidateURLLoading(true)
            const { data } = await watchlistAPIs.verifyChannelURL({ url: item?.trim() })
            accumulatedURLs.push({ ...data, isInvalid: false })
          } catch (error: Unknown) {
            const errorDetails = error?.response?.data?.error_details
            accumulatedURLs.push({
              channel_url: errorDetails?.channel_url || item,
              platform: errorDetails?.platform || undefined,
              isInvalid: true,
            })
          } finally {
            setValidateURLLoading(false)
          }
      }),
    )

    setFormData((prevData) => ({
      ...prevData,
      addedURLs: uniqBy([...prevData.addedURLs, ...accumulatedURLs], 'channel_url'),
    }))
    setFilteredData(uniqBy([...formData.addedURLs, ...accumulatedURLs], 'channel_url'))
  }

  const handleEditURLAndVerify = async (url: string, previousURL: string) => {
    const previousURLs = formData.addedURLs?.filter((item) => item.channel_url !== previousURL)
    try {
      setValidateURLLoading(true)
      if (url) {
        const { data } = await watchlistAPIs.verifyChannelURL({ url })
        setFormData((prevData) => {
          return {
            ...prevData,
            url: '',
            platform: '',
            edit_url: '',
            previousURL: '',
            addedURLs: uniqBy([...previousURLs, { ...data, isInvalid: false }], 'channel_url'),
          }
        })
        setFilteredData(uniqBy([...previousURLs, { ...data, isInvalid: false }], 'channel_url'))
      }
    } catch (error: Unknown) {
      handleVerifyURLInCaseError(error, previousURL, previousURLs, url)
    } finally {
      setValidateURLLoading(false)
    }
  }

  const verifyAndGetPlatform = async (url: string, previousURL?: string) => {
    if (previousURL) {
      await handleEditURLAndVerify(url, previousURL)
    } else {
      await handleMultipleURLsAndVerify(url)
    }
  }

  const handleConfirmEditURL = () => {
    const { edit_url } = form.getFieldsValue()
    verifyAndGetPlatform(edit_url, formData.previousURL)
  }

  const cancelURLEditing = () =>
    setFormData((prevData) => ({
      ...prevData,
      edit_url: '',
      platform: '',
      previousURL: '',
    }))

  const getSupportedPlatform = async () => {
    try {
      const { data } = await watchlistAPIs.getSupportedPlatforms()
      setSupportedPlatform(data?.supported_platforms as SupportedPlatformTypes[])
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearchClick = async (data?: DetailsObjType) => {
    setCorporateMode('stats')
    setShowSnippets(true)
    const detailObj: DetailsObjType = {
      comingFrom: '',
      conditions: { keywords_expression: form.getFieldValue('booleanSearch') },
      mode: 'narrative',
      name: 'Power Insights',
    }
    const values = form.getFieldsValue()
    setFormData((prevData) => ({ ...prevData, ...values }))
    fetchSnippetsInfo(data?.conditions ? data : detailObj, false)
    fetchPowerInsights(data?.conditions ? data : detailObj, false)
  }

  useEffect(() => {
    getSupportedPlatform()
  }, [])

  return {
    form,
    formData,
    activeStep,
    setFormData,
    filteredData,
    showSnippets,
    isLoadingFeed,
    handleEditURL,
    isSearchActive,
    handleNextStep,
    handleBackStep,
    isBooleanActive,
    setFilteredData,
    handleDeleteURL,
    selectedRowKeys,
    invalidLinkCount,
    cancelURLEditing,
    supportedPlatform,
    handleSearchClick,
    handleValuesChange,
    createBrandLoading,
    validateURLLoading,
    handleSelectedRows,
    handleConfirmEditURL,
    verifyAndGetPlatform,
    booleanSearchLoading,
    copilotSearchHandler,
    handleBulkRowsSelection,
    isCorporateCommunicationsTheme,
    fetchInsightsMetrics,
    corporateMode,
    setCorporateMode,
  }
}

export default useHandleCreateBrand
