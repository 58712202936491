import React, { useState, useEffect, useCallback } from 'react'

import { ReactComponent as RightArrow } from 'assets/images/right-arrow.svg'

import './Carousel.scss'

export type CarouselProps<T> = {
  items: T[]
  renderItem: (item: T, index: number) => React.ReactNode
  emptyItem?: () => React.ReactNode | JSX.Element
  maxDots?: number
  isLoading?: boolean
  showArrows?: boolean
  autoPlay?: boolean
  autoPlayInterval?: number
  className?: string
}

const Carousel = <T,>({
  items,
  renderItem,
  emptyItem,
  maxDots,
  showArrows = true,
  isLoading = false,
  autoPlay = false,
  autoPlayInterval = 3000,
  className = '',
}: CarouselProps<T>) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [direction, setDirection] = useState<'next' | 'prev' | null>(null)
  const [isTransitioning, setIsTransitioning] = useState(false)

  const totalItems = items?.length
  const displayDots = maxDots ? Math.min(maxDots, totalItems) : totalItems
  const dotsStep = maxDots ? Math.ceil(totalItems / maxDots) : 1

  const goToNext = useCallback(() => {
    if (!isTransitioning && totalItems > 1) {
      setDirection('next')
      setIsTransitioning(true)
      setCurrentIndex((prev) => (prev + 1) % totalItems)
    }
  }, [isTransitioning, totalItems])

  const goToPrev = useCallback(() => {
    if (!isTransitioning && totalItems > 1) {
      setDirection('prev')
      setIsTransitioning(true)
      setCurrentIndex((prev) => (prev - 1 + totalItems) % totalItems)
    }
  }, [isTransitioning, totalItems])

  const goToIndex = useCallback(
    (index: number) => {
      if (!isTransitioning && index !== currentIndex) {
        setDirection(index > currentIndex ? 'next' : 'prev')
        setIsTransitioning(true)
        setCurrentIndex(index)
      }
    },
    [currentIndex, isTransitioning],
  )

  useEffect(() => {
    if (autoPlay && totalItems > 1) {
      const interval = setInterval(goToNext, autoPlayInterval)
      return () => clearInterval(interval)
    }
  }, [autoPlay, autoPlayInterval, goToNext, totalItems])

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false)
        setDirection(null)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isTransitioning])

  const renderDots = () => {
    const dots = []
    for (let i = 0; i < displayDots; i++) {
      const actualIndex = i * dotsStep
      dots.push(
        <button
          key={i}
          className={`carousel-dot ${actualIndex === currentIndex ? 'active' : ''}`}
          onClick={() => goToIndex(actualIndex)}
          aria-label={`Go to slide ${i + 1}`}
        />,
      )
    }
    return dots
  }

  return (
    <div className={`slide-carousel-container ${className}`}>
      <div className='carousel-content'>
        {items?.length > 0 &&
          !isLoading &&
          items?.map((item, index) => {
            const isActive = index === currentIndex
            let slideClasses = 'carousel-slide'

            if (isActive) {
              slideClasses += ' active'
              if (isTransitioning) {
                slideClasses += direction === 'next' ? ' slide-in-right' : ' slide-in-left'
              }
            } else if (
              isTransitioning &&
              ((direction === 'next' && index === (currentIndex - 1 + totalItems) % totalItems) ||
                (direction === 'prev' && index === (currentIndex + 1) % totalItems))
            ) {
              slideClasses += direction === 'next' ? ' slide-out-left' : ' slide-out-right'
            } else {
              slideClasses += ' hidden'
            }

            return (
              <div key={index} className={slideClasses}>
                {renderItem(item, index)}
              </div>
            )
          })}
        {totalItems ? null : emptyItem?.()}
      </div>

      {totalItems > 1 && (
        <div className='carousel-controls'>
          {showArrows && (
            <button className='carousel-arrow prev' onClick={goToPrev} aria-label='Previous slide'>
              <RightArrow />
            </button>
          )}

          <div className='carousel-dots'>{renderDots()}</div>

          {showArrows && (
            <button className='carousel-arrow next' onClick={goToNext} aria-label='Next slide'>
              <RightArrow />
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Carousel
