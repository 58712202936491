import axios from 'axios'
import { ROUTES } from 'settings/settings'
import { CreateFlagBodyType } from 'store/flags/types'

export function createTopic({
  data,
  method = 'post',
  id,
}: {
  data: CreateFlagBodyType
  method?: 'post' | 'put'
  id: string
}) {
  return axios({
    method: method,
    url: ROUTES.followIssue.replace(':id', id),
    data,
  })
}
