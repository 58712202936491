import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'
import { store } from 'store'
import { useUserRole } from 'hooks/UserRoleContext'

type Props = {
  element: JSX.Element
  allowedRoles: string[]
}

export const PrivateRoute = observer(({ element, allowedRoles }: Props): JSX.Element => {
  const { pathname } = useLocation()
  const userName = store.userStore.userName
  const userRole = useUserRole()?.userRole
  const sku = store.tenantsStore.sku
  const isCorporateCommunicationsTheme = store.tenantsStore.isCorporateCommunicationsTheme

  const isAllowed = allowedRoles.includes(userRole)

  const skuAllowedPaths = ['brand-dashboard', 'influencers', 'investigate', 'settings']

  if (sku && isAllowed && userName) {
    if (skuAllowedPaths.some((item) => pathname?.includes(item))) return element
    return <Navigate replace to={sku ? '/brand-dashboard' : isCorporateCommunicationsTheme ? '/brands' : '/assets'} />
  }

  if (!userName) {
    return <Navigate replace to='/signin' />
  }

  if (!isAllowed) {
    return <Navigate replace to={sku ? '/brand-dashboard' : isCorporateCommunicationsTheme ? '/brands' : '/assets'} />
  }

  return element
})
