import axios from 'axios'
import { SnippetFilterPayload } from 'models/models'
import { ROUTES } from 'settings/settings'

interface Props extends SnippetFilterPayload {
  name: string
  include_titles: boolean
  include_summary: boolean
  maximum_clips: number
  total_snippets: number
  start_date: string
  end_date: string
  sort_by: string
  order_by: string
  filters: {
    platform: string[]
    sentiment: string[]
  }
}

export const createClipReport = async ({ page, per_page = 50, query, sort, conditions, ...restParams }: Props) => {
  const {
    name,
    include_titles,
    include_summary,
    maximum_clips,
    total_snippets,
    start_date,
    end_date,
    sort_by,
    order_by,
    filters,
  } = restParams
  const q = `${query.date}${query.community}${query.platform}${query.sentiment}${query.source}${query.risk}${query.people}${query.politician}${query.company}${query.country}${query.language}${query.languageRisk}${query.translationLanguage}`

  const query_params = `q=${q}&sort=${sort}&per_page=${per_page}&page=${page}`

  const endpoint = `${process.env.REACT_APP_API}${ROUTES.clipReport}`

  const payload = {
    name,
    include_titles,
    include_summary,
    maximum_clips,
    total_snippets,
    start_date,
    end_date,
    sort_by,
    order_by,
    filters,
    query_params,
    query_payload: { conditions },
  }

  return axios.post(endpoint, payload)
}
