import { Form, Input, Tooltip } from 'antd'
import classNames from 'classnames'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'

const booleanSearchValidation = [
  { message: 'There is an issue with your syntax.' },
  () => ({
    validator(_: any, value: any) {
      if (value.length === 0) {
        return Promise.reject('Boolean Keyword Search is required')
      }

      const stack: string[] = []
      const values = value.split('')
      let extra: boolean = false

      values.forEach((el: string) => {
        if (extra) return
        if (el === '(') return stack.push('(')
        if (el === ')') {
          if (stack.length === 0) {
            extra = true
          } else return stack.pop()
        }
      })

      if (extra) {
        return Promise.reject(new Error("You're missing a “(” from your expression."))
      }

      if (value.match(/"/g)?.length % 2) {
        return Promise.reject(new Error(`You have an extra ' " ' in your expression.`))
      }

      if (stack.length > 0) {
        return Promise.reject(new Error("You're missing a “)” from your expression."))
      }

      return Promise.resolve()
    },
  }),
]

const AddBrandTextArea = ({
  label = '',
  name,
  isRequiredField = true,
  hidden = false,
}: {
  label: string
  name: string
  isRequiredField: boolean
  hidden?: boolean
}) => {
  const booleanKeywords = label === 'Boolean Keyword Search'

  const placeholder = booleanKeywords
    ? '("China" OR "Chinese" OR "PRC") AND ("Taiwan" OR "Taiwanese" OR "ROC") AND ("political tension*" OR "sovereignty dispute*")'
    : 'Describe the topic you are looking for as you would to a junior analyst. E.g. Conversations around China and Taiwan political tensions'

  const tooltip = booleanKeywords ? (
    <Tooltip
      placement='right'
      overlayClassName='pendulum-themed-tooltip ant-tooltip-zindex'
      overlayStyle={{ width: '370px' }}
      overlayInnerStyle={{ height: 'auto', width: '370px', overflow: 'auto', maxHeight: '600px' }}
      destroyTooltipOnHide
      title={
        <>
          <ul className='create-narrative__boolean-search-tooltip'>
            <li>
              <p>
                Pendulum uses boolean operators to allow you to define what you are looking for. If you have not used
                boolean operators before, you can watch this short
                <a href='https://www.youtube.com/watch?v=bCAULDuMcso' target='_blank' rel='noreferrer'>
                  {' '}
                  video
                </a>
                . Other useful resources are
                <a href='https://www.youtube.com/watch?v=Ed7EswsnEbM' target='_blank' rel='noreferrer'>
                  {' '}
                  boolean modifiers
                </a>{' '}
                and{' '}
                <a href='https://www.youtube.com/watch?v=BoujP8KoK7E' target='_blank' rel='noreferrer'>
                  keyword selection
                </a>
                .
              </p>
              <p>
                Boolean searches are very useful for a quick access to the data but can be very challenging if the topic
                you are evaluating is very complex and nuanced. If you need any assistance{' '}
                <button className='here-button'>click here</button> and tell us how we can help.
              </p>
              <p>
                The data in this screen is limited to the last 90 days and is intended to give you examples of what your
                search results will look like. You can also edit right away as you see things to remove or add. Once you
                click Publish, your narrative will run against all of Pendulum's data and will return all matching
                results.
              </p>
              <p>
                Pendulum uses the following operators and it is important that they are all UPPERCASE when included.
              </p>
              <span>AND: search for the presence of all terms in your search.</span>
              <br />
              <span>OR: search for the presence of either term.</span>
              <br />
              <span>NOT: remove items that contain a specific term.</span>
              <br />
              <span>
                ~N (proximity): find two words that are within N words of each other. Write the words in quotes,
                followed by ~N. Ex: "Black White" ~2 shows results where "black" and "white" are no more than two words
                apart.
              </span>
              <br />
              <span>
                * (wildcard): search for variations of the term, like plurals. Works only for a single word, not a
                phrase.
              </span>
            </li>
            <br />
            <span>Some search examples:</span>
            <ul className='create-narrative__boolean-search-example'>
              <li>
                <span> Hans Niemann and Magnus Carlsen recent scandal:</span>
              </li>
              <ul>
                <li>
                  <p>
                    ((("Hans Demon" OR "Hans Niemann" OR ((Hans OR Niemann OR Neiman OR Nieman) AND Chess)) OR ("Magnus
                    Carlsen" OR ((Magnus OR Carlsen OR Carlson OR Carlton) AND Chess))) AND (Cheat* OR Scandal OR
                    Withdr* OR Drama OR Device OR Vibr*))
                  </p>
                </li>
              </ul>
              <li>
                <span>Narrowing down to the Elite 8 men's basketball game in 2022 march madness tournament:</span>
              </li>
              <ul>
                <li>
                  <p>(Arkansas OR Razorbacks) AND (Duke OR “Blue Devils”) AND “march madness” NOT “1994 final”</p>
                </li>
              </ul>
            </ul>
          </ul>
        </>
      }>
      <InfoIcon className='stsc_tac_ht_info_icon' height={12} width={12} />
    </Tooltip>
  ) : (
    <Tooltip
      placement='right'
      overlayClassName='pendulum-themed-tooltip ant-tooltip-zindex'
      overlayStyle={{ width: '250px' }}
      overlayInnerStyle={{ maxHeight: '350px', width: '250px', overflow: 'auto' }}
      title={
        <div className='create-narrative__copilot-tooltip'>
          <p>
            Describe the topic you are looking for like
            <br />
            you would when talking to a very junior
            <br />
            analyst who is helping you. Feel free to be
            <br />
            as descriptive as you like. You can also call
            <br />
            out things that you may not want to include
            <br />
            in the search results. Some examples
            <br />
            below:
          </p>

          <ul>
            <li>
              Discussions relating to China and
              <br />
              Taiwan relationships and any impacts
              <br />
              that can have on geopolitical and
              <br />
              economic stability
            </li>
            <li>
              Conversations about various Qanon
              <br />
              topics and conspiracy theories
            </li>
            <li>
              Conversations discussing various forms
              <br />
              of human rights violations related with
              <br />
              the Kingdom of Saudi Arabia
            </li>
          </ul>
        </div>
      }>
      <InfoIcon className='stsc_tac_ht_info_icon' height={12} width={12} />
    </Tooltip>
  )

  const userSearchStringValidations = { required: isRequiredField, message: 'Pendulum Co-Pilot Search is required' }

  const validationRules = name === 'userSearchString' ? [userSearchStringValidations] : booleanSearchValidation

  return (
    <div className={classNames('creation-flow__search', { 'creation-flow__hide': hidden })}>
      <div className='creation-flow__search__header'>
        <div className='creation-flow__search__header__text'>{label}</div>
        <div className='creation-flow__search__header__info-icon'>{tooltip}</div>
      </div>
      <Form.Item
        hidden={hidden}
        name={name}
        rules={validationRules}
        shouldUpdate={(prev, current) => prev.field !== current.field}>
        <Input.TextArea placeholder={placeholder} />
      </Form.Item>
    </div>
  )
}

export default AddBrandTextArea
