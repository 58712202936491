import { useState } from 'react'
import classNames from 'classnames'
import { Button, Form, Input, Modal, Spin, Tooltip } from 'antd'

import Platform from 'components/Vectors/Platform'
import AddLinks from 'components/Vectors/CreateEditWatchListSteps/AddLinks'
import AddDetails from 'components/Vectors/CreateEditWatchListSteps/AddDetails'
import CreateEditWatchlistNavbar from 'components/Vectors/CreateEditWatchlistNavbar'
import CreateEditWatchlistHeader from 'components/Vectors/CreateEditWatchlistHeader'
import CreateEditWatchlistFooter from 'components/Vectors/CreateEditWatchlistFooter'

import { ReactComponent as BetaTag } from 'assets/images/beta-icon.svg'
import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

import useCreateInfluencer from './useCreateInfluencer'

import './CreateEditWatchListStepper.scss'

const betaTagMap = {
  bluesky: <BetaTag className='betaTag' />,
  linkedin: <BetaTag className='betaTag' />,
}

const CreateEditWatchListStepper = () => {
  const [isCreatorInfoLoading, setIsCreatorInfoLoading] = useState<boolean>(false)

  const {
    id,
    form,
    formData,
    isLoading,
    stepTitle,
    sortStates,
    totalSteps,
    activeStep,
    setFormData,
    applyFilters,
    filteredData,
    handleEditURL,
    onCancelClick,
    selectedFilter,
    handleNextStep,
    handleDeleteURL,
    selectedRowKeys,
    invalidLinkCount,
    cancelButtonText,
    cancelURLEditing,
    influencerLoading,
    handleInputChange,
    supportedPlatform,
    handleTableChange,
    continueButtonText,
    setSelectedRowKeys,
    validateURLLoading,
    handleConfirmEditURL,
    verifyAndGetPlatform,
    handleBulkRowsSelection,
    handleSelectedRows,
  } = useCreateInfluencer()

  const handleCreatorInfoLoading = (isLoading: boolean) => {
    setIsCreatorInfoLoading(isLoading)
  }

  const platforms = supportedPlatform?.map((pf) => {
    const matchingIcon = PLATFORM_ICONS_GREY?.find((item) => item.title?.toLowerCase() === pf?.platform?.toLowerCase())

    return matchingIcon ? { ...matchingIcon, ...pf } : pf
  })

  const sampleURLObject = platforms?.find((item) => item.platform?.toLowerCase() === formData.platform?.toLowerCase())

  return (
    <div className='watchlist-builder'>
      <Form form={form} layout='vertical' initialValues={formData}>
        <div className='watchlist-builder__section watchlist-builder__section--left'></div>
        <div className='watchlist-builder__section watchlist-builder__section--center'>
          <CreateEditWatchlistNavbar stepTitle={stepTitle()} step={activeStep} totalSteps={totalSteps} />
          {activeStep !== 1 && (
            <CreateEditWatchlistHeader
              id={id || ''}
              activeStep={activeStep}
              addedURLs={formData?.addedURLs}
              validateURLLoading={validateURLLoading}
              isCreatorInfoLoading={isCreatorInfoLoading}
              verifyAndGetPlatform={verifyAndGetPlatform}
            />
          )}
          <Spin spinning={influencerLoading || isLoading}>
            <div className='watchlist-builder__section--center__form'>
              {activeStep === 1 && <AddDetails />}
              {(activeStep === 2 || activeStep === 3) && (
                <AddLinks
                  formData={formData}
                  sortStates={sortStates}
                  activeStep={activeStep}
                  setFormData={setFormData}
                  filteredData={filteredData}
                  handleEditURL={handleEditURL}
                  handleFilterURLs={applyFilters}
                  selectedFilter={selectedFilter}
                  selectedRowKeys={selectedRowKeys}
                  handleDeleteURL={handleDeleteURL}
                  handleInputChange={handleInputChange}
                  handleTableChange={handleTableChange}
                  setSelectedRowKeys={setSelectedRowKeys}
                  handleSelectedRows={handleSelectedRows}
                  handleBulkRowsSelection={handleBulkRowsSelection}
                  handleCreatorInfoLoading={handleCreatorInfoLoading}
                />
              )}
            </div>
          </Spin>
          <CreateEditWatchlistFooter
            onCancel={onCancelClick}
            onContinue={handleNextStep}
            confirmButtonLoading={isLoading}
            selectedRowKeys={selectedRowKeys}
            cancelButtonText={cancelButtonText}
            invalidLinkCount={!!invalidLinkCount || (!formData.addedURLs?.length && activeStep > 1)}
            continueButtonText={continueButtonText}
          />
        </div>

        <div className='watchlist-builder__section watchlist-builder__section--right'>
          {activeStep !== 1 && (
            <>
              <div className='label'>Platforms supported</div>
              <div className='platforms'>
                {platforms?.map(
                  (item, index) =>
                    item.title && (
                      <Tooltip
                        title={
                          !!item.sample_urls[0] ? (
                            <div>
                              URL example:
                              {item?.sample_urls?.map((url, index) => <div key={index}>{url}</div>) || ''}
                            </div>
                          ) : (
                            ''
                          )
                        }
                        placement='top'
                        overlayStyle={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          maxWidth: '600px',
                          textOverflow: 'ellipsis',
                        }}>
                        <span
                          className={classNames('platform', item.title?.toLowerCase())}
                          key={`${item.title}${index}right`}>
                          <span className='icon'>{item.icon}</span>
                          <span className='title'>{item.title}</span>
                          {betaTagMap[item.title?.toLowerCase() as keyof typeof betaTagMap] || null}
                        </span>
                      </Tooltip>
                    ),
                )}
              </div>
            </>
          )}
        </div>

        <Modal
          open={!!formData.edit_url && !formData?.platform}
          destroyOnClose
          onOk={() => null}
          closable={true}
          title='Edit link'
          onCancel={cancelURLEditing}
          className='pulse-date-selection-modal watchlist-edit-url'
          footer={
            <div className='pulse-content-selection-modal__footer'>
              <Button type='text' onClick={cancelURLEditing}>
                Cancel
              </Button>
              <Button type='primary' onClick={handleConfirmEditURL} loading={validateURLLoading}>
                Confirm
              </Button>
            </div>
          }>
          <div className='edit-url-modal--content'>
            <div className='edit-url-modal__info'>
              <div className='edit-url-modal__info__icon'>
                <InfoIcon />
              </div>
              <div className='edit-url-modal__info__text'>
                <div className='edit-url-modal__info__text__title'>
                  We were unable to identify the intended platform for the provided link
                </div>
                <div className='edit-url-modal__info__text__description'>
                  Please ensure the link is correct and compatible with our supported platforms. Here’s a guide to
                  formatting links:
                  <ul>
                    <li>
                      Start the URL with <span>https://.</span>
                    </li>
                    <li>Avoid spaces and special characters that are not URL-friendly.</li>
                    <li>Ensure the link is active and not restricted.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='edit-url-modal__input-field'>
              <label>
                <span className='input-label'>
                  Link <span className='asterisk'>*</span>
                </span>
                <Form.Item
                  name='edit_url'
                  rules={[{ required: true, message: 'URL required' }]}
                  shouldUpdate={(prev, current) => prev.field !== current.field}>
                  <Input placeholder='Please paste correct URL' key={'watchlist-add-details-name-input'} />
                </Form.Item>
                <span className='error-message'>This link is invalid. Please add the correct link.</span>
              </label>
            </div>
          </div>
        </Modal>
        <Modal
          open={!!formData.edit_url && !!formData?.platform}
          destroyOnClose
          onOk={() => null}
          closable={true}
          title='Edit link'
          onCancel={cancelURLEditing}
          className='pulse-date-selection-modal watchlist-edit-url'
          footer={
            <div className='pulse-content-selection-modal__footer'>
              <Button type='text' onClick={cancelURLEditing}>
                Cancel
              </Button>
              <Button type='primary' onClick={handleConfirmEditURL} loading={validateURLLoading}>
                Confirm
              </Button>
            </div>
          }>
          <div className='edit-url-modal--content'>
            <div className='previous-info'>
              <div className='previous-info--header'>
                <div className='previous-info__text'>Platform</div>
                <div className='previous-info__platform'>
                  <Platform platform={formData.platform || ''} />
                </div>
              </div>
              <div className='previous-info__url'>
                <InfoIcon />
                <div>
                  URL example: {sampleURLObject?.sample_urls?.map((url, index) => <div key={index}>{url}</div>) || ''}
                </div>
              </div>
            </div>
            <div className='edit-url-modal__input-field'>
              <label>
                <span className='input-label'>
                  Link <span className='asterisk'>*</span>
                </span>
                <Form.Item
                  name='edit_url'
                  rules={[{ required: true, message: 'URL required' }]}
                  shouldUpdate={(prev, current) => prev.field !== current.field}>
                  <Input placeholder='Please paste correct URL' key={'watchlist-add-details-name-input'} />
                </Form.Item>
                <span className='error-message'>This link is invalid. Please add the correct link.</span>
              </label>
            </div>
          </div>
        </Modal>
      </Form>
    </div>
  )
}

export default CreateEditWatchListStepper
