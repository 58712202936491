import { SetStateAction, useCallback, useState } from 'react'
import { Form, Input, Menu, Select, Switch, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'

import AddBrandTextArea from './AddBrandTextArea'
import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as SparkleIcon } from 'assets/images/icons/sparkle.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/icons/flag/loader_icon.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/twitter-x-new-logo.svg'
import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/language-icon.svg'
import { ReactComponent as ScrollArrowIcon } from 'assets/images/icons/power-insights/right-arrow-circular.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-tag.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg'
import { ReactComponent as CheckIcon } from 'assets/images/icons/outline/check.svg'
import { ReactComponent as WarningIcon } from 'assets/images/search-result.svg'
import { SUPPORTED_LANGUAGES_CODE } from 'settings/settings'
import { store } from 'store'
import classNames from 'classnames'
import { AddBrandFormDataType } from './types'
import './AddSearchStep.scss'
import 'pages/Vectors/CreateNarrative/CreateNarrativeForm/CreateNarrativeForm.scss'

const AddSearchStep = ({
  isSearchActive,
  isTopicCreation,
  isBooleanActive,
  handleSearchClick,
  convertBooleanClick,
  convertBooleanLoading,
  isEditMode,
  form,
  formData,
  setFormData,
  setLanguageRef,
  setLanguageState,
  setSelectedLanguagesCode,
  languageRef,
  setLanguageScrollOffset,
  selectedLanguagesCode,
  setTranslatedLanguageText,
  translatedLanguageText,
  languageState,
  languageScrollOffset,
  selectRef,
  isNarrative,
}: {
  isSearchActive: boolean
  isBooleanActive: Boolean
  isTopicCreation: boolean
  handleSearchClick: () => void
  convertBooleanClick: () => void
  convertBooleanLoading: boolean
  isEditMode: boolean
  form: any
  formData: AddBrandFormDataType
  setFormData: any
  setLanguageRef: any
  setLanguageState: any
  setSelectedLanguagesCode: any
  languageRef: any
  setLanguageScrollOffset: any
  selectedLanguagesCode: any
  setTranslatedLanguageText: any
  translatedLanguageText: any
  languageState: any
  languageScrollOffset: any
  selectRef: any
  isNarrative: boolean
}) => {
  const {
    isAdditionalPlatformsFetching,
    hasTwitterAsAdditionalPlatform,
    isCorporateCommunicationsTheme,
    changeLabelTextBasedOnTheme,
  } = store.tenantsStore
  const {
    selectedLanguages,
    setSelectedLanguages,
    setBooleanSearchTranslation,
    setSelectedTranslationTab,
    booleanSearchTranslation: allBooleanTranslations,
    fetchBooleanSearchTranslation,
    selectedTranslationTab,
    selectedTabLanguage,
    isBooleanSearchTranslating,
  } = store.defineNarrativeStore

  const { snippetsFilter, setSnippetsFilter } = store.assetsModalStore

  const [isCoPilotClosed, setIsCoPilotClosed] = useState(false)

  const listRefCallback = useCallback((node: SetStateAction<HTMLElement | null>) => {
    setLanguageRef(node)
  }, [])

  const closeLanguageOption = () => {
    setLanguageState('off')
    setSelectedLanguages([])
    setSelectedLanguagesCode([])
    setFormData({ ...formData, languages: [] })
    form.setFieldValue('languages', [])
    setBooleanSearchTranslation({ Default: allBooleanTranslations['Default'] })
    setSelectedTranslationTab('Default')
    if (isCoPilotClosed) {
      form.setFieldsValue({ booleanSearch: allBooleanTranslations['Default'] || formData.userSearchString || '' })
    } else form.setFieldsValue({ copilotText: formData.userSearchString || '' })
  }

  const cancelLanguageOption = () => {
    if (selectedLanguages.length) {
      form.setFieldValue('languages', selectedLanguages)
      setLanguageState('selected')
      setSelectedLanguagesCode(selectedLanguages)
    } else closeLanguageOption()
  }

  const addLanguages = (language: string[]) => {
    if (language.length <= 10) setSelectedLanguagesCode(language)
  }

  const scrollHandler = (scrollOffset: number, direction: 'right' | 'left') => {
    if (languageRef) {
      const currentScroll = languageRef.scrollLeft
      const maxScroll = languageRef.scrollWidth - languageRef.clientWidth
      setLanguageScrollOffset(currentScroll)

      if (currentScroll > 0 && direction === 'left') {
        languageRef.scrollLeft = Math.max(0, currentScroll - scrollOffset)
      } else if (currentScroll <= maxScroll && direction === 'right') {
        languageRef.scrollLeft = Math.min(maxScroll, currentScroll + scrollOffset)
      }
    }
  }

  const saveLanguage = async (language?: string) => {
    if (languageRef) {
      setLanguageScrollOffset(0)
      languageRef.scrollLeft = 0
    }
    if (selectedLanguagesCode.length === 0) {
      setSelectedLanguages([])
      setLanguageState('off')
      setTranslatedLanguageText('')
      setSelectedTranslationTab('Default')
      setBooleanSearchTranslation({})
    } else if (selectedLanguagesCode.length <= 10) {
      let booleanSearchValue = ''
      if (language) {
        booleanSearchValue = formData['booleanSearch']
      } else if (formData['booleanSearch'] !== allBooleanTranslations[selectedTranslationTab]) {
        booleanSearchValue = formData['booleanSearch']
      } else {
        booleanSearchValue = allBooleanTranslations[selectedTranslationTab] || formData['booleanSearch'] || ''
      }
      setSelectedLanguages(selectedLanguagesCode)
      setLanguageState('selected')
      setTranslatedLanguageText(formData['booleanSearch'])
      const response = await fetchBooleanSearchTranslation(language, {
        ...formData,
        languages: selectedLanguagesCode,
      })

      if (isCoPilotClosed)
        form.setFieldsValue({
          booleanSearch: booleanSearchValue,
        })
      else form.setFieldsValue({ copilotText: formData.userSearchString || '' })
      return response
    }
  }

  const selectLanguageTab = async ({ key }: any) => {
    if (isBooleanSearchTranslating) return
    let translation = allBooleanTranslations
    if (translatedLanguageText !== formData['booleanSearch']) {
      const response = await saveLanguage(selectedTabLanguage)
      translation = {
        ...allBooleanTranslations,
        ...response,
      }
    }
    setSelectedTranslationTab(key)
    form.setFieldsValue({ booleanSearch: translation[key] || '' })
    setFormData({ ...formData, booleanSearch: translation[key] || '' })
    setSnippetsFilter({ ...snippetsFilter, filter_language: key === 'Default' ? 'en' : key })
  }

  const onLangaugeEditIconClick = () => {
    if (languageState !== 'on') setLanguageState('on')
    else {
      selectedLanguagesCode.length ? setLanguageState('selected') : setLanguageState('off')
    }
  }

  const customPopupContainer = (triggerNode: any) => {
    return triggerNode.parentNode
  }

  return (
    <>
      <label className='creation-flow__brand-name'>
        <span className='input-label'>
          {isTopicCreation ? 'Topic Name' : 'Brand name'} <span className='asterisk'>*</span>
        </span>
        <Form.Item
          name='brandName'
          rules={[{ required: true, message: `Please input ${isTopicCreation ? 'topic' : 'brand'} name` }]}
          shouldUpdate={(prev, current) => prev.field !== current.field}>
          <Input placeholder='Add name' />
        </Form.Item>
      </label>
      <div className='creation-flow__heading'>
        {isCoPilotClosed && (
          <div className='creation-flow__heading__spark-icon' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
            <SparkleIcon />
          </div>
        )}
        {!isCoPilotClosed && (
          <div className='creation-flow__heading__text' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
            Close Co-Pilot
          </div>
        )}
        {isCoPilotClosed && (
          <>
            <div className='creation-flow__heading__text' onClick={() => setIsCoPilotClosed(!isCoPilotClosed)}>
              Pendulum Co-Pilot
            </div>
            <div className='creation-flow__heading__info-icon'>
              <InfoIcon />
            </div>
          </>
        )}
      </div>

      {!isCoPilotClosed && (
        <>
          <AddBrandTextArea label='Pendulum Co-Pilot Search' name='userSearchString' isRequiredField={false} />

          <ButtonImage
            loading={convertBooleanLoading}
            disabled={!isBooleanActive || convertBooleanLoading}
            icon={LoaderIcon}
            onClick={convertBooleanClick}
            className='creation-flow__btn'>
            Convert to boolean
          </ButtonImage>
        </>
      )}
      {isNarrative && (
        <div className='create-narrative create-narrative-overrides'>
          <div
            className={classNames(
              { 'create-narrative__selected-language': languageState === 'selected' },
              {
                'create-narrative__unselected-language': languageState === 'off',
              },
            )}>
            {languageState === 'selected' && (
              <div className={'create-narrative__languages-nav-container'}>
                {languageScrollOffset > 0 && (
                  <ScrollArrowIcon
                    className='create-narrative__languages-nav-container--left'
                    onClick={() => {
                      scrollHandler(25, 'left')
                    }}
                  />
                )}
                <nav className='create-narrative__languages-nav' ref={listRefCallback}>
                  <div>
                    <Menu
                      mode='horizontal'
                      defaultSelectedKeys={['Default']}
                      triggerSubMenuAction='click'
                      disabledOverflow
                      selectedKeys={[selectedTranslationTab]}
                      disabled={isBooleanSearchTranslating}>
                      {['Default', ...selectedLanguages].map((language) => {
                        return (
                          <Menu.Item key={language} id={language} onClick={selectLanguageTab}>
                            <span>{language === 'Default' ? 'Default' : SUPPORTED_LANGUAGES_CODE[language]}</span>
                          </Menu.Item>
                        )
                      })}
                    </Menu>
                  </div>
                </nav>

                {languageRef && languageScrollOffset < languageRef?.scrollWidth - languageRef?.clientWidth && (
                  <ScrollArrowIcon
                    className='create-narrative__languages-nav-container--right'
                    onClick={() => {
                      scrollHandler(25, 'right')
                    }}
                  />
                )}
              </div>
            )}

            <div className='create-narrative__language-switch-container'>
              {languageState === 'selected' && (
                <EditIcon
                  className='create-narrative__language-switch-container__edit-icon'
                  onClick={onLangaugeEditIconClick}
                />
              )}
              <Tooltip placement='bottom' title='Translate'>
                <LanguageIcon
                  aria-label='Translate'
                  className={classNames({
                    'create-narrative__language-switch-container__black-icon': languageState !== 'off',
                  })}
                />
              </Tooltip>

              <Switch
                size={'small'}
                checked={languageState !== 'off' ? true : false}
                onChange={(value) => {
                  value ? setLanguageState('on') : closeLanguageOption()
                }}
                disabled={!isEditMode && form.getFieldValue('booleanSearch')?.length <= 0}
              />
            </div>
          </div>

          {languageState === 'on' && (
            <div
              className={classNames('create-narrative__select-container', {
                'create-narrative__select-container--open': true,
              })}>
              <div className='create-narrative__select-container__description-div'>
                {selectedLanguagesCode.length < 10 ? (
                  <span className='create-narrative__select-container__description'>
                    {
                      'You can select up to 10 languages at a time for each narrative. Pendulum will automatically translate your search in the selected languages.'
                    }
                  </span>
                ) : (
                  <div className='create-narrative__select-container__warning'>
                    <WarningIcon />
                    <span>{'You can add 10 languages at a time. Please unselect one to include another.'}</span>
                  </div>
                )}

                <div className='create-narrative__select-container__search-div'>
                  <Form.Item name='languages' style={{ width: '100%' }}>
                    <Select
                      ref={selectRef}
                      style={{ width: '95%' }}
                      className='create-narrative__select-container__div'
                      popupClassName='create-narrative__select-container__dropdown'
                      getPopupContainer={customPopupContainer}
                      placeholder={
                        <>
                          <SearchIcon />
                          <span>Search language</span>
                        </>
                      }
                      suffixIcon={<></>}
                      open={true}
                      mode='multiple'
                      menuItemSelectedIcon={<CheckIcon />}
                      onChange={(value) => {
                        if (value?.length > 10) {
                          value.pop()
                        } else addLanguages(value)
                      }}
                      value={selectedLanguagesCode}
                      defaultValue={selectedLanguages}
                      filterOption={(input: string, option?: { label: string; value: string }) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      optionFilterProp='label'
                      placement='bottomLeft'>
                      {Object.keys(SUPPORTED_LANGUAGES_CODE)?.map((key) => {
                        return (
                          <Select.Option value={key} key={key} label={SUPPORTED_LANGUAGES_CODE[key]}>
                            <span>{SUPPORTED_LANGUAGES_CODE[key]}</span>
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  <CloseIcon onClick={() => closeLanguageOption()} />
                </div>
              </div>
              <div className='create-narrative__select-container__buttons'>
                <button
                  className='create-narrative__select-container__buttons__cancel'
                  onClick={() => cancelLanguageOption()}>
                  Cancel
                </button>
                <button className='create-narrative__select-container__buttons__add' onClick={() => saveLanguage()}>
                  <span> Add language</span>
                  {selectedLanguagesCode.length > 0 && (
                    <div className='create-narrative__language-count'>{selectedLanguagesCode.length}</div>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <AddBrandTextArea label='Boolean Keyword Search' name='booleanSearch' isRequiredField />
      {((isEditMode && isTopicCreation) || !isTopicCreation) && (
        <ButtonImage
          loading={false}
          disabled={!isSearchActive}
          onClick={handleSearchClick}
          type='button'
          className='creation-flow__btn preview-btn'>
          Search
        </ButtonImage>
      )}

      {isNarrative && !isEditMode && !isAdditionalPlatformsFetching && hasTwitterAsAdditionalPlatform && (
        <div className='twitter-expansion'>
          <div className='twitter-expansion-header'>
            <div className='twitter-expansion-header__title'>
              <TwitterIcon className='twitter-expansion-header__twitter-icon' />
              <h2>Twitter Expansion</h2>
              <Tooltip
                placement='top'
                title={
                  <p>
                    By enabling X (Twitter) expansion, Pendulum will query X directly once your{' '}
                    {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)} is published, and pull
                    data on a continuous basis. Without enabling X (Twitter) Expansion, your
                    {changeLabelTextBasedOnTheme('Narrative', isCorporateCommunicationsTheme)} will be matched against
                    Tweets in Pendulum’s existing data lake.
                  </p>
                }>
                <InfoIcon />
              </Tooltip>
            </div>
            <Form.Item name='twitter_expansion' valuePropName='checked'>
              <Switch size='small' />
            </Form.Item>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(AddSearchStep)
