import { store } from 'store'
import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import NewPieChart from './NewPieChart'
import NewEmptyState from './NewEmptyState'

import { ReactComponent as ArrowIcon } from 'assets/images/downArrow-icon.svg'

import { ConditionsPayloadType } from 'types/types'
import { AssetListObjType } from 'store/asset/types'
import { SnippetFilterPayload } from 'models/models'

const NewPortfolioShareChart = ({
  additionalConditions,
  openSnippetDiscovery,
  subStore = 'assets',
  showMetricSelection,
  isPowerInsightsMode,
  isUsedInModal = true,
  skipBrandIntersection = false,
}: {
  additionalConditions?: ConditionsPayloadType
  openSnippetDiscovery?: (asset: AssetListObjType | null) => void
  subStore?: 'assets' | 'vectors'
  isUsedInModal?: boolean
  showMetricSelection?: boolean
  isPowerInsightsMode?: boolean
  skipBrandIntersection?: boolean
}) => {
  const [hoveredCell, setHoveredCell] = useState('')
  const [selectedIndexLocal, setSelectedIndexLocal] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const { assetsList, assetsLoading } = store.assetsStore
  const { graphColorPalette, fetchInsightsMetrics, getDate, activeItem, filterChips } = store[`${subStore}Store`]

  const { snippetsFilter, setSnippetsFilter, getSnippetFilterPayload, updateFilterChips } =
    store[`${subStore}${isUsedInModal ? 'ModalStore' : 'Store'}`]

  const [chartData, setChartData] = useState<{ name: string; total_value: number }[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [activeMetric, setActiveMetric] = useState<'posts' | 'impression' | 'engagement'>('posts')

  const createDataForChart = async () => {
    setLoading(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const assetsToFetch = assetsList.items.filter((asset: AssetListObjType) => Object.keys(asset.conditions).length > 0)

    const requestParams: any = {
      q: `start_date:gte:${getDate.startDate},end_date:lte:${getDate.endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}`,
    }

    if (activeMetric !== 'posts') {
      requestParams.measure = activeMetric
    }

    try {
      const responses = await Promise.all(
        assetsToFetch.map((asset: any) => {
          const conditions = skipBrandIntersection ? [asset.conditions] : [asset.conditions, ...payload.conditions]
          if (additionalConditions && !skipBrandIntersection) {
            conditions.push(additionalConditions)
          }

          return fetchInsightsMetrics({ params: requestParams, data: { conditions: conditions } })
        }),
      )
      const totalCount = responses.reduce((sum, response) => sum + (response === 'error' ? 0 : response), 0)
      setChartData(
        assetsToFetch.map((asset, index) => ({
          name: asset.name,
          total_value: responses[index] !== 'error' ? responses[index] : 0,
        })),
      )
      setTotalCount(totalCount)
    } catch (error) {
      console.error('Failed to fetch insights metrics', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!assetsLoading && assetsList.total_count > 0) {
      createDataForChart()
    }
    if (!filterChips.assets.value) {
      setSelectedIndexLocal('')
    }
  }, [assetsList, snippetsFilter, additionalConditions, activeMetric, activeItem, filterChips])

  const onSentimentClicked = (brand: any) => {
    if (brand) {
      const asset = assetsList.items.find((item) => item.name === brand.name)
      if (asset) {
        setSelectedIndexLocal(asset.name)
        if (isPowerInsightsMode) {
          updateFilterChips('assets', asset.name)
          setSnippetsFilter({
            ...snippetsFilter,
            assets: { name: asset.name, conditions: asset?.conditions },
          })
        }
        openSnippetDiscovery?.(asset)
      }
    } else {
      setSelectedIndexLocal('')
      updateFilterChips('assets', '')
      let { assets, ...filter } = snippetsFilter
      setSnippetsFilter(filter)
    }
    setHoveredCell('')
  }

  if (assetsLoading || loading) {
    return <NewEmptyState />
  }

  return (
    <div id='newPortfolioChart'>
      {showMetricSelection && (
        <Select
          onChange={(value) => setActiveMetric(value)}
          value={activeMetric}
          className='selectMetric'
          suffixIcon={<ArrowIcon />}>
          <Select.Option value='posts'>Mentions</Select.Option>
          <Select.Option value='impression'>Impressions</Select.Option>
          <Select.Option value='engagement'>Engagement</Select.Option>
        </Select>
      )}

      <NewPieChart
        height={400}
        width={300}
        legendWidth={250}
        data={chartData}
        colorPalette={graphColorPalette.Executives}
        totalCount={totalCount}
        narrativeTotal={totalCount}
        onClickCell={onSentimentClicked}
        showLabelsBasedOnSelection
        millifyStats
        hoveredCell={hoveredCell}
        setHoveredCell={setHoveredCell}
        selectedIndexLocal={selectedIndexLocal}
        isUsedInModal={isUsedInModal}
        customLegendWrapperClass='new-pie-chart__scrollable'
        snippetsFilter={snippetsFilter}
      />
    </div>
  )
}

export default observer(NewPortfolioShareChart)
