import { store } from 'store'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as FlagsIcon } from 'assets/images/powerinsight-tabs/flag.svg'
import { ReactComponent as TrendIcon } from 'assets/images/powerinsight-tabs/trend.svg'
import { ReactComponent as PeopleIcon } from 'assets/images/powerinsight-tabs/people.svg'
import { ReactComponent as AssetsIcon } from 'assets/images/powerinsight-tabs/assets.svg'
import { ReactComponent as MetricsIcon } from 'assets/images/powerinsight-tabs/metrics.svg'
import { ReactComponent as CreatorIcon } from 'assets/images/powerinsight-tabs/creators.svg'
import { ReactComponent as LanguageIcon } from 'assets/images/powerinsight-tabs/languages.svg'
import { ReactComponent as HiveIcon } from 'assets/images/powerinsight-tabs/watchlist_hive.svg'
import { ReactComponent as CountriesIcon } from 'assets/images/powerinsight-tabs/countries.svg'
import { ReactComponent as CompaniesIcon } from 'assets/images/powerinsight-tabs/companies.svg'
import { ReactComponent as SentimentIcon } from 'assets/images/powerinsight-tabs/sentiments.svg'
import { ReactComponent as PoliticianIcon } from 'assets/images/powerinsight-tabs/politicians.svg'
import { ReactComponent as CommunitiesAudiencesIcon } from 'assets/images/powerinsight-tabs/audience.svg'
import { ReactComponent as WatchListIcon } from 'assets/images/powerinsight-tabs/influencer_watchlist.svg'
import { ReactComponent as CashTagIcon } from 'assets/images/icons/dashboard/cashtag.svg'
import { ReactComponent as HashTagIcon } from 'assets/images/icons/dashboard/hashtag.svg'
import { ReactComponent as HandlesIcon } from 'assets/images/icons/dashboard/handles.svg'

import { DetailsObjType } from 'types/types'
import { GenericDispatch } from 'utils/commonTypes'

import './PowerInsightTabsListVertical.scss'
import HiveHoverState from './HiveHoverState'
import { Tooltip } from 'antd'

const PowerInsightTabsListVertical = observer(
  ({
    isDashboard = false,
    selectedPowerInsightTab,
    setSelectedPowerInsightTab,
  }: {
    isDashboard?: boolean
    selectedPowerInsightTab: string
    setSelectedPowerInsightTab: GenericDispatch<string>
  }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const detailObj = location.state as DetailsObjType | null

    const { tenantsStore } = store

    const { changeLabelTextBasedOnTheme, isCorporateCommunicationsTheme } = tenantsStore

    const POWER_INSIGHT_TABS = [
      {
        key: 'metrics',
        label: 'Metrics',
        show: true,
        path: 'metrics',
        fill: true,
        icon: <MetricsIcon color='red' />,
      },
      {
        key: 'assets',
        label: changeLabelTextBasedOnTheme('Assets', isCorporateCommunicationsTheme),
        show: true,
        path: 'assets',
        fill: true,
        icon: <AssetsIcon />,
      },
      {
        key: 'topics',
        label: changeLabelTextBasedOnTheme('Topics', isCorporateCommunicationsTheme),
        show: true,
        path: 'topics_flags',
        icon: <FlagsIcon />,
      },
      {
        key: 'trends-timeline',
        label: 'Trends',
        show: true,
        path: 'trends',
        icon: <TrendIcon />,
      },
      {
        key: 'creators',
        label: 'Creators',
        path: 'creators',
        icon: <CreatorIcon />,
        show: true,
      },
      {
        key: 'watchlist',
        path: changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Watchlist', isCorporateCommunicationsTheme),
        icon: <WatchListIcon />,
      },
      {
        key: 'creator-hive',
        path: 'creator_hive',
        fill: true,
        label: changeLabelTextBasedOnTheme('Creator Hive', isCorporateCommunicationsTheme),
        icon: <HiveIcon />,
        icon2: <InfoIcon />,
        icon2Class: 'info-icon',
        tooltip: <HiveHoverState />,
        show: true,
      },
      {
        key: 'communities',
        path: changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Communities', isCorporateCommunicationsTheme),
        icon: <CommunitiesAudiencesIcon />,
        show: true,
      },
      {
        key: 'map',
        path: changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Map', isCorporateCommunicationsTheme),
        show: true,
        icon: <CountriesIcon />,
      },
      {
        key: 'language-risks',
        path: changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme)?.toLowerCase(),
        label: changeLabelTextBasedOnTheme('Language Risk', isCorporateCommunicationsTheme),
        show: true,
        icon: <SentimentIcon />,
      },
      {
        key: 'companies',
        label: 'Companies',
        path: 'companies',
        show: true,
        icon: <CompaniesIcon />,
      },
      {
        key: 'hashtags',
        label: 'Hash Tags',
        path: 'hashtags',
        show: isCorporateCommunicationsTheme,
        icon: <HashTagIcon />,
      },
      {
        key: 'cashtags',
        label: 'Cash Tags',
        path: 'cashtags',
        show: isCorporateCommunicationsTheme,
        icon: <CashTagIcon />,
      },
      {
        key: 'handles',
        label: 'Handles',
        path: 'handles',
        show: isCorporateCommunicationsTheme,
        icon: <HandlesIcon />,
      },
      {
        key: 'people',
        label: 'People',
        path: 'people',
        show: true,
        icon: <PeopleIcon />,
      },
      {
        key: 'politician',
        label: 'Politicians',
        path: 'politician',
        show: true,
        icon: <PoliticianIcon />,
      },
      {
        key: 'languages',
        label: 'Languages',
        path: 'languages',
        show: true,
        icon: <LanguageIcon />,
      },
    ]

    return (
      <div className={classNames('PowerInsightTabsListVertical')}>
        <div className={classNames('fixed', { height: !isDashboard })}>
          {POWER_INSIGHT_TABS.filter((tab) => tab.show)?.map((tab) => (
            <div
              onClick={() => {
                setSelectedPowerInsightTab(tab?.key || '')
                const state = {
                  id: detailObj?.id,
                  name: detailObj?.name,
                  mode: detailObj?.mode,
                  ownerId: detailObj?.ownerId,
                  conditions: detailObj?.conditions,
                  tabName: tab.path,
                  activeTab: tab.path,
                }
                navigate(tab.path as string, { state: JSON.parse(JSON.stringify(state)) })
              }}
              className={classNames(`tab-item`, {
                'tab-item-selected': tab.path
                  ? location.pathname?.includes(tab.path)
                  : !!(!tab.path && !selectedPowerInsightTab),
                fill: tab.fill,
              })}>
              <span className='tab-item__left-icon'>{tab.icon}</span>
              <h6 className='title'>{tab.label}</h6>
              {tab?.icon2 && (
                <Tooltip title={tab?.tooltip || ''} placement='right'>
                  <span className={classNames('tab-item__right-icon', { [tab?.icon2Class]: tab?.icon2Class })}>
                    {tab.icon2}
                  </span>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  },
)

export default PowerInsightTabsListVertical
