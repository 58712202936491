import { Menu } from 'antd'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
import { useLocation, Link } from 'react-router-dom'
import SubMenu from 'antd/lib/menu/SubMenu'

import { ReactComponent as UsersIcon } from 'assets/images/users-icon.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/your-profile.svg'
import { ReactComponent as SpotlightIcon } from 'assets/images/icon-notification.svg'
import { ReactComponent as APIdocsIcon } from 'assets/images/api-docs-icon.svg'
import { ReactComponent as ManageIcon } from 'assets/images/filtericon.svg'

import usePermissions from 'hooks/usePermissions'
import { MANAGE_ADMIN_ACTIONS } from 'settings/settings'

import './NavigationSettings.scss'

export const NavigationSettings = observer((): JSX.Element => {
  const location = useLocation()
  const { userStore } = store
  const { currentRole, tenantId } = userStore
  let selectedKeys: string[] = ['1']

  if (location.pathname.includes('/profile')) selectedKeys = ['1']
  else if (location.pathname.includes('/users')) selectedKeys = ['2']
  else if (location.pathname.includes('/spotlight')) selectedKeys = ['3']
  else if (location.pathname.includes('/power-insights')) selectedKeys = ['4']
  else if (location.pathname.includes('/api-docs')) selectedKeys = ['5']
  else if (location.pathname.includes('/manage')) {
    const path = location?.pathname?.split('/').pop()
    selectedKeys = [path || '']
  }

  const canManageTenant = usePermissions('manageTenant') === 'no_access'
  return (
    <nav className='navigation-settings'>
      <Menu mode='inline' selectedKeys={selectedKeys} triggerSubMenuAction='click' defaultOpenKeys={['admin-actions']}>
        <Menu.Item key='1' id='profile'>
          <Link to='/settings/profile'>
            <ProfileIcon />
            Profile
          </Link>
        </Menu.Item>

        {!canManageTenant && (
          <Menu.Item key='2' id='tenants-configuration'>
            <Link to={`/settings/tenants/configuration/${tenantId}`}>
              <UsersIcon />
              Tenant Configuration
            </Link>
          </Menu.Item>
        )}

        {currentRole === 'DEPLOYMENT_STRATEGIST' && (
          <Menu.Item key='3' id='spotlight'>
            <Link to='/settings/spotlight'>
              <SpotlightIcon />
              Spotlight
            </Link>
          </Menu.Item>
        )}

        <Menu.Item key='5' id='api-docs'>
          <Link to='/settings/api-docs'>
            <APIdocsIcon />
            API Docs
          </Link>
        </Menu.Item>
        {(currentRole === 'ADMIN' || currentRole === 'PENDULUM_ADMIN') && (
          <SubMenu key='admin-actions' icon={<ManageIcon />} title='Manage'>
            {Object.entries(MANAGE_ADMIN_ACTIONS).map(([key, value]) => (
              <Menu.Item key={key}>
                <Link to={value.link}>{value.title}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        )}
      </Menu>
    </nav>
  )
})
