import { observer } from 'mobx-react-lite'
import { store } from 'store'
import millify from 'millify'
import { useEffect, useState } from 'react'
import { Spin, Tooltip } from 'antd'

import Avatar from 'assets/images/LogoiconMobile.svg'
import { ReactComponent as DefaultPlatform } from 'assets/images/unidentified.svg'
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg'

import { DetailsObjType } from 'types/types'
import { SubStore } from 'types/types'
import { CreatorsList } from '../CreatorsList/CreatorsList'
import SearchInput from 'components/common/Inputs/SearchInput/SearchInput'
import { ICreatorData } from 'models/models'
import { GenericDispatch } from 'utils/commonTypes'
import { API } from 'api/api'
import { PLATFORM_ICONS_GREY } from 'settings/settings'

import ButtonImage from 'components/common/Buttons/ButtonImage/ButtonImage'
import { ReactComponent as AddIcon } from 'assets/images/add-icon-white.svg'
import AddToWatchlist from 'components/PowerInsights/AddToWatchlist/AddToWatchlist'
import { ReactComponent as CopyIcon } from 'assets/images/copy-icon.svg'
import { ReactComponent as CopiedIcon } from 'assets/images/copied_icon.svg'
import WarningModal from 'components/Monitor/SnippetList/MonitorSnippetItem/WarningModal/WarningModal'
import classNames from 'classnames'
import { ReactComponent as LeftArrowIcon } from 'assets/images/icons/power-insights/arrow-left.svg'
import './CreatorDetailsPage.scss'

export type CreatorInfo = {
  channel_id: string
  channel_title: string
  channel_url: string
  channel_thumb_url: string
  channel_type: string
  platform: string
  communities: any
  followers_count: number
  following_count: number
  view_count: number
  updated: any
  channel_name: string
  user_name: string
  channel_about: string
  related_profiles: { url: string; platform: string | null }[]
}

type SimilarCreator = {
  channel_id: string
  channel_title: string
  channel_name: string
  channel_url: string
  channel_thumb_url: string
  channel_type: string
  view_count: number
  platform: string
  followers_count: number
  following_count: number
  score: string
}

type WatchlistInfo = {
  id: string
  name: string
  description: string
  tenant_id: string
  owner_id: string
  channels: {
    platform: string
    channel_id: string
    channel_url: string
    input_channel_url: string
    user_input_url: string
    channel_thumb_url: string
  }[]
  total_channels: number
  alert_id: string
  created: string
  last_update_time: string | null
  last_updated_by: string | null
}

interface Props {
  subStore: SubStore
  isBackButtonEnabled?: boolean
  parentDetailObj: DetailsObjType | null
  previousItem?: any
  setActivePowerInsightTab?: GenericDispatch<string>
  corporateCommunications?: boolean
  onClose?: () => void
}
export const CreatorDetailsPage = observer((props: Props) => {
  const {
    subStore,
    parentDetailObj,
    previousItem,
    setActivePowerInsightTab,
    corporateCommunications = false,
    onClose = () => {},
  } = props

  const [searchInput, setSearchInput] = useState('')
  const [creatorInfo, setCreatorInfo] = useState<CreatorInfo | null>(null)
  const [similarCreators, setSimilarCreators] = useState<{
    total_count: number
    items: ICreatorData[]
  }>({ total_count: 0, items: [] })
  const [searchedCreators, setSearchedCreators] = useState<ICreatorData[]>([])
  const [showSearchedData, setShowSearchedData] = useState<boolean>(false)
  const [creatorWatchlistData, setCreatorWatchlistData] = useState<WatchlistInfo[]>([])
  const [isOpenAddToWatchlist, setIsOpenAddToWatchlist] = useState(false)
  const [copied, setCopied] = useState<string | null>(null)
  const [channelUrl, setChannelUrl] = useState('')

  const {
    activeItem,
    snippetsFilter,
    openedCreatorId,
    setPreviousInfo,
    setOpenedCreatorId,
    setOpenedCreatorIdHistory,
    fetchSnippetsInfo,
    resetSnippetFilters,
  } = store[`${subStore}Store`]

  const { isLoadingCreators } = store['loaderStore']

  const copyToClipboard = (event: any, url?: string) => {
    if (!url) return
    event.stopPropagation()
    navigator.clipboard
      .writeText(url)
      .then((res) => {
        setCopied(url)
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard. Please try again.', { error })
      })
  }

  useEffect(() => {
    let copiedRef: NodeJS.Timeout | undefined
    if (copied) {
      copiedRef = setTimeout(() => {
        setCopied(null)
      }, 3000)
    }

    return () => {
      if (copiedRef) {
        clearTimeout(copiedRef)
      }
    }
  }, [copied])

  useEffect(() => {
    fetchCreator()
    fetchCreatorWatchlistsInfo()
    fetchSimilarCreators()
    if (!corporateCommunications) resetSnippetFilters()
  }, [openedCreatorId])

  const fetchCreator = async () => {
    try {
      setPreviousInfo({ filter: snippetsFilter, item: previousItem ? previousItem : activeItem })
      let { data } = await API.get({
        route: 'channel',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: openedCreatorId,
      })
      if (data?.items?.length > 0) {
        const creator = data.items[0]
        setCreatorInfo(creator)
        fetchSnippetsInfo({
          id: creator.channel_id,
          name: creator.channel_title || creator.channel_name,
          conditions: { channel_ids: [creator.channel_id] },
          mode: 'channel',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCreatorWatchlistsInfo = async () => {
    try {
      const { data } = await API.get({
        route: 'watchlist',
        customAPI: process.env.REACT_APP_API,
        customProps: { channel_id: openedCreatorId },
        isPromise: true,
      })
      if (data?.items?.length > 0) {
        const updatedData = data?.items?.map((item: any) => {
          return {
            ...item,
            channels: item.channels?.flatMap((channel: any) => channel.channel_by_platform),
          }
        })
        setCreatorWatchlistData(updatedData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSimilarCreators = async () => {
    try {
      let { data } = await API.get({
        route: 'similarChannels',
        customAPI: process.env.REACT_APP_SEARCH_API,
        ids: openedCreatorId,
      })

      if (data?.items?.length > 0) {
        const creatorsData: SimilarCreator[] = data.items?.[0].similar_channels || []

        const updatedData = creatorsData.map((item) => {
          return {
            channel_id: item.channel_id,
            channel_title: item.channel_title || item.channel_name,
            channel_name: item.channel_name,
            channel_url: item.channel_url,
            channel_thumb_url: item.channel_thumb_url,
            channel_type: item.channel_type,
            platform: item.platform,
            followers_count: item.followers_count,
            view_count: item.view_count,
            snippet_count: 0,
            impression_sum: 0,
          }
        })

        setSimilarCreators({
          items: updatedData,
          total_count: updatedData.length,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const searchCreators = () => {
    if (searchInput.length > 0) {
      setShowSearchedData(true)
      const creators =
        similarCreators?.items?.filter((creator) =>
          (creator.channel_title || creator.channel_name).toLowerCase().includes(searchInput.toLowerCase()),
        ) || []
      setSearchedCreators(creators)
    } else {
      setShowSearchedData(false)
      setSearchedCreators([])
    }
  }

  const updateSimilarCreatorWatchlistInfo = (channelInfo: { channel_id: string; status: boolean }[]) => {
    const data = similarCreators
    if (data && channelInfo.length > 0) {
      channelInfo.forEach((info) => {
        let index = data?.items.findIndex((item) => item.channel_id === info.channel_id)
        if (index >= 0) {
          data.items[index].hasWatchlist = info.status
        }
      })
      setSimilarCreators(data)
    }
  }

  const getPlatformIcon = (platform: string) =>
    PLATFORM_ICONS_GREY.find((item) => item.title.toLowerCase() === platform)?.icon || <DefaultPlatform />

  return (
    <Spin spinning={isLoadingCreators}>
      <div className='creator-details'>
        <ButtonImage
          onClick={() => {
            const id = setOpenedCreatorIdHistory('', 'pop')
            if (id) {
              setOpenedCreatorId(id)
              return
            }
            if (corporateCommunications) {
              onClose()
            } else {
              setActivePowerInsightTab?.('creators')
              setOpenedCreatorId('')
              fetchSnippetsInfo(parentDetailObj!)
            }
          }}
          className='creator-details__back-btn'
          icon={LeftArrowIcon}>
          Back
        </ButtonImage>
        <div className='creator-details__info-container'>
          <img
            className='creator-details__info-container__avatar'
            src={creatorInfo?.channel_thumb_url || Avatar}
            alt='user'
            onError={(e: any) => {
              e.target.src = Avatar
            }}
          />
          <div className='creator-details__info-container__container-0'>
            <div className='creator-details__info-container__container-1'>
              <div className='creator-details__info-container__container-2'>
                <span className='creator-details__info-container__title'>
                  {creatorInfo?.channel_title || creatorInfo?.channel_name}
                </span>
                <span className='creator-details__info-container__url'>{creatorInfo?.channel_url}</span>
              </div>

              <div className='creator-details__info-container__container-3'>
                <div className='creator-details__info-container__container-4'>
                  <span className='creator-details__info-container__following-container__count'>
                    {Number.isSafeInteger(creatorInfo?.followers_count) && Number(creatorInfo?.followers_count) >= 0
                      ? millify(Number(creatorInfo?.followers_count))
                      : 'N/A'}
                  </span>
                  <span className='creator-details__info-container__following-container__title'>Followers</span>
                </div>
                <div className='creator-details__info-container__container-4'>
                  <span className='creator-details__info-container__following-container__count'>
                    {Number.isSafeInteger(creatorInfo?.following_count) && Number(creatorInfo?.following_count) >= 0
                      ? millify(Number(creatorInfo?.following_count))
                      : 'N/A'}
                  </span>
                  <span className='creator-details__info-container__following-container__title'>Following</span>
                </div>
              </div>
            </div>

            <div className='creator-details__info-container__social_ids'>
              {creatorInfo?.platform && (
                <Tooltip title={creatorInfo?.channel_url} overlayClassName='platforms-tooltip'>
                  <div
                    className={classNames(
                      'creator-details__info-container__platform',
                      `${creatorInfo?.platform?.toLowerCase()}`,
                    )}
                    onClick={() => setChannelUrl(creatorInfo?.channel_url || '')}>
                    <span className='icon'>{getPlatformIcon(creatorInfo?.platform?.toLowerCase() || '')}</span>
                    <span
                      className='creator-details__info-container__platform__copy-icon'
                      onClick={(e: any) => {
                        if (!copied) {
                          copyToClipboard(e, creatorInfo?.channel_url)
                        }
                      }}>
                      {copied === creatorInfo?.channel_url ? <CopiedIcon /> : <CopyIcon />}
                    </span>
                  </div>
                </Tooltip>
              )}

              {creatorInfo?.related_profiles?.map((item) => (
                <Tooltip title={item.url} overlayClassName='platforms-tooltip'>
                  <div
                    className={classNames(
                      'creator-details__info-container__platform',
                      `${item.platform?.toLowerCase()}`,
                    )}
                    onClick={() => setChannelUrl(item.url || '')}>
                    <span className='icon'>{getPlatformIcon(item.platform?.toLowerCase() || '')}</span>
                    <span
                      className='creator-details__info-container__platform__copy-icon'
                      onClick={(e: any) => {
                        if (!copied) {
                          copyToClipboard(e, item.url)
                        }
                      }}>
                      {copied === item.url ? <CopiedIcon /> : <CopyIcon />}
                    </span>
                  </div>
                </Tooltip>
              ))}
              {channelUrl && (
                <WarningModal
                  link={channelUrl}
                  onContinue={() => {
                    setChannelUrl('')
                    window.open(channelUrl || '#', '_blank', 'noopener,noreferrer')
                  }}
                  onClose={() => {
                    setChannelUrl('')
                  }}
                />
              )}
            </div>
          </div>
        </div>

        {creatorWatchlistData.length > 0 && (
          <div className='creator-details__watchlists'>
            <span className='creator-details__watchlists__heading'>Related watchlists:</span>
            <div className='creator-details__watchlists__grid'>
              {creatorWatchlistData.map((item) => (
                <div className='creator-details__watchlists__container'>
                  <div className='creator-details__watchlists__logo-container'>
                    <div className='creator-details__watchlists__logo-wrapper'>
                      {item.channels?.slice(0, 3)?.map((channel, index) => (
                        <img
                          src={channel?.channel_thumb_url || Avatar}
                          className='creator-details__watchlists__logo'
                          alt={'Logo' + index}
                          onError={(e: any) => {
                            e.target.src = Avatar
                          }}
                        />
                      ))}
                    </div>
                    {item.total_channels > 3 && (
                      <div className='creator-details__watchlists__remaining-count'>{`+${
                        item.total_channels - 3
                      }`}</div>
                    )}
                  </div>
                  <div className='creator-details__watchlists__info'>
                    <span className='creator-details__watchlists__title'>{item.name}</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
                      <circle cx='2' cy='2' r='2' fill='#D0D5DD' />
                    </svg>
                    <span className='creator-details__watchlists__count'>{`${item.total_channels} creators`}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <ButtonImage
          onClick={() => setIsOpenAddToWatchlist(true)}
          className='creator-details__watchlists__add-to-watchlist'
          icon={AddIcon}>
          Add to {corporateCommunications ? 'Influencer List' : 'Watchlist'}
        </ButtonImage>
        {isOpenAddToWatchlist && (
          <AddToWatchlist
            open={isOpenAddToWatchlist}
            onClose={() => setIsOpenAddToWatchlist(false)}
            creatorsInfo={creatorInfo ? [creatorInfo] : []}
          />
        )}
        <hr />

        <div className='creator-details__suggested-container'>
          <span className='creator-details__similar-text'>Similar creators</span>
          <SearchInput
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onPressEnter={searchCreators}
            onClear={() => {
              setShowSearchedData(false)
              setSearchInput('')
              setSearchedCreators([])
            }}
            className='creator-details__search__container'
            placeholder={'Search'}
            iconClassName={'creator-details__search__icon'}
            prefix={SearchIcon}
            showIcon={true}
          />
          <CreatorsList
            data={similarCreators}
            subStore={subStore}
            showSearchedData={showSearchedData}
            searchedCreator={searchedCreators || []}
            isLoadingPICreators={false}
            showSelectionCheckBoxes={false}
            isSimilarCreators={true}
            updateSimilarCreatorWatchlistInfo={updateSimilarCreatorWatchlistInfo}
          />
        </div>
      </div>
    </Spin>
  )
})
