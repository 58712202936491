import { Button } from 'antd'

import { ReactComponent as PlusIcon } from 'assets/images/plus-icon.svg'
import { ReactComponent as EmptyFileIcon } from 'assets/images/empty_file.svg'

import classNames from 'classnames'

import './DashboardEmptyState.scss'

const LoadingSVG = () => (
  <svg width='167' height='149' viewBox='0 0 167 149' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M112.494 51.236C100.647 51.236 88.5868 50.7547 77.3054 47.6124C66.2363 44.5408 56.0732 38.5816 47.0141 31.6882C41.0832 27.2011 35.6902 23.6341 27.99 24.172C20.4504 24.5847 13.244 27.4137 7.43716 32.2402C-2.35799 40.7331 -0.885884 56.629 3.035 67.7972C8.92341 84.6131 26.8434 96.2625 42.074 103.878C59.6685 112.668 79.004 117.764 98.382 120.708C115.368 123.284 137.194 125.167 151.916 114.069C165.433 103.878 169.142 80.6073 165.83 64.8954C165.027 60.2496 162.555 56.0568 158.88 53.1044C149.382 46.1686 135.213 50.7972 124.54 51.0378C120.577 51.1228 116.543 51.2218 112.494 51.236Z'
      fill='#F5EDDE'
    />
    <path
      d='M83.5058 148.238C112.204 148.238 135.468 146.787 135.468 144.996C135.468 143.206 112.204 141.755 83.5058 141.755C54.8078 141.755 31.5435 143.206 31.5435 144.996C31.5435 146.787 54.8078 148.238 83.5058 148.238Z'
      fill='#F5EDDE'
    />
    <path
      d='M9.67403 100.822C9.67403 101.197 9.36983 101.501 8.9946 101.501C8.61936 101.501 8.31516 101.197 8.31516 100.822C8.31516 100.447 8.61935 100.142 8.9946 100.142C9.36984 100.142 9.67403 100.447 9.67403 100.822Z'
      fill='#CFCFCF'
      stroke='#EAECF0'
      strokeWidth='1.41548'
    />
    <path
      d='M68.783 119.801V125.902'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M65.739 122.858H71.8256'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M141.865 23.8604H25.1305C22.113 23.8604 19.6667 26.3066 19.6667 29.3241V109.398C19.6667 112.416 22.113 114.862 25.1305 114.862H141.865C144.883 114.862 147.329 112.416 147.329 109.398V29.3241C147.329 26.3066 144.883 23.8604 141.865 23.8604Z'
      fill='white'
      stroke='#D0D5DD'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M130.118 48.0645H36.8657C34.7081 48.0645 32.959 49.8136 32.959 51.9712V102.858C32.959 105.015 34.7081 106.765 36.8657 106.765H130.118C132.275 106.765 134.024 105.015 134.024 102.858V51.9712C134.024 49.8136 132.275 48.0645 130.118 48.0645Z'
      fill='white'
      stroke='#D0D5DD'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M147.329 39.1915V29.4105C147.331 28.6918 147.191 27.9798 146.917 27.3153C146.644 26.6508 146.241 26.0468 145.734 25.5379C145.226 25.0291 144.623 24.6253 143.96 24.3499C143.296 24.0744 142.584 23.9326 141.865 23.9326H25.1305C24.4118 23.9326 23.7002 24.0744 23.0364 24.3499C22.3725 24.6253 21.7696 25.0291 21.2621 25.5379C20.7545 26.0468 20.3523 26.6508 20.0786 27.3153C19.8049 27.9798 19.6649 28.6918 19.6668 29.4105V39.3189L147.329 39.1915Z'
      fill='#D0D5DD'
    />
    <path
      d='M58.0259 14.6738V32.2966'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M108.983 14.6738V32.2966'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M128.589 14.4633C128.589 14.8386 128.284 15.1428 127.909 15.1428C127.534 15.1428 127.23 14.8386 127.23 14.4633C127.23 14.0881 127.534 13.7839 127.909 13.7839C128.284 13.7839 128.589 14.0881 128.589 14.4633Z'
      fill='#CFCFCF'
      stroke='#EAECF0'
      strokeWidth='1.41548'
    />
    <path
      d='M41.904 15.6792C41.904 16.0544 41.5998 16.3586 41.2246 16.3586C40.8493 16.3586 40.5451 16.0544 40.5451 15.6792C40.5451 15.3039 40.8493 14.9997 41.2246 14.9997C41.5998 14.9997 41.904 15.3039 41.904 15.6792Z'
      fill='#CFCFCF'
      stroke='#EAECF0'
      strokeWidth='1.41548'
    />
    <path
      d='M76.7256 6.49316V12.5797'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M73.6819 9.53711H79.7685'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M12.9155 1V7.08658' stroke='#EAECF0' strokeWidth='1.41548' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M9.87183 4.04395H15.9726'
      stroke='#EAECF0'
      strokeWidth='1.41548'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M43.6167 77.5361H122.884' stroke='#EAECF0' strokeWidth='5.66193' strokeLinecap='round' />
    <path d='M53.5251 77.5361H90.3277' stroke='#D0D5DD' strokeWidth='5.66193' strokeLinecap='round' />
    <path d='M43.6167 77.5361H80.4193' stroke='#CCA55A' strokeWidth='5.66193' strokeLinecap='round' />
  </svg>
)

const Video = ({ isLoading }: { isLoading: boolean }) => (
  <video width='385' height='200' controls className={classNames('no-brand-video', { loading: isLoading })}>
    <source
      src='https://pendulum-webapp-images.s3.us-west-2.amazonaws.com/videos/Ping+Dashboard+Overview.mp4'
      type='video/mp4'
    />
    Your browser does not support the video tag.
  </video>
)

const DashboardEmptyState = ({
  title = '',
  buttonText = '',
  description = '',
  onClick,
  isSku = false,
  isSkuLoading = false,
  canCreate = true,
}: {
  title: string
  buttonText?: string
  description: string
  onClick?: () => void
  isSku?: boolean
  isSkuLoading?: boolean
  canCreate: boolean
}) => {
  return (
    <div className={classNames('no-brand-placeholder', { isSkuLoading })}>
      {isSku && (isSkuLoading ? <LoadingSVG /> : <Video isLoading={isSkuLoading} />)}

      {!isSku && (
        <div className='no-brand-placeholder__logo'>
          <EmptyFileIcon />
        </div>
      )}

      <div className='no-brand-placeholder__info'>
        <div className='no-brand-placeholder__info__title'>{title}</div>
        <div className='no-brand-placeholder__info__description'>{description}</div>
      </div>

      {buttonText && (
        <Button icon={<PlusIcon />} type='primary' onClick={onClick} disabled={!canCreate}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default DashboardEmptyState
