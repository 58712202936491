import { Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import ReactECharts from 'echarts-for-react'

import { Unknown } from 'utils/commonTypes'

import BrandDataFetching from 'components/common/DataFetching/BrandDataFetching'

import { ReactComponent as EmptyFile } from 'assets/images/empty_file.svg'

import { NarrativeListDataType } from 'types/types'
import { store } from 'store'

const FollowedTopicChart = ({
  data = [],
  loading = false,
  handleBarClick,
  subStore = 'assets',
}: {
  data?: Unknown
  loading: boolean
  subStore?: 'assets' | 'vectors'
  handleBarClick?: (value: NarrativeListDataType) => void
}) => {
  const { updateFilterChips, setSnippetsFilter, snippetsFilter, filterChips } = store[`${subStore}Store`]

  const [selectedBar, setSelectedBar] = useState<number | null>(null)

  const barHeight = data?.length * 2 * 20 + 100
  const chartHeight = Math.max(barHeight, 400)

  const option = {
    xAxis: { show: false, type: 'value' },
    yAxis: { show: false, type: 'category', data: data?.map((item: Unknown) => item.name) },
    series: [
      {
        type: 'bar',
        data: data,
        barWidth: 20,
        itemStyle: {
          color: (params: any) => (params.dataIndex === selectedBar ? '#cca55a' : '#A336D9'),
          borderRadius: 4,
        },
        label: {
          show: true,
          position: [0, -18],
          formatter: (params: any) => `{a|${params.name}}`,
          rich: {
            a: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#333',
              align: 'left',
            },
          },
        },
      },
      {
        type: 'bar',
        data: data,
        barWidth: 20,
        itemStyle: {
          color: 'transparent',
        },
        label: {
          show: true,
          distance: 0,
          position: 'right',
          offset: [10, -24],
          formatter: (params: any) => `{b|${params.value}}`,
          rich: {
            b: {
              fontSize: 12,
              fontWeight: 'bold',
              color: '#333',
              align: 'left',
              verticalAlign: 'middle',
            },
          },
        },
      },
    ],
    grid: {
      left: 0,
      right: 50,
      top: 20,
      bottom: 0,
      containLabel: false,
    },
  }

  const CreatorLoader = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width: '100%',
        }}>
        {Array.from({ length: 10 }, (value) => (
          <Skeleton.Input active={true} size='large' style={{ height: 20, borderRadius: '4px', minWidth: '100%' }} />
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (!filterChips.flags.value) {
      setSelectedBar(null)
    }
  }, [filterChips])

  return (
    <>
      {loading ? (
        <CreatorLoader />
      ) : data?.length > 0 ? (
        <ReactECharts
          option={option}
          style={{ height: chartHeight, width: '100%' }}
          onEvents={{
            click: (params: Unknown) => {
              const newSelection = params.dataIndex === selectedBar ? null : params.dataIndex
              setSelectedBar(newSelection)
              if (newSelection !== null) {
                updateFilterChips('flags', params.data.name)
                setSnippetsFilter({
                  ...snippetsFilter,
                  flags: { name: params.data.name, conditions: params.data?.conditions },
                })
              }
              if (newSelection === null) {
                updateFilterChips('flags', '')
                let { flags, ...filter } = snippetsFilter
                setSnippetsFilter(filter)
              }
              handleBarClick?.(newSelection !== null ? params.data : null)
            },
          }}
        />
      ) : (
        <BrandDataFetching emptyStateIcon={<EmptyFile />} emptyStateTitle={'No Data to display'} />
      )}
    </>
  )
}

export default observer(FollowedTopicChart)
