import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Button, Form, Input, Modal, Spin } from 'antd'

import AddSearchStep from './AddSearchStep'
import Platform from 'components/Vectors/Platform'
import AddLinksAndLogoStep from './AddLinksAndLogoStep'

import { ReactComponent as InfoIcon } from 'assets/images/infoicon.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/deleteicon.svg'
import { ReactComponent as EmptyStateIcon } from 'assets/images/icons/dashboard/empty-state.svg'

import SnippetListWithFilters from 'components/SnippetListWithFilters/SnippetListWithFilters'
import useHandleCreateBrand from 'components/Asset/BrandsDashboard/hooks/useHandleCreateBrand'
import TopicsInfoStats from './TopicsInfoStats'

import { PLATFORM_ICONS_GREY } from 'settings/settings'

import { DetailsObjType } from 'types/types'
import { MaybeNull } from 'utils/commonTypes'
import { AssetListObjType } from 'store/asset/types'

import './AddBrandWizard.scss'
import { store } from 'store'

const AddBrandWizard = ({
  isTopicCreation,
  isNarrative = false,
  editedBrandData,
  handleCloseModal,
  setSelectedBrand,
}: {
  isTopicCreation: boolean
  isNarrative?: boolean
  handleCloseModal: (updatedData?: any) => void
  editedBrandData: MaybeNull<AssetListObjType>
  setSelectedBrand: (brand: AssetListObjType) => void
}) => {
  const isEditMode = (editedBrandData?.id?.length ?? 0) > 0
  const {
    form,
    formData,
    activeStep,
    setFormData,
    filteredData,
    showSnippets,
    isLoadingFeed,
    handleEditURL,
    isSearchActive,
    handleBackStep,
    handleNextStep,
    setFilteredData,
    isBooleanActive,
    handleDeleteURL,
    selectedRowKeys,
    invalidLinkCount,
    cancelURLEditing,
    supportedPlatform,
    handleSearchClick,
    handleValuesChange,
    createBrandLoading,
    validateURLLoading,
    handleSelectedRows,
    handleConfirmEditURL,
    verifyAndGetPlatform,
    booleanSearchLoading,
    copilotSearchHandler,
    handleBulkRowsSelection,
    isCorporateCommunicationsTheme,
    fetchInsightsMetrics,
    corporateMode,
    setCorporateMode,
  } = useHandleCreateBrand(setSelectedBrand, isTopicCreation, isNarrative, isEditMode)
  const { setSelectedLanguages, setBooleanSearchTranslation } = store.defineNarrativeStore
  const { resetSnippetFilters } = store.assetsModalStore

  const [languageState, setLanguageState] = useState<'on' | 'off' | 'selected'>('off')
  const [selectedLanguagesCode, setSelectedLanguagesCode] = useState<string[]>([])
  const [languageRef, setLanguageRef] = useState<HTMLElement | null>(null)
  const [translatedLanguageText, setTranslatedLanguageText] = useState('')
  const [languageScrollOffset, setLanguageScrollOffset] = useState(0)
  const selectRef = useRef(null)

  const platforms = supportedPlatform?.map((pf) => {
    const matchingIcon = PLATFORM_ICONS_GREY?.find((item) => item.title?.toLowerCase() === pf?.platform?.toLowerCase())
    return matchingIcon ? { ...matchingIcon, ...pf } : pf
  })

  const sampleURLObject = platforms?.find((item) => item.platform?.toLowerCase() === formData.platform?.toLowerCase())

  const brandFinalStep = 3
  const topicFinalStep = 1
  const isTopicStatsMode = isTopicCreation && !isEditMode && corporateMode === 'stats'
  const showCorporateStatsMode = isTopicStatsMode && isCorporateCommunicationsTheme && showSnippets
  const addBrandTitle = editedBrandData?.id ? 'Edit brand' : 'Add new brand'
  let addTopicTitle = isNarrative ? 'Create new Horizon topic' : 'Create new Followed topic'
  if (editedBrandData?.id) addTopicTitle = 'Edit topic'
  else if (showCorporateStatsMode)
    addTopicTitle = isNarrative ? 'Preview new Horizon topic' : 'Preview new Followed topic'

  const brandContinueButtonText = editedBrandData?.id ? 'Confirm and Update Brand' : 'Confirm and Save new Brand'
  const topicContinueButtonText = editedBrandData?.id
    ? 'Confirm and Update Topic'
    : isTopicStatsMode
    ? isNarrative
      ? 'Create Topic'
      : 'Create and Follow Topic'
    : 'Next'

  const modalTitle = isTopicCreation ? addTopicTitle : addBrandTitle
  const finalStep = isTopicCreation ? topicFinalStep : brandFinalStep
  const continueButtonText = isTopicCreation ? topicContinueButtonText : brandContinueButtonText

  useEffect(() => {
    return () => {
      setBooleanSearchTranslation({})
      setSelectedLanguages([])
      resetSnippetFilters(90)
    }
  }, [])

  useEffect(() => {
    if (editedBrandData?.id) {
      const data: any = {
        brandName: editedBrandData?.name,
        logoURL: editedBrandData?.metadata?.brand_url || '',
        booleanKeyword: editedBrandData?.insight_condition || '',
        booleanSearch: editedBrandData?.insight_condition || '',
        addedURLs:
          editedBrandData?.metadata?.brand_media_links?.map((item) => ({
            platform: item.platform,
            channel_url: item.url,
          })) || [],
        userSearchString: '',
      }
      if (
        editedBrandData['multi_lang_kwd_expression'] &&
        Object.keys(editedBrandData['multi_lang_kwd_expression'])?.length &&
        isNarrative
      ) {
        setLanguageState('selected')
        setTranslatedLanguageText(editedBrandData['boolean_query'])
        setSelectedLanguagesCode(Object.keys(editedBrandData['multi_lang_kwd_expression']))
        setSelectedLanguages(Object.keys(editedBrandData['multi_lang_kwd_expression']))
        setBooleanSearchTranslation({
          ...editedBrandData['multi_lang_kwd_expression'],
          Default: editedBrandData['boolean_query'],
        })
        data.booleanSearch = editedBrandData['boolean_query']
      }
      form.setFieldsValue(data)
      setFilteredData(data.addedURLs)
      setFormData({
        ...formData,
        ...data,
      })
      const detailObj: DetailsObjType = {
        comingFrom: '',
        conditions: {
          keywords_expression: isTopicCreation ? editedBrandData?.boolean_query : editedBrandData?.insight_condition,
        },
        mode: 'narrative',
        name: 'Power Insights',
      }
      handleSearchClick(detailObj)
    }
  }, [editedBrandData])

  return (
    <div>
      <Modal
        open={true}
        destroyOnClose
        onOk={() => null}
        closable={true}
        title={modalTitle}
        onCancel={handleCloseModal}
        className='pulse-date-selection-modal brand-creation-modal'
        footer={
          <div
            className={classNames('pulse-content-selection-modal__footer brand-creation-modal__footer', {
              footer: isTopicStatsMode,
            })}>
            {isTopicStatsMode && corporateMode === 'stats' && (
              <Button type='text' className='footer__edit-btn' onClick={() => setCorporateMode('search')}>
                Back to editing
              </Button>
            )}

            <div>
              <Button type='text' onClick={() => (activeStep < 2 ? handleCloseModal() : handleBackStep())}>
                {activeStep < 2 ? 'Cancel' : 'Back'}
              </Button>
              <Button
                type='primary'
                loading={createBrandLoading}
                onClick={() => handleNextStep(handleCloseModal, editedBrandData)}
                disabled={!!invalidLinkCount && !selectedRowKeys?.length}
                icon={selectedRowKeys?.length ? <DeleteIcon /> : undefined}
                className={classNames('continue-btn', { 'delete-btn': selectedRowKeys?.length })}>
                {selectedRowKeys?.length
                  ? `Delete ${selectedRowKeys?.length} ${selectedRowKeys?.length > 1 ? 'links' : 'link'} Selected`
                  : activeStep === finalStep
                  ? continueButtonText
                  : 'Continue'}
              </Button>
            </div>
          </div>
        }>
        <Form form={form} layout='vertical' initialValues={formData} onValuesChange={handleValuesChange}>
          <div className='brand-creation-modal__content'>
            <div className='brand-creation-modal__content--left-section'>
              <div className='creation-flow'>
                {showCorporateStatsMode && activeStep === 1 ? (
                  <TopicsInfoStats form={formData} fetchInsightsMetrics={fetchInsightsMetrics} subStore='vectors' />
                ) : (
                  activeStep === 1 && (
                    <AddSearchStep
                      isTopicCreation={isTopicCreation}
                      isSearchActive={isSearchActive}
                      isBooleanActive={isBooleanActive}
                      handleSearchClick={handleSearchClick}
                      convertBooleanClick={copilotSearchHandler}
                      convertBooleanLoading={booleanSearchLoading}
                      isEditMode={isEditMode}
                      form={form}
                      formData={formData}
                      setFormData={setFormData}
                      languageRef={languageRef}
                      languageScrollOffset={languageScrollOffset}
                      languageState={languageState}
                      selectRef={selectRef}
                      selectedLanguagesCode={selectedLanguagesCode}
                      setLanguageRef={setLanguageRef}
                      setLanguageScrollOffset={setLanguageScrollOffset}
                      setLanguageState={setLanguageState}
                      setSelectedLanguagesCode={setSelectedLanguagesCode}
                      setTranslatedLanguageText={setTranslatedLanguageText}
                      translatedLanguageText={translatedLanguageText}
                      isNarrative={isNarrative}
                    />
                  )
                )}
                {(activeStep === 2 || activeStep === 3) && !isTopicCreation && (
                  <AddLinksAndLogoStep
                    formData={formData}
                    activeStep={activeStep}
                    setFormData={setFormData}
                    filteredData={filteredData}
                    handleEditURL={handleEditURL}
                    handleDeleteURL={handleDeleteURL}
                    selectedRowKeys={selectedRowKeys}
                    validateURLLoading={validateURLLoading}
                    handleSelectedRows={handleSelectedRows}
                    verifyAndGetPlatform={verifyAndGetPlatform}
                    handleBulkRowsSelection={handleBulkRowsSelection}
                  />
                )}
              </div>
            </div>
            <div className='brand-creation-modal__content--right-section right-section'>
              <Spin spinning={isLoadingFeed} className='right-section--spinner' />
              {showSnippets && (
                <SnippetListWithFilters
                  containFiltersInComponent
                  uniqueScrollId='add-new-brand__id'
                  isCustomEmptyState
                  emptyStateDescription={''}
                  emptyStateIcon={<EmptyStateIcon />}
                  emptyStateTitle={'No snippets found'}
                  isForModal
                  noOfDays={90}
                />
              )}
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        open={!!formData.edit_url && !formData?.platform}
        destroyOnClose
        onOk={() => null}
        closable={true}
        title='Edit link'
        onCancel={cancelURLEditing}
        className='pulse-date-selection-modal watchlist-edit-url'
        footer={
          <div className='pulse-content-selection-modal__footer'>
            <Button type='text' onClick={cancelURLEditing}>
              Cancel
            </Button>
            <Button type='primary' onClick={handleConfirmEditURL} loading={validateURLLoading}>
              Confirm
            </Button>
          </div>
        }>
        <div className='edit-url-modal--content'>
          <div className='edit-url-modal__info'>
            <div className='edit-url-modal__info__icon'>
              <InfoIcon />
            </div>
            <div className='edit-url-modal__info__text'>
              <div className='edit-url-modal__info__text__title'>
                We were unable to identify the intended platform for the provided link
              </div>
              <div className='edit-url-modal__info__text__description'>
                Please ensure the link is correct and compatible with our supported platforms. Here’s a guide to
                formatting links:
                <ul>
                  <li>
                    Start the URL with <span>https://.</span>
                  </li>
                  <li>Avoid spaces and special characters that are not URL-friendly.</li>
                  <li>Ensure the link is active and not restricted.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='edit-url-modal__input-field'>
            <label>
              <span className='input-label'>
                Link <span className='asterisk'>*</span>
              </span>
              <Form.Item
                name='edit_url'
                rules={[{ required: true, message: 'URL required' }]}
                shouldUpdate={(prev, current) => prev.field !== current.field}>
                <Input placeholder='Please paste correct URL' key={'watchlist-add-details-name-input'} />
              </Form.Item>
              <span className='error-message'>This link is invalid. Please add the correct link.</span>
            </label>
          </div>
        </div>
      </Modal>
      <Modal
        open={!!formData.edit_url && !!formData?.platform}
        destroyOnClose
        onOk={() => null}
        closable={true}
        title='Edit link'
        onCancel={cancelURLEditing}
        className='pulse-date-selection-modal watchlist-edit-url'
        footer={
          <div className='pulse-content-selection-modal__footer'>
            <Button type='text' onClick={cancelURLEditing}>
              Cancel
            </Button>
            <Button type='primary' onClick={handleConfirmEditURL} loading={validateURLLoading}>
              Confirm
            </Button>
          </div>
        }>
        <div className='edit-url-modal--content'>
          <div className='previous-info'>
            <div className='previous-info--header'>
              <div className='previous-info__text'>Platform</div>
              <div className='previous-info__platform'>
                <Platform platform={formData.platform || ''} />
              </div>
            </div>
            <div className='previous-info__url'>
              <InfoIcon />
              <div>
                URL example: {sampleURLObject?.sample_urls?.map((url, index) => <div key={index}>{url}</div>) || ''}
              </div>
            </div>
          </div>
          <div className='edit-url-modal__input-field'>
            <label>
              <span className='input-label'>
                Link <span className='asterisk'>*</span>
              </span>
              <Form.Item
                name='edit_url'
                rules={[{ required: true, message: 'URL required' }]}
                shouldUpdate={(prev, current) => prev.field !== current.field}>
                <Input placeholder='Please paste correct URL' key={'watchlist-add-details-name-input'} />
              </Form.Item>
              <span className='error-message'>This link is invalid. Please add the correct link.</span>
            </label>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default observer(AddBrandWizard)
