import { useMemo } from 'react'
import classNames from 'classnames'
import { Input, Table, TableProps } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import CreatorInfo from '../CreatorInfo'
import Checkbox from 'components/common/Checkbox/Checkbox'

import { ReactComponent as NoDataIcon } from 'assets/images/no_data_icon.svg'
import { ReactComponent as WarningIcon } from 'assets/images/warningicon.svg'
import { ReactComponent as ArrowIcon } from 'assets/images/sort-arrow-icon.svg'
import { ReactComponent as DefaultSortIcon } from 'assets/images/sorter-icon.svg'

import { PLATFORM_ICONS_GREY } from 'settings/settings'
import { GenericDispatch, Unknown } from 'utils/commonTypes'
import { betaTag } from 'components/Monitor/SnippetFilter/SnippetFilter'
import * as types from 'pages/Vectors/CreateEditWatchListStepper/types'
import Platform from '../Platform'

const AddLinks = ({
  formData,
  activeStep,
  sortStates,
  setFormData,
  filteredData,
  handleEditURL,
  selectedFilter,
  handleDeleteURL,
  selectedRowKeys,
  handleFilterURLs,
  handleInputChange,
  handleTableChange,
  handleSelectedRows,
  handleBulkRowsSelection,
  handleCreatorInfoLoading,
}: {
  activeStep: number
  selectedFilter: string
  selectedRowKeys: string[]
  sortStates: types.SortInfo
  handleTableChange: Unknown
  formData: types.FormDataType
  handleBulkRowsSelection: () => void
  handleDeleteURL: (val: string) => void
  handleFilterURLs: (url: string) => void
  filteredData: types.FormDataType['addedURLs']
  setSelectedRowKeys: GenericDispatch<string[]>
  setFormData: GenericDispatch<types.FormDataType>
  handleSelectedRows: (val: string) => void
  handleEditURL: (val: string, platform?: string) => void
  handleCreatorInfoLoading: (isLoading: boolean) => void
  handleInputChange: (url: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const invalidLinkCount = formData?.addedURLs?.filter((item) => item.isInvalid)?.length

  const shapedDynamicData = useMemo(
    () =>
      formData.addedURLs.reduce((acc: types.ChannelDataType[], data) => {
        const existingPlatform = acc.find((entry) => entry.platform?.toLowerCase() === data.platform?.toLowerCase())
        if (existingPlatform) {
          existingPlatform.count = (existingPlatform.count || 0) + 1
        } else {
          const platformDetails = PLATFORM_ICONS_GREY.find(
            (iconData) => iconData.title.toLowerCase() === data.platform?.toLowerCase(),
          )
          acc.push({
            ...data,
            count: 1,
            betaTag: betaTag[data.platform?.toLowerCase() as keyof typeof betaTag] || null,
            icon: platformDetails?.icon || null,
            title: platformDetails?.title || data.platform,
          })
        }
        return acc
      }, []),
    [formData.addedURLs],
  )

  const Sorter = (props: types.SorterProps) => {
    const { order } = props

    return order ? (
      <ArrowIcon className={classNames({ 'creators-list__table__sortIcon': order === 'ascend' })} />
    ) : (
      <DefaultSortIcon />
    )
  }

  const rowSelection: TableProps<Unknown>['rowSelection'] = {
    selectedRowKeys,
    renderCell: (_, record) => {
      return (
        <Checkbox
          checked={selectedRowKeys?.includes(record?.channel_url)}
          onClick={() => handleSelectedRows(record?.channel_url)}
        />
      )
    },
    columnTitle: (
      <Checkbox checked={selectedRowKeys.length === filteredData?.length} onClick={handleBulkRowsSelection} />
    ),
  }

  const columns = useMemo(
    () => [
      {
        title: () => (
          <span className='creators-list__table__title'>
            Platform
            <Sorter order={sortStates.columnName === 'platform' ? sortStates.direction : null} />
          </span>
        ),
        dataIndex: 'platform',
        key: 'platform',
        showSorterTooltip: false,
        sorter: true,
        render: (platform: string) => <Platform platform={platform} />,
      },
      {
        title: () => (
          <span className='creators-list__table__title'>
            Creator
            <Sorter order={sortStates.columnName === 'channel_url' ? sortStates.direction : null} />
          </span>
        ),
        dataIndex: 'channel_url',
        key: 'channel_url',
        showSorterTooltip: false,
        sorter: true,
        render: (_: Unknown, data: types.ChannelDataType) => (
          <span className='creator-info-column'>
            <CreatorInfo
              urlData={data}
              setFormData={setFormData}
              handleEditURL={handleEditURL}
              handleDeleteURL={handleDeleteURL}
              handleCreatorInfoLoading={handleCreatorInfoLoading}
            />
          </span>
        ),
      },
    ],
    [sortStates, handleEditURL, handleDeleteURL],
  )

  return (
    <>
      {!!shapedDynamicData?.length ? (
        <div className='add-links'>
          <div className='add-links__label'>You added {formData?.addedURLs?.length} links:</div>
          <div className='add-links__search-input'>
            <Input size='large' placeholder='Search' prefix={<SearchOutlined />} onChange={handleInputChange} />
            <div className='add-links__search-input__applied-platform'>
              {!!formData?.addedURLs?.length && (
                <span
                  className={classNames('add-links__search-input__applied-platform__platform', {
                    active: selectedFilter === 'all',
                  })}
                  onClick={() => handleFilterURLs('all')}>
                  All Platforms
                </span>
              )}
              {shapedDynamicData?.map(
                (item, index) =>
                  item?.platform && (
                    <span
                      className={classNames('add-links__search-input__applied-platform__platform', {
                        active: selectedFilter === item.platform,
                      })}
                      key={`${item.title}${index}center`}
                      onClick={() => handleFilterURLs(item?.platform as string)}>
                      {item.icon}
                      {
                        <span>
                          {/* @ts-ignore */}
                          {item.title} {item?.count > 1 && ':'}
                        </span>
                      }
                      {betaTag[item?.title?.toLowerCase() as keyof typeof betaTag] || null}
                      {/* @ts-ignore */}
                      {item?.count > 1 && <span>{item.count}</span>}
                    </span>
                  ),
              )}
              {!!invalidLinkCount && (
                <span
                  className={classNames('add-links__search-input__applied-platform__invalid-links-counts', {
                    active: selectedFilter === 'invalid',
                  })}
                  onClick={() => handleFilterURLs('invalid')}>
                  <span>
                    <WarningIcon />
                  </span>
                  <span>
                    {invalidLinkCount} invalid Link{invalidLinkCount > 1 ? 's' : ''}
                  </span>
                </span>
              )}
            </div>
          </div>

          <div className='add-links__platform-table'>
            <Table
              columns={columns}
              pagination={false}
              rowKey={'channel_url'}
              dataSource={filteredData}
              onChange={handleTableChange}
              className='creators-list__table'
              rowSelection={activeStep === 2 ? { type: 'checkbox', ...rowSelection } : undefined}
            />
          </div>
        </div>
      ) : (
        <div className='no-data-screen'>
          <div className='no-data-screen__icon'>
            <NoDataIcon />
          </div>
          <div className='no-data-screen__text'>
            <div className='no-data-screen__text__title'>No links have been added yet</div>
            <div className='no-data-screen__text__description'>Add links to get started.</div>
          </div>
        </div>
      )}
    </>
  )
}

export default AddLinks
