import millify from 'millify'
import { store } from 'store'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'

import InfoTooltip from 'components/Asset/BrandsDashboard/components/InfoTooltip/InfoTooltip'

import { ReactComponent as UpArrowIcon } from 'assets/images/icons/monitor/up_arrow_icon.svg'

import { AssetListObjType } from 'store/asset/types'
import { SearchConditions, SnippetFilterPayload } from 'models/models'
import { ConditionsDataType, ConditionsPayloadType, FlagsCountType, NarrativeListDataType, SubStore } from 'types/types'

import { getDaysDifference } from 'services/Util/getDaysDifference'
import { getPercentageDifference } from 'services/Util/getPercentageDifference'

import './NewMentionViewCard.scss'

type PropTypes = {
  measure?: 'impression' | 'engagement' | 'impressions' | null
  selectedItem: AssetListObjType | NarrativeListDataType | undefined
  type: 'Mentions' | 'Views' | 'Engagement' | 'Impressions'
  customGraphColors?: {
    shadeColorUp: string
    lineColorUp: string
    shadeColorDown: string
    lineColorDown: string
    theme?: string
  }
  chartWrapperClassName?: string
  subStore?: SubStore
  tooltipText?: string
}

const NewMentionViewCard = observer((props: PropTypes) => {
  const {
    selectedItem,
    measure = null,
    type,
    customGraphColors = null,
    subStore = 'assets',
    tooltipText = null,
  } = props

  const { fetchInsightsMetrics, getDate, snippetsFilter, getSnippetFilterPayload } = store[`${subStore}Store`]
  const [postsCount, setPostsCount] = useState<FlagsCountType>({
    currentFlagCount: null,
    prevFlagCount: null,
  })
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

  const renderConditions = useMemo(() => {
    if (selectedItem && selectedItem?.conditions && !Array.isArray(selectedItem?.conditions)) {
      return [selectedItem.conditions]
    } else if (selectedItem && selectedItem?.conditions && Array.isArray(selectedItem?.conditions))
      return selectedItem.conditions
    return []
  }, [selectedItem])

  const insightsMetricsHandler = async () => {
    setIsLoadingData(true)
    const payload: SnippetFilterPayload = await getSnippetFilterPayload()
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })

    let prevStartDate = new Date(startDate)
    let prevEndDate = new Date(startDate)
    prevStartDate.setDate(prevStartDate.getDate() - daysDifference)
    prevEndDate.setDate(prevEndDate.getDate() - 1)
    const prevStartDateFormat = prevStartDate.toISOString().split('T')[0]
    const prevEndDateFormat = prevEndDate.toISOString().split('T')[0]

    const dateKey = payload.query.date?.includes('post_uploaded')

    const requestParams: { q: string; measure?: string } = {
      q: `${dateKey ? 'post_uploaded' : 'start_date'}:gte:${startDate},${
        dateKey ? 'post_uploaded' : 'end_date'
      }:lte:${endDate}${payload.query.community}${payload.query.platform}${payload.query.sentiment}${
        payload.query.risk
      }${payload.query.source}`,
    }
    const requestParamsForPrev: { q: string; measure?: string } = {
      q: `${dateKey ? 'post_uploaded' : 'start_date'}:gte:${prevStartDateFormat},${
        dateKey ? 'post_uploaded' : 'end_date'
      }:lte:${prevEndDateFormat}${payload.query.community}${payload.query.platform}${payload.query.sentiment}${
        payload.query.risk
      }${payload.query.source}`,
    }
    if (measure === 'impression') {
      requestParams.measure = 'impression'
      requestParamsForPrev.measure = 'impression'
    }
    if (measure === 'engagement') {
      requestParams.measure = 'engagement'
      requestParamsForPrev.measure = 'engagement'
    }
    if (renderConditions.length) {
      const requestData: ConditionsDataType = {
        conditions: renderConditions as ConditionsPayloadType[],
      }
      if (snippetsFilter.flags && Object.keys(snippetsFilter.flags).length > 0) {
        requestData.conditions.push(snippetsFilter.flags.conditions as SearchConditions)
      }

      const [currentFlag, prevFlag] = await Promise.all([
        fetchInsightsMetrics({ params: requestParams, data: requestData, fullResponse: true }),
        fetchInsightsMetrics({ params: requestParamsForPrev, data: requestData, fullResponse: true }),
      ])
      setPostsCount({
        currentFlagCount: currentFlag === 'error' ? 0 : currentFlag.total_value,
        prevFlagCount: prevFlag === 'error' ? 0 : prevFlag.total_value,
      })
    } else {
      setPostsCount({
        currentFlagCount: null,
        prevFlagCount: null,
      })
    }
    setIsLoadingData(false)
  }

  useEffect(() => {
    if (selectedItem) {
      insightsMetricsHandler()
    }
  }, [renderConditions, snippetsFilter, selectedItem])

  const percentageDifference = useMemo((): number => {
    if (postsCount.currentFlagCount && postsCount.prevFlagCount) {
      return getPercentageDifference(postsCount.currentFlagCount, postsCount.prevFlagCount)
    }
    return 0
  }, [postsCount.currentFlagCount, postsCount.prevFlagCount])

  const comparisonDuration = useMemo(() => {
    const startDate = getDate.startDate
    const endDate = getDate.endDate
    const daysDifference = getDaysDifference({ startDate: new Date(startDate), endDate: new Date(endDate) })
    switch (daysDifference - 1) {
      case 1:
        return 'Yesterday'
      case 7:
        return 'last 7 days'
      case 30:
        return 'last 30 days'
      case 31:
        return 'last 30 days'
      case 90:
        return 'last 90 days'
      case 180:
        return 'last 6 months'
      case 365:
        return 'last 1 year'
      case 730:
        return 'last 2 year'
      default:
        return `last ${daysDifference - 1} days`
    }
  }, [getDate.startDate, getDate.endDate])

  const matrixIcon = {
    impression: (
      <svg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M2.20801 2.74903C4.70737 0.249673 8.75962 0.249673 11.259 2.74903L13.1335 4.62355L15.008 2.74903C17.5074 0.249673 21.5596 0.249673 24.059 2.74903C26.5583 5.24838 26.5583 9.30064 24.059 11.8L13.1335 22.7255L2.20801 11.8C-0.291343 9.30064 -0.291343 5.24838 2.20801 2.74903Z'
          fill='#FDE272'
        />
      </svg>
    ),
    engagement: (
      <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.46924 1.52222C9.24053 0.668678 8.36319 0.162146 7.50964 0.390853C6.6561 0.61956 6.14957 1.4969 6.37827 2.35045L6.79238 3.89593C7.02109 4.74947 7.89843 5.256 8.75198 5.0273C9.60552 4.79859 10.1121 3.92125 9.88335 3.06771L9.46924 1.52222Z'
          fill='#7A5AF8'
        />
        <path
          d='M2.68101 6.04771C1.82747 5.819 0.950127 6.32553 0.72142 7.17908C0.492713 8.03262 0.999245 8.90996 1.85279 9.13867L3.39827 9.55278C4.25182 9.78149 5.12916 9.27495 5.35786 8.42141C5.58657 7.56786 5.08004 6.69052 4.22649 6.46182L2.68101 6.04771Z'
          fill='#7A5AF8'
        />
        <path
          d='M16.7825 5.13826C17.4074 4.51342 17.4074 3.50035 16.7825 2.87552C16.1577 2.25068 15.1446 2.25068 14.5198 2.87552L13.3884 4.00689C12.7636 4.63173 12.7636 5.64479 13.3884 6.26963C14.0133 6.89447 15.0263 6.89447 15.6512 6.26963L16.7825 5.13826Z'
          fill='#7A5AF8'
        />
        <path
          d='M5.46882 16.452L6.60019 15.3206C7.22503 14.6958 7.22503 13.6827 6.60019 13.0579C5.97536 12.433 4.96229 12.433 4.33745 13.0579L3.20608 14.1892C2.58124 14.8141 2.58124 15.8271 3.20608 16.452C3.83092 17.0768 4.84399 17.0768 5.46882 16.452Z'
          fill='#7A5AF8'
        />
        <path
          d='M10.5887 8.17829C9.99444 7.94059 9.31569 8.0799 8.86311 8.53249C8.41053 8.98506 8.27121 9.66381 8.50891 10.2581L14.9089 26.2581C15.1429 26.8431 15.6985 27.2363 16.328 27.2625C16.9575 27.2887 17.5438 26.9429 17.8256 26.3794L20.0328 21.9649L24.8631 26.7952C25.4879 27.4201 26.501 27.4201 27.1258 26.7952C27.7507 26.1704 27.7507 25.1573 27.1258 24.5325L22.2955 19.7022L26.71 17.4949C27.2736 17.2132 27.6193 16.6269 27.5931 15.9974C27.5669 15.3678 27.1737 14.8123 26.5887 14.5783L10.5887 8.17829Z'
          fill='#7A5AF8'
        />
      </svg>
    ),
    impressions: (
      <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19.5882 6.01178C15.8392 2.26274 9.76081 2.26274 6.01178 6.01178C2.26274 9.76081 2.26274 15.8392 6.01178 19.5882C9.27032 22.8468 14.2921 23.2743 18.0111 20.8653C18.7528 20.3849 19.7435 20.5967 20.2239 21.3383C20.7043 22.08 20.4925 23.0706 19.7508 23.5511C14.7911 26.7637 8.09695 26.1989 3.74903 21.851C-1.24968 16.8523 -1.24968 8.74774 3.74903 3.74903C8.74774 -1.24968 16.8523 -1.24968 21.851 3.74903C24.35 6.24805 25.6 9.52672 25.6 12.8C25.6 15.451 23.451 17.6 20.8 17.6C19.7195 17.6 18.7223 17.243 17.9201 16.6404C16.7525 18.1947 14.8936 19.2 12.8 19.2C9.26538 19.2 6.4 16.3346 6.4 12.8C6.4 9.26538 9.26538 6.4 12.8 6.4C16.3346 6.4 19.2 9.26538 19.2 12.8C19.2 13.6837 19.9163 14.4 20.8 14.4C21.6837 14.4 22.4 13.6837 22.4 12.8C22.4 10.3407 21.463 7.88656 19.5882 6.01178ZM16 12.8C16 11.0327 14.5673 9.6 12.8 9.6C11.0327 9.6 9.6 11.0327 9.6 12.8C9.6 14.5673 11.0327 16 12.8 16C14.5673 16 16 14.5673 16 12.8Z'
          fill='#DD2590'
        />
      </svg>
    ),
  }

  if (isLoadingData) {
    return (
      <>
        <span className='monitor-assets__graph__title'>{type}</span>
        <div className='new-mention-view-card'>
          <div className='new-mention-view-card__empty-state'>
            <p className='skeleton-paragraph__small'>
              <Skeleton paragraph active />
            </p>
            <p className='skeleton-paragraph__large'>
              <Skeleton paragraph active />
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='new-mention-view-card__title'>
        <span className='monitor-assets__graph__title'>{type}</span>
        {tooltipText && <InfoTooltip text={tooltipText} width={20} height={20} />}
      </div>

      <div className='new-mention-view-card'>
        <div className='new-mention-view-card__status'>
          <div className='new-mention-view-card__status-stats'>
            {measure && (
              <div className={classNames('new-mention-view-card__status-stats__icon', { [measure]: measure })}>
                {matrixIcon[measure]}
              </div>
            )}
            <div className='new-mention-view-card__status-stats__count'>
              {millify(postsCount?.currentFlagCount || 0)}
            </div>
          </div>
          <div className='new-mention-view-card__status__trend'>
            <div
              className={classNames(
                `new-mention-view-card__status__trend__detail  `,
                {
                  trending: percentageDifference >= 0,
                },
                `${customGraphColors?.theme ? customGraphColors?.theme : ''}`,
              )}>
              <UpArrowIcon />
              <span className='new-mention-view-card__status__trend__detail__percentage'>
                {percentageDifference.toFixed(0)}%
              </span>
            </div>
            <div className='new-mention-view-card__status__trend__duration'>vs {comparisonDuration}</div>
          </div>
        </div>
      </div>
    </>
  )
})

export default NewMentionViewCard
